import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState, useContext } from 'react'
import { Button, ButtonGroup, Card } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Axios from '../../../../plugins/axios';
import { useParams } from 'react-router-dom';
import Conversion from '../../../CommonComponents/Conversion';
import RelevantLiveList from './RelevantLiveList';
import { Context } from '../../../../../MasterLayout';

const RelevantLive = forwardRef((props, ref) => {
    const [onLoad, setOnLoad] = useState(false)
    const { dispatch } = useContext(Context);
    const [relevantLiveFilter, setrelevantLiveFilter] = useState('7_days');
    const dateRangeRef = useRef();
    const dateFilterOption = {
        '7_days': {
            label: 'Past 7 Days',
            begin_date: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0],
            end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
        },
        '15_days': {
            label: 'Past 15 Days',
            begin_date: new Date(new Date().setDate(new Date().getDate() - 15)).toISOString().split('T')[0],
            end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
        },
        '30_days': {
            label: 'Past 30 Days',
            begin_date: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0],
            end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
        }
    };
    const [dateRange, setDateRange] = useState(dateFilterOption[relevantLiveFilter]);

    useImperativeHandle(ref, () => ({
        async relevantLive() {
            if (onLoad == false) {
                // relevantVideos(page);
            }
            setOnLoad(true);
        }
    }));

    const dateFilterOptionHtml = Object.keys(dateFilterOption).map(function (key) {
        return <Button key={key} variant="outline-dark" size="xs" className={(relevantLiveFilter == key ? 'btn-primary' : '')} onClick={() => { setrelevantLiveFilter(key); }}>{dateFilterOption[key].label}</Button>
    });
    const handleApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD')
        );
        setDateRange({ begin_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') })
        setrelevantLiveFilter('custom');
    };
    const relevantLiveProductRef = useRef();

    const handleCancel = (event, picker) => {
        picker.element.val('');
        setDateRange([]);
    };
    const params = useParams();
    const changeDate = (startDate, endDate) => {
        dateRangeRef.current.setStartDate(moment(new Date(startDate)).format('MM/DD/YYYY'));
        dateRangeRef.current.setEndDate(moment(new Date(endDate)).format('MM/DD/YYYY'));
    };
    const [relevantLive, setrelevantLive] = useState();
    const [relevantLivelist, setrelevantLivelist] = useState([]);

    const relevantLiveApi = async () => {
        try {
            let bodyParams = {};
            if (relevantLiveFilter == 'custom') {
                bodyParams = {
                    begin_date: dateRange.begin_date,
                    end_date: dateRange.end_date,
                };
            } else {
                bodyParams = dateFilterOption[relevantLiveFilter];
                setDateRange(bodyParams);
            }
            bodyParams.author_id = params.influencerId;

            changeDate(bodyParams.begin_date, bodyParams.end_date);

            let response = await Axios.post('/api/influencer-relevant-lives', bodyParams);
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });

            if (response && response.status === 200) {
                setrelevantLive(response.data.data);
                let docs = [];
                if (response?.data?.data?.live_list?.length) {
                    for (let i = 0; i < 4; i++) {
                        if (response?.data?.data?.live_list[i]) {
                            docs.push(response?.data?.data?.live_list[i])
                        } else { break }
                    };
                    setrelevantLivelist(docs);
                }


                await relevantLiveProductRef.current.relevantLiveProduct(response.data.data);
            }
        } catch (error) {
            console.error('Error: ' + error.message);
        };
    }
    useEffect(() => {
        relevantLiveApi();
    }, [relevantLiveFilter]);

    const handlePageClick = (event) => {
        const startIndex = ((event.selected + 1) - 1) * 4;
        const endIndex = Math.min(startIndex + 4, relevantLive?.live_list?.length);
        const docs = [];
        for (let i = startIndex; i < endIndex; i++) {
            if (relevantLive?.live_list[i]) {
                docs.push(relevantLive?.live_list[i])
            } else { break; }
        };
        setrelevantLivelist(docs)
    };

    return (
        <div>
            <Card>
                <Card.Body>
                    <div className="row mb-4">
                        <div className="col-lg-5">
                            <h3>Relevant Live</h3>
                        </div>
                        <div className="col-lg-7 text-right">
                            <div className='d-flex justify-content-end align-items-center'>
                                <ButtonGroup className='mr-4'>
                                    {dateFilterOptionHtml}
                                </ButtonGroup>
                                <DateRangePicker
                                    ref={dateRangeRef}
                                    initialSettings={{ startDate: moment(new Date(dateRange.begin_date)).format('MM/DD/YYYY'), endDate: moment(new Date(dateRange.end_date)).format('MM/DD/YYYY') }}
                                    onApply={handleApply} onCancel={handleCancel} /*onCallback={handleCallback}*/
                                >
                                    <input type="text" placeholder={'First Seen'} className="form-control form-control-sm input-daterange-timepicker w-auto" value={dateRange.begin_date + " - " + dateRange.end_date} />
                                </DateRangePicker>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex mb-4'>
                        <Card className="bg-light mr-3">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>Sales</strong></p>
                                    <h3 className='mb-0'>{relevantLive?.sold_count ? <Conversion value={relevantLive?.sold_count} /> : '0'}</h3>
                                    <p className='d-flex'>To the Previous Cycle &nbsp;
                                        <p
                                            className={
                                                relevantLive?.sold_count_incr > 0 ? 'text-success mb-0' :
                                                    relevantLive?.sold_count_incr < 0 ? 'text-danger mb-0' :
                                                        'text-muted mb-0'
                                            }
                                        >
                                            {(((relevantLive?.sold_count_incr / 1000).toFixed(1)) > 0 && '+')}  {relevantLive?.sold_count_incr ? Math.abs(relevantLive?.sold_count_incr) >= 1000 ?
                                                ((relevantLive?.sold_count_incr / 1000).toFixed(1)) + 'k' :
                                                <Conversion value={relevantLive?.sold_count_incr} /> : 'Not enough lives'}
                                        </p>
                                    </p>

                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-light mr-3">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>Revenue</strong></p>
                                    <div className='d-flex'>
                                        <h3 className='mb-0'>{relevantLive?.total_sales_usd_str}</h3>
                                        <p className='mb-0'>({relevantLive?.total_sales_str})</p>
                                    </div>
                                    <div className='d-flex'>To the Previous Cycle
                                        &nbsp;
                                        <p className={`mb-0 ${relevantLive?.total_sales_cycle_crc > 0 ? 'text-success' :
                                            relevantLive?.total_sales_cycle_crc < 0 ? 'text-danger' :
                                                'text-muted'
                                            }`}
                                        >
                                            {((relevantLive?.total_sales_cycle_crc * 100).toFixed(2) > 0 && '+')}
                                            {relevantLive?.total_sales_cycle_crc ? `${(relevantLive?.total_sales_cycle_crc * 100).toFixed(2)}%` : 'Not enough lives'}
                                        </p>
                                    </div>

                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card className="bg-light mr-3">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>Sales volume explosion</strong></p>
                                    <h3>{relevantLive?.sold_count_cycle_crc ? `${(relevantLive?.sold_count_cycle_crc * 100).toFixed(2) || '-'}%` : '--'}</h3>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-light mr-3">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>Sales Rate</strong></p>
                                    <h3>{relevantLive?.conversion_rate ? `${(relevantLive?.conversion_rate * 100).toFixed(2) || '0'}%` : '--'}</h3>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <RelevantLiveList relevantLivelist={relevantLivelist} totalCount={relevantLive?.live_list?.length} handlePageClick={handlePageClick} />
                </Card.Body>
            </Card>
        </div>
    )
})

export default RelevantLive