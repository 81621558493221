import axios from "axios";
import CryptoJS from "crypto-js";

const timestamp = new Date().getTime();
const generateSign = async () => {
    const hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, process.env.REACT_APP_SIGN_SECRET);
    hmac.update(JSON.parse(localStorage.getItem('user'))._id + Math.ceil(timestamp / 900000));
    return hmac.finalize().toString();
};

const Axios = axios.create({
    baseURL: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:5151",
    headers: { 'Request-Origin': process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:5151" }
});

// Request interceptor for API calls
Axios.interceptors.request.use(
    async config => {
        if (localStorage.getItem('token')) {
            config.headers['authorization'] = localStorage.getItem('token');
            config.headers['cookies'] = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).email : '';
            config.headers['userid'] = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))._id : '';
            config.headers['x-forwarded-for'] = localStorage.getItem('ip_address') ? localStorage.getItem('ip_address') : '';
            config.headers['sign'] = localStorage.getItem('user') ? await generateSign() : '';
            config.headers['timestamp'] = timestamp;
        };
        return config;
    },
    error => {
        return Promise.reject(error);
    });

// Response interceptor for API calls
Axios.interceptors.response.use((response) => {
    if (response.status == 200 && response.data.statusCode == 400 && response.data.data == null) {
        window.location.href = '/dashboard';
    }
    return response
}, async function (error) {
    if (error.response.status === 401 || error.response.status === 500) {
        localStorage.clear();
        window.location.href = '/login';
        return Promise.reject(error);
    } else if (error.response.status === 405) {
        window.location.href = '/profile?tab=subscription';
        return Promise.reject(error);
    } else {
        return Promise.reject(error);
    };
});

export default Axios;