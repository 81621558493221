import React, { useState, useEffect, useContext } from 'react'
import { Button, Card, Form } from 'react-bootstrap';
import Axios from '../plugins/axios';
import { Context } from '../../MasterLayout';
import { useTranslation } from 'react-i18next';


function PriceCalculator() {
  const { t } = useTranslation();
  const { dispatch } = useContext(Context);

  const currencyArray = [
    { label: 'United States( $ )', value: 'USA', logo: '$' },
    { label: 'United Kingdom( ￡ )', value: 'GB', logo: '￡' },
    { label: 'Indonesia( Rp )', value: 'ID', logo: 'Rp' },
    { label: 'Thailand( ฿ )', value: 'TH', logo: '฿' },
    { label: 'Viet Nam( ₫ )', value: 'VN', logo: '₫' },
    { label: 'Malaysia( MYR )', value: 'MY', logo: 'MYR' },
    { label: 'Philippines( ₱ )', value: 'PH', logo: '₱' },
  ];

  const [currency, setCurrency] = useState({ label: 'United States( $ )', value: 'USA', logo: '$' });
  const [country, setCountry] = useState({ label: 'United States( $ )', value: 'USA', logo: '$' });
  const [amount, setAmount] = useState({
    sellingPrice: '',
    costPrice: '',
    expressOrLogisticsFee: '',
    buyerShippingFee: '',
    packingFee: '',
    influencerCommissionRate: '',
    platformCommissionRate: '',
    VATRate: '',
    PayPalHandlingFeeRate: '',
    returnRate: ''
  });

  const [calculatedPrice, setCalculatedPrice] = useState({
    sellingPrice: 0,
    costPrice: 0,
    buyerShippingFee: 0,
    packingFee: 0,
    expressOrLogisticsFee: 0,
    platformCommission: 0,
    PayPalHandlingFee: 0,
    totalExpenditure: 0,
    VAT: 0,
    commodityIncome: 0,
    returnDeduction: 0,
    grossProfit: 0,
    grossProfitMargin: 0,
    ROI: 0,
  });

  const [error, setError] = useState({ sellingPrice: false, costPrice: false, expressOrLogisticsFee: false });
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    (async function () {
      let response = await Axios.get('/api/check-subscription');
      dispatch({ type: 'subscription_expired', response: response.data.statusCode });
    })();
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;

    setAmount((v) => ({ ...v, [id]: parseFloat(value) }));
    setError((v) => ({ ...v, [id]: value === '' ? true : false }));
  };

  function validation() {
    let isValid = true;

    if (amount.sellingPrice === '') {
      isValid = false;
      setError((v) => ({ ...v, sellingPrice: true }));
    };

    if (amount.costPrice === '') {
      isValid = false;
      setError((v) => ({ ...v, costPrice: true }));
    };

    if (amount.expressOrLogisticsFee === '') {
      isValid = false;
      setError((v) => ({ ...v, expressOrLogisticsFee: true }));
    };

    if (isValid) {
      if (amount.sellingPrice && amount.sellingPrice < 0) setAmount((v) => ({ ...v, sellingPrice: 0 }));
      if (amount.costPrice && amount.costPrice < 0) setAmount((v) => ({ ...v, costPrice: 0 }));
      if (amount.expressOrLogisticsFee && amount.expressOrLogisticsFee < 0) setAmount((v) => ({ ...v, expressOrLogisticsFee: 0 }));
      if (amount.buyerShippingFee && amount.buyerShippingFee < 0) setAmount((v) => ({ ...v, buyerShippingFee: 0 }));
      if (amount.packingFee && amount.packingFee < 0) setAmount((v) => ({ ...v, packingFee: 0 }));

      if (amount.influencerCommissionRate) {
        if (amount.influencerCommissionRate < 0) {
          setAmount((v) => ({ ...v, influencerCommissionRate: 0 }));
        } else if (amount.influencerCommissionRate > 100) {
          setAmount((v) => ({ ...v, influencerCommissionRate: 100 }));
        };
      };
      if (amount.platformCommissionRate) {
        if (amount.platformCommissionRate < 0) {
          setAmount((v) => ({ ...v, platformCommissionRate: 0 }));
        } else if (amount.platformCommissionRate > 100) {
          setAmount((v) => ({ ...v, platformCommissionRate: 100 }));
        };
      };
      if (amount.VATRate) {
        if (amount.VATRate < 0) {
          setAmount((v) => ({ ...v, VATRate: 0 }));
        } else if (amount.VATRate > 100) {
          setAmount((v) => ({ ...v, VATRate: 100 }));
        };
      };
      if (amount.PayPalHandlingFeeRate) {
        if (amount.PayPalHandlingFeeRate < 0) {
          setAmount((v) => ({ ...v, PayPalHandlingFeeRate: 0 }));
        } else if (amount.PayPalHandlingFeeRate > 100) {
          setAmount((v) => ({ ...v, PayPalHandlingFeeRate: 100 }));
        };
      };
      if (amount.returnRate) {
        if (amount.returnRate < 0) {
          setAmount((v) => ({ ...v, returnRate: 0 }));
        } else if (amount.returnRate > 100) {
          setAmount((v) => ({ ...v, returnRate: 100 }));
        };
      };
    };

    return isValid;
  };

  const handleReset = () => {
    setAmount({
      sellingPrice: '',
      costPrice: '',
      expressOrLogisticsFee: '',
      buyerShippingFee: '',
      packingFee: '',
      influencerCommissionRate: '',
      platformCommissionRate: '',
      VATRate: '',
      PayPalHandlingFeeRate: '',
      returnRate: '',
      totalExpenditure: ''
    });

    setError({ sellingPrice: false, costPrice: false, expressOrLogisticsFee: false, });
    setShowDetails(false);
  };

  const handleCalculations = () => {
    const validated = validation();

    if (validated) {
      const { sellingPrice, costPrice, expressOrLogisticsFee, buyerShippingFee, packingFee, influencerCommissionRate, platformCommissionRate, VATRate, PayPalHandlingFeeRate, returnRate } = amount;

      const calculatedPriceData = { ...calculatedPrice };

      // Spend Details
      const finalSellingPrice = (sellingPrice + (buyerShippingFee || 0));

      calculatedPriceData.sellingPrice = sellingPrice;
      calculatedPriceData.costPrice = costPrice;
      calculatedPriceData.buyerShippingFee = buyerShippingFee || 0;
      calculatedPriceData.packingFee = packingFee || 0;
      calculatedPriceData.expressOrLogisticsFee = expressOrLogisticsFee;

      calculatedPriceData.influencerCommission = ((sellingPrice / 100) * (influencerCommissionRate || 0));

      calculatedPriceData.VAT = (finalSellingPrice - ((finalSellingPrice * 100) / ((VATRate || 0) + 100)));
      calculatedPriceData.VAT = (calculatedPriceData.VAT - ((calculatedPriceData.VAT * (returnRate || 0)) / 100));

      calculatedPriceData.platformCommission = (((finalSellingPrice - (calculatedPriceData.VAT || 0)) * (platformCommissionRate || 0)) / 100);

      calculatedPriceData.PayPalHandlingFee = ((finalSellingPrice * (PayPalHandlingFeeRate || 0)) / 100);
      calculatedPriceData.totalExpenditure = (expressOrLogisticsFee + (packingFee || 0) + calculatedPriceData.influencerCommission + calculatedPriceData.platformCommission + calculatedPriceData.VAT + calculatedPriceData.PayPalHandlingFee);


      // Income Details
      calculatedPriceData.commodityIncome = (finalSellingPrice - calculatedPriceData.totalExpenditure);
      calculatedPriceData.returnDeduction = ((finalSellingPrice * (returnRate || 0)) / 100);
      calculatedPriceData.grossProfit = (finalSellingPrice - (costPrice + calculatedPriceData.totalExpenditure));


      calculatedPriceData.grossProfitMargin = ((calculatedPriceData.grossProfit / calculatedPriceData.commodityIncome) * 100);
      calculatedPriceData.ROI = ((calculatedPriceData.grossProfit / (costPrice + calculatedPriceData.totalExpenditure)) * 100);

      setCountry(currency);
      setCalculatedPrice({ ...calculatedPriceData });
      setShowDetails(true);
    };
  };

  return (
    <Card>
      <Card.Body>
        <div className='row'>
          <div className='col-lg-12 mb-4 text-center'>
            <h1 className='mb-0'>{t("price_calculator.long_text_1")}</h1>
            <p>{t("price_calculator.long_text_2")}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='form-group row'>
              <label className='mb-1 col-lg-6 small text-right'>{t("price_calculator.Country_or_Region")}</label>
              <div className='col-lg-6'>
                <Form.Select className="form-control form-control-sm" onChange={(e) => setCurrency(currencyArray[e.target.value])}>
                  {currencyArray.map((v, i) => { return <option key={i} value={i}>{v.label}</option> })}
                </Form.Select>
              </div>
            </div>
            <div className='form-group row'>
              <label className='mb-1 col-lg-6 small text-right' htmlFor="sellingPrice" >{t("price_calculator.selling_price")}<span className='ml-1 text-danger'>*</span></label>
              <div className='col-lg-6'>
                <input className='form-control form-control-sm' value={amount.sellingPrice} type="number" id='sellingPrice' onChange={(e) => handleChange(e)} />
                {error.sellingPrice ? <p className='text-danger mb-0'>{t("price_calculator.please_enter_selling_price")}</p> : ''}
              </div>
            </div>
            <div className='form-group row'>
              <label className='mb-1 col-lg-6 small text-right'>{t("price_calculator.cost_price")}<span className='ml-1 text-danger'>*</span></label>
              <div className='col-lg-6'>
                <input className='form-control form-control-sm' value={amount.costPrice} type="number" id='costPrice' onChange={(e) => handleChange(e)} />
                {error.costPrice ? <p className='text-danger mb-0'>{t("price_calculator.long_text_3")}</p> : ''}
              </div>
            </div>
            <div className='form-group row'>
              <label className='mb-1 col-lg-6 small text-right'>{t("price_calculator.Express_or_logistics_fee")}<span className='ml-1 text-danger'>*</span></label>
              <div className='col-lg-6'>
                <input className='form-control form-control-sm' value={amount.expressOrLogisticsFee} type="number" id='expressOrLogisticsFee' onChange={(e) => handleChange(e)} />
                {error.expressOrLogisticsFee ? <p className='text-danger mb-0'>{t("price_calculator.long_text_4")}</p> : ''}
              </div>
            </div>
            <div className='form-group row'>
              <label className='mb-1 col-lg-6 small text-right'>{t("price_calculator.buyer_shipping_fee")}</label>
              <div className='col-lg-6'>
                <input className='form-control form-control-sm' value={amount.buyerShippingFee} type="number" id='buyerShippingFee' onChange={(e) => handleChange(e)} />
              </div>
            </div>
            <div className='form-group row'>
              <label className='mb-1 col-lg-6 small text-right'>{t("price_calculator.packing_fee")}</label>
              <div className='col-lg-6'>
                <input className='form-control form-control-sm' value={amount.packingFee} type="number" id='packingFee' onChange={(e) => handleChange(e)} />
              </div>
            </div>
            <div className='form-group row'>
              <label className='mb-1 col-lg-6 small text-right'>{t("price_calculator.influencer_commission_rate")}</label>
              <div className='col-lg-6'>
                <input className='form-control form-control-sm' value={amount.influencerCommissionRate} type="number" id='influencerCommissionRate' onChange={(e) => handleChange(e)} />
              </div>
            </div>
            <div className='form-group row'>
              <label className='mb-1 col-lg-6 small text-right'>{t("price_calculator.platform_commission_rate")}</label>
              <div className='col-lg-6'>
                <input className='form-control form-control-sm' value={amount.platformCommissionRate} type="number" id='platformCommissionRate' onChange={(e) => handleChange(e)} />
              </div>
            </div>
            <div className='form-group row'>
              <label className='mb-1 col-lg-6 small text-right'>{t("price_calculator.VAT_rate")}</label>
              <div className='col-lg-6'>
                <input className='form-control form-control-sm' value={amount.VATRate} type="number" id='VATRate' onChange={(e) => handleChange(e)} />
              </div>
            </div>
            <div className='form-group row'>
              <label className='mb-1 col-lg-6 small text-right'>{t("price_calculator.payPal_handling_fee_rate")}</label>
              <div className='col-lg-6'>
                <input className='form-control form-control-sm' value={amount.PayPalHandlingFeeRate} type="number" id='PayPalHandlingFeeRate' onChange={(e) => handleChange(e)} />
              </div>
            </div>
            <div className='form-group row'>
              <label className='mb-1 col-lg-6 small text-right'>{t("price_calculator.return_rate")}</label>
              <div className='col-lg-6'>
                <input className='form-control form-control-sm' value={amount.returnRate} type="number" id='returnRate' onChange={(e) => handleChange(e)} />
              </div>
            </div>
            <div className='form-group text-right row'>
              <div className='col-lg-12'>
                <Button variant='primary' size="sm" onClick={() => handleCalculations()}>{t("price_calculator.calculate")}</Button>
                <Button variant='outline-danger' className='ml-2' size="sm" onClick={() => handleReset()}>{t("price_calculator.reset")}</Button>
              </div>
            </div>
          </div>
          <div className='col-lg-8'>
            {showDetails === true ? <div className=''>
              <Card className="bg-light">
                <Card.Header className='border-0'>
                  <Card.Title>{t("price_calculator.spend_details")}</Card.Title>
                </Card.Header>
                <Card.Body className='pt-0'>
                  <div className='row'>
                    <div className='col-lg-4 mb-2'>
                      <p className="mb-0">{t("price_calculator.Country_or_Region")}</p>
                      <h4>{country.label}</h4>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <p className="mb-0">{t("price_calculator.selling_price_2")} ( {country.logo} )</p>
                      <h4>{(calculatedPrice.sellingPrice || 0).toFixed(2)}</h4>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <p className="mb-0">{t("price_calculator.Cost_Price2")} ( {country.logo} )</p>
                      <h4>{(calculatedPrice.costPrice || 0).toFixed(2)}</h4>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <p className="mb-0">{t("price_calculator.express_logistics_fee")} ( {country.logo} )</p>
                      <h4>{(calculatedPrice.expressOrLogisticsFee || 0).toFixed(2)}</h4>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <p className="mb-0">{t("price_calculator.buyer_shipping_fee_2")} ( {country.logo} )</p>
                      <h4>{(calculatedPrice.buyerShippingFee || 0).toFixed(2)}</h4>
                    </div>
                    <div className='col-lg-4 mb-0'>
                      <p className="mb-0">{t("price_calculator.packing_fee_2")} ( {country.logo} )</p>
                      <h4>{(calculatedPrice.packingFee || 0).toFixed(2)}</h4>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <p className="mb-0">{t("price_calculator.influencer_commission_2")} ( {country.logo} )</p>
                      <h4>{(calculatedPrice.influencerCommission || 0).toFixed(2)}</h4>
                    </div>
                    <div className='col-lg-4 mb-0'>
                      <p className="mb-0">{t("price_calculator.platform_commission")} ( {country.logo} )</p>
                      <h4>{(calculatedPrice.platformCommission || 0).toFixed(2)}</h4>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <p className="mb-0">{t("price_calculator.VAT_2")} ( {country.logo} )</p>
                      <h4>{(calculatedPrice.VAT || 0).toFixed(2)}</h4>
                    </div>
                    <div className='col-lg-4 mb-0'>
                      <p className="mb-0">{t("price_calculator.payPal_handling_fee")} ( {country.logo} )</p>
                      <h4>{(calculatedPrice.PayPalHandlingFee || 0).toFixed(2)}</h4>
                    </div>
                    <div className='col-lg-4 mb-2'>
                      <p className="mb-0">{t("price_calculator.total_expenditure")} ( {country.logo} )</p>
                      <h4>{(calculatedPrice.totalExpenditure || 0).toFixed(2)}</h4>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Card className="bg-light">
                <Card.Header className='border-0'>
                  <Card.Title>{t("price_calculator.income_details")}</Card.Title>
                </Card.Header>
                <Card.Body className='pt-0'>
                  <div className='row'>
                    <div className='col-lg-4 mb-0'>
                      <p className="mb-0">{t("price_calculator.commodity_income")} ( {country.logo} )</p>
                      <h4>{(calculatedPrice.commodityIncome || 0).toFixed(2)}</h4>
                    </div>
                    <div className='col-lg-4 mb-0'>
                      <p className="mb-0">{t("price_calculator.return_deduction")} ( {country.logo} )</p>
                      <h4>{(calculatedPrice.returnDeduction || 0).toFixed(2)}</h4>
                    </div>
                    <div className='col-lg-4 mb-0'>
                      <p className="mb-0">{t("price_calculator.gross_profit")} ( {country.logo} )</p>
                      <h4>{calculatedPrice.sellingPrice ? (calculatedPrice.grossProfit || 0).toFixed(2) : '-'}</h4>
                    </div>
                    <div className='col-lg-4 mb-0'>
                      <p className="mb-0">{t("price_calculator.gross_profit_margin")}</p>
                      <h4>{calculatedPrice.sellingPrice ? (calculatedPrice.grossProfitMargin || 0).toFixed(2) : '-'}</h4>
                    </div>
                    <div className='col-lg-4 mb-0'>
                      <p className="mb-0">{t("price_calculator.ROI")}</p>
                      <h4>{calculatedPrice.sellingPrice ? (calculatedPrice.ROI || 0).toFixed(2) : '-'}</h4>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
              :
              <p className='d-flex align-items-center' style={{ position: "relative", top: "15rem", left: '17rem' }}>
                {t("price_calculator.wait_to_calculate")}
              </p>
            }
          </div>
        </div>
      </Card.Body>
    </Card>

  )
}

export default PriceCalculator