import React, { Fragment } from "react";
//import InfiniteScroll from "react-infinite-scroll-component";

import InfiniteScroll from "react-infinite-scroll-component";

/** DOCS { https://github.com/ankeetmaini/react-infinite-scroll-component#readme } */

function InfiniteScrollWrapper({ isInfiniteScrollOn, lengthData, functionNext, hasMore, fetchInfo, children }) {

  //  const data = React.useMemo(() => items, [items]);

  //if (!isInfiniteScrollOn) return <>{children}</>;
  if (!isInfiniteScrollOn) return <>{children}</>;

  return (
    <div>
      <InfiniteScroll
        dataLength={lengthData}
        next={functionNext}
        hasMore={hasMore}
        loader={
          <div className="text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        }
        endMessage={
          <p className="mb-0 text-center">
            <b>All contents are loaded</b>
          </p>
        }
        scrollThreshold={0.95}
      >
        {children} {/** !!! DO NOT TOUCH !!! => It wraps the table */}
      </InfiniteScroll>
    </div>
  );
}

export default InfiniteScrollWrapper;