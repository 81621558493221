import Axios from "../../../plugins/axios";
import { React, useState, useEffect, useRef, useContext } from 'react';
import { ButtonGroup, Button, Card, Table, Popover, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Bar } from "react-chartjs-2";
import { Link, useParams } from 'react-router-dom';
import ConvertMillisecond from "../ConvertMillisecond";
import moment from 'moment';
import SalesProductMatrix from "./SalesAnalysisComp/SalesProductMatrix";
import VideoMatrix from "./SalesAnalysisComp/VideoMatrix";
import Conversion from "../../CommonComponents/Conversion";
import { TikTokEmbed } from 'react-social-media-embed';
import { Context } from '../../../../MasterLayout';
import { useTranslation } from 'react-i18next';


const options = {
    plugins: {
        tooltips: {
            enabled: false,
        },
        legend: {
            display: false,
            labels: {
                usePointStyle: false,
            },
        },
    },
    maintainAspectRatio: false,

    scales: {
        yAxes: [
            {
                display: false,
                ticks: {
                    beginAtZero: true,
                    min: 0,
                },
            },
        ],
        xAxes: [
            {
                display: false,
                categoryPercentage: 1.0,
                barPercentage: 1,
                gridLines: {
                    display: false,
                    drawBorder: false,
                },
            },
        ],
    },
};

const TopSalesFilter = (product) => {
    const { t } = useTranslation();

    const params = useParams();
    const salesProductRef = useRef();
    const videoTrendRef = useRef();
    const { dispatch } = useContext(Context);
    const [salesAnalysisFilter, setSalesAnalysisFilter] = useState('7_days');
    const [cycle, setCycle] = useState('H24');
    const [productSalesTrend, setProductSalesTrend] = useState({});
    const [salesAnalysis, setSalesAnalysis] = useState({});
    const [videoPromotionTrend, setVideoPromotionTrend] = useState({});
    const [liveStreamingList, setLiveStreamingList] = useState([]);
    const [sorting, setSorting] = useState({ sort: 58, sort_type: 'DESC' });
    const [hotInfluencers, setHotInfluencers] = useState([]);
    const [videoListWithGoods, setVideoListWithGoods] = useState([]);
    const [favouriteGoods, setFavouriteGoods] = useState([]);
    const [favouriteInfluencers, setFavouriteInfluencers] = useState([]);
    const [onlyOneTimeDataFetch, setOnlyOneTimeDataFetch] = useState(false);
    const sortType = sorting.sort_type == 'DESC' ? 'ASC' : 'DESC';
    const sortClass = sorting.sort_type == 'DESC' ? 'sorting-desc' : 'sorting-asc';
    const [dateFilterOption] = useState({
        '7_days': {
            label: 'Past 7 Days',
            start_date: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0],
            end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
        },
        '15_days': {
            label: 'Past 15 Days',
            start_date: new Date(new Date().setDate(new Date().getDate() - 15)).toISOString().split('T')[0],
            end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
        },
        '30_days': {
            label: 'Past 30 Days',
            start_date: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0],
            end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
        }
    });
    const [dateRange, setDateRange] = useState(dateFilterOption[salesAnalysisFilter]);
    const [contentModal, setContentModal] = useState(false);
    const [tikTokEmbedID, setTikTokEmbedID] = useState('');
    const cycleList = {
        'H24': '1 Day',
        'D3': '3 Days',
        'W1': 'Weekly',
    };
    useEffect(() => {
        //do operation on state change
        if (onlyOneTimeDataFetch == false) {
            hotInfluencersApi();
            liveStreamingListApi(sorting);
            getVersion(cycle);
            setOnlyOneTimeDataFetch(true);
        }
        salesAnalysisApi();
        videoPromotionTrendApi();
    }, [dateRange]);

    const getVersion = async (cycle) => {
        let response = await Axios.get('/api/version', { params: { cycle: cycle, rank_type: 'PRODUCT_VIDEO_RISING_RANK', size: 8 } });
        if (response && response.status === 200) {
            response = await Axios.post('/api/product-video-breakout', {
                product_id: params.productId,
                version: response.data.data[0].version,
                sort: 27,
                size: 8,
                cursor: 0,
                sort_type: 'DESC',
                cycle: cycle,
            }).catch(e => console.error('Axios-Error: ' + e.message));
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });
            if (response && response.status === 200) {
                let array = await response.data.favorite ? response.data.favorite.map((value, index) => value.video_id) : favouriteGoods;

                setFavouriteGoods(array);
                setVideoListWithGoods(response.data.data);
            }
        }
    };

    const markFavouriteGoods = async (data) => {
        await Axios.post('/api/favorite-product-video', data);
        const index = favouriteGoods.indexOf(data.id);
        if (index > -1) {
            favouriteGoods.splice(index, 1);
        } else {
            favouriteGoods.push(data.id);
        }

        setFavouriteGoods(favouriteGoods.concat([]));
    };

    const hotInfluencersApi = async () => {
        try {
            let response = await Axios.post('/api/product/author/rank', { product_id: params.productId });
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });

            if (response && response.status === 200) {
                let array = await response.data.favorite ? response.data.favorite.map((value, index) => value.influencer_id) : favouriteGoods;

                setFavouriteInfluencers(array);
                setHotInfluencers(response.data.data);
            }
        } catch (error) {
            console.error('Error: ' + error.message);
        };
    }

    const markFavouriteInfluencers = async (data) => {
        await Axios.post('/api/favorite-influencer', data);
        const index = favouriteInfluencers.indexOf(data.influencer_id);
        if (index > -1) {
            favouriteInfluencers.splice(index, 1);
        } else {
            favouriteInfluencers.push(data.influencer_id);
        }

        setFavouriteInfluencers(favouriteInfluencers.concat([]));
    };

    const salesAnalysisApi = async () => {
        try {
            let response = await Axios.post('/api/product/sales-analysis', {
                start_date: dateRange.start_date,
                end_date: dateRange.end_date,
                product_id: params.productId
            });
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });

            if (response && response.status === 200) {
                if (response.data.data && response.data.data.sort_by_date) {
                    var labels = [], data = [
                        {
                            label: "Sales",
                            data: [],
                            borderColor: "rgba(210, 47, 110, 1)",
                            backgroundColor: "#2f7efe",
                        },
                        {
                            label: "Revenue",
                            data: [],
                            borderColor: "#2bc155",
                            backgroundColor: "#5c3799",
                        },
                    ];
                    response.data.data.sort_by_date.map(function (value, index) {
                        labels[index] = value.statistical_day;
                        data[0]['data'][index] = value.daily_sales_count;
                        data[1]['data'][index] = value.daily_sales_amount_usd_num;
                    });
                    data[0]['data'] = data[0]['data'].reverse();
                    data[1]['data'] = data[1]['data'].reverse();
                    setProductSalesTrend({
                        labels: labels.reverse(),
                        datasets: data,
                    });
                }

                setSalesAnalysis(response.data.data);
                await salesProductRef.current.salesProduct(response.data.data);
            }
        } catch (error) {
            console.error('Error: ' + error.message);
        };
    }

    const videoPromotionTrendApi = async () => {
        try {
            let response = await Axios.post('/api/product/sales-trend', {
                start_date: dateRange.start_date,
                end_date: dateRange.end_date,
                product_id: params.productId
            });
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });

            if (response && response.status === 200) {
                if (response.data.data && response.data.data.trends) {
                    var labels = [], data = [
                        {
                            label: "Number of Videos",
                            data: [],
                            borderColor: "rgba(210, 47, 110, 1)",
                            backgroundColor: "#2f7efe",
                        },
                        {
                            label: "Number of live broadcasts",
                            data: [],
                            borderColor: "#2bc155",
                            backgroundColor: "#5c3799",
                        },
                    ];
                    response.data.data.trends.map(function (value, index) {
                        labels[index] = value.date;
                        data[0]['data'][index] = value.video_count;
                        data[1]['data'][index] = value.live_count;
                    });
                    data[0]['data'] = data[0]['data'].reverse();
                    data[1]['data'] = data[1]['data'].reverse();                    
                }
                setVideoPromotionTrend(response.data.data);
                await videoTrendRef.current.videoTrand(response.data.data);
            }
        } catch (error) {
            console.error('Error: ' + error.message);
        };
    }

    const tableHead = [
        {
            title: '#',
            className: 'text-right',
        },
        {
            title: t('product_research.product_details.live_intro'),
        },
        {
            title: t('common.revenue'),
            className: 'text-right sorting',
            sort: 52,
        },
        {
            title: t('common.sales'),
            className: 'text-right sorting',
            sort: 51,
        },
        {
            title: t('product_research.product_details.sales_conversion_rate'),
            className: 'text-right sorting',
            sort: 58,
        },
        {
            title: t('product_research.product_details.coumulative_br_viewers'),
            className: 'text-right sorting',
            sort: 50,
        },
        {
            title: t('product_research.product_details.online_br_viewers_peak'),
            className: 'text-right sorting',
            sort: 56,
        },
        {
            title: t('product_research.product_details.average_viewers'),
            className: 'text-right sorting',
            sort: 57,
        },
        {
            title: t('product_research.product_details.listed_products'),
            className: 'text-right sorting',
            sort: 53,
        },
    ];
    const liveStreamingListApi = async (sorting) => {
        try {
            let bodyParams = {
                cursor: 0,
                size: 8,
                product_id: params.productId,
                sort: sorting.sort,
                sort_type: sorting.sort_type
            };

            let response = await Axios.post('/api/relevant-lives', bodyParams);
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });

            if (response && response.status === 200) {
                setLiveStreamingList(response.data.data);
            }
        } catch (error) {
            console.error('Error: ' + error.message);
        };
    }
    const handleSort = async (sort, sortType) => {
        if (sort != sorting.sort || (sortType && sortType != sorting.sort_type)) {
            setSorting({ sort, sort_type: sortType });
            liveStreamingListApi({ sort, sort_type: sortType });
        }
    };

    const handleApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD')
        );
        setDateRange({ start_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') })
        setSalesAnalysisFilter('custom');
    };

    const dateFilterOptionHtml = Object.keys(dateFilterOption).map(function (key) {
        return <Button key={key} variant="outline-dark" size="xs" className={(salesAnalysisFilter == key ? 'btn-primary' : '')} onClick={() => { setSalesAnalysisFilter(key); setDateRange(dateFilterOption[key]); }}>{dateFilterOption[key].label}</Button>
    });

    const cycleHtml = Object.keys(cycleList).map(function (key) {
        return <Button key={key} variant={"outline-primary " + (cycle == key ? 'btn-primary' : '')} size="xs" onClick={() => { setCycle(key); getVersion(key); }}>{cycleList[key]}</Button>
    });

    return (
        <>
            <Card className='rounded-0'>
                <Card.Body>
                    <div className="row mb-4">
                        <div className="col-lg-5">
                            <h3>{t('product_research.product_details.product_statistics')}</h3>
                        </div>
                        <div className="col-lg-7 text-right">
                            <div className='d-flex justify-content-end align-items-center'>
                                <ButtonGroup className='mr-4'>
                                    {dateFilterOptionHtml}
                                </ButtonGroup>
                                <DateRangePicker
                                    initialSettings={{ startDate: moment(new Date(dateRange.start_date)).format('MM/DD/YYYY'), endDate: moment(new Date(dateRange.end_date)).format('MM/DD/YYYY') }}
                                    onApply={handleApply}
                                >
                                    <input type="text" placeholder={'First Seen'} className="form-control form-control-sm input-daterange-timepicker w-auto" value={dateRange.start_date + " - " + dateRange.end_date} />
                                </DateRangePicker>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex mb-4'>
                        <Card className="bg-light mr-3">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>{t('common.sales')}</strong></p>
                                    <h3>{salesAnalysis.sold_count || 0}</h3>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-light mr-3">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>{t('common.revenue')}</strong></p>
                                    <h3>{salesAnalysis.total_sales_usd || '-'} <small>({salesAnalysis.total_sales || '-'})</small></h3>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-light mr-3">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>{t('common.videos')}</strong></p>
                                    <h3>{salesAnalysis.video_count || '-'}</h3>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-light mr-3">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>{t('common.relevant_influencers')}</strong></p>
                                    <h3>{salesAnalysis.author_count || '-'}</h3>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-light">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>{t('common.relevant_live')}</strong></p>
                                    <h3>{salesAnalysis.live_count || '-'}</h3>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    {/* Product Sales Trend */}
                    <div className="row mb-4">
                        <div className="col-lg-12">
                            <h3>{t('product_research.product_details.product_sales_trend')}</h3>
                            <div style={{ "height": "400px" }}>
                                {productSalesTrend.labels && <Bar data={productSalesTrend} options={options} />}
                            </div>
                        </div>
                    </div>

                    {/* Metrics */}
                    {salesAnalysis && <SalesProductMatrix ref={salesProductRef} />}
                </Card.Body>
            </Card>
            <Card className='rounded-0'>
                <Card.Body>
                    <div className="row mb-4">
                        <div className="col-lg-5">
                            <h3>{t('product_research.product_details.video_or_live_promotion_trend')}</h3>
                        </div>
                        <div className="col-lg-7 text-right">
                            <div className='d-flex justify-content-end align-items-center'>
                                <ButtonGroup className='mr-4'>
                                    {dateFilterOptionHtml}
                                </ButtonGroup>
                                <DateRangePicker
                                    initialSettings={{ startDate: moment(new Date(dateRange.start_date)).format('MM/DD/YYYY'), endDate: moment(new Date(dateRange.end_date)).format('MM/DD/YYYY') }}
                                    onApply={handleApply}
                                >
                                    <input type="text" placeholder={'First Seen'} className="form-control form-control-sm input-daterange-timepicker w-auto" value={dateRange.start_date + " - " + dateRange.end_date} />
                                </DateRangePicker>
                            </div>
                        </div>
                        {/* <div className="col-lg-12">
                     <div style={{ "height": "400px" }}>
                         {videoTrendChart.labels && <Bar data={videoTrendChart} options={options} />} 
                     </div>
                  </div> */}
                    </div>
                    {videoPromotionTrend && <VideoMatrix ref={videoTrendRef} />}
                </Card.Body>
            </Card>
            <Card className='rounded-0'>
                <Card.Body>
                    <div className="row mb-4">
                        <div className="col-lg-12 mb-2">
                            <h3>{t('product_research.product_details.live_streaming_list')}</h3>
                        </div>
                        <div className="col-lg-12">
                            <div className="fh-table">
                                <Table responsive className="w-100">
                                    <div id="example_wrapper" className="dataTables_wrapper">
                                        <table id="example" className="display w-100 dataTable">
                                            <thead>
                                                <tr role="row" className='bg-light'>
                                                    {tableHead.map((value, index) => (
                                                        <th key={index} className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort ? sortClass : '')} onClick={(e) => { value.sort && handleSort(value.sort, sorting.sort == value.sort ? sortType : 'DESC') }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {liveStreamingList.map((value, index) => (
                                                    <tr key={index}>
                                                        <td className="text-right">{index + 1}</td>
                                                        <td>
                                                            <div className="d-flex">
                                                                <div className="prd-img mr-3">
                                                                    <OverlayTrigger
                                                                        key="right"
                                                                        trigger={["hover", "focus"]}
                                                                        placement="right"
                                                                        rootClose
                                                                        overlay={
                                                                            <Popover>
                                                                                <Popover.Body>
                                                                                    {value.room_cover && <img src={'https://t-img.picturehaven.net/product/' + value.room_cover + '?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={500} />}
                                                                                </Popover.Body>
                                                                            </Popover>
                                                                        }
                                                                    >
                                                                        <Button variant="link" className='p-0' size="xs">
                                                                            {value.room_cover && <img src={'https://t-img.picturehaven.net/product/' + value.room_cover + '?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={80} />}
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                </div>
                                                                <div className="prd-title">
                                                                    <p className='mb-2'>
                                                                        <OverlayTrigger overlay={<Tooltip>{value.title}</Tooltip>}>
                                                                            <span>{value.title.length > 40 ? value.title.substring(0, 40) + '...' : value.title}</span>
                                                                        </OverlayTrigger>
                                                                    </p>
                                                                    <p className="mb-2">
                                                                        {t('product_research.product_details.duration')}: <ConvertMillisecond ms={value.live_time} />
                                                                    </p>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="mr-2">
                                                                            <OverlayTrigger
                                                                                key="right"
                                                                                trigger={["hover", "focus"]}
                                                                                placement="right"
                                                                                rootClose
                                                                                overlay={
                                                                                    <Popover>
                                                                                        <Popover.Body>
                                                                                            {value.room_cover && <img src={'https://t-img.picturehaven.net/product/' + value.room_cover + '?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={500} />}
                                                                                        </Popover.Body>
                                                                                    </Popover>
                                                                                }
                                                                            >
                                                                                <Button variant="link" className='p-0' size="xs">
                                                                                    {value.room_cover && <img src={'https://t-img.picturehaven.net/product/' + value.room_cover + '?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={25} />}
                                                                                </Button>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                        <div>
                                                                            {value.nickname}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="text-right">
                                                            {value.total_sales_usd}<br />
                                                            <span className="text-muted">{value.total_sales_str ? '(' + value.total_sales_str + ')' : ''}</span>
                                                        </td>
                                                        <td className="text-right">{value.sold_count || '-'}</td>
                                                        <td className="text-right">{value.sales_conversion_rate || '-'}</td>
                                                        <td className="text-right">{value.total_user}</td>
                                                        <td className="text-right">{value.max_user_count}</td>
                                                        <td className="text-right">{value.avg_user_count}</td>
                                                        <td className="text-right">{value.product_count}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Table>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <div className='row'>
                <div className='col-lg-6'>
                    <Card className='rounded-0'>
                        <Card.Body>
                            <div className="row mb-4">
                                <div className="col-lg-5">
                                    <h3>{t('product_research.product_details.video_list_with_goods')}</h3>
                                </div>
                                <div className="col-lg-7 text-right">
                                    <div className='d-flex justify-content-end align-items-center'>
                                        <ButtonGroup>
                                            {cycleHtml}
                                        </ButtonGroup>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="fh-table">
                                        <Table responsive className="w-100 mt-3">
                                            <div id="example_wrapper" className="dataTables_wrapper">
                                                <table id="example" className="display w-100 dataTable">
                                                    <thead>
                                                        <tr role="row">
                                                            <th className="text-right">#</th>
                                                            <th>{t('common.videos')}</th>
                                                            <th className="text-right">{t('common.views')}</th>
                                                            <th className="text-right">{t('common.favorite')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {videoListWithGoods.map((value, index) => (
                                                            <tr key={index}>
                                                                <td className="text-right">{index + 1}</td>
                                                                <td>
                                                                    <div className="d-flex">
                                                                        <div className="prd-img mr-3">
                                                                            <Link to={'/products/' + value.id + '/video-details'} target="_blank">
                                                                                {value.origin_cover_privatization && <img src={'https://t-img.picturehaven.net/tikmeta/' + value.origin_cover_privatization + '?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={80} />}
                                                                            </Link>
                                                                            <Button
                                                                                variant="primary"
                                                                                className="p-0 video-popup-btn"
                                                                                onClick={() => { setTikTokEmbedID(value.id); setContentModal(!contentModal); }}
                                                                            >
                                                                                <i className="fa fa-play-circle" aria-hidden="true"></i>
                                                                            </Button>
                                                                        </div>
                                                                        <div className="prd-title">
                                                                            <p className='mb-2'>
                                                                                <OverlayTrigger overlay={<Tooltip>{value.desc}</Tooltip>}>
                                                                                    <Link to={'/products/' + value.id + '/video-details'} target="_blank">
                                                                                        <span>{value.desc.length > 22 ? value.desc.substring(0, 22) + '...' : value.desc || '-'}</span>
                                                                                    </Link>
                                                                                </OverlayTrigger>
                                                                            </p>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="mr-2">
                                                                                    <OverlayTrigger
                                                                                        key="right"
                                                                                        trigger={["hover", "focus"]}
                                                                                        placement="right"
                                                                                        rootClose
                                                                                        overlay={
                                                                                            <Popover>
                                                                                                <Popover.Body>
                                                                                                    {value.avatar_larger_privatization && <img src={'https://t-img.picturehaven.net/tikmeta/' + value.avatar_larger_privatization + '?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={500} />}
                                                                                                </Popover.Body>
                                                                                            </Popover>
                                                                                        }
                                                                                    >
                                                                                        <Button variant="link" className='p-0' size="xs">
                                                                                            <Link to={'/influencers/details/' + value.author_id + '/video-creators'} target="_blank">
                                                                                                {value.avatar_larger_privatization && <img src={'https://t-img.picturehaven.net/tikmeta/' + value.avatar_larger_privatization + '?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={25} />}
                                                                                            </Link>
                                                                                        </Button>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                                <div>
                                                                                    <Link to={'/influencers/top-sales-influencers/video-details/' + value.author_id + '/video-creators'} target="_blank">
                                                                                        {value.author_nickname || '-'}
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="text-right">
                                                                    <Conversion value={value.play_count || 0} />
                                                                </td>
                                                                <td className="text-right">
                                                                    <Button variant="outline-dark" size="xs" className="px-2 py-1 mr-1"
                                                                        onClick={() => { markFavouriteGoods({ id: value.id, country_code: value.country_code }); }}>
                                                                        <i className={"fa " + (favouriteGoods.indexOf(value.id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i></Button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        {videoListWithGoods.length == 0 && <tr><td colSpan="4" className="text-center">{t('common.no_data_found')}</td></tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-lg-6'>
                    <Card className='rounded-0'>
                        <Card.Body>
                            <div className="row mb-4">
                                <div className="col-lg-12">
                                    <h3>{t('product_research.product_details.hot_influencers')}</h3>
                                </div>
                                <div className="col-lg-12">
                                    <div className="fh-table">
                                        <Table responsive className="w-100 mt-3">
                                            <div id="example_wrapper" className="dataTables_wrapper">
                                                <table id="example" className="display w-100 dataTable">
                                                    <thead>
                                                        <tr role="row">
                                                            <th className="text-right">#</th>
                                                            <th>{t('common.influencers')}</th>
                                                            <th className="text-right">{t('common.videos')}</th>
                                                            <th className="text-right">{t('product_research.product_details.number_of')} <br />{t('product_research.product_details.live_broadcasts')}</th>
                                                            <th className="text-right">{t('common.favorite')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {hotInfluencers.map((value, index) => (
                                                            <tr key={index}>
                                                                <td className="text-right">{index + 1}</td>
                                                                <td>
                                                                    <div className="d-flex">
                                                                        <div className="prd-img mr-3">
                                                                            <OverlayTrigger
                                                                                key="right"
                                                                                trigger={["hover", "focus"]}
                                                                                placement="right"
                                                                                rootClose
                                                                                overlay={
                                                                                    <Popover>
                                                                                        <Popover.Body>
                                                                                            {value.avatar_larger_privatization && <img src={'https://t-img.picturehaven.net/tikmeta/' + value.avatar_larger_privatization + '?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={500} />}
                                                                                        </Popover.Body>
                                                                                    </Popover>
                                                                                }
                                                                            >
                                                                                <Button variant="link" className='p-0' size="xs">
                                                                                    <Link to={'/influencers/top-sales-influencers/video-details/' + value.id + '/video-creators'} target="_blank">
                                                                                        {value.avatar_larger_privatization && <img src={'https://t-img.picturehaven.net/tikmeta/' + value.avatar_larger_privatization + '?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={80} />}
                                                                                    </Link>
                                                                                </Button>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                        <div className="prd-title">
                                                                            <Link to={'/influencers/top-sales-influencers/video-details/' + value.id + '/video-creators'} target="_blank">
                                                                                <p className='mb-2'>{value.nickname}</p>
                                                                            </Link>
                                                                            {value?.category_list && value?.category_list.length != 0 && <div className="mb-1">
                                                                                <span className="btn btn-outline-dark btn-xs px-2 py-1 mr-1 mb-1">{value?.category_list[0]?.category_name}</span> <br />
                                                                                {value?.category_list.length > 1 && <OverlayTrigger
                                                                                    key="right"
                                                                                    trigger="click"
                                                                                    placement="bottom"
                                                                                    rootClose
                                                                                    overlay={
                                                                                        <Popover className="variants-tooltip">
                                                                                            <Popover.Body>
                                                                                                <div className="m-2">
                                                                                                    {value.category_list && value.category_list.map((v, i) => {
                                                                                                        if (i != 0) return <span key={i} className="btn btn-outline-dark btn-xs px-2 py-1  mr-1">{v.category_name}</span>
                                                                                                    })}
                                                                                                </div>
                                                                                            </Popover.Body>
                                                                                        </Popover>
                                                                                    }
                                                                                >
                                                                                    <Button variant="outline-dark" size="xs" className='px-2 py-1'>{value.category_list.length > 1 ? ('+' + (value.category_list.length - 1)) : ''}  <i className="fa fa-chevron-down" aria-hidden="true"></i></Button>
                                                                                </OverlayTrigger>}
                                                                            </div>}

                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="text-right">
                                                                    {value.video_count || '-'}
                                                                </td>
                                                                <td className="text-right">
                                                                    {value.live_count || '-'}
                                                                </td>
                                                                <td className="text-right">
                                                                    <Button variant="outline-dark" size="xs" className="px-2 py-1 mr-1"
                                                                        onClick={() => { markFavouriteInfluencers({ influencer_id: value.id, nickname: value.nickname }); }}>
                                                                        <i className={"fa " + (favouriteInfluencers.indexOf(value.id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i></Button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            {/* <!-- Video Modal --> */}
            <Modal className="fade video-popup" show={contentModal} >
                <Modal.Body className='p-0'>
                    <div className='d-flex'>
                        <div className='text-center'>
                            <TikTokEmbed url={`https://www.tiktok.com/embed/${tikTokEmbedID}`} width={325} />
                        </div>
                        <div className='px-3'>
                            <div className='d-flex flex-column'>
                                <Button
                                    variant="dark"
                                    className="mb-3"
                                    onClick={() => { setTikTokEmbedID(''); setContentModal(!contentModal); }}
                                >
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                                {/* <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-line-chart" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-download" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-file-code-o" aria-hidden="true"></i>
                        </Button> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </ Modal>
        </>
    );
};

export default TopSalesFilter;