import React from 'react';
import InfiniteScrollWrapper from '../../Products/InfiniteScrollWrapper';
import VideoCardView from './VideoCardView';
import VideoListView from './VideoListView';


const ViralVideos = ({ functionNext, items, hasMore, favourite, markFavourite, handleSort, sorting, view }) => {    
    return (
        <>
            <InfiniteScrollWrapper
                isInfiniteScrollOn={true}
                lengthData={items.length}
                functionNext={functionNext}
                hasMore={hasMore}
            >
                {view === 'card' && 
                <VideoCardView items={items} favourite={favourite} markFavourite={markFavourite}/>
                }
                {view === 'list' && 
                <VideoListView items={items} favourite={favourite} markFavourite={markFavourite} handleSort={handleSort} sorting={sorting}/>
                }
            </InfiniteScrollWrapper>
            
        </>
    );
};

export default ViralVideos;
