import React from "react";
import "../../css/landing/landing.css";
import "../../css/landing/bootstrap.min.css";
import "../../css/landing/glightbox.min.css";
import "../../css/landing/main.css";
import "../../css/landing/tiny-slider.css";
import { Nav, Tab } from 'react-bootstrap'
import ProductsresearchImg from "../../../src/images/products-research.png";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useTranslation } from 'react-i18next';

const MyTiktokshop = () => {
   const { t } = useTranslation();   
   return (
      <>
         <Header />
         <section id="home" className="hero-area hero-inner-area">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-lg-7 col-md-12 col-12">
                     <div className="hero-content">
                        <h1>{t('mytiktokshop.unlock_the_power_of_your')}</h1>
                        <div className="button">
                           <a href="/pre-register" className="btn">{t('mytiktokshop.get_started')}</a>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-5 col-md-12 col-12 text-center">
                     <div className="hero-image">
                        <img src={ProductsresearchImg} alt="" width={445} height={392}  />
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section id="features" className="features section pt-0 pb-0">
            <Tab.Container
               defaultActiveKey="storemanager"
            >
               <div className="bg-light py-5 mb-0">
                  <div className="container">
                     <div className="row mb-4">
                        <div className="col-12">
                           <div className="section-title p-0">
                              <h2 className="mb-2">{t('mytiktokshop.integrate_analyze_optimize')}</h2>
                              <p>{t('mytiktokshop.step_into_a_new_era')}</p>
                           </div>
                        </div>
                     </div>
                     <Nav as="ul" className="nav-pills mb-4">
                        <Nav.Item as="li" key="storemanager">
                           <Nav.Link eventKey="storemanager">
                           {t('mytiktokshop.store_manager')}
                           </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li" key="profitsdashboard">
                           <Nav.Link eventKey="profitsdashboard" >
                           {t('mytiktokshop.profits_dashboard')}
                           </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li" key="productmasterlist">
                           <Nav.Link eventKey="productmasterlist" >
                           {t('mytiktokshop.product_master_list')}
                           </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="li" key="reports">
                           <Nav.Link eventKey="reports" >
                           {t('mytiktokshop.reports')}
                           </Nav.Link>
                        </Nav.Item>
                     </Nav>
                  </div>

                  <div className="container">
                     <Tab.Content>
                        <Tab.Pane
                           eventKey="storemanager"
                           key="storemanager"
                        >
                           <div className="row align-items-center">
                              <div className="col-lg-7 col-md-12 col-12">
                                 <div className="hero-content">
                                    <h3 className="mb-2">{t('mytiktokshop.store_manager_centralized')}</h3>
                                    <p className="lead">{t('mytiktokshop.effortlessly_manage_multiple')}</p>
                                    <div className="button">
                                       <a href="/#" className="text-primary">{t('mytiktokshop.learn_more')} <i className="fa fa-arrow-right" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-lg-5 col-md-12 col-12 text-center">
                                 <div className="hero-image">
                                    <img src={ProductsresearchImg} alt="" width={445} height={392}  />
                                 </div>
                              </div>
                           </div>
                        </Tab.Pane>
                        <Tab.Pane
                           eventKey="profitsdashboard"
                           key="profitsdashboard"
                        >
                           <div className="row align-items-center">
                              <div className="col-lg-7 col-md-12 col-12">
                                 <div className="hero-content">
                                    <h3 className="mb-2">{t('mytiktokshop.profits_dashboard_visualize')}</h3>
                                    <p className="lead">{t('mytiktokshop.get_a_comprehensive')}</p>
                                    <div className="button">
                                       <a href="/#" className="text-primary">{t('mytiktokshop.learn_more')} <i className="fa fa-arrow-right" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-lg-5 col-md-12 col-12 text-center">
                                 <div className="hero-image">
                                    <img src={ProductsresearchImg} alt="" width={445} height={392}  />
                                 </div>
                              </div>
                           </div>
                        </Tab.Pane>
                        <Tab.Pane
                           eventKey="productmasterlist"
                           key="productmasterlist"
                        >
                           <div className="row align-items-center">
                              <div className="col-lg-7 col-md-12 col-12">
                                 <div className="hero-content">
                                    <h3 className="mb-2">{t('mytiktokshop.product_master_list_precision')}</h3>
                                    <p className="lead">{t('mytiktokshop.optimize_your_product')}</p>
                                    <div className="button">
                                       <a href="/#" className="text-primary">{t('mytiktokshop.learn_more')} <i className="fa fa-arrow-right" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-lg-5 col-md-12 col-12 text-center">
                                 <div className="hero-image">
                                    <img src={ProductsresearchImg} alt="" width={445} height={392}  />
                                 </div>
                              </div>
                           </div>
                        </Tab.Pane>
                        <Tab.Pane
                           eventKey="reports"
                           key="reports"
                        >
                           <div className="row align-items-center">
                              <div className="col-lg-7 col-md-12 col-12">
                                 <div className="hero-content">
                                    <h3 className="mb-2">{t('mytiktokshop.reports_tailored')}</h3>
                                    <p className="lead">{t('mytiktokshop.generate_custom_reports')}</p>
                                    <div className="button">
                                       <a href="/#" className="text-primary">{t('mytiktokshop.learn_more')} <i className="fa fa-arrow-right" aria-hidden="true"></i></a>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-lg-5 col-md-12 col-12 text-center">
                                 <div className="hero-image">
                                    <img src={ProductsresearchImg} alt="" width={445} height={392}  />
                                 </div>
                              </div>
                           </div>
                        </Tab.Pane>
                     </Tab.Content>
                  </div>
               </div>
            </Tab.Container>
         </section>

         <section id="pricing" className="pricing-table section pt-0">
            <div className="container">
               <div className="row">
                  <div className="col-lg-6 col-md-6 col-12">
                     <div className="single-table">
                        <div className="table-head">
                           <h4 className="title">{t('mytiktokshop.advanced_features_for_precision')}</h4>
                           <p>{t('mytiktokshop.refine_your_analytics')}</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                     <div className="single-table">
                        <div className="table-head">
                           <h4 className="title">{t('mytiktokshop.why_viralytic')}</h4>
                           <p>- {t('mytiktokshop.real_time_data_base')}</p>
                           <p>- {t('mytiktokshop.seamless_integration_connect')}</p>
                           <p>- {t('mytiktokshop.comprehensive_solutions')}</p>
                        </div>
                     </div>
                  </div>
                  
               </div>
            </div>
         </section>

         <section className="section call-action">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                     <div className="cta-content">
                        <h2>{t('mytiktokshop.focus_on_tiktok_analytics')}</h2>
                        <div className="button">
                           <a href="/#" className="btn">{t('mytiktokshop.start_free_trial')}</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <Footer />
      </>
   );
};

export default MyTiktokshop;