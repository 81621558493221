function Conversion({ value }) {
    let text = parseFloat(value);
    if (text >= 1000000000000) {
        text = parseFloat(text / 1000000000000).toFixed(1) + 'T';
    } else if (text >= 1000000000) {
        text = parseFloat(text / 1000000000).toFixed(1) + 'B'
    } else if (text >= 1000000) {
        text = parseFloat(text / 1000000).toFixed(1) + 'M'
    } else if (text >= 1000) {
        text = parseFloat(text / 1000).toFixed(1) + 'K'
    };
    return (text);
};

export default Conversion;