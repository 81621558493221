import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import Axios from "../../plugins/axios.js";
import Footer from "../../pages/components/Footer.js";
import Header from "../../pages/components/Header.js";
import { ToastContainer, toast } from 'react-toastify';

const ContactSupport = () => {
   const { t } = useTranslation();
   const isUserLoggedIn = localStorage.getItem('user') && localStorage.getItem('token') ? true : false;
   const [name, setName] = useState('');
   const [nameMessage, setNameMessage] = useState('');
   const [email, setEmail] = useState('');
   const [emailMessage, setEmailMessage] = useState('');
   const [message, setMessage] = useState('');

   const handleSubmit = async () => {
      if (!name || !email) {
         if (!name) setNameMessage(t('registration.step_1.long_text_4'));
         if (!email) setEmailMessage(t('registration.step_1.long_text_2'));
      } else {
         try {
            let response = await Axios.post("/api/contact-support", { name, email, message }).catch((e) => console.error('Axios-Catch: handleSubmit ' + e.message));
            if (response && response.data.statusCode === 200) {
               toast.success("Message Successfully Sent", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               });
               setName(''); setNameMessage(''); setEmail(''); setEmailMessage(''); setMessage('');
            };
         } catch (error) {
            console.error('Error-Catch: handleSubmit ' + error.message);
         };
      };
   };

   return (
      <>
         {isUserLoggedIn === false && <Header />}
         {isUserLoggedIn == false && <section id="home" className="hero-area hero-inner-area py-5">
            <div className="container">
               <div className="row align-items-center"></div>
            </div>
         </section>}
         <Card>
            <Card.Body>
               <div className="row justify-content-center">
                  <div className="col-lg-4">
                     <section className="text-center">
                        <h2>{t('PageName.contact_support')}</h2><br />
                        <div className="input-group mb-3">
                           <input
                              type="text"
                              className={`form-control ${nameMessage ? 'is-invalid' : ''}`}
                              placeholder={t('registration.step_1.full_name')}
                              value={name}
                              onChange={(e) => { setName(e.target.value); setNameMessage(''); }}
                              onBlur={() => !name ? setNameMessage(t('registration.step_1.long_text_7')) : ''}
                              required
                           />
                           {nameMessage && <div className="invalid-feedback">{nameMessage}</div>}
                        </div>

                        <div className="input-group mb-3">
                           <input
                              type="email"
                              className={`form-control ${emailMessage ? 'is-invalid' : ''}`}
                              placeholder={t('registration.step_1.email_address')}
                              value={email}
                              onChange={(e) => { setEmail(e.target.value); setEmailMessage('') }}
                              onBlur={() => !email ? setEmailMessage(t('registration.step_1.email_address_is_required')) : ''}
                              required
                           />
                           {emailMessage && <div className="invalid-feedback">{emailMessage}</div>}
                        </div>

                        <div className="input-group mb-3">
                           <textarea className={`form-control`} placeholder="Message" value={message} rows={3} onChange={(e) => setMessage(e.target.value)}>
                           </textarea>
                        </div>
                     </section>
                  </div>
               </div>

               <div className="text-center toolbar toolbar-bottom p-2">
                  <button className="btn btn-primary sw-btn-next" onClick={handleSubmit}>
                     <ToastContainer
                        position="bottom-left"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                     />Submit</button>
               </div>
            </Card.Body>
         </Card>
         {isUserLoggedIn === false && <section className="section call-action">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                     <div className="cta-content">
                        <h2>{t('pricing.focus_on_tiktok_analytics')}</h2>
                        <div className="button">
                           <a href="/pre-register" className="btn">{t('pricing.start_free_trial')}</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>}
         {isUserLoggedIn === false && <Footer />}
      </>
   );
};

export default ContactSupport;