import React, { useEffect, useState, useContext } from 'react'
import Axios from "../../plugins/axios.js";
import { useNavigate } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { Context } from '../../../MasterLayout.js';
import PlanModal from '../CommonComponents/PlanModal.js';

function Redirect() {
    const navigate = useNavigate();
    const { dispatch } = useContext(Context);
    const [authSuccess, setauthSuccess] = useState(true);
    const [storesave, setstoresave] = useState();
    const [loader, setloader] = useState(true)
    const [SuccessSave, setSuccessSave] = useState(false)
    const [storeName, setStoreName] = useState('')
    const [errorStoreName, setErrorStoreName] = useState({ valid: false, message: "" })
    const [modalShow, setModalShow] = useState(false);
    const [limitMsg, setLimitMsg] = useState('');

    function getParameterByName(name, url) {
        // eslint-disable-next-line no-useless-escape
        name = name.replace(/[\[\]]/g, "\\$&");
        const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const currentUrl = window.location.href;
                const authCode = getParameterByName('code', currentUrl);
                const state = getParameterByName('state', currentUrl);
                const localState = localStorage.getItem('state')
                if (state === localState) {
                    const Auth_data = {
                        code: authCode,
                        state: state,
                    };
                    let response = await Axios.post('/api/token-get', Auth_data);
                    dispatch({ type: 'subscription_expired', response: response.data.statusCode });
                    setloader(false)
                    if (response?.data?.statusCode === 400) {
                        setauthSuccess(false)
                        setTimeout(() => {
                            return navigate('/tiktok-store/store-manager');
                        }, 4000);
                    } else {
                        setstoresave(response?.data?.data);
                    }
                } else {
                    setauthSuccess(false)
                    setTimeout(() => {
                        return navigate('/tiktok-store/store-manager');
                    }, 4000);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [])

    const handleSaveStore = async () => {
        if (!storeName.length) {
            return setErrorStoreName({ valid: true, message: "Please enter store name" })
        }
        else if (storeName.length < 3) {
            return setErrorStoreName({ valid: true, message: "Store Name should be more than 3 characters" })
        }
        storesave.data.name = storeName;
        try {
            let response = await Axios.post('/api/store', storesave);
            if (response?.data?.statusCode === 402) {
                setLimitMsg(response?.data?.msg || '');
                setModalShow(true);
            }
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });
            if (response?.data?.statusCode === 400) {
                setErrorStoreName({ valid: true, message: response?.data?.message })
                return
            } else {
                setSuccessSave(true)
                setTimeout(() => {
                    return navigate('/tiktok-store/store-listing');
                }, 2000);
            }
        } catch (error) {
            return setErrorStoreName({ valid: true, message: error.message })

        }

    }
    const handleChange = (e) => {
        setStoreName(e.target.value);
        if (e.target.value.length > 3) {
            return setErrorStoreName({ valid: false, message: "" })
        } else if (!e.target.value.length) {
            return setErrorStoreName({ valid: true, message: "Please enter store name" })
        }
    }
    return (
        <div>
            {limitMsg && <div className='d-flex flex-row justify-content-center'>
                <h4 className='flex my-2 text-center mx-2'><strong>{limitMsg}</strong></h4>
            </div>}
            {!loader ? (
                authSuccess ? (
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <h1>Welcome to My TikTok Store</h1>
                        <Form.Control placeholder='Enter Store Name' value={storeName} onChange={(e) => handleChange(e)} className='w-25 my-2'></Form.Control>
                        <h5 style={{ color: "red" }}>{errorStoreName.valid ? errorStoreName.message : ""}</h5>
                        <Button onClick={handleSaveStore}>Save Store</Button>
                    </div>
                ) : (
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <h1>Connecting....</h1>
                        <h1>Redirecting to connecting page</h1>
                    </div>
                )
            ) : (
                ''
            )}
            {SuccessSave && (
                <h1 className='d-flex mt-4 align-items-center justify-content-center'>Successfully store saved</h1>
            )}
            <PlanModal setModalShow={setModalShow} modalShow={modalShow} />
        </div>
    )
}

export default Redirect