import React from 'react';
import { Link } from 'react-router-dom';
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import InfiniteScrollWrapper from '../../../Products/InfiniteScrollWrapper';
import { useTranslation } from 'react-i18next';
import Conversion from '../../../CommonComponents/Conversion';
import { Sparklines, SparklinesLine } from 'react-sparklines';

const HashtageSearch = ({ functionNext, items, favourite, markFavourite, hasMore, handleSort, sorting }) => {
   const { t } = useTranslation();

   const tableHead = [
      { title: t('creator_center.hashtage_ranking.table.hashtage') },
      { title: t('creator_center.hashtage_ranking.table.posts'), className: 'text-left' },
      { title: t('creator_center.hashtage_ranking.table.views'), className: 'text-left', sort: 2 },
      { title: t('creator_center.hashtage_ranking.table.likes'), className: 'text-left', sort: 3 },
      { title: t('creator_center.hashtage_ranking.table.like_rate'), className: 'text-left', sort: 8},
      { title: t('creator_center.hashtage_ranking.table.related_video_trends'), className: 'text-left' },
      { title: t('creator_center.hashtage_ranking.table.hot_videos'), className: 'text-left' },
      { title: t('creator_center.hashtage_ranking.table.favourite'), className: 'text-right' }
   ];

   return (
      <InfiniteScrollWrapper
         isInfiniteScrollOn={true}
         lengthData={items.length}
         functionNext={functionNext}
         hasMore={hasMore}
      >
         <table id="example" className="display w-100 dataTable">
            <thead>
               <tr role="row">
                  {tableHead.map((value, index) => (
                     <th key={index} className={(value.className || '') + ' ' + (value.sort && value.sort === sorting.sort ? 'sorting-desc-blue' : (value.sort ? 'sorting-desc-icon' : ''))} onClick={(e) => { value.sort && handleSort(value.sort) }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                  ))}
               </tr>
            </thead>
            <tbody>
               {items.map((hashtage, index) => (
                  <tr key={index}>
                     <td>
                        <div className="d-flex">
                           <div className="prd-img mr-3">
                              {hashtage.cover_large_privatization && <img src={"https://t-img.picturehaven.net/tikmeta/" + hashtage.cover_large_privatization + "?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />}
                           </div>
                           <div className="prd-title d-flex justify-content-start align-items-center">
                              <p>
                                 <OverlayTrigger overlay={<Tooltip>{hashtage.title}</Tooltip>}>
                                    <Link to={'/hashtage/' + hashtage.id + '/details'}>
                                       <span className='font-weight-normal text-dark'>{hashtage.title.length > 40 ? hashtage.title.substring(0, 40) + '...' : hashtage.title}</span>
                                    </Link>
                                 </OverlayTrigger>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td className="text-right">{hashtage?.video_count ? <Conversion value={hashtage?.video_count} /> : '--'}</td>
                     <td className="text-right">{hashtage?.play_count ? <Conversion value={hashtage?.play_count} /> : '--'}</td>
                     <td className="text-right">{hashtage?.digg_count ? <Conversion value={hashtage?.digg_count} /> : '--'}</td>
                     <td className="text-right">{hashtage?.video_count ? <Conversion value={hashtage?.video_count} /> : '--'}</td>
                     <td className="text-center ml-2">
                        <div className='w-50 d-flex justify-content-center'>
                           {hashtage.trends && hashtage.trends.length > 0 && <Sparklines data={hashtage.trends.map((value, index) => value.video_count_incr).reverse() || [0, 0]} width={60} height={30}>
                              <SparklinesLine color="#2f7efe" />
                           </Sparklines>}
                           {(!hashtage.trends || hashtage?.trends.length === 0) ? '/' : ''}
                        </div>
                     </td>
                     <td className="text-left">
                        <div className="d-flex justify-content-left align-items-left">
                           {hashtage.top_video_list && hashtage.top_video_list.map(video => (
                              <div className="prd-img mx-1">
                                 {video.origin_cover_privatization &&
                                    <img src={"https://t-img.picturehaven.net/tikmeta/" + video.origin_cover_privatization + "?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />
                                 }
                                 <Button variant="primary" className="p-0 video-popup-btn">
                                    <i className="fa fa-play-circle" aria-hidden="true"></i>
                                 </Button>
                              </div>
                           ))}
                        </div>
                     </td>
                     <td className="text-right">
                        <div className="d-flex justify-content-end">
                           {<Button
                              variant="outline-dark" size="xs"
                              className="px-2 py-1"
                              onClick={() => { markFavourite({ hashtage_id: hashtage.id, nickname: hashtage?.nickname }); }}>
                              <i className={"fa " + (favourite.indexOf(hashtage.id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                           </Button>}
                        </div>
                     </td>
                  </tr>
               ))}
            </tbody>
         </table>
      </InfiniteScrollWrapper>
   );
};

export default HashtageSearch;
