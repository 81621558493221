import React, { useState, useRef, useContext } from 'react';
import { Button, Modal, Card } from "react-bootstrap";
import Axios from "../../plugins/axios";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCard from "../StripeCard/Stripecard";
import moment from "moment";
import { Context } from '../../../MasterLayout';
import { useTranslation } from 'react-i18next';
import PlanTable from '../CommonComponents/PlanTable';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Subscription = ({ user, func }) => {
    const { t } = useTranslation();

    const stripeRef = useRef(null);
    const addressRef = useRef(null);
    const { dispatch } = useContext(Context);
    const [address, setAddress] = useState(null);
    const [billingModalShow, setBillingModalShow] = useState(false);
    const [updateModalShow, setUpdateModalShow] = useState(false);
    const [cancelModalShow, setCancelModalShow] = useState(false);
    const [reason, setReason] = useState('');
    const [reasonMessage, setReasonMessage] = useState('');
    const [stripePaymentId, setStripePaymentId] = useState('');
    const [coupon] = useState('');
    const [addressMessage, setAddressMessage] = useState('');
    const [stripeMessage, setStripeMessage] = useState('');

    const onSelectAddress = async () => {

        const autocomplete = new window.google.maps.places.Autocomplete(addressRef.current);

        window.google.maps.event.addListener(autocomplete, 'place_changed', async function () {
            const place = await autocomplete.getPlace();
            if (place.address_components) {
                let addressObj = {
                    address: place.formatted_address,
                    postal: place.address_components[place.address_components.length - 1]?.long_name,
                    country: place.address_components[place.address_components.length - 2]?.long_name,
                    state: place.address_components[place.address_components.length - 3]?.long_name,
                    city: place.address_components[place.address_components.length - 4]?.long_name,
                };
                setAddress(addressObj)
            };
        });
    };

    const updateSubscription = async (plan) => {
        user.plan = user.plan ? user.plan : {};
        user.plan.slug = plan.slug;

        if (!user.pm_last_four || !user.pm_type || user.user_type == 'free_full_access') {
            user = { ...user, paymentMethodId: stripePaymentId, address, slug: plan.slug, coupon }
        }
        dispatch({ type: 'loader_show', response: true });

        const response = await Axios.post('/api/subscription/update', user).catch((e) => {
            console.error('Axios-Catch: onBlurEmail ' + e.message);
        });

        if (response && response.status == 200) {
            setUpdateModalShow(!updateModalShow);
            func();
        };

        dispatch({ type: 'loader_hide', response: true });
    };

    const cancelSubscription = async () => {
        if (!reason) {
            setReasonMessage('Reason is required.')
        } else {
            dispatch({ type: 'loader_show', response: true });
            user.reason = reason;
            const response = await Axios.post('/api/subscription/cancel', user).catch((e) => {
                console.error('Axios-Catch: onBlurEmail ' + e.message);
            });

            if (response && response.status == 200) {
                setCancelModalShow(!cancelModalShow);
                func();
            };
            dispatch({ type: 'loader_hide', response: true });
        };
    };

    const revokeSubscription = async () => {
        dispatch({ type: 'loader_show', response: true });
        const response = await Axios.post('/api/subscription/revoke', user).catch((e) => {
            console.error('Axios-Catch: onBlurEmail ' + e.message);
        });

        if (response && response.status == 200) {
            func();
        };
        dispatch({ type: 'loader_hide', response: true });
    };

    const updateSub = async () => {
        if (!user.pm_last_four || !user.pm_type || user.user_type == 'free_full_access') {
            setBillingModalShow(!billingModalShow);
        } else {
            setUpdateModalShow(!updateModalShow);
        };
    };

    const toSelectPlan = async () => {
        if (!address) {
            setAddressMessage('Address is Required.');
        } else {
            dispatch({ type: 'loader_show', response: true });
            let stripeResponse = await stripeRef.current.stripePaymentMethod(user.full_name, user.email);
            if (stripeResponse.statusCode === 200 && stripeResponse.data && stripeResponse.data.id) {
                setStripePaymentId(stripeResponse.data.id);
                setBillingModalShow(!billingModalShow);
                setUpdateModalShow(!updateModalShow);
            } else {
                setStripeMessage(stripeResponse.message);
            };
            dispatch({ type: 'loader_hide', response: true });
        };
    };

    return (
        <>
            <Card>
                <Card.Body>

                    <h4>{t('user_profile.active_subscription')}</h4>
                    <p>{t('user_profile.your_active_plan')}</p>

                    {user?.subscription?.plan?.status == 'active' || user?.subscription?.plan?.status == 'trialing' || (user?.subscription?.stripe_status == 'active' && user?.user_type == 'free_full_access') ?
                        <div>
                            {user.subscription?.status == 'active' || (user.subscription?.status == 'active' && user.user_type == 'free_full_access') ?
                                <div>
                                    <h5>{t('user_profile.you_are_subscribed_to')} {user.plan.name} {t('user_profile.plan')}</h5>
                                    {user.plan.slug !== 'free_plan'
                                        ? <p>{t('user_profile.your_next_billing_will_be_on')} {user.subscription?.nextBillingDate || moment(new Date(user.subscription?.trial_ends_at)).format('MMM DD, YYYY')}.</p>
                                        : <p>You have unlimited free plan access.</p>}
                                    {(user.subscription?.plan?.id != process.env.REACT_APP_STANDARD_ANNUAL || user.user_type == 'free_full_access') &&
                                        <button type="button" className="btn btn-primary btn-sm mr-2" onClick={() => updateSub()} >{t('user_profile.update_subscription')}</button>
                                    }
                                    <button type="button" className="btn btn-outline-danger btn-sm mr-2" onClick={() => setCancelModalShow(!cancelModalShow)}>{t('user_profile.cancel_subscription')}</button>
                                </div>
                                :
                                <div>
                                    <h5>{t('user_profile.cancelled_subscription')}: {user.plan.name}.</h5>
                                    <p>{t('user_profile.revoke_before')} {user.subscription.nextBillingDate || moment(new Date(user.subscription?.trial_ends_at)).format('MMM DD, YYYY')},{t('user_profile.for_seamless_use')}</p>
                                    <button type="button" className="btn btn-primary btn-sm mr-2" onClick={() => revokeSubscription()} >{t('user_profile.revoke_subscription')}</button>
                                </div>}
                        </div>
                        :
                        <div>
                            <h5>{t('user_profile.theres_no_active_plan')}</h5>
                            <button type="button" className="btn btn-primary btn-sm mr-2" onClick={() => updateSub()}>{t('user_profile.subscribed_now')}</button>
                        </div>}

                    <Modal
                        className="fade bd-example-modal-lg"
                        show={updateModalShow}
                        size="xl"
                    >
                        <Modal.Header>
                            <Modal.Title>{t('user_profile.update_subscription')}</Modal.Title>
                            <Button
                                variant="danger"
                                className="close"
                                size="sm"
                                onClick={() => setUpdateModalShow(!updateModalShow)}
                            >
                                X
                            </Button>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <PlanTable func={updateSubscription} text={"Start Now"} />
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal
                        className="fade bd-example-modal-lg"
                        show={cancelModalShow}
                        size="lg"
                    >
                        <Modal.Header>
                            <Modal.Title> {t('user_profile.cancel_subscription')}</Modal.Title>
                            <Button
                                variant="danger"
                                className="close"
                                size="sm"
                                onClick={() => setCancelModalShow(!cancelModalShow)}
                            >
                                X
                            </Button>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group">
                                <textarea
                                    className={`form-control ${reasonMessage ? 'is-invalid' : ''}`}
                                    rows="5"
                                    placeholder={t('user_profile.reason_for_cancellation')}
                                    value={reason}
                                    onChange={(e) => {
                                        setReason(e.target.value); setReasonMessage('');
                                    }}
                                    onBlur={() => !reason ? setReasonMessage(t('user_profile.reason_is_required')) : ''}
                                    required
                                >
                                </textarea>
                                {reasonMessage && <div className="invalid-feedback">{reasonMessage}</div>}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="danger"
                                size="sm"
                                onClick={() => setCancelModalShow(!cancelModalShow)}
                            >
                                {t('user_profile.close')}
                            </Button>
                            <Button
                                variant="primary"
                                size="sm"
                                onClick={() => cancelSubscription()}
                            >
                                {t('user_profile.submit_request')}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        className="fade bd-example-modal-lg"
                        show={billingModalShow}
                        size="lg"
                    >
                        <Modal.Header>
                            <Modal.Title> {t('user_profile.update_billing_information')}</Modal.Title>
                            <Button
                                variant="danger"
                                className="close"
                                size="sm"
                                onClick={() => setBillingModalShow(!billingModalShow)}
                            >
                                X
                            </Button>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group" onClick={() => setAddressMessage('')}>
                                <input
                                    ref={addressRef}
                                    type="text"
                                    className={`form-control ${addressMessage ? 'is-invalid' : ''}`}
                                    placeholder="Full Address"
                                    onChange={() => setAddressMessage('')}
                                    onBlur={() => !address ? setAddressMessage(t('user_profile.address_is_required')) : ''}
                                    onSelect={() => { onSelectAddress() }}
                                    required
                                />
                                {addressMessage && <div className="invalid-feedback">{addressMessage}</div>}
                            </div>
                            <div className="form-group" onClick={() => setStripeMessage('')}>
                                <Elements stripe={stripePromise}>
                                    <StripeCard ref={stripeRef} />
                                </Elements>
                                {stripeMessage && <div className="text-danger">{stripeMessage}</div>}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="danger"
                                size="sm"
                                onClick={() => setBillingModalShow(!billingModalShow)}
                            >
                                {t('user_profile.cancel')}
                            </Button>
                            <Button
                                variant="primary"
                                type="button"
                                size="sm"
                                className="btn btn-primary"
                                onClick={() => toSelectPlan()}
                            >
                                {t('user_profile.next')}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Card.Body>
            </Card>
        </>
    );
};

export default Subscription;