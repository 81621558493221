import React from 'react'
import { Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import Conversion from '../../../CommonComponents/Conversion'
import { useTranslation } from 'react-i18next';

function SalesMatricsTabProdVideo({ currentpage,historys, totalCount, handlePageClick }) {
    const { t } = useTranslation();

    return (
        <div>
            <div>
                <div className="card-body">
                    <Table responsive className="w-100">
                        <div id="example_wrapper" className="dataTables_wrapper">
                            <table id="example" className="display w-100 dataTable">
                                <thead>
                                    <tr role="row">
                                        <th className='text-center'>{t('product_research.product_details.date')}</th>
                                        <th className='text-center'>{t('common.likes')}</th>
                                        <th className='text-center'>{t('product_research.product_details.new_likes')}</th>
                                        <th className='text-center'>{t('common.comments')}</th>
                                        <th className='text-center'>{t('product_research.product_details.new_comments')}</th>
                                        <th className='text-center'>{t('common.comments')}</th>
                                        <th className='text-center'>{t('product_research.product_details.new_shares')}</th>
                                        <th className='text-center'>{t('product_research.product_details.interaction_rate')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {historys?.map((product) => (
                                        <tr>
                                            <td className='text-center'>{product?.statistical_day}</td>
                                            <td className='text-center'>
                                                {product?.digg_count? <Conversion value={product?.digg_count}/>:'0'}
                                            </td>
                                            <td 
                                            className={`text-center ${
                                                product?.digg_count_change > 0 ? 'text-success' :
                                                product?.digg_count_change < 0 ? 'text-danger' :
                                                'text-muted'
                                            }`}
                                            
                                            >
                                                {product?.hasOwnProperty('digg_count_change') ? (
                                                    <>
                                                        {product?.digg_count_change > 0 ? '+' : ''}
                                                        <Conversion value={product?.digg_count_change} />
                                                    </>
                                                ) : '--'}
                                            </td>
                                            <td className='text-center'>{product?.comment_count}</td>
                                            <td 
                                            className={`text-center ${
                                                product?.comment_count_change > 0 ? 'text-success' :
                                                product?.comment_count_change < 0 ? 'text-danger' :
                                                'text-muted'
                                            }`}
                                            >
                                                {product?.hasOwnProperty('comment_count_change') ? (
                                                    <>
                                                        {product?.comment_count_change > 0 ? '+' : ''}
                                                        <Conversion value={product?.comment_count_change} />
                                                    </>
                                                ) : '--'}
                                            </td>
                                            <td className='text-center'>{product?.share_count}</td>
                                            <td 
                                            className={`text-center ${
                                                product?.share_count_change > 0 ? 'text-success' :
                                                product?.share_count_change < 0 ? 'text-danger' :
                                                'text-muted'
                                            }`}
                                            >
                                                {product?.hasOwnProperty('share_count_change') ? (
                                                    <>
                                                        {product?.share_count_change > 0 ? '+' : ''}
                                                        <Conversion value={product?.share_count_change} />
                                                    </>
                                                ) : '--'}
                                            </td>
                                            <td className='text-center'>{(product?.interaction_rate * 100).toFixed(2)}%</td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </Table>
                    <div className="dataTables_paginate paging_simple_numbers">
                        <ReactPaginate
                            nextLabel="Next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={1}
                            marginPagesDisplayed={1}
                            pageCount={totalCount / 5}
                            previousLabel="< Previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            forcePage={currentpage-1}
                            renderOnZeroPageCount={null}
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SalesMatricsTabProdVideo