import React from 'react'
import ShopRankingFilter from './ShopRankingListTabs/ShopRankingFilter'

function Shopsearchfilter() {
    return (
        <div>
            <ShopRankingFilter defaultCycle={'H24'} tab={"shops-search"} />
        </div>
    )
}

export default Shopsearchfilter