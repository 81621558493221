import React, { useEffect, useState, forwardRef, useImperativeHandle, useContext } from "react";
import { Button, Card, OverlayTrigger, Popover, Tooltip, Table, Modal } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom';
import Axios from "../../../plugins/axios";
import { Context } from '../../../../MasterLayout';
import { useTranslation } from 'react-i18next';
import InfiniteScrollWrapper from "../../Products/InfiniteScrollWrapper";
import Category from "./Category";
import Conversion from "../../CommonComponents/Conversion";
import { TikTokEmbed } from 'react-social-media-embed';

const RelevantVideosTab = forwardRef((props, ref) => {
   const { t } = useTranslation();
   const { dispatch } = useContext(Context);
   const params = useParams();
   const [page, setPage] = useState(0);
   const [onLoad, setOnLoad] = useState(false);
   const [items, setItems] = useState([]);
   const [hasMore, setHasMore] = useState(true);
   const [sorting, setSorting] = useState({ sort: 18, sort_type: 'DESC' });
   const [favourite, setFavourite] = useState([]);
   const [category1, setCategory1] = useState('');
   const [category2, setCategory2] = useState('');
   const [categoryId1, setCategoryId1] = useState('');
   const [categoryId2, setCategoryId2] = useState('');
   const [keyword, setKeyword] = useState('');
   const [searchClick, setSearchClick] = useState(0);
   const sortClass = sorting.sort_type == 'DESC' ? 'sorting-desc' : 'sorting-asc';
   const sortType = sorting.sort_type == 'DESC' ? 'ASC' : 'DESC';
   const [contentModal, setContentModal] = useState(false);
   const [tikTokEmbedID, setTikTokEmbedID] = useState('');

   const tableHead = [
      {
         title: t('common.rank'),
         className: 'text-center',
      },
      {
         title: t('common.influencers'),
         className: 'text-rigth',
      },
      {
         title: t('common.videos'),
         className: 'text-rigth',
      },
      {
         title: t('common.revenue'),
         className: 'text-center sorting',
         sort: 18
      },
      {
         title: t('common.sales'),
         className: 'text-center sorting',
         sort: 19
      },
      {
         title: t('common.views'),
         className: 'text-center sorting',
         sort: 3
      },
      {
         title: t('common.likes'),
         className: 'text-center sorting',
         sort: 8
      },
      {
         title: t('common.comments'),
         className: 'text-center sorting',
         sort: 5,
      },
      {
         title: t('common.shares'),
         className: 'text-center sorting',
         sort: 6,
      },
      {
         title: t('common.post_date'),
         className: 'text-center sorting',
         sort: 26
      },
      {
         title: t('common.favorite'),
         className: 'text-center',
      },
   ];

   useImperativeHandle(ref, () => ({
      async relevantVideosTab() {
         if (onLoad == false) {
            relevantVideos(page);
         }
         setOnLoad(true);
      }
   }));

   useEffect(() => {
      if (onLoad == true) {
         setPage(0);
         relevantVideos(0);
      }
   }, [sorting, category1, category2, searchClick]);

   const relevantVideos = async (page) => {
      try {
         let data = {
            sort: sorting.sort,
            page: page,
            sort_type: sorting.sort_type,
            seller_id: params.shop_id,
         }

         if (keyword) {
            data['keyword'] = keyword;
         }
         if (category1 != '') {
            data['category1'] = category1;
            data['category_id1'] = categoryId1;
         }
         if (category2 != '') {
            data['category2'] = category2;
            data['category_id2'] = categoryId2;
         }

         let response = await Axios.post('/api/shop/relevant-videos', data);
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });
         if (response && response.status === 200) {
            let array = await response.data.favorite ? response.data.favorite.map((value, index) => value.video_id) : favourite;

            if (page == 0) {
               setItems(response.data.data);
               setFavourite(array);
            } else {
               setItems(items.concat(response.data.data));
               setFavourite(favourite.concat(array));
            }
            setHasMore(response.data.data.length === 0 || response.data.data.length < 20 ? false : true);
         }
      } catch (error) {
         console.error('Error: ' + error.message);
      };
   }
   const handleSort = async (sort, sortType) => {
      if (sort != sorting.sort || (sortType && sortType != sorting.sort_type)) {
         setSorting({ sort, sort_type: sortType });
      }
   };
   const functionNext = async () => {
      if (items.length > 0) {
         let number = page + 1;
         setPage(number);
         relevantVideos(number);
      }
   }

   const handleCategory = async (categoryIds, categoryNames) => {
      setCategory1(categoryNames[0] ? categoryNames[0] : '');
      setCategory2(categoryNames[1] ? categoryNames[1] : '');
      setCategoryId1(categoryIds[0] ? categoryIds[0] : '');
      setCategoryId2(categoryIds[1] ? categoryIds[1] : '');
   };

   const markFavourite = async (data) => {
      await Axios.post('/api/favorite-product-video', data);
      const index = favourite.indexOf(data.influencer_id);
      if (index > -1) {
         favourite.splice(index, 1);
      } else {
         favourite.push(data.influencer_id);
      }

      setFavourite(favourite.concat([]));
   };

   return (
      <>
         <Card className='rounded-0'>
            <Card.Body>
               <div className='topfilter'>
                  <h3>{t('common.video_list')}</h3>
                  <div className="d-flex align-items-center mb-3 justify-content-end">
                     <div className="input-group mb-3 w-auto">
                        <input type="text" className="form-control form-control-sm" placeholder={t("common.search_videos")} value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                        <div className="input-group-append">
                           <button className="btn btn-primary btn-xs" type="button" onClick={() => { setItems([]); setSearchClick(searchClick + 1); }}>
                              {t('common.search')}
                           </button>
                        </div>
                     </div>
                  </div>
                  <Category category={props.category} showCategory={props.shop?.category_list} handleCategory={handleCategory} categoryId1={categoryId1} />
               </div>
            </Card.Body>
         </Card>
         <Card>
            <Card.Body className='p-2'>
               <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                     <InfiniteScrollWrapper
                        isInfiniteScrollOn={true}
                        lengthData={items.length}
                        functionNext={functionNext}
                        hasMore={hasMore}
                     >
                        <table id="example" className="display w-100 dataTable">
                           <thead>
                              <tr role="row">
                                 {tableHead.map((value, index) => (
                                    <th className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort ? sortClass : '')} onClick={(e) => { value.sort && handleSort(value.sort, sorting.sort == value.sort ? sortType : 'DESC') }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                                 ))}
                              </tr>
                           </thead>
                           <tbody>
                              {items.map((value, index) => (
                                 <tr key={index}>
                                    <td className="text-center">{index + 1}</td>
                                    <td>
                                       <div className="d-flex">
                                          <div className="prd-img mr-3">
                                             <Link to={'/products/' + value.id + '/video-details'} target="_blank">
                                                {value.origin_cover_privatization && <img src={"https://t-img.picturehaven.net/tikmeta/" + value.origin_cover_privatization + "?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={80} />}
                                             </Link>
                                             <Button
                                                variant="primary"
                                                className="p-0 video-popup-btn"
                                                onClick={() => { setTikTokEmbedID(value.id); setContentModal(!contentModal); }}
                                             >
                                                <i className="fa fa-play-circle" aria-hidden="true"></i>
                                             </Button>
                                          </div>
                                          <div className="prd-title">
                                             <OverlayTrigger overlay={<Tooltip>{value.desc}</Tooltip>}>
                                                <Link to={'/products/' + value.id + '/video-details'} target="_blank">
                                                   <p className='mb-2'>{value.desc.length > 40 ? value.desc.substring(0, 40) + '...' : value.desc}</p>
                                                </Link>
                                             </OverlayTrigger>
                                             <div className="d-flex align-items-center">
                                                <span className="text-muted">
                                                   Video duration: {value.video_duration || ''}s
                                                </span>
                                             </div>
                                          </div>
                                       </div>
                                    </td>
                                    <td>
                                       <div className="d-flex">
                                          <div className="prd-img mr-3">
                                             <OverlayTrigger
                                                key="right"
                                                trigger={["hover", "focus"]}
                                                placement="right"
                                                rootClose
                                                overlay={
                                                   <Popover>
                                                      <Popover.Body>
                                                         {value.avatar_larger_privatization && <img src={'https://t-img.picturehaven.net/tikmeta/' + value.avatar_larger_privatization + '?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={500} />}
                                                      </Popover.Body>
                                                   </Popover>
                                                }
                                             >
                                                <Button variant="link" className='p-0' size="xs">
                                                   <Link to={'/influencers/details/' + value.author_id + '/breakout'} target="_blank">
                                                      {value.avatar_larger_privatization && <img src={'https://t-img.picturehaven.net/tikmeta/' + value.avatar_larger_privatization + '?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={80} />}
                                                   </Link>
                                                </Button>
                                             </OverlayTrigger>
                                          </div>
                                          <div className="prd-title">
                                             <p className='mb-2'>
                                                <Link to={'/influencers/details/' + value.author_id + '/breakout'} target="_blank">
                                                   {value.author_nickname || '-'}
                                                </Link>
                                             </p>
                                             <div className="d-flex align-items-center">
                                                <span className="text-muted">  {value.sys_country && <span className={value?.sys_country?.country_code.toLowerCase() ? `fi fi-${value?.sys_country?.country_code.toLowerCase()} mr-1` : ''}></span>}{value.sys_country && value.sys_country.country}</span>
                                             </div>
                                             <div className="d-flex align-items-center">
                                                <span className="text-muted">
                                                   Followers: <Conversion value={value.author_follower_count || 0} />
                                                </span>
                                             </div>
                                          </div>
                                       </div>
                                    </td>
                                    <td className="text-center">
                                       {value.total_estimated_sales_amount_usd || ''}<br />
                                       <span className="text-muted">({value.total_estimated_sales_amount_str || '-'})</span>
                                    </td>
                                    <td className="text-center">{value.total_estimated_sales_volume_str || '-'}</td>
                                    <td className="text-center"><Conversion value={value.play_count || 0} /></td>
                                    <td className="text-center"><Conversion value={value.digg_count || 0} /></td>
                                    <td className="text-center"><Conversion value={value.comment_count || 0} /></td>
                                    <td className="text-center"><Conversion value={value.share_count || 0} /></td>
                                    <td className="text-center">{value.create_time ? new Date(value.create_time * 1000).toISOString().split('T')[0] : '-'}</td>
                                    <td className="text-center">
                                       <Button
                                          variant="outline-dark" size="xs"
                                          className="px-2 py-1"
                                          onClick={() => { markFavourite({ id: value.id, country_code: value.sys_country.country_code }); }}>
                                          <i className={"fa " + (favourite.indexOf(value.id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                                       </Button>
                                    </td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>
                     </InfiniteScrollWrapper>
                  </div>
               </Table>
            </Card.Body>
         </Card>
         <Modal className="fade video-popup" show={contentModal} >
            <Modal.Body className='p-0'>
               <div className='d-flex'>
                  <div className='text-right'>
                     <TikTokEmbed url={`https://www.tiktok.com/embed/${tikTokEmbedID}`} width={325} />
                  </div>
                  <div className='px-3'>
                     <div className='d-flex flex-column'>
                        <Button
                           variant="dark"
                           className="mb-3"
                           onClick={() => { setTikTokEmbedID(''); setContentModal(!contentModal); }}
                        >
                           <i className="fa fa-times" aria-hidden="true"></i>
                        </Button>
                        {/* <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-line-chart" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-download" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-file-code-o" aria-hidden="true"></i>
                        </Button> */}
                     </div>
                  </div>
               </div>
            </Modal.Body>
         </ Modal>
      </>
   )
});

export default RelevantVideosTab;