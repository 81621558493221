import React, { useContext } from 'react';
import { Button, Table } from "react-bootstrap";
import { Context } from '../../../MasterLayout.js';
import { useTranslation } from 'react-i18next';
import InfiniteScrollWrapper from '../Products/InfiniteScrollWrapper.js';
import moment from 'moment';
import { Link } from 'react-router-dom';

const OrdersTable = ({ items = [], functionNext = () => { }, hasMore = false }) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(Context);

  const headList = [
    {
      title: 'Order ID',
      className: '',
      sort: '',
    },
    {
      title: 'Created Date',
      className: '',
      sort: '',
    },
    {
      title: 'Customer',
      className: '',
      sort: '',
    },
    {
      title: 'Price',
      className: '',
      sort: '',
    },
    {
      title: 'Status',
      className: '',
      sort: '',
    },
    {
      title: 'Action',
      className: '',
      sort: '',
    },
  ];

  return (
    <div>
      <Table responsive className="w-100">
        <div id="example_wrapper" className="dataTables_wrapper">
          <InfiniteScrollWrapper
            isInfiniteScrollOn={true}
            lengthData={items.length}
            functionNext={functionNext}
            hasMore={hasMore}
          >
            <table id="example" className="display w-100 dataTable">
              <thead>
                <tr role="row">
                  {headList.map((v, i) => (<th key={i} className={v.className}>{v.title}</th>))}
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={index}>
                    <td className="">
                      {item?.id}
                    </td>
                    <td className="">
                      {item?.create_time ? moment(item?.create_time).format("DD-MM-YYYY") : '-'}
                    </td>
                    <td className="">
                      {item?.recipient_address?.name}
                    </td>
                    <td className="">
                      {item?.payment?.original_total_product_price}
                    </td>
                    <td className="">
                      {item?.status}
                    </td>
                    <td className="">
                      <Link to={`/tiktok-store/orders/${item?._id}/manage-order`} target="_blank">
                        <Button variant="outline-primary" className="btn-xs">
                          <i className="fa fa-lg fa-gears" aria-hidden="true"></i>
                        </Button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>

            </table>
          </InfiniteScrollWrapper>
        </div>
      </Table >
    </div >
  );
};

export default OrdersTable;