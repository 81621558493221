import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import InfiniteScrollWrapper from '../../Products/InfiniteScrollWrapper';
import { useTranslation } from 'react-i18next';
import Conversion from '../../CommonComponents/Conversion';
import { TikTokEmbed } from 'react-social-media-embed';

const BreakoutSongs = ({ functionNext, items, favourite, markFavourite, hasMore, handleSort, sorting }) => {
   const { t } = useTranslation();
   const [tikTokEmbedID, setTikTokEmbedID] = useState('');
   const [contentModal, setContentModal] = useState(false);

   const tableHead = [
      { title: t('creator_center.songs_ranking.table.ranking'), className: 'text-right' },
      { title: t('creator_center.songs_ranking.table.songs') },
      { title: t('creator_center.songs_ranking.table.title'), className: 'text-left' },
      { title: t('creator_center.songs_ranking.table.duration'), className: 'text-right' },
      { title: t('creator_center.songs_ranking.table.videos'), className: 'text-right' },
      { title: t('creator_center.songs_ranking.table.hotVideos'), className: 'text-left' },
      { title: t('creator_center.songs_ranking.table.favourite'), className: 'text-right' }
   ];

   const formatDuration = (durationInSeconds) => {
      if (!durationInSeconds || isNaN(durationInSeconds)) return '-';
      const hours = Math.floor(durationInSeconds / 3600);
      const minutes = Math.floor((durationInSeconds % 3600) / 60);
      const seconds = durationInSeconds % 60;
      return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
   };

   const pad = (number) => {
      return number.toString().padStart(2, '0');
   };

   return (
      <>
         <InfiniteScrollWrapper
            isInfiniteScrollOn={true}
            lengthData={items.length}
            functionNext={functionNext}
            hasMore={hasMore}
         >
            <table id="example" className="display w-100 dataTable">
               <thead>
                  <tr role="row">
                     {tableHead.map((value, index) => (
                        <th key={index} className={(value.className || '') + ' ' + (value.sort && value.sort === sorting.sort ? 'sorting-desc-blue' : (value.sort ? 'sorting-desc-icon' : ''))} onClick={(e) => { value.sort && handleSort(value.sort) }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                     ))}
                  </tr>
               </thead>
               <tbody>
                  {items.map((song, index) => (
                     <tr key={index}>
                        <td><p className="ml-4">{index + 1}</p></td>
                        <td>
                           <div className="d-flex">
                              <div className="prd-img mr-3">
                                 {song.cover_large_privatization && <img src={"https://t-img.picturehaven.net/tikmeta/" + song.cover_large_privatization + "?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />}
                                 <Button variant="primary" className="p-0 video-popup-btn" onClick={() => { setTikTokEmbedID(song.id); setContentModal(!contentModal); }}>
                                    <i className="fa fa-play-circle" aria-hidden="true"></i>
                                 </Button>
                              </div>
                           </div>
                        </td>
                        <td className="text-left">
                           <div className="prd-title">
                              <p>
                                 <OverlayTrigger overlay={<Tooltip>{song.title}</Tooltip>}>
                                    <Link to={'/song/' + song.id + '/details'}>
                                       <span className='font-weight-normal text-dark'>{song.title.length > 40 ? song.title.substring(0, 40) + '...' : song.title}</span>
                                    </Link>
                                 </OverlayTrigger>
                              </p>
                           </div>
                        </td>
                        <td className="text-left duration-cell">{formatDuration(song.duration) || '-'}</td>
                        <td className="text-left">{song?.video_count ? <Conversion value={song?.video_count} /> : '--'}</td>
                        <td className="text-left">
                           <div className="d-flex justify-content-left align-items-left">
                              {song.top_video_list && song.top_video_list.map(video => (
                                 <div className="prd-img mx-1">
                                    {video.origin_cover_privatization &&
                                       <img src={"https://t-img.picturehaven.net/tikmeta/" + video.origin_cover_privatization + "?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />
                                    }
                                    <Button variant="primary" className="p-0 video-popup-btn" onClick={() => { setTikTokEmbedID(video.id); setContentModal(!contentModal); }}>
                                       <i className="fa fa-play-circle" aria-hidden="true"></i>
                                    </Button>
                                 </div>
                              ))}
                           </div>
                        </td>
                        <td className="text-left">
                           <div className="d-flex justify-content-left">
                              {<Button
                                 variant="outline-dark" size="xs"
                                 className="px-2 py-1"
                                 onClick={() => { markFavourite({ music_id: song.id, nickname: song?.nickname }); }}>
                                 <i className={"fa " + (favourite.indexOf(song.id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                              </Button>}
                           </div>
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
         </InfiniteScrollWrapper>
         <Modal className="fade video-popup" show={contentModal}>
            <Modal.Body className='p-0'>
               <div className='d-flex'>
                  <div className='text-center'>
                     <TikTokEmbed url={`https://www.tiktok.com/embed/${tikTokEmbedID}`} width={325} />
                  </div>
                  <div className='px-3'>
                     <div className='d-flex flex-column'>
                        <Button
                           variant="dark"
                           className="mb-3"
                           onClick={() => { setTikTokEmbedID(''); setContentModal(!contentModal); }}
                        >
                           <i className="fa fa-times" aria-hidden="true"></i>
                        </Button>      
                     </div>
                  </div>
               </div>
            </Modal.Body>
         </Modal>
      </>
   );
};

export default BreakoutSongs;
