import React, { useState } from "react";
import logo from '../../images/logo.png';
import FooterSlick from "./components/FooterSlick";
import Axios from "../plugins/axios";

const ForgotPassword = () => {
   const [email, setEmail] = useState('');
   const [validEmail, setValidEmail] = useState(true);
   const [emailMessage, setEmailMessage] = useState('');


   const handleBlur = (e) => {
      setEmail(e.target.value);
   };

   const checkEmail = async () => {
      // eslint-disable-next-line no-useless-escape
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (emailRegex.test(email)) {
         try {
            const response = await Axios.post('/api/forgotpassword', {
               email: email.toLocaleLowerCase(),
            });

            if (response && response.status === 200) {
               if (response.data && response.data.statusCode === 200) {
                  if (response.data.validEmail) {
                     setValidEmail(true);
                     setEmailMessage(response.data.message);
                     // setDataMessage(response.data.message);
                  } else {
                     setValidEmail(false);
                     setEmailMessage(response.data.message);
                  }
               } else {
                  setValidEmail(false);
                  setEmailMessage(response.data.message);

               }
            } else {
               setValidEmail(false);
               setEmailMessage('Server Error, Try Again Later');
            }
         } catch (error) {
            setValidEmail(false);
            setEmailMessage('Server Error, Try Again Later');
            console.error('Catch: onBlurEmail ' + error.message);
         }
      } else {
         setValidEmail(false);
         setEmailMessage('Please Enter A Valid E-mail Address.');
      }
   };

   const submitHandler = async (e) => {
      e.preventDefault();
      await checkEmail();  // Call the checkEmail function
      // Now you can use validEmail, emailMessage, and goSteps as needed
   };

   return (
      <>
         <div className="row justify-content-center align-items-center h-80">
            <div className="col-md-6">
               <div className="row no-gutters">
                  <div className="col-xl-12">
                     <div className="auth-form">
                        <div className="text-center mb-3">
                           <a href="/"><img src={logo} alt="Viralytic" /></a>
                        </div>
                        <h4 className="text-center mb-4"> Forgot Password</h4>
                        <div className="authincation-content p-5">
                           <form onSubmit={submitHandler}>
                              <div className="form-group">
                                 <label className="mb-1 text-white"> <strong>Email</strong>  </label>
                                 <input type="email" value={email} className={`form-control ${validEmail ? '' : 'is-invalid'}`} placeholder="hello@example.com" onChange={handleBlur} />
                                 {!validEmail && <div className="invalid-feedback">{emailMessage}</div>}
                              </div>
                              <div className="text-center mt-4">
                                 <button type="submit" className="btn bg-white text-primary btn-block">SUBMIT</button>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div >
         <FooterSlick />
      </>
   );
};

export default ForgotPassword;
