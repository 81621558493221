import React, { forwardRef, useState, useImperativeHandle } from "react";
import { Table, Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Conversion from "../../../CommonComponents/Conversion";
import { useTranslation } from 'react-i18next';


const VideoMatrix = forwardRef((props, ref) => {
   const { t } = useTranslation();
   const liveHeadList = [
      {
         title: t('product_research.product_details.date'),
         className: 'text-right sorting',
         sort: 'live_date',
      },
      {
         title: t('product_research.product_details.number_of_live_br_broadcasts'),
         className: 'text-right sorting',
         sort: 'live_count',
      },
      {
         title: t('product_research.product_details.calculative_viewers'),
         className: 'text-right sorting',
         sort: 'total_user',
      },
      {
         title: t('product_research.product_details.average_viewers'),
         className: 'text-right sorting',
         sort: 'avg_user_count',
      },
      {
         title: t('product_research.product_details.online_br_viewers_peak'),
         className: 'text-right sorting',
         sort: 'max_user_count',
      },
      {
         title: t('product_research.product_details.sales_conversion_rate'),
         className: 'text-right sorting',
         sort: 'sales_conversion_rate',
      },
      {
         title: t('common.sales'),
         className: 'text-right sorting',
         sort: 'live_sales',
      },
   ];

   const videoHeadList = [
      {
         title: t('product_research.product_details.date'),
         className: 'text-right sorting',
         sort: 'video_date',
      },
      {
         title: t('product_research.product_details.number_of_Videos'),
         className: 'text-right sorting',
         sort: 'video_count',
      },
      {
         title: t('common.views'),
         className: 'text-right sorting',
         sort: 'play_count',
      },
      {
         title: t('common.likes'),
         className: 'text-right sorting',
         sort: 'digg_count',
      },
      {
         title: t('common.comments'),
         className: 'text-right sorting',
         sort: 'comment_count',
      },
      {
         title: t('product_research.product_details.shares'),
         className: 'text-right sorting',
         sort: 'share_count',
      },
      {
         title: t('common.sales'),
         className: 'text-right sorting',
         sort: 'video_sales',
      },
   ];

   const [videoPromotionTrend, setVideoPromotionTrend] = useState({});
   const [liveVideoSorting, setLiveVideoSorting] = useState({ sort: 'live_date', sort_type: 'DESC' });
   const liveVideoSortType = liveVideoSorting.sort_type == 'DESC' ? 'ASC' : 'DESC';
   const liveVideoSortClass = liveVideoSorting.sort_type == 'DESC' ? 'sorting-desc' : 'sorting-asc';


   useImperativeHandle(ref, () => ({
      async videoTrand(data) {
         let live_date = [...data.live_trends.reverse()];
         let video_date = [...data.video_trends.reverse()];
         data = { ...data, live_date, video_date };
         setVideoPromotionTrend(data);
      },
   }));

   const videoTrendsSort = async (sort, sortType) => {
      if (sort != liveVideoSorting.sort || (sortType && sortType != liveVideoSorting.sort_type)) {
         let obj = { ...videoPromotionTrend };
         if (sort == 'live_date') {
            obj.live_date.reverse();
         } else if (sort == 'live_sales') {
            sortType == 'ASC' ? obj.live_trends.sort(function (a, b) { return b.sold_count - a.sold_count }) : obj.live_trends.sort(function (a, b) { return a.sold_count - b.sold_count });
         } else if (obj.live_trends[0].hasOwnProperty(sort)) {
            sortType == 'ASC' ? obj.live_trends.sort(function (a, b) { return b[sort] - a[sort] }) : obj.live_trends.sort(function (a, b) { return a[sort] - b[sort] });
         } else if (sort == 'video_date') {
            obj.video_date.reverse();
         } else if (sort == 'video_sales') {
            sortType == 'ASC' ? obj.video_trends.sort(function (a, b) { return b.sold_count - a.sold_count }) : obj.video_trends.sort(function (a, b) { return a.sold_count - b.sold_count });
         } else if (obj.video_trends[0].hasOwnProperty(sort)) {
            sortType == 'ASC' ? obj.video_trends.sort(function (a, b) { return b[sort] - a[sort] }) : obj.video_trends.sort(function (a, b) { return a[sort] - b[sort] });
         };
         setVideoPromotionTrend(obj);
         setLiveVideoSorting({ sort, sort_type: sortType });
      };
   };


   return (

      <div className="row mb-4">
         <div className="col-lg-12">
            <h3>{t('product_research.product_details.metrics')}</h3>
         </div>
         <div className="col-lg-12">
            <Tab.Container
               defaultActiveKey="live"
            >
               <Nav as="ul" className="nav-tabs mb-2 bg-white">
                  <Nav.Item as="li" key="live">
                     <Nav.Link eventKey="live" as={Link} onClick={() => { setLiveVideoSorting({ sort: 'live_date', sort_type: 'DESC' }) }}
                     >
                        {t('product_research.product_details.live')}
                     </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" key="videos">
                     <Nav.Link eventKey="videos" as={Link} onClick={() => { setLiveVideoSorting({ sort: 'video_date', sort_type: 'DESC' }) }}
                     >
                         {t('common.videos')}
                     </Nav.Link>
                  </Nav.Item>
               </Nav>
               <Tab.Content>
                  <Tab.Pane
                     eventKey="live"
                     key="live"
                  >
                     <div className="fh-table">
                        <Table responsive className="w-100">
                           <div id="example_wrapper" className="dataTables_wrapper">
                              <table id="example" className="display w-100 dataTable">
                                 <thead>
                                    <tr role="row" className='bg-light'>
                                       {liveHeadList.map((v, i) => (
                                          <th key={i} className={(v.className || '') + ' ' + (v.sort && v.sort == liveVideoSorting.sort ? liveVideoSortClass : '')} onClick={() => { v.sort && videoTrendsSort(v.sort, liveVideoSorting.sort == v.sort ? liveVideoSortType : 'DESC') }} dangerouslySetInnerHTML={{ __html: v.title }}></th>
                                       ))}
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {videoPromotionTrend.live_date && liveVideoSorting.sort == 'live_date' && videoPromotionTrend.live_date.map((value, index) => (
                                       <tr key={index}>
                                          <td className="text-right">{value.date || '-'}</td>
                                          <td className="text-right">{value.live_count || '-'}</td>
                                          <td className="text-right">{value.total_user || '-'}</td>
                                          <td className="text-right">{value.avg_user_count || '-'}</td>
                                          <td className="text-right">{value.max_user_count || '-'}</td>
                                          <td className="text-right">{value.sales_conversion_rate ? parseFloat(value.sales_conversion_rate * 100).toFixed(2) + '%' : '-'}</td>
                                          <td className="text-right">{value.sold_count || '-'}</td>
                                       </tr>
                                    ))}
                                    {videoPromotionTrend.live_date && liveVideoSorting.sort == 'live_date' && videoPromotionTrend.live_date.map((value, index) => (
                                       <tr key={index}>
                                          <td className="text-right">{value.date || '-'}</td>
                                          <td className="text-right"><Conversion value={value.live_count || 0} /></td>
                                          <td className="text-right"><Conversion value={value.total_user || 0} /></td>
                                          <td className="text-right"><Conversion value={value.avg_user_count || 0} /></td>
                                          <td className="text-right"><Conversion value={value.max_user_count || 0} /></td>
                                          <td className="text-right">{value.sales_conversion_rate ? parseFloat(value.sales_conversion_rate * 100).toFixed(2) + '%' : '-'}</td>
                                          <td className="text-right"><Conversion value={value.sold_count || 0} /></td>
                                       </tr>
                                    ))}
                                    {videoPromotionTrend.live_trends && liveVideoSorting.sort != 'live_date' && videoPromotionTrend.live_trends.map((value, index) => (
                                       <tr key={index}>
                                          <td className="text-right">{value.date || '-'}</td>
                                          <td className="text-right"><Conversion value={value.live_count || 0} /></td>
                                          <td className="text-right"><Conversion value={value.total_user || 0} /></td>
                                          <td className="text-right"><Conversion value={value.avg_user_count || 0} /></td>
                                          <td className="text-right"><Conversion value={value.max_user_count || 0} /></td>
                                          <td className="text-right">{value.sales_conversion_rate ? parseFloat(value.sales_conversion_rate * 100).toFixed(2) + '%' : '-'}</td>
                                          <td className="text-right"><Conversion value={value.sold_count || 0} /></td>
                                       </tr>
                                    ))}
                                 </tbody>
                              </table>
                           </div>
                        </Table>
                     </div>
                  </Tab.Pane>
                  <Tab.Pane
                     eventKey="videos"
                     key="videos"
                  >
                     <div className="fh-table">
                        <Table responsive className="w-100">
                           <div id="example_wrapper" className="dataTables_wrapper">
                              <table id="example" className="display w-100 dataTable">
                                 <thead>
                                    <tr role="row" className='bg-light'>
                                       {videoHeadList.map((v, i) => (
                                          <th key={i} className={(v.className || '') + ' ' + (v.sort && v.sort == liveVideoSorting.sort ? liveVideoSortClass : '')} onClick={() => { v.sort && videoTrendsSort(v.sort, liveVideoSorting.sort == v.sort ? liveVideoSortType : 'DESC') }} dangerouslySetInnerHTML={{ __html: v.title }}></th>
                                       ))}
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {videoPromotionTrend.video_date && liveVideoSorting.sort == 'video_date' && videoPromotionTrend.video_date.map((value, index) => (
                                       <tr key={index}>
                                          <td className="text-right">{value.date || '-'}</td>
                                          <td className="text-right">{value.video_count || '-'}</td>
                                          <td className="text-right">{value.play_count || '-'}</td>
                                          <td className="text-right">{value.digg_count || '-'}</td>
                                          <td className="text-right">{value.comment_count || '-'}</td>
                                          <td className="text-right">{value.share_count || '-'}</td>
                                          <td className="text-right">{value.sold_count || '-'}</td>
                                       </tr>
                                    ))}
                                    {videoPromotionTrend.video_date && liveVideoSorting.sort == 'video_date' && videoPromotionTrend.video_date.map((value, index) => (
                                       <tr key={index}>
                                          <td className="text-right">{value.date || '-'}</td>
                                          <td className="text-right"><Conversion value={value.video_count || 0} /></td>
                                          <td className="text-right"><Conversion value={value.play_count || 0} /></td>
                                          <td className="text-right"><Conversion value={value.digg_count || 0} /></td>
                                          <td className="text-right"><Conversion value={value.comment_count || 0} /></td>
                                          <td className="text-right"><Conversion value={value.share_count || 0} /></td>
                                          <td className="text-right"><Conversion value={value.sold_count || 0} /></td>
                                       </tr>
                                    ))}
                                    {videoPromotionTrend.video_trends && liveVideoSorting.sort != 'video_date' && videoPromotionTrend.video_trends.map((value, index) => (
                                       <tr key={index}>
                                          <td className="text-right">{value.date || '-'}</td>
                                          <td className="text-right"><Conversion value={value.video_count || 0} /></td>
                                          <td className="text-right"><Conversion value={value.play_count || 0} /></td>
                                          <td className="text-right"><Conversion value={value.digg_count || 0} /></td>
                                          <td className="text-right"><Conversion value={value.comment_count || 0} /></td>
                                          <td className="text-right"><Conversion value={value.share_count || 0} /></td>
                                          <td className="text-right"><Conversion value={value.sold_count || 0} /></td>
                                       </tr>
                                    ))}
                                 </tbody>
                              </table>
                           </div>
                        </Table>
                     </div>
                  </Tab.Pane>
               </Tab.Content>
            </Tab.Container >
         </div>
      </div>
   );
});

export default VideoMatrix;