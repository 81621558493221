import React, { useContext, useEffect, useState } from 'react'
import Category from '../../Products/Category'
import { Button, Card, OverlayTrigger, Popover, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import Type from '../../CommonComponents/Type';
import Axios from '../../../plugins/axios';
import InfiniteScrollWrapper from '../../Products/InfiniteScrollWrapper';
import Conversion from '../../CommonComponents/Conversion';
import { FormatTime } from '../../CommonComponents/FormatNumber';
import { Context } from '../../../../MasterLayout';
import { Link } from 'react-router-dom';

function HotLiveChannelFilter() {
    const { dispatch } = useContext(Context);

    const { t } = useTranslation();
    const rankType = 'LIVE_RISING'
    const [countryCode, setCountryCode] = useState('US');
    const [category1, setCategory1] = useState('');
    const [category2, setCategory2] = useState('');
    const [categoryId1, setCategoryId1] = useState('');
    const [categoryId2, setCategoryId2] = useState('');
    const [versionList, setVersionList] = useState([]);
    const [version, setVersion] = useState('');
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [items, setItems] = useState([]);
    const cycle = "H1";
    const [sorting, setSorting] = useState({ sort: "51", sort_type: 'DESC' });
    const [prev, setPrev] = useState(false);
    const [next, setNext] = useState(false);
    const [num, setnum] = useState(6);
    const [type, setType] = useState('');
    const [Timebuttons, setTimebuttons] = useState([])
    const [TimebuttonsDisplay, setTimebuttonsDisplay] = useState([])
    const [DayVersion, setDayVersion] = useState("")
    const [PrevVersion, setPrevVersion] = useState({ startdateprev: "", enddateprev: "" })
    const [timeKey, setTimekey] = useState('')

    const hotLive = async (page) => {
        try {
            let params = {
                page,
                sort: sorting.sort,
            };
            if (countryCode != '') {
                params['country_code'] = countryCode;
            }


            if (cycle != '') {
                params['cycle'] = cycle;
            }
            if (version != '' && DayVersion === '') {
                params['version'] = version;
            }
            if (DayVersion != '') {
                params['version'] = DayVersion;
            }

            //CATEGORY
            if (category1 != '') {
                params['category1'] = category1;
            }
            if (category2 != '') {
                params['category2'] = category2;
            }
            if (categoryId1 != '') {
                params['category_id1'] = categoryId1;
            }
            if (categoryId2 != '') {
                params['category_id2'] = categoryId2;
            }

            if (type != '') {
                params['category'] = type.namecn;
            }
            params['sort_type'] = sorting.sort_type;

            let response = await Axios.post('/api/live/hot-live-channels', params);
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });

            if (response && response.status === 200 && response.data.data) {
                if (page == 0) {
                    setItems(response.data.data);
                } else {
                    setItems(items.concat(response.data.data));
                }
                if (response.data.data.length === 0 || response.data.data.length < 20) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                }
            }
        } catch (error) {
            console.error('Error: ' + error.message);
        };
    }

    useEffect(() => {
        //do operation on state change
        setItems([]);
        setPage(0);
        if (version === '') {
            getVersion(cycle);
        } else {
            hotLive(0);
        }
    }, [type, version, countryCode, DayVersion, category1, category2,sorting])

    //VERSION
    const getVersion = async (cycle) => {
        let params = {
            cycle
        }
        if (rankType) {
            params['rank_type'] = rankType;
        }
        let response = await Axios.get('/api/version', { params });
        if (response.data.data) {
            handleVersionlist(response.data.data[0].version)
            setVersion(response.data.data[0].version)
        }
        setVersionList(response.data.data ? response.data.data : []);
    };

    //COUNTRY
    const selectCountry = async (countryCode) => {
        setCountryCode(countryCode);
    };
    const regionList = Object.keys(global.config.region).map(function (key) {
        return <a key={key} className={"btn my-1 " + (countryCode == key ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 d-flex align-items-center mb-2"} onClick={() => { selectCountry(key); }}><span className={`fi fi-${key.toLocaleLowerCase()} mr-1`}></span>{global.config.region[key]}</a>
    });

    //CATEGORY

    const handleCategory = async (categoryIds, categoryNames) => {
        setCategory1(categoryNames[0] ? categoryNames[0] : '');
        setCategory2(categoryNames[1] ? categoryNames[1] : '');
        setCategoryId1(categoryIds[0] ? categoryIds[0] : '');
        setCategoryId2(categoryIds[1] ? categoryIds[1] : '');
    };

    //TYPE
    const setSingleType = async (e) => {
        setType(e);
    }
    const handleType = async (categoryIds, categoryNames) => {
        setType(categoryNames[0])
    };

    //TIMINGS 
    const handleClick = (time) => {
        setTimekey(time);
        const currentDate = PrevVersion.enddateprev;
        currentDate.setHours(parseInt(time), 0, 0, 0);
        const startDate = currentDate.toISOString().substring(0, 10) + ('0' + time).slice(-2) + '00';
        currentDate.setHours(currentDate.getHours() + 1);
        const endDate = currentDate.toISOString().substring(0, 10) + ('0' + currentDate.getHours()).slice(-2) + '00';
        const newstartDate = startDate.replace("-", "");
        const newenddate = endDate.replace("-", "");
        const dateRange = 'H1-' + newstartDate.replace("-", "").slice(0, -2) + '-' + newenddate.replace("-", "").slice(0, -2);
        setDayVersion(dateRange)

    };

    const handleVersionlist = (e) => {
        setVersion(e);
        setDayVersion("");
        const dateString = e.substring(3);
        const startDateString = dateString.substring(0, 8);
        const endDateString = dateString.substring(11);
        const startDate = new Date(`${startDateString.slice(0, 4)}-${startDateString.slice(4, 6)}-${startDateString.slice(6, 8)}`);
        const endDate = new Date(`${endDateString.slice(0, 4)}-${endDateString.slice(4, 6)}-${endDateString.slice(6, 8)}`);

        const buttons = generateButtons(startDate, endDate);
        setTimebuttons(buttons);
        setTimebuttonsDisplay(buttons.slice(0, 6))
        if (buttons.length > 6) {
            setNext(true)
        } else { setNext(false) }
        setPrev(false);
        setnum(6)
    }


    const generateButtons = (startDateE, endDateE) => {
        const buttons = [];
        const startDate = startDateE;
        const endDate = endDateE;
        const enddatecurrenttime = new Date(endDate).toDateString() === new Date().toDateString() ? true : false
        if (enddatecurrenttime) {
            startDate.setHours(0, 0, 0, 0);
        } else {
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 0, 0, 0);
        }
        setPrevVersion({ startdateprev: startDate, enddateprev: endDate });
        while (startDate <= endDate) {
            const hour = startDate.getHours();
            const formattedHour = hour < 10 ? '0' + hour : hour;
            buttons.push(
                { formattedHour: formattedHour, key: hour }
            );
            startDate.setHours(hour + 1);
        }

        return buttons;
    };

    const handleNextTimebuttons = () => {
        const selectedbuttons = Timebuttons.slice(num, num + 6);

        setPrev(true)
        setnum(num + 6)
        setTimebuttonsDisplay(Timebuttons.slice(num, num + 6))
        if (selectedbuttons[5].key === Timebuttons[Timebuttons.length - 1].key) {
            setNext(false)
        }
    }

    const handlePrevTimebuttons = () => {
        const selectedbuttons = Timebuttons.slice(num - 12, num - 6)
        setnum(num - 6)
        setTimebuttonsDisplay(Timebuttons.slice(num - 12, num - 6))
        if (selectedbuttons[0].key === Timebuttons[0].key) {
            setPrev(false)
        } else {
            setNext(true)
        }

    }

    const functionNext = async () => {
        let number = page + 1;
        setPage(number);
        hotLive(number)
    }

    const tableHead = [
        {
            title: t('live.rank'),
        },
        {
            title: t('live.live_intro'),
        },
        {
            title: t('common.influencers'),
        },
        {
            title: t('common.sales'),
            className: 'text-right sorting',
            sort: 51
        },
        {
            title: t('common.revenue'),
            className: 'text-right sorting',
            sort: 52
        }
    ]
    const sortType = sorting.sort_type == 'DESC' ? 'ASC' : 'DESC';
    const sortClass = sorting.sort_type == 'DESC' ? 'sorting-desc' : 'sorting-asc';
    const handleSort = async (sort, sortType) => {
        if (sort != sorting.sort || (sortType && sortType != sorting.sort_type)) {
           setSorting({ sort, sort_type: sortType });
        }
     };
     
    return (
        <div>
            <Card className='rounded-0'>
                <Card.Body>
                    <div className='topfilter'>
                        <p><small>{t('common.long_text_1')}</small></p>
                        <div className="d-flex align-items-center flex-wrap mb-2">
                            <p className="m-0 mr-3"><strong>{t('common.country')}</strong></p>
                            {regionList}
                        </div>
                        <Category singleCategory={false} handleCategory={handleCategory} categoryId1={categoryId1} />
                        <Type singleCategory={true} setSingleCategory={setSingleType} handleCategory={handleType} categoryId1={type?.name || ""} />
                        <div className="d-flex align-items-center mb-2">
                            <p className="m-0 mr-3"><strong>{t('live.date_or_time')}</strong></p>

                            <div className="dropdown product-rating-menu">
                                <div className="form-group mb-0 mr-3">
                                    <select className="form-control form-control-sm" onChange={(e) => handleVersionlist(e.target.value)} value={version}>
                                        {versionList.map((value, index) => (
                                            <option key={index} value={value.version}>{value.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            {prev ? <Button onClick={handlePrevTimebuttons}>{t('live.prev')}</Button> : ''}
                            {TimebuttonsDisplay.map((e) => (
                                <a className={"btn " + (e.key === timeKey ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mx-2 mb-2"} key={e.key} onClick={() => handleClick(e.key)}>
                                    {e.formattedHour}:00
                                </a>
                            ))}
                            {next ? <Button onClick={handleNextTimebuttons}>{t('live.next')}</Button> : ''}
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body className='p-2'>
                    <Table responsive className="w-100">
                        <div id="example_wrapper" className="dataTables_wrapper">
                            <div>
                                <InfiniteScrollWrapper
                                    isInfiniteScrollOn={true}
                                    lengthData={items.length}
                                    functionNext={functionNext}
                                    hasMore={hasMore}
                                >
                                    <table id="example" className="display w-100 dataTable">
                                        <thead>
                                            <tr role="row">
                                                {tableHead.map((value, index) => (
                                                    <th className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort ? sortClass : '')} onClick={(e) => { value.sort && handleSort(value.sort, sorting.sort == value.sort ? sortType : 'DESC') }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items?.map((live, index) => (
                                                <tr key={index}>
                                                    <td>{live?.rank}</td>
                                                    <td>
                                                        <div className='d-flex'>
                                                            <div className="prd-img mr-3">
                                                                <OverlayTrigger
                                                                    key="right"
                                                                    trigger={["hover", "focus"]}
                                                                    placement="right"
                                                                    rootClose
                                                                    overlay={
                                                                        <Popover>
                                                                            <Popover.Body>
                                                                                <img alt="" width={500} src={`https://t-img.picturehaven.net/product/${live?.room_cover ? live?.room_cover : live?.author?.author_avatar}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                                            </Popover.Body>
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <Button variant="link" className='p-0' size="xs">
                                                                        <img alt="" width={70} src={`https://t-img.picturehaven.net/product/${live?.room_cover ? live?.room_cover : live?.author?.author_avatar}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className='prd-title'>
                                                                <Link to={'/live/' + live.id + "/" + 'details'} target="_blank">
                                                                <p>{live?.nickname ? live?.nickname : live?.author?.nickname}</p>
                                                                </Link>
                                                                <p> {t('live.duration')}: <FormatTime milliseconds={live?.live_time} /></p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex'>
                                                            <div className="prd-img mr-3">
                                                                <OverlayTrigger
                                                                    key="right"
                                                                    trigger={["hover", "focus"]}
                                                                    placement="right"
                                                                    rootClose
                                                                    overlay={
                                                                        <Popover>
                                                                            <Popover.Body>
                                                                                <img alt="" width={500} src={`https://t-img.picturehaven.net/tikmeta/${live?.author.author_avatar ? live?.author.author_avatar : live?.room_cover}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                                            </Popover.Body>
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <Button variant="link" className='p-0' size="xs">
                                                                        <img alt="" width={70} src={`https://t-img.picturehaven.net/tikmeta/${live?.author.author_avatar ? live?.author.author_avatar : live?.room_cover}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className='prd-title'>
                                                                <span className={`fi fi-${countryCode.toLowerCase()} mr-1`}></span>{live?.sys_country?.country ? live?.sys_country?.country : live?.currency}
                                                                <p> {t('common.followers')}: <Conversion value={live?.author.follower_count} /></p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td><Conversion value={live.sold_count} /></td>
                                                    <td className='text-right'>
                                                        <div>
                                                            {live?.total_sales_usd || '--'}<br />
                                                            <span className='text-muted'>{live?.total_sales ? `(${live?.total_sales})` : '--'}</span>
                                                        </div>
                                                    </td>
                                                </tr>))}
                                        </tbody>

                                    </table></InfiniteScrollWrapper>
                            </div>
                        </div>
                    </Table>
                </Card.Body>
            </Card>
        </div>
    )
}

export default HotLiveChannelFilter