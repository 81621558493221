import React, { useContext, useEffect, useState } from 'react'
import Category from '../../Products/Category'
import { Button, Card, Form, OverlayTrigger, Popover, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import RangeSlider from '../../Products/RangeSlider';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import Axios from '../../../plugins/axios';
import InfiniteScrollWrapper from '../../Products/InfiniteScrollWrapper';
import { FormatTime, TimestampFormat } from '../../CommonComponents/FormatNumber';
import Conversion from '../../CommonComponents/Conversion';
import { Context } from '../../../../MasterLayout';
import { Link } from 'react-router-dom';


function LiveSearchFilter() {
    const { dispatch } = useContext(Context);

    const { t } = useTranslation();
    const [countryCode, setCountryCode] = useState('US');
    const [category1, setCategory1] = useState('');
    const [category2, setCategory2] = useState('');
    const [categoryId1, setCategoryId1] = useState('');
    const [categoryId2, setCategoryId2] = useState('');
    const [followersRange, setFollowersRange] = useState([]);
    const [sorting, setSorting] = useState({ sort: "52", sort_type: 'DESC' });
    const [SearchValue, setSearchValue] = useState('');
    const [searchkeyword, setsearchkeyword] = useState('');

    const isCommerce = false;
    const cycle = 'H1';
    const [hasMore, setHasMore] = useState(true);
    const [liveStart, setLivestart] = useState("");
    const [liveEnd, setLiveEnd] = useState("");
    const typeList = global.config.chineseCategories;
    const rankType = "LIVE_RISING"
    const [typeValue, settypeValue] = useState("");
    const [page, setPage] = useState(0);
    const [items, setItems] = useState([]);

    const [StoreStatusorgname, setStoreStatusOrgname] = useState(t('live.live_type'));
    const [StoreStatus, setStoreStatus] = useState("");
    const [version, setVersion] = useState('H1');
    const [versionList, setVersionList] = useState([]);
    const [storePerformanceDateRange] = useState({
        'yesterday': {
            start_date: new Date(new Date().setDate(new Date().getDate() - 1)),
            end_date: new Date()
        },
        'last_7_days': {
            start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
            end_date: new Date()
        },
        'last_30_days': {
            start_date: new Date(new Date().setDate(new Date().getDate() - 30)),
            end_date: new Date()
        }
    });
    const [dateRange, setDateRange] = useState({ start_date: new Date(storePerformanceDateRange['yesterday'].start_date), end_date: new Date(storePerformanceDateRange['yesterday'].end_date) });
    const [Live, setLive] = useState(false);
    const [status, setStatus] = useState("");


    //COUNTRY
    const selectCountry = async (countryCode) => {
        setCountryCode(countryCode);
    };
    const regionList = Object.keys(global.config.region).map(function (key) {
        return <a key={key} className={"btn my-1 " + (countryCode == key ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 d-flex align-items-center mb-2"} onClick={() => { selectCountry(key); }}><span className={`fi fi-${key.toLocaleLowerCase()} mr-1`}></span>{global.config.region[key]}</a>
    });

    //CATEGORY

    const handleCategory = async (categoryIds, categoryNames) => {
        setCategory1(categoryNames[0] ? categoryNames[0] : '');
        setCategory2(categoryNames[1] ? categoryNames[1] : '');
        setCategoryId1(categoryIds[0] ? categoryIds[0] : '');
        setCategoryId2(categoryIds[1] ? categoryIds[1] : '');
    };

    const onclickSetStoreStatus = (e) => {
        const value = e.target.getAttribute('value')
        const text = e.target.innerText
        if (value == 1 || value == 2) {
            setStoreStatus(value)
            setStoreStatusOrgname(text);
        } else {
            setStoreStatus("")
            setStoreStatusOrgname(text);
        }
    };

    function dateconevrsion(inputDateString) {
        const date = new Date(inputDateString);
        const currentDate = new Date();
        let currentTime
        const exactcurrentTime = currentDate.getHours() + ':' + currentDate.getMinutes();
        if (exactcurrentTime.length === 4) {
            let [hours, minutes] = exactcurrentTime.split(":");
            minutes = minutes.padStart(2, '0');
            let formattedTime = `${hours}:${minutes}`;
            currentTime = formattedTime;
        } else {
            currentTime = exactcurrentTime;
        }
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const isToday = currentDate.toDateString() === date.toDateString();

        let formattedDateString = `${year}-${month}-${day}`;
        formattedDateString += isToday ? ` ${currentTime}` : ` 00:00`;

        return formattedDateString
    }
    const handleDateRange = (name, filter) => {
        let dateRangeFilter = { ...storePerformanceDateRange };
        dateRangeFilter[name] = filter;
        const livestartdate = dateconevrsion(dateRangeFilter.yesterday.start_date)
        const liveenddate = dateconevrsion(dateRangeFilter.yesterday.end_date)
        setLivestart(livestartdate);
        setLiveEnd(liveenddate)

    };
    const handleApply = (event, picker) => {
        picker.element.val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
        setDateRange({ start_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') })
        handleDateRange("yesterday", { start_date: picker.startDate.toISOString(), end_date: picker.endDate.toISOString() })
    };


    const toggleSwitchLive = () => {
        setLive(!Live);
        Live ? setStatus(4) : setStatus(2)
    }

    const liveSearch = async (page) => {
        try {
            let params = {
                page,
                sort: sorting.sort,
            };
            if (countryCode != '') {
                params['country_code'] = countryCode;
            }

            if (cycle != '') {
                params['cycle'] = cycle;
            }
            if (typeValue) {
                params['category'] = typeList.find(e => e.name === typeValue)?.namecn;
            }
            //Followers Range
            if (followersRange.to || followersRange.from) {
                params['fan_start'] = followersRange.from;
            }
            if (followersRange.to && followersRange.to != '') {
                params['fan_end'] = followersRange.to;
            }
            //CATEGORY
            if (category1 != '') {
                params['category1'] = category1;
            }
            if (category2 != '') {
                params['category2'] = category2;
            }
            if (categoryId1 != '') {
                params['category_id1'] = categoryId1;
            }
            if (categoryId2 != '') {
                params['category_id2'] = categoryId2;
            }
            if (StoreStatus != '') {
                params['live_type'] = StoreStatus;
            }
            if (status != '') {
                params['on_live'] = status;
            }
            if (searchkeyword != '') {
                params['keyword'] = searchkeyword;
            }
            const initiallivestart = dateconevrsion(storePerformanceDateRange.last_7_days.start_date)
            const initialliveend = dateconevrsion(storePerformanceDateRange.last_7_days.end_date)
            params['live_start'] = liveStart ? liveStart : initiallivestart;
            params['live_end'] = liveEnd ? liveEnd : initialliveend;
            params['sort_type'] = sorting.sort_type;

            let response = await Axios.post('/api/live/search', params);
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });

            if (response && response.status === 200 && response.data.data) {
                if (page == 0) {
                    setItems(response.data.data);
                } else {
                    setItems(items.concat(response.data.data));
                }
                if (response.data.data.length === 0 || response.data.data.length < 20) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                }
            }
        } catch (error) {
            console.error('Error: ' + error.message);
        };
    }
    const getVersion = async (cycle) => {
        try {
            let params = {
                cycle
            }
            if (rankType) {
                params['rank_type'] = rankType;
            }
            let response = await Axios.get('/api/version', { params });
            if (response.data.data) {
                setVersion(response.data.data[0].version);
            }
            setVersionList(response.data.data ? response.data.data : []);
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        async function fetchData() {
            setItems([]);
            setPage(0);
            if (version == 'W1') {
                getVersion(cycle);
            } else {
                liveSearch(0);
            }
        }
        fetchData()
    }, [sorting, searchkeyword, liveEnd, liveStart, storePerformanceDateRange, category1, category2, countryCode, StoreStatus, status, versionList, isCommerce, typeValue, followersRange, version]);



    const functionNext = async () => {
        let number = page + 1;
        setPage(number);
        liveSearch(number)
    }
    const tableHead = [
        {
            title: t('live.rank'),
        },
        {
            title: t('live.live_intro'),
        },
        {
            title: t('common.influencers'),
        },
        {
            title: t('live.start_time'),
            className: 'text-right sorting',
            sort: 49
        },
        {
            title: t('live.number_of_viewers'),
            className: 'text-right sorting',
            sort: 50
        },
        {
            title: t('common.sales'),
            className: 'text-right sorting',
            sort: 51
        },
        {
            title: t('common.revenue'),
            className: 'text-right sorting',
            sort: 52
        },
        {
            title: t('common.products'),
            className: 'text-right sorting',
            sort: 53
        }
    ]


    const sortType = sorting.sort_type == 'DESC' ? 'ASC' : 'DESC';
    const sortClass = sorting.sort_type == 'DESC' ? 'sorting-desc' : 'sorting-asc';
    const handleSort = async (sort, sortType) => {
        if (sort != sorting.sort || (sortType && sortType != sorting.sort_type)) {
            setSorting({ sort, sort_type: sortType });
        }
    };
    const handleEnterKeyPress = async (e) => {
        if (e.key === 'Enter') {
            setsearchkeyword(e.target.value);
        }
    };
    return (
        <div>
            <Card className='rounded-0'>
                <Card.Body>
                    <div className='topfilter'>
                        <Form.Control onKeyPress={handleEnterKeyPress} value={SearchValue} onChange={(e) => setSearchValue(e.target.value)} className="w-25 h-25" placeholder={t('live.search_live')} />
                        <div className="d-flex align-items-center flex-wrap mb-2">
                            <p className="m-0 mr-3"><strong>{t('common.country')}</strong></p>
                            {regionList}
                        </div>
                        <Category singleCategory={false} handleCategory={handleCategory} categoryId1={categoryId1} />
                        <div className="d-flex align-items-center mb-2">
                            <p className="m-0 mr-3"><strong>{t('common.filters')}</strong></p>

                            <div className="dropdown product-rating-menu">
                                <div className="form-group mb-0 mr-3">
                                    <select className="form-control form-control-sm" onChange={(e) => { settypeValue(e.target.value); }}>
                                        {typeList.map((value, index) => (
                                            <option key={index} value={value.name}>{value.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="dropdown product-rating-menu">
                                <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle"
                                    role="button"
                                    id="dropdownMenuLink"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {t('common.followers')}
                                </a>
                                <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                    <RangeSlider min={0} max={10000000} defaultMin={0} defaultMax={10000000} rangeOption={global.config.rangeOption} rangeOptionName={'views'} step={10} handleUpdate={(e) => { setFollowersRange(e) }} />
                                </div>
                            </div>
                            <div className="dropdown ml-2">
                                <button className="btn btn-outline-dark dropdown-toggle btn-xs" type="button" data-toggle="dropdown" aria-expanded="false">
                                    {StoreStatusorgname}
                                </button>
                                <div className="dropdown-menu">
                                    <a className="dropdown-item" href="#" value='Store status' onClick={onclickSetStoreStatus}>   {t('live.live_type')}</a>
                                    <a className="dropdown-item" href="#" value='1' onClick={onclickSetStoreStatus}>{t('live.personal_live')}</a>
                                    <a className="dropdown-item" href="#" value='2' onClick={onclickSetStoreStatus}>{t('live.shop_live')}</a>
                                </div>
                            </div>
                            <DateRangePicker
                                initialSettings={
                                    {
                                        startDate: moment(new Date(dateRange.start_date)).format('MM/DD/YYYY'), endDate: moment(new Date(dateRange.end_date)).format('MM/DD/YYYY'),
                                        ranges: {
                                            Today: [moment().toDate(), moment().toDate()],
                                            Yesterday: [
                                                moment().subtract(1, 'days').toDate(),
                                                moment().subtract(1, 'days').toDate(),
                                            ],
                                            'Last 7 Days': [
                                                moment().subtract(6, 'days').toDate(),
                                                moment().toDate(),
                                            ],
                                            'Last 15 Days': [
                                                moment().subtract(13, 'days').toDate(),
                                                moment().toDate(),
                                            ],
                                            'Last 30 Days': [
                                                moment().subtract(29, 'days').toDate(),
                                                moment().toDate(),
                                            ],
                                            'This Month': [
                                                moment().startOf('month').toDate(),
                                                moment().endOf('month').toDate(),
                                            ],
                                            'Last Month': [
                                                moment().subtract(1, 'month').startOf('month').toDate(),
                                                moment().subtract(1, 'month').endOf('month').toDate(),
                                            ],
                                            'Last 3 Month': [
                                                moment().subtract(3, 'month').startOf('month').toDate(),
                                                moment().subtract(3, 'month').endOf('month').toDate(),
                                            ],
                                        },
                                    }}
                                onApply={handleApply}
                            >
                                <input type="text" className="form-control w-auto form-control-sm input-daterange-timepicker pull-right" />
                            </DateRangePicker>
                            <div>
                                <Form.Check
                                    type="switch"
                                    id={`custom-switch-1`}
                                    checked={Live}
                                    onChange={(e) => toggleSwitchLive()}
                                />
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body className='p-2'>
                    <Table responsive className="w-100">
                        <div id="example_wrapper" className="dataTables_wrapper">
                            <div>
                                <InfiniteScrollWrapper
                                    isInfiniteScrollOn={true}
                                    lengthData={items.length}
                                    functionNext={functionNext}
                                    hasMore={hasMore}
                                >
                                    <table id="example" className="display w-100 dataTable">
                                        <thead>
                                            <tr role="row">
                                                {tableHead.map((value, index) => (
                                                    <th className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort ? sortClass : '')} onClick={(e) => { value.sort && handleSort(value.sort, sorting.sort == value.sort ? sortType : 'DESC') }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items?.map((live, index) => (
                                                <tr key={index}>
                                                    <td>{live?.rank}</td>
                                                    <td>
                                                        <div className='d-flex'>
                                                            <div className="prd-img mr-3">
                                                                <OverlayTrigger
                                                                    key="right"
                                                                    trigger={["hover", "focus"]}
                                                                    placement="right"
                                                                    rootClose
                                                                    overlay={
                                                                        <Popover>
                                                                            <Popover.Body>
                                                                                <img alt="" width={500} src={`https://t-img.picturehaven.net/product/${live?.room_cover ? live?.room_cover : live?.author?.author_avatar}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                                            </Popover.Body>
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <Button variant="link" className='p-0' size="xs">
                                                                        <img alt="" width={70} src={`https://t-img.picturehaven.net/product/${live?.room_cover ? live?.room_cover : live?.author?.author_avatar}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className='prd-title'>
                                                                <Link to={'/live/' + live.id + "/" + 'details'} target="_blank">
                                                                    <p>{live?.nickname ? live?.nickname : live?.author?.nickname}</p>
                                                                </Link>
                                                                <p>Duration: <FormatTime milliseconds={live?.live_time} /></p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex'>
                                                            <div className="prd-img mr-3">
                                                                <OverlayTrigger
                                                                    key="right"
                                                                    trigger={["hover", "focus"]}
                                                                    placement="right"
                                                                    rootClose
                                                                    overlay={
                                                                        <Popover>
                                                                            <Popover.Body>
                                                                                <img alt="" width={500} src={`https://t-img.picturehaven.net/product/${live?.room_cover ? live?.room_cover : live?.author?.author_avatar}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                                            </Popover.Body>
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <Button variant="link" className='p-0' size="xs">
                                                                        <img alt="" width={70} src={`https://t-img.picturehaven.net/product/${live?.room_cover ? live?.room_cover : live?.author?.author_avatar}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className='prd-title'>
                                                                <span className={`fi fi-${countryCode.toLowerCase()} mr-1`}></span>{live?.sys_country?.country}
                                                                <p>Followers: <Conversion value={live?.follower_count} /></p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <TimestampFormat timestamp={live.live_start_time} /> &nbsp;
                                                        <FormatTime milliseconds={live.live_time} />
                                                    </td>
                                                    <td><Conversion value={live.total_user} /></td>
                                                    <td><Conversion value={live.sold_count} /></td>
                                                    <td className='text-right'>
                                                        <div>
                                                            {live?.total_sales_usd || '--'}<br />
                                                            <span className='text-muted'>(${live?.total_sales ? <Conversion value={live.total_sales} /> : '--'})</span>
                                                        </div>
                                                    </td>
                                                    <td><Conversion value={live.product_count} /></td>
                                                </tr>))}
                                        </tbody>

                                    </table></InfiniteScrollWrapper>
                            </div>
                        </div>
                    </Table>
                </Card.Body>
            </Card>
        </div>
    )
}

export default LiveSearchFilter