import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { Card, Row, Col, OverlayTrigger, Table, Tooltip, Popover, Button } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Axios from '../../plugins/axios';
import Select from "react-select";
import StoreFetch from "./StoreFetch";
import ProfitLanding from "./ProfitLanding";
import StorePerformance from "./StorePerformance";
import NetProfitChart from './NetProfitChart';
import UnitSoldChart from './UnitSoldChart.js';
import { Context } from '../../../MasterLayout.js';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OrdersTable from './OrdersTable.js';
import { isEmpty } from '../CommonComponents/isEmpty.js';
import useFormattedNumber from '../CommonComponents/FormatNumberUS.js';
import formattedNumber from '../CommonComponents/FormatNumberUS.js';

const ProfitDashboard = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(Context);
  const [onLoad, setOnLoad] = useState(true);
  const [stores, setStores] = useState([]);
  const [productCount, setProductCount] = useState(0);
  const [sellerShippingPrice, setSellerShippingPrice] = useState(0);
  const [currentStore, setCurrentStore] = useState('');
  const [summaryData, setSummaryData] = useState(null);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [storePerformanceDateRange, setStorePerformanceDateRange] = useState({
    'yesterday': {
      start_date: new Date(new Date().setDate(new Date().getDate() - 1)),
      end_date: new Date()
    },
    'last_7_days': {
      start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
      end_date: new Date()
    },
    'last_30_days': {
      start_date: new Date(new Date().setDate(new Date().getDate() - 30)),
      end_date: new Date()
    }
  });
  const [bestSellerDateRange, setBestSellerDateRange] = useState({ start_date: new Date(new Date().setDate(new Date().getDate() - 30)), end_date: new Date() });

  useEffect(() => {
    setOnLoad(true);
    if (stores.length == 0) {
      getStores()
    } else {
      getSummaryReport();
    };
  }, [currentStore]);

  const getStores = async () => {
    try {
      dispatch({ type: 'loader_show', response: true });
      setOnLoad(true);
      let response = await Axios.get('/api/dashboard/stores').catch((e) => console.error('Axios-Catch: ' + e.message));
      dispatch({ type: 'subscription_expired', response: response.data.statusCode });

      if (response && response.status === 200) {
        if (response.data?.data?.stores.length != 0) {
          response.data.data.stores.map((v) => {
            v.value = v._id;
            v.label = v.name;
            return v;
          });
          setStores(response.data.data.stores);
          let storeIndex = 0;
          if (response.data?.data?.default_store) {
            storeIndex = response.data.data.stores.findIndex(n => n._id == response.data.data.default_store)
          };
          setCurrentStore(response.data.data.stores[storeIndex]);
          setProductCount(response.data?.data?.productCount);
        } else {
          setOnLoad(false);
          dispatch({ type: 'loader_hide', response: true });
        };
      };
    } catch (error) {
      console.error('Error-Catch: ' + error.message);
      dispatch({ type: 'loader_hide', response: true });
    };
  };

  const getSummaryReport = async () => {
    try {
      // await getOrders(1);
      dispatch({ type: 'loader_show', response: true });
      setOnLoad(true);
      let response = await Axios.post('/api/dashboard/summary', { store_id: currentStore._id }).catch((e) => console.error('Axios-Catch: ' + e.message));
      dispatch({ type: 'subscription_expired', response: response.data.statusCode });

      if (response && response.status === 200) {
        (response.data?.data?.order_detail && response.data?.data?.best_seller) ? setSummaryData(response.data?.data) : setSummaryData([]);
        if (response.data?.data?.order_detail && response.data?.data?.best_seller) setBestSellerDateRange({ start_date: new Date(response.data.data.best_seller.value.last_30_days.start_date), end_date: new Date(response.data.data.best_seller.value.last_30_days.end_date) });
        setProductCount(response.data?.data?.productCount ? response.data.data.productCount : 0);
        let ourSellerShippingPrice = 0;
        //response?.data?.data?.best_seller?.value?.last_30_days?.current?.map(v => ourSellerShippingPrice += parseFloat(v.total_our_shipping_charges));
        setSellerShippingPrice(ourSellerShippingPrice);
      };
    } catch (error) {
      console.error('Error-Catch: ' + error.message);
    };
    setOnLoad(false);
    dispatch({ type: 'loader_hide', response: true });
  };

  const getOrders = async (currentPage) => {
    try {
      dispatch({ type: 'loader_show', response: true });
      let response = await Axios.get(`/api/orders?store_id=${currentStore._id}&page=${currentPage}`).catch((e) => console.error('Axios-Catch: ' + e.message));
      dispatch({ type: 'subscription_expired', response: response.data.statusCode });

      if (response && response.status === 200) {
        setItems(response.data.data)
        setHasMore(response.data.data >= 10)
      };
    } catch (error) {
      console.error('Error-Catch: ' + error.message);
    };
    dispatch({ type: 'loader_hide', response: true });
  };

  const handleApply = (event, picker) => {
    picker.element.val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
    setBestSellerDateRange({ start_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') })
    getBestSeller({ start_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') })
  };


  const handleDateRange = (name, filter) => {
    let dateRangeFilter = { ...storePerformanceDateRange };
    dateRangeFilter[name] = filter;
    setStorePerformanceDateRange(dateRangeFilter);
  };

  const getBestSeller = async (obj) => {
    try {
      dispatch({ type: 'loader_show', response: true });
      let response = await Axios.post('/api/dashboard/best-sellers', {
        store_id: currentStore._id, ...obj
      }).catch((e) => console.error('Axios-Catch: ' + e.message));
      dispatch({ type: 'subscription_expired', response: response.data.statusCode });

      if (response && response.status === 200) {
        let summaryObj = { ...summaryData };
        summaryObj.best_seller.value.last_30_days = response.data.data;
        setSummaryData(summaryObj);
      };
    } catch (error) {
      console.error('Error-Catch: ' + error.message);
    };
    dispatch({ type: 'loader_hide', response: true });
  };

  const functionNext = async () => {
    if (items.length > 0) {
      let number = page + 1;
      setPage(number);
      await getOrders(number)
    }
  }

  return (
    <>
      {!onLoad &&
        <>
          {stores.length != 0 &&
            <>
              <div className="revenue-section">
                <Row className="align-items-center mb-3">
                  <Col md="6" className="text-center text-sm-left">
                  </Col>
                  <Col md="6" className="">
                    <div className="d-flex align-items-center justify-content-end flex-column flex-sm-row">
                      {/* <Form>
                        <Form.Check
                          className="mb-2 mb-sm-0"
                          type="switch"
                          id="custom-switch"
                          label="Comparison"
                        />
                      </Form> */}
                      <div className="dropdown product-rating-menu">
                        <Select
                          closeMenuOnSelect={true}
                          defaultValue={currentStore}
                          options={stores}
                          onChange={(e) => setCurrentStore(e)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              {productCount != 0 &&
                <>
                  <div className="revenue-section">
                    <Row className="align-items-center mb-3">
                      <Col md="6" className="text-center text-sm-left">
                        <h4 className="fw-bolder">{t('dashboard.revenue_summary')}</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <Card>
                          <Card.Body className="px-0 py-2">
                            <div className="row justify-content-center px-3">
                              <Col>
                                <h5>
                                  <i className="fa fa-info-circle" aria-hidden="true"></i>{" "}
                                  {t('dashboard.today_gross_revenue')}
                                </h5>
                              </Col>
                              <Col className="text-end">
                                <p className="mb-0">{summaryData?.order_detail?.value?.today?.start_date ? moment(new Date(summaryData.order_detail.value.today.start_date)).format('MMM DD, YYYY') : '--/--/--'}</p>
                              </Col>
                            </div>
                            <hr className="mt-0" />
                            <div className="px-3">
                              <h2 className="text-primary">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.today?.current?.total_payment).toFixed(2))}</h2>
                              <div className="row">
                                <div className="col-4">
                                  <h4>{t('dashboard.yesterday')}</h4>
                                  <h4 className="text-primary font-w400">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.yesterday?.current?.total_payment).toFixed(2))}</h4>
                                </div>
                                <div className="col-4 pe-0">
                                  <h4>{t('dashboard.change')}</h4>
                                  <h4 className="text-primary font-w400">{formattedNumber(parseFloat(parseFloat((parseFloat(summaryData?.order_detail?.value?.today?.current?.total_payment) - parseFloat(summaryData?.order_detail?.value?.yesterday?.current?.total_payment)) / (parseFloat(summaryData?.order_detail?.value?.yesterday?.current?.total_payment) == 0 ? 1 : parseFloat(summaryData?.order_detail?.value?.yesterday?.current?.total_payment)) * 100).toFixed(2)).toFixed(2))}% </h4>
                                </div>
                                <div className="col-4">
                                  <h5 className="fs-80 text-center mb-0">
                                    <i className="fa fa-handshake-o" aria-hidden="true"></i>
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg="4">
                        <Card>
                          <Card.Body className="px-0 py-2">
                            <div className="row justify-content-center px-3">
                              <Col>
                                <h5>
                                  <i className="fa fa-info-circle" aria-hidden="true"></i>{" "}
                                  {t('dashboard.today_orders')}
                                </h5>
                              </Col>
                              <Col className="text-end">
                                <p className="mb-0">{summaryData?.order_detail?.value?.today?.start_date ? moment(new Date(summaryData.order_detail.value.today.start_date)).format('MMM DD, YYYY') : '--/--/--'}</p>
                              </Col>
                            </div>
                            <hr className="mt-0" />
                            <div className="px-3">
                              <h2 className="text-primary">{formattedNumber(parseFloat(summaryData?.order_detail?.value?.today?.current?.order_count).toFixed(2))}</h2>
                              <div className="row">
                                <div className="col-4">
                                  <h4>{t('dashboard.yesterday')}</h4>
                                  <h4 className="text-primary font-w400">{formattedNumber(parseFloat(summaryData?.order_detail?.value?.yesterday?.current?.order_count).toFixed(2))}</h4>
                                </div>
                                <div className="col-4 pr-0">
                                  <h4>{t('dashboard.change')}</h4>
                                  <h4 className="text-primary font-w400">{formattedNumber(parseFloat(parseFloat((parseFloat(summaryData?.order_detail?.value?.today?.current?.order_count) - parseFloat(summaryData?.order_detail?.value?.yesterday?.current?.order_count)) / (parseFloat(summaryData?.order_detail?.value?.yesterday?.current?.order_count) == 0 ? 1 : parseFloat(summaryData?.order_detail?.value?.yesterday?.current?.order_count)) * 100).toFixed(2)).toFixed(2))}% </h4>
                                </div>
                                <div className="col-4">
                                  <h5 className="fs-80 text-center mb-0">
                                    <i className="fa fa-shopping-bag" aria-hidden="true"></i>
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg="4">
                        <Card>
                          <Card.Body className="px-0 py-2">
                            <div className="row justify-content-center px-3">
                              <Col>
                                <h5>
                                  <i className="fa fa-info-circle" aria-hidden="true"></i>{" "}
                                  {t('dashboard.today_Profits')}
                                </h5>
                              </Col>
                              <Col className="text-end">
                                <p className="mb-0">{summaryData?.order_detail?.value?.today?.start_date ? moment(new Date(summaryData.order_detail.value.today.start_date)).format('MMM DD, YYYY') : '--/--/--'}</p>
                              </Col>
                            </div>
                            <hr className="mt-0" />
                            <div className="px-3">
                              <h2 className="text-primary">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.today?.current?.net_profit).toFixed(2))}</h2>
                              <div className="row">
                                <div className="col-4">
                                  <h4>{t('dashboard.yesterday')}</h4>
                                  <h4 className="text-primary font-w400">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.today?.current?.net_profit).toFixed(2))}</h4>
                                </div>
                                <div className="col-4 pr-0">
                                  <h4>{t('dashboard.change')}</h4>
                                  <h4 className="text-primary font-w400">{
                                    formattedNumber(parseFloat(parseFloat((parseFloat(summaryData?.order_detail?.value?.today?.current?.net_profit) - parseFloat(summaryData?.order_detail?.value?.yesterday?.current?.net_profit)) / (parseFloat(summaryData?.order_detail?.value?.yesterday?.current?.net_profit) == 0 ? 1 : parseFloat(summaryData?.order_detail?.value?.yesterday?.current?.net_profit)) * 100).toFixed(2)).toFixed(2))}% </h4>
                                </div>
                                <div className="col-4">
                                  <h5 className="fs-80 text-center mb-0">
                                    <i className="fa fa-line-chart" aria-hidden="true"></i>
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </div>

                  <div className="store-performance-section">
                    <Row>
                      <Col>
                        <h4 className="fw-bolder">{t('dashboard.store_performance')}</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        {summaryData?.order_detail?.value?.today && currentStore && <StorePerformance date={storePerformanceDateRange['yesterday']} name={"yesterday"} func={handleDateRange} currentStore={currentStore} />}
                      </Col>
                      <Col lg="4">
                        {summaryData?.order_detail?.value?.last_7_days && currentStore && <StorePerformance date={storePerformanceDateRange['last_7_days']} name={"last_7_days"} func={handleDateRange} currentStore={currentStore} />}
                      </Col>
                      <Col lg="4">
                        {summaryData?.order_detail?.value?.last_30_days && currentStore && <StorePerformance date={storePerformanceDateRange['last_30_days']} name={"last_30_days"} func={handleDateRange} currentStore={currentStore} />}
                      </Col>
                    </Row>
                  </div>

                  <div className="store-trends-section">
                    <Row>
                      <Col>
                        <h4 className="fw-bolder">{t('dashboard.store_trends')}</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <Card>
                          <Card.Body className="p-0">
                            <div className="row justify-content-center p-3">
                              {/* Remove bellow call */}
                              <Col className="text-center p-5">
                                <div style={{ "height": "400px" }}>
                                  < UnitSoldChart data={summaryData.store_trend} />
                                </div>
                              </Col>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg="6">
                        <Card>
                          <Card.Body className="p-0">
                            <div className="row justify-content-center p-3">
                              {/* Remove bellow call */}
                              <Col className="text-center p-5">
                                <div style={{ "height": "400px" }}>
                                  < NetProfitChart data={summaryData.store_trend} />
                                </div>
                              </Col>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </div>

                  {/* {!isEmpty(items) && <div className="bestsellers-section">
                    <Row className="align-items-center mb-3">
                      <Col md="6" className="text-center text-sm-left">
                        <h4 className="fw-bolder">Today's Orders</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card>
                          <Card.Body className="p-0">
                            <OrdersTable items={items} hasMore={hasMore} functionNext={functionNext} />
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </div>} */}


                  <div className="bestsellers-section">
                    <Row className="align-items-center mb-3">
                      <Col md="6" className="text-center text-sm-left">
                        <h4 className="fw-bolder">{t('dashboard.bestsellers')}</h4>
                      </Col>
                      <Col md="6" className="">
                        <div className="d-flex align-items-center justify-content-end flex-column flex-sm-row">
                          <DateRangePicker onApply={handleApply} initialSettings={{
                            startDate: moment(new Date(bestSellerDateRange.start_date)).format('MM/DD/YYYY'), endDate: moment(new Date(bestSellerDateRange.end_date)).format('MM/DD/YYYY'),
                            ranges: {
                              'Today': [moment().toDate(), moment().toDate()],
                              'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                              'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                              'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
                              'This Month': [moment().startOf('month').toDate(), moment().toDate()],
                              'Last Month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                              'Last 3 Month': [moment().subtract(3, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                              'Last 6 Month': [moment().subtract(6, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                            },
                          }}>
                            <input type="text" className="form-control w-auto form-control-sm input-daterange-timepicker pull-right" />
                          </DateRangePicker>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card>
                          <Card.Body className="p-0">
                            <Table responsive className="w-100">
                              <thead>
                                <tr role="row">
                                  <th>{t('common.rank')}</th>
                                  <th>{t('dashboard.product_details')}</th>
                                  <th dangerouslySetInnerHTML={{ __html: t('dashboard.total_revenue') }}></th>
                                  <th dangerouslySetInnerHTML={{ __html: t('dashboard.avg_selling_price') }}></th>
                                  <th dangerouslySetInnerHTML={{ __html: t('dashboard.tiktok_expenses_th') }}></th>
                                  <th>{t('dashboard.cogs')}</th>
                                  <th dangerouslySetInnerHTML={{ __html: t('dashboard.net_profit_th') }}></th>
                                  <th>{t('dashboard.margin')}</th>
                                  <th dangerouslySetInnerHTML={{ __html: t('dashboard.units_sold_th') }}></th>
                                </tr>
                              </thead>
                              <tbody>
                                {summaryData.best_seller.value.last_30_days.current.map((v, i) => (
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>
                                      <div className="d-flex">
                                        <div className="prd-img mr-3">
                                          <OverlayTrigger
                                            key="right"
                                            trigger={["hover", "focus"]}
                                            placement="right"
                                            rootClose
                                            overlay={
                                              <Popover>
                                                <Popover.Body>
                                                  {v.product?.main_images && v.product?.main_images.length > 0 && v.product?.main_images[0]?.thumb_urls && v.product?.main_images[0]?.thumb_urls.length > 0 && <img src={v.product.main_images[0].thumb_urls[0]} alt="" width={500} className="prod-thum-img" />}
                                                </Popover.Body>
                                              </Popover>
                                            }
                                          >
                                            <Button variant="link" className='p-0' size="xs">
                                              <Link to={'/products/' + v.product?.product_id + '/details'} target="_blank">
                                                {v.product?.main_images && v.product?.main_images.length > 0 && v.product?.main_images[0]?.thumb_urls && v.product?.main_images[0]?.thumb_urls.length > 0 && <img src={v.product.main_images[0].thumb_urls[0]} alt="" width={70} className="prod-thum-img" />}
                                              </Link>
                                            </Button>
                                          </OverlayTrigger>
                                        </div>
                                        <div className="prd-title">
                                          <p>
                                            <OverlayTrigger overlay={<Tooltip>{v.product?.title}</Tooltip>}>
                                              <Link to={'/products/' + v.product?.product_id + '/details'} target="_blank">
                                                <span>{v.product?.title ? v.product.title.length > 50 ? v.product.title.substring(0, 50) + '...' : v.product.title : '-'}</span>
                                              </Link>
                                            </OverlayTrigger>
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    <td>$ {formattedNumber(parseFloat(v?.total_payment).toFixed(2))}</td>
                                    <td>$ {parseFloat(v?.unit_sold) > 0 ? formattedNumber(parseFloat(parseFloat(v.total_payment) / parseFloat(v.unit_sold)).toFixed(2)) : '-'}</td>
                                    <td>$ {formattedNumber(parseFloat(v?.total_charges).toFixed(2))}</td>
                                    <td>$ {formattedNumber(parseFloat(v?.total_cost).toFixed(2))}</td>
                                    <td>$ {formattedNumber(parseFloat(v?.net_profit).toFixed(2))}</td>
                                    <td>{v?.total_payment && v?.total_payment != 0 ? formattedNumber(parseFloat(((parseFloat(v?.profit_loss) / parseFloat(v?.total_payment)) * 100)).toFixed(2)) + ' %' : '-'}</td>
                                    <td>{formattedNumber(parseFloat(v.unit_sold).toFixed(2))}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </div>

                  <div className="store-summary-section">
                    <Row>
                      <Col>
                        <h4 className="fw-bolder">{t('dashboard.store_summary')}</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card>
                          <Card.Body className="p-0">
                            <Table responsive className="w-100">
                              <tbody>
                                <tr>
                                  <th>{t('dashboard.period')}</th>
                                  <td className="text-right"><b>{t('dashboard.yesterday')}</b> <br />{summaryData?.order_detail?.value?.yesterday?.start_date ? moment(new Date(summaryData.order_detail.value.yesterday.start_date)).format('DD/MM/YYYY') : '--/--/--'}</td>
                                  <td className="text-right"><b>7 day</b> <br />{summaryData?.order_detail?.value?.last_7_days?.start_date ? moment(new Date(summaryData.order_detail.value.last_7_days.start_date)).format('DD/MM/YYYY') : '--/--/--'} TO {summaryData?.order_detail?.value?.last_7_days?.end_date ? moment(new Date(summaryData.order_detail.value.last_7_days.end_date)).format('DD/MM/YYYY') : '--/--/--'}</td>
                                  <td className="text-right"><b>30 day</b> <br />{summaryData?.order_detail?.value?.last_30_days?.start_date ? moment(new Date(summaryData.order_detail.value.last_30_days.start_date)).format('DD/MM/YYYY') : '--/--/--'} TO {summaryData?.order_detail?.value?.last_30_days?.end_date ? moment(new Date(summaryData.order_detail.value.last_30_days.end_date)).format('DD/MM/YYYY') : '--/--/--'}</td>
                                  <td className="text-right"><b>60 day</b> <br />{summaryData?.order_detail?.value?.last_60_days?.start_date ? moment(new Date(summaryData.order_detail.value.last_60_days.start_date)).format('DD/MM/YYYY') : '--/--/--'} TO {summaryData?.order_detail?.value?.last_60_days?.end_date ? moment(new Date(summaryData.order_detail.value.last_60_days.end_date)).format('DD/MM/YYYY') : '--/--/--'}</td>
                                  <td className="text-right"><b>90 day</b> <br />{summaryData?.order_detail?.value?.last_90_days?.start_date ? moment(new Date(summaryData.order_detail.value.last_90_days.start_date)).format('DD/MM/YYYY') : '--/--/--'} TO {summaryData?.order_detail?.value?.last_90_days?.end_date ? moment(new Date(summaryData.order_detail.value.last_90_days.end_date)).format('DD/MM/YYYY') : '--/--/--'}</td>
                                  <td className="text-right"><b>{t('dashboard.month_to_date')}</b> <br />{summaryData?.order_detail?.value?.month_to_date?.start_date ? moment(new Date(summaryData.order_detail.value.month_to_date.start_date)).format('DD/MM/YYYY') : '--/--/--'} TO {summaryData?.order_detail?.value?.month_to_date?.end_date ? moment(new Date(summaryData.order_detail.value.month_to_date.end_date)).format('DD/MM/YYYY') : '--/--/--'}</td>
                                  <td className="text-right"><b>{t('dashboard.year_to_date')}</b> <br />{summaryData?.order_detail?.value?.year_to_date?.start_date ? moment(new Date(summaryData.order_detail.value.year_to_date.start_date)).format('DD/MM/YYYY') : '--/--/--'} TO {summaryData?.order_detail?.value?.year_to_date?.end_date ? moment(new Date(summaryData.order_detail.value.year_to_date.end_date)).format('DD/MM/YYYY') : '--/--/--'}</td>
                                </tr>
                                <tr>
                                  <th>{t('dashboard.gross_revenue')}</th>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.yesterday?.current?.total_payment).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_7_days?.current?.total_payment).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_30_days?.current?.total_payment).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_60_days?.current?.total_payment).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_90_days?.current?.total_payment).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.month_to_date?.current?.total_payment).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.year_to_date?.current?.total_payment).toFixed(2))}</td>
                                </tr>
                                <tr>
                                  <th>{t('dashboard.tiktok_expenses')}</th>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.yesterday?.current?.total_charges).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_7_days?.current?.total_charges).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_30_days?.current?.total_charges).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_60_days?.current?.total_charges).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_90_days?.current?.total_charges).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.month_to_date?.current?.total_charges).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.year_to_date?.current?.total_charges).toFixed(2))}</td>
                                </tr>
                                <tr>
                                  <th>{t('dashboard.commission')}</th>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.yesterday?.current?.total_commision).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_7_days?.current?.total_commision).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_30_days?.current?.total_commision).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_60_days?.current?.total_commision).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_90_days?.current?.total_commision).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.month_to_date?.current?.total_commision).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.year_to_date?.current?.total_commision).toFixed(2))}</td>
                                </tr>
                                <tr>
                                  <th>{t('dashboard.referral_fee')}</th>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.yesterday?.current?.total_referral_fee_amount).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_7_days?.current?.total_referral_fee_amount).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_30_days?.current?.total_referral_fee_amount).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_60_days?.current?.total_referral_fee_amount).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_90_days?.current?.total_referral_fee_amount).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.month_to_date?.current?.total_referral_fee_amount).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.year_to_date?.current?.total_referral_fee_amount).toFixed(2))}</td>
                                </tr>
                                <tr>
                                  <th>{t('dashboard.shipping_fee')}</th>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.yesterday?.current?.total_shipping_price).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_7_days?.current?.total_shipping_price).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_30_days?.current?.total_shipping_price).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_60_days?.current?.total_shipping_price).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_90_days?.current?.total_shipping_price).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.month_to_date?.current?.total_shipping_price).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.year_to_date?.current?.total_shipping_price).toFixed(2))}</td>
                                </tr>
                                <tr>
                                  <th>{t('dashboard.cogs')}</th>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.yesterday?.current?.total_cost).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_7_days?.current?.total_cost).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_30_days?.current?.total_cost).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_60_days?.current?.total_cost).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_90_days?.current?.total_cost).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.month_to_date?.current?.total_cost).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.year_to_date?.current?.total_cost).toFixed(2))}</td>
                                </tr>
                                <tr>
                                  <th>{t('dashboard.net_profit')}</th>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.yesterday?.current?.net_profit).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_7_days?.current?.net_profit).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_30_days?.current?.net_profit).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_60_days?.current?.net_profit).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_90_days?.current?.net_profit).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.month_to_date?.current?.net_profit).toFixed(2))}</td>
                                  <td className="text-right">$ {formattedNumber(parseFloat(summaryData?.order_detail?.value?.year_to_date?.current?.net_profit).toFixed(2))}</td>
                                </tr>
                                <tr>
                                  <th>{t('dashboard.orders')}</th>
                                  <td className="text-right">{formattedNumber(parseFloat(summaryData?.order_detail?.value?.yesterday?.current?.order_count).toFixed(2))}</td>
                                  <td className="text-right">{formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_7_days?.current?.order_count).toFixed(2))}</td>
                                  <td className="text-right">{formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_30_days?.current?.order_count).toFixed(2))}</td>
                                  <td className="text-right">{formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_60_days?.current?.order_count).toFixed(2))}</td>
                                  <td className="text-right">{formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_90_days?.current?.order_count).toFixed(2))}</td>
                                  <td className="text-right">{formattedNumber(parseFloat(summaryData?.order_detail?.value?.month_to_date?.current?.order_count).toFixed(2))}</td>
                                  <td className="text-right">{formattedNumber(parseFloat(summaryData?.order_detail?.value?.year_to_date?.current?.order_count).toFixed(2))}</td>
                                </tr>
                                <tr>
                                  <th>{t('dashboard.units_sold')}</th>
                                  <td className="text-right">{formattedNumber(parseFloat(summaryData?.order_detail?.value?.yesterday?.current?.total_quantity_sold).toFixed(2))}</td>
                                  <td className="text-right">{formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_7_days?.current?.total_quantity_sold).toFixed(2))}</td>
                                  <td className="text-right">{formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_30_days?.current?.total_quantity_sold).toFixed(2))}</td>
                                  <td className="text-right">{formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_60_days?.current?.total_quantity_sold).toFixed(2))}</td>
                                  <td className="text-right">{formattedNumber(parseFloat(summaryData?.order_detail?.value?.last_90_days?.current?.total_quantity_sold).toFixed(2))}</td>
                                  <td className="text-right">{formattedNumber(parseFloat(summaryData?.order_detail?.value?.month_to_date?.current?.total_quantity_sold).toFixed(2))}</td>
                                  <td className="text-right">{formattedNumber(parseFloat(summaryData?.order_detail?.value?.year_to_date?.current?.total_quantity_sold).toFixed(2))}</td>
                                </tr>
                                <tr>
                                  <th>{t('dashboard.margin')}</th>
                                  <td className="text-right">{summaryData?.order_detail?.value?.yesterday?.current?.total_payment && summaryData?.order_detail?.value?.yesterday?.current?.total_payment != 0 ? formattedNumber(parseFloat((parseFloat(summaryData?.order_detail?.value?.yesterday?.current?.profit_loss) / parseFloat(summaryData.order_detail.value.yesterday.current.total_payment)) * 100).toFixed(2)) + ' %' : '-'}</td>
                                  <td className="text-right">{summaryData?.order_detail?.value?.last_7_days?.current?.total_payment && summaryData?.order_detail?.value?.last_7_days?.current?.total_payment != 0 ? formattedNumber(parseFloat((parseFloat(summaryData?.order_detail?.value?.last_7_days?.current?.profit_loss) / parseFloat(summaryData.order_detail.value.last_7_days.current.total_payment)) * 100).toFixed(2)) + ' %' : '-'}</td>
                                  <td className="text-right">{summaryData?.order_detail?.value?.last_30_days?.current?.total_payment && summaryData?.order_detail?.value?.last_30_days?.current?.total_payment != 0 ? formattedNumber(parseFloat((parseFloat(summaryData?.order_detail?.value?.last_30_days?.current?.profit_loss) / parseFloat(summaryData.order_detail.value.last_30_days.current.total_payment)) * 100).toFixed(2)) + ' %' : '-'}</td>
                                  <td className="text-right">{summaryData?.order_detail?.value?.last_60_days?.current?.total_payment && summaryData?.order_detail?.value?.last_60_days?.current?.total_payment != 0 ? formattedNumber(parseFloat((parseFloat(summaryData?.order_detail?.value?.last_60_days?.current?.profit_loss) / parseFloat(summaryData.order_detail.value.last_60_days.current.total_payment)) * 100).toFixed(2)) + ' %' : '-'}</td>
                                  <td className="text-right">{summaryData?.order_detail?.value?.last_90_days?.current?.total_payment && summaryData?.order_detail?.value?.last_90_days?.current?.total_payment != 0 ? formattedNumber(parseFloat((parseFloat(summaryData?.order_detail?.value?.last_90_days?.current?.profit_loss) / parseFloat(summaryData.order_detail.value.last_90_days.current.total_payment)) * 100).toFixed(2)) + ' %' : '-'}</td>
                                  <td className="text-right">{summaryData?.order_detail?.value?.month_to_date?.current?.total_payment && summaryData?.order_detail?.value?.month_to_date?.current?.total_payment != 0 ? formattedNumber(parseFloat((parseFloat(summaryData?.order_detail?.value?.month_to_date?.current?.profit_loss) / parseFloat(summaryData.order_detail.value.month_to_date.current.total_payment)) * 100).toFixed(2)) + ' %' : '-'}</td>
                                  <td className="text-right">{summaryData?.order_detail?.value?.year_to_date?.current?.total_payment && summaryData?.order_detail?.value?.year_to_date?.current?.total_payment != 0 ? formattedNumber(parseFloat((parseFloat(summaryData?.order_detail?.value?.year_to_date?.current?.profit_loss) / parseFloat(summaryData.order_detail.value.year_to_date.current.total_payment)) * 100).toFixed(2)) + ' %' : '-'}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </>
              }
              {productCount == 0 && <StoreFetch />}
            </>
          }
          {stores.length == 0 && <ProfitLanding />}
        </>
      }
    </>
  );
};

export default ProfitDashboard;