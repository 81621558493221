import React from "react";
import "../../css/landing/landing.css";
import "../../css/landing/bootstrap.min.css";
import "../../css/landing/glightbox.min.css";
import "../../css/landing/main.css";
import "../../css/landing/tiny-slider.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useTranslation } from 'react-i18next';
import PlanTable from "../components/CommonComponents/PlanTable";

const Pricing = () => {
   const { t } = useTranslation();

   const redirectFunc = () => {
      window.location.href = '/pre-register';
   }

   return (
      <>

         <Header />
         <section id="home" className="hero-area hero-inner-area py-5">
            <div className="container">
               <div className="row align-items-center">

               </div>
            </div>
         </section>

         <section id="pricing" className="pricing-table section">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <div className="section-title mb-0 px-0">
                        <h2>{t('pricing.choose_your_viralytic_plan')}</h2>
                        <p>{t('pricing.experience_the_full_potential')}</p>
                     </div>
                  </div>
               </div>
               <div className="row justify-content-center">
                  <PlanTable func={redirectFunc} text={t('pricing.start_free_trial')} showFree />
               </div>
            </div>
         </section >


         <section className="section call-action">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                     <div className="cta-content">
                        <h2>{t('pricing.focus_on_tiktok_analytics')}</h2>
                        <div className="button">
                           <a href="/pre-register" className="btn">{t('pricing.start_free_trial')}</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <Footer />
      </>
   );
};

export default Pricing;