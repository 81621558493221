import React from "react";
import "../../css/landing/landing.css";
import "../../css/landing/bootstrap.min.css";
import "../../css/landing/glightbox.min.css";
import "../../css/landing/main.css";
import "../../css/landing/tiny-slider.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useTranslation } from 'react-i18next';

const OurStory = () => {
   const { t } = useTranslation();
   return (
      <>
         <Header />

         <section id="home" className="hero-area hero-inner-area py-5">
            <div className="container">
               <div className="row align-items-center">
               </div>
            </div>
         </section>
         <section id="pricing" className="pricing-table section">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <div className="section-title p-0">
                        <h2>{t('ourstory.our_journey_from_tiktok')}</h2>
                        <p>{t('ourstory.behind_viralytic_lies')}</p>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-lg-6 col-md-6 col-12">
                     <div className="single-table">
                        <div className="table-head">
                           <h4 className="title">{t('ourstory.our_beginning')}</h4>
                           <p>{t('ourstory.as_fellow_tiktok_shop')}</p>
                        </div>                        
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                     <div className="single-table">
                        <div className="table-head">
                           <h4 className="title">{t('ourstory.turning_pain_points')}</h4>
                           <p>{t('ourstory.our_shared_vision')}</p>
                        </div>                        
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                     <div className="single-table">
                        <div className="table-head">
                           <h4 className="title">{t('ourstory.creating_viralytic')}</h4>
                           <p>{t('ourstory.months_of_dedication')}</p>
                        </div>                        
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                     <div className="single-table">
                        <div className="table-head">
                           <h4 className="title">{t('ourstory.the_heart_of_viralytic')}</h4>
                           <p>{t('ourstory.at_the_heart_of_viralytic')}</p>
                        </div>                        
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                     <div className="single-table">
                        <div className="table-head">
                           <h4 className="title">{t('ourstory.our_mission_continues')}</h4>
                           <p>{t('ourstory.as_viralytic_continues')}</p>
                        </div>                        
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                     <div className="single-table">
                        <div className="table-head">
                           <h4 className="title">{t('ourstory.thank_you_for_being')}</h4>
                           <p>{t('ourstory.viralytic_is_more_than')}</p>
                        </div>                        
                     </div>
                  </div> 
                  <div className="col-lg-6 col-md-6 col-12">
                     <div className="single-table">
                        <div className="table-head">
                           <h4 className="title">{t('ourstory.join_us_in_shaping')}</h4>
                           <p>{t('ourstory.whether_youre_a')}</p>
                        </div>                        
                     </div>
                  </div>                  
               </div>
            </div>
         </section>

         <section className="section call-action">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                     <div className="cta-content">
                        <h2>{t('ourstory.focus_on_tiktok_analytics')}</h2>
                        <div className="button">
                           <a href="/pre-register" className="btn">{t('ourstory.start_free_trial')}</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <Footer />
      </>
   );
};

export default OurStory;