import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle, useContext } from "react";
import { ButtonGroup, Button, Card } from "react-bootstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Line } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import ReactApexChart from "react-apexcharts";
import Axios from "../../../plugins/axios";
import moment from 'moment';
import { Context } from '../../../../MasterLayout';
import { useTranslation } from 'react-i18next';

const CustomerReviewstab = forwardRef((props, ref) => {
   const { t } = useTranslation();

   const { dispatch } = useContext(Context);
   const lineGraphOptions = {
      plugins: {
         legend: {
            display: false
         }
      },
      scales: {
         y:
         {
            min: 0,
            max: 5,
            ticks: {
               beginAtZero: true,
               padding: 0,
            },
         },
         x:
         {
            ticks: {
               padding: 0,
            },
            gridLines: {
               display: false,
               drawBorder: false,
            },
         },
      },
   };

   let pieChartOptions = {
      chart: {
         type: 'donut',
      },
      labels: ['5-Star Reviews', '4-Star Reviews', '3-Star Reviews', '2-Star Reviews', '1-Star Reviews',],
      responsive: [{
         breakpoint: 480,
         options: {
            chart: {
               width: 200
            },
            legend: {
               position: 'bottom'
            }
         }
      }],
      plotOptions: {
         pie: {
            donut: {
               labels: {
                  show: true,
                  total: {
                     showAlways: true,
                     show: true
                  }
               }
            },
            dataLabels: {
               offset: -5
            }
         }
      },
   };

   const params = useParams();
   const dateRangeRef = useRef();
   const [customerReviewsFilter, setCustomerReviewsFilter] = useState('7_days');
   const [dateFilterOption] = useState({
      '7_days': {
         label: 'Past 7 Days',
         start_day: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0],
         end_day: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
      },
      '15_days': {
         label: 'Past 15 Days',
         start_day: new Date(new Date().setDate(new Date().getDate() - 15)).toISOString().split('T')[0],
         end_day: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
      },
      '30_days': {
         label: 'Past 30 Days',
         start_day: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0],
         end_day: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
      }
   });
   const [dateRange, setDateRange] = useState(dateFilterOption[customerReviewsFilter]);
   const [customerComment, setCustomerComment] = useState({ data: [], options: pieChartOptions });
   const [customerRating, setCustomerRating] = useState({
      data: {
         defaultFontFamily: "Poppins",
         labels: [],
         datasets: [
            {
               data: [],
               borderColor: "rgba(41,43,232,1)",
               backgroundColor: "rgba(41,43,232, 0)",
               tension: 0.4
            },
         ],
      },
      options: lineGraphOptions
   });


   useImperativeHandle(ref, () => ({
      async customerReviewsTab() {
         customerReviews();
      }
   }));

   useEffect(() => {
      customerReviews();
   }, [customerReviewsFilter]);


   const customerReviews = async () => {
      try {
         let bodyParams = {};
         if (customerReviewsFilter == 'custom') {
            bodyParams = {
               start_day: dateRange.start_day,
               end_day: dateRange.end_day,
            };
         } else {
            bodyParams = dateFilterOption[customerReviewsFilter];
            setDateRange(bodyParams);
         }
         bodyParams.product_id = params.productId;

         changeDate(bodyParams.start_day, bodyParams.end_day);

         let response = await Axios.post('/api/customer-review', bodyParams);
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });
         if (response && response.status === 200) {
            await changePieChartData(response.data.data.comment);
            await changeLineGraphData(response.data.data.rating);
         }
      } catch (error) {
         console.error('Error: ' + error.message);
      };
   };

   const changeLineGraphData = async (data) => {
      let labelsArray = [], dataArray = [];

      data.sort_by_date.map((v) => {
         labelsArray.push(v.statistical_day);
         dataArray.push(parseFloat(v.product_rating));
      });

      setCustomerRating({
         data: {
            defaultFontFamily: "Poppins",
            labels: labelsArray,
            datasets: [
               {
                  data: dataArray,
                  borderColor: "rgba(41,43,232,1)",
                  backgroundColor: "rgba(41,43,232, 0)",
                  tension: 0.4
               },
            ],
         },
         options: lineGraphOptions
      });
   };

   const changePieChartData = async (data) => {
      let dataArray = [];

      data.product_review_filters.map((v) => {
         dataArray.push(v.count);
      });
      setCustomerComment({ data: dataArray, options: pieChartOptions })
   };

   const dateFilterOptionHtml = Object.keys(dateFilterOption).map(function (key) {
      return <Button key={key} variant="outline-dark" size="xs" className={(customerReviewsFilter == key ? 'btn-primary' : '')} onClick={() => { setCustomerReviewsFilter(key); }}>{dateFilterOption[key].label}</Button>
   });

   const handleApply = (event, picker) => {
      picker.element.val(
         picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD')
      );
      setDateRange({ start_day: picker.startDate.format('YYYY-MM-DD'), end_day: picker.endDate.format('YYYY-MM-DD') })
      setCustomerReviewsFilter('custom');
   };
   const handleCancel = (event, picker) => {
      picker.element.val('');
      setDateRange([]);
   };
   const changeDate = (startDate, endDate) => {
      dateRangeRef.current.setStartDate(moment(new Date(startDate)).format('MM/DD/YYYY'));
      dateRangeRef.current.setEndDate(moment(new Date(endDate)).format('MM/DD/YYYY'));
   };

   return (
      <>

         <Card className='rounded-0'>
            <Card.Body>
               <div className='row'>
                  <div className="col-lg-12">
                  </div>
                  <div className='col-lg-6 mb-4'>
                     <div className='row align-items-center justify-content-end mb-3'>
                        <div className="col-lg-12">
                           <h3>{t('product_research.product_details.product_rating')}</h3>
                        </div>
                        <div className="col-lg-12 text-right">
                           <div className="d-flex justify-content-end align-items-center">
                              <ButtonGroup className='mr-4'>
                                 {dateFilterOptionHtml}
                              </ButtonGroup>
                              <DateRangePicker
                                 ref={dateRangeRef}
                                 initialSettings={{ startDate: moment(new Date(dateRange.start_day)).format('MM/DD/YYYY'), endDate: moment(new Date(dateRange.end_day)).format('MM/DD/YYYY') }}
                                 onApply={handleApply} onCancel={handleCancel} /*onCallback={handleCallback}*/
                              >
                                 <input type="text" placeholder={'First Seen'} className="form-control form-control-sm input-daterange-timepicker w-auto" value={dateRange.start_day + " - " + dateRange.end_day} />
                              </DateRangePicker>
                           </div>
                        </div>

                     </div>
                     {customerRating?.data?.data?.length != 0 && <div style={{ "height": "400px" }}>
                        <Line
                           data={customerRating.data}
                           options={customerRating.options}
                        />
                     </div>}
                  </div>

                  <div className="col-lg-6 mb-4">
                     <h3>{t('product_research.product_details.comment_proportion')}</h3>
                     <div
                        id="chart"
                        className="text-center m-auto"
                     >
                        <div
                           id="chart"
                           className="text-center m-auto"
                           style={{ width: "80%" }}
                        >
                           < ReactApexChart
                              options={customerComment.options}
                              series={customerComment.data}
                              type="donut"
                              height={350}
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </Card.Body>
         </Card>
      </>
   );
});

export default CustomerReviewstab;