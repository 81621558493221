export function formatNumber(num) {
    if (Math.abs(num) >= 1000) {
        const formattedNum = (num / 1000).toFixed(1);
        return formattedNum + 'k';
    }
    return num.toString();
}

export function FormatDate({ timestamp }) {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}/${month}/${day}`;

    return formattedDate;
}

export function DateFormat({ timestamp }) {
    const unixTimestamp = timestamp;

    const milliseconds = unixTimestamp * 1000;

    const date = new Date(milliseconds);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    // IF REQUIRED IN FUTURE
    // const hours = String(date.getHours()).padStart(2, '0');
    // const minutes = String(date.getMinutes()).padStart(2, '0');
    // const seconds = String(date.getSeconds()).padStart(2, '0');
    const dateString = `${year}-${month}-${day}`
    return dateString;
}

export function FormatTime({ milliseconds }) {
    let seconds = Math.floor(milliseconds / 1000);

    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;

    const formattedTime = `${hours}h ${minutes}m ${seconds}s`;

    return formattedTime;
}

export function TimestampFormat({ timestamp }) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}/${month}/${day}`;
    return formattedDate;
}