import React from "react";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Form from 'react-bootstrap/Form';


class RangeSlider extends React.Component<any, any> {
   constructor(props) {
      super(props);
      this.state = {
         min: this.props.min,
         max: this.props.max,
         value: [this.props.min, this.props.max],
      };
   }

   onMinChange = (e) => {
      this.setState({
         min: e.target.value,
      });
   };

   onMinBlur = (e) => {
      if (e.target.value < this.props.min) {
         this.setState({
            min: 0,
         });
      } else if (e.target.value > this.props.max) {
         this.setState({
            min: this.props.max,
         });
      }
   };

   onMaxChange = (e) => {
      this.setState({
         max: e.target.value,
      });
   };

   handleBlur = (e) => {
      let value = parseFloat(e.target.value || 0), name = e.target.name;
      
      if (e.target.value < this.props.min) {
         value = parseFloat(this.props.min);
      } else if (e.target.value > this.props.max) {
         value = parseFloat(this.props.max);
      }
      if (name == 'min') {
         this.setState({
            min: value,
         });
      } else {
         this.setState({
            max: value,
         });
      }

      this.setState({
         value: [parseFloat(this.state.min), parseFloat(this.state.max)]
      });
      
      this.props.handleUpdate({from: this.state.min, to: this.state.max});
   };

   onSliderChange = (value) => {
      this.setState({
         min: value[0],
         max: value[1],
         value: value,
      });
   };

   onRadioChange = (e) => {
      let range = e.target.value.split('-')
      this.setState({
         min: range[0],
         max: range[1],
         value: range
      });
      this.props.handleUpdate({from: range[0], to: range[1]});
   };

   onChangeCompleted = () => {
      this.props.handleUpdate({from: this.state.min, to: this.state.max});
   };

   render() {
      const rangeOption = this.props.rangeOption;
      const rangeOptionName = this.props.rangeOptionName;
      const onRadioChange = this.onRadioChange;
      const radioHtml = Object.keys(this.props.rangeOption).map(function (key) {
         return <Form.Check
            key={key}
            label={rangeOption[key]}
            name={rangeOptionName}
            type='radio'
            id={`inline-radio-${rangeOptionName}-${rangeOption[key]}`}
            value={key}
            onChange={onRadioChange}
         />
      })
      return (
         <div>
            <Slider
               range
               defaultValue={this.state.value}
               min={this.props.min}
               max={this.props.max}
               onChange={this.onSliderChange}
               value={this.state.value}
               allowCross={false}
               step={this.props.step}
               onChangeComplete={this.onChangeCompleted}

            />

            <div className="d-flex mt-3 mb-3">
               <div>
                  <label className="mb-1">From</label>
                  <input type="number" value={this.state.min} onChange={this.onMinChange} onBlur={this.handleBlur} name="min" placeholder="From" className="form-control form-control-sm w-100 mr-2" />
               </div>
               <div>
                  <label className="mb-1">To</label>
                  <input type="number" value={this.state.max} onChange={this.onMaxChange} onBlur={this.handleBlur} name="max" placeholder="To" className="form-control form-control-sm w-100 ml-2" />
               </div>
            </div>
            <Form>
               <div key={`inline-radio`} className="mb-3">
               {radioHtml}
               </div>
            </Form>

         </div>
      );
   }
}


export default RangeSlider;
