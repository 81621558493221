import React from 'react'
import { Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { useTranslation } from 'react-i18next';

function SalesMatricsTab({currentpage, saleshistory, totalCount, handlePageClick }) {
    const { t } = useTranslation();
    return (
        <div>
            <div>
                <div className="card-body">
                    <Table responsive className="w-100">
                        <div id="example_wrapper" className="dataTables_wrapper">
                            <table id="example" className="display w-100 dataTable">
                                <thead>
                                    <tr role="row">
                                        <th className='text-right'>{t('product_research.product_details.date')}</th>
                                        <th className='text-right'>{t('product_research.product_details.growth_rate')}</th>
                                        <th className='text-right'>{t('product_research.product_details.daily_conversion_rate')}</th>
                                        <th className='text-right'>{t('common.sales')}</th>
                                        <th className='text-right'>{t('common.revenue')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {saleshistory?.map((product) => (
                                        <tr>
                                            <td className='text-right'>{product?.date||'--'}</td>
                                            <td 
                                            className={`text-right ${
                                                product?.cycle_crc > 0 ? 'text-success' :
                                                product?.cycle_crc < 0 ? 'text-danger' :
                                                'text-muted'
                                            }`}
                                            >
                                                {product?.hasOwnProperty('cycle_crc') ? `${product?.cycle_crc > 0 ? '+' : ''}${(product?.cycle_crc * 100).toFixed(2)}%` : '--'}
                                            </td>
                                            <td 
                                            className={`text-right ${
                                                product?.conversion_rate > 0 ? 'text-success' :
                                                product?.conversion_rate < 0 ? 'text-danger' :
                                                'text-muted'
                                            }`}
                                            >
                                                {product?.hasOwnProperty('conversion_rate') ? `${product?.conversion_rate > 0 ? '+' : ''}${(product?.conversion_rate * 100).toFixed(2)}%` : '--'}
                                            </td>
                                            <td className='text-right'>{product?.sold_count}</td>
                                            <td className='text-right'>
                                                {product?.sales_amount_usd_str||'--'}<br />
                                                <span className='text-muted'>({product?.sales_amount_str||'--'})</span>
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </Table>
                    <div className="dataTables_paginate paging_simple_numbers">
                        <ReactPaginate
                            nextLabel="Next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={1}
                            marginPagesDisplayed={1}
                            pageCount={totalCount / 5}
                            previousLabel="< Previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            forcePage={currentpage-1}
                            renderOnZeroPageCount={null}
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SalesMatricsTab