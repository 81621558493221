import React from 'react'
import InfiniteScrollWrapper from '../../Products/InfiniteScrollWrapper';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function TopVideoCreators({ sortType, sorting, sortClass, handleSort, functionNext, items, favourite, markFavourite, hasMore }) {
  const { t } = useTranslation();

  const tableHead = [
    {
      title: '#',
      className: 'text-right',
    },
    {
      title: t('common.influencers'),
    },
    {
      title: t('common.revenue'),
      className: 'text-right sorting',
      sort: 18,
    },
    {
      title: t('common.sales'),
      className: 'text-right sorting',
      sort: 19,
    },
    {
      title: t('influencers.atv'),
      className: 'text-right sorting',
      sort: 20,
    },
    {
      title: t('common.products'),
      className: 'text-right sorting',
      sort: 15,
    },
    {
      title: t('common.videos'),
      className: 'text-right sorting',
      sort: 12,
    },
    {
      title: t('influencers.avg_views'),
      className: 'text-right sorting',
      sort: 16,
    },
    {
      title: t('common.followers'),
      className: 'text-right sorting',
      sort: 1,
    },
    {
      title: t('influencers.followers_change'),
      className: 'text-right sorting',
      sort: 17,
    },
  ];

  const countryicon = Object.keys(global?.config?.region).find(key => global?.config?.region[key] === items[0]?.sys_country?.country)

  return (
    <div>
      <InfiniteScrollWrapper
        isInfiniteScrollOn={true}
        lengthData={items.length}
        functionNext={functionNext}
        hasMore={hasMore}
      >
        <table id="example" className="display w-100 dataTable">
          <thead>
            <tr role="row">
              {tableHead.map((value, index) => (
                <th key={index} className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort ? sortClass : '')} onClick={(e) => { value.sort && handleSort(value.sort, sorting.sort == value.sort ? sortType : 'DESC') }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
              ))}
            </tr>
          </thead>
          <tbody>
            {items?.map((influencer, index) => (
              <tr key={index}>
                <td>{influencer.rank}</td>
                <td>
                  <div className='d-flex'>
                    <div className="prd-img mr-3">
                      <OverlayTrigger
                        key="right"
                        trigger={["hover", "focus"]}
                        placement="right"
                        rootClose
                        overlay={
                          <Popover>
                            <Popover.Body>
                              {influencer.avatar_larger_privatization &&
                                <img alt="" width={500} src={`https://t-img.picturehaven.net/tikmeta/${influencer.avatar_larger_privatization}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                              }
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <Button variant="link" className='p-0' size="xs">
                          <Link to={'/influencers/details/' + influencer.id + "/video-creators"} target="_blank">
                            {influencer.avatar_larger_privatization &&
                              <img alt="" width={70} className="prod-thum-img" src={`https://t-img.picturehaven.net/tikmeta/${influencer.avatar_larger_privatization}?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                            }
                          </Link>
                        </Button>
                      </OverlayTrigger>
                    </div>
                    <div className='prd-title'>
                      <Link to={'/influencers/details/' + influencer.id + "/video-creators"} target="_blank">
                        <p>{influencer?.nickname || '--'}</p>
                      </Link>
                      <p className='small'><span className={`fi fi-${countryicon.toLocaleLowerCase()} mr-1`}></span>{influencer?.sys_country?.country}</p>
                      {influencer?.categories?.map((e, i) => (
                        <p key={i}>{e?.name}</p>
                      ))}
                    </div>
                  </div>
                </td>
                <td className='text-right'>                  
                  {influencer?.estimated_sales_amount_usd||'--'} <br />
                  <span className='text-muted'>{influencer?.estimated_sales_amount ? `(${influencer?.estimated_sales_amount})` : '--'}</span>                  
                </td>
                <td className='text-right'>{influencer?.estimated_sales_volume}</td>
                <td className='text-right'>                  
                  {influencer?.estimated_avg_price_usd} <br />
                  <span className='text-muted'>{influencer?.estimated_avg_price ? `(${influencer?.estimated_avg_price})` : '--'}</span>                  
                </td>
                <td className='text-right'>{influencer?.related_products||'--'}</td>
                <td className='text-right'>{influencer?.related_videos||'--'}</td>
                <td className='text-right'>{influencer?.avg_play_count||'--'}</td>
                <td className='text-right'>{influencer?.follower_count_str||'--'}</td>
                <td className='text-right'>
                  {influencer.hasOwnProperty('follower_count_incr') ?
                    (influencer?.follower_count_incr > 0 ? <i className="fa fa-arrow-up mr-2 text-success" aria-hidden="true"></i> : 
                    <i className="fa fa-arrow-down mr-2 text-danger" aria-hidden="true"></i>) :
                    <i className="fa fa-arrow-up mr-2" aria-hidden="true">&nbsp;--</i>
                  }{influencer?.follower_count_incr}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </InfiniteScrollWrapper>
    </div>
  )
}

export default TopVideoCreators