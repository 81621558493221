import { React } from 'react';
import { Tab, Nav } from "react-bootstrap";
import TopSalesFilter from "./TopSalesFilter";
import FavoriteProduct from "./FavoriteProduct";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const TopSales = () => {
   const { t } = useTranslation();
   const search = useLocation().search;
   const tab = new URLSearchParams(search).get("tab") || 'hot';

   return (      
      <div className="custom-tab-1">
         <Tab.Container
            defaultActiveKey={tab}
         >
            <Nav as="ul" className="nav-tabs mb-0 bg-white">
               <Nav.Item as="li" key="hot">
                  <Nav.Link eventKey="hot" as={Link} to="?tab=hot"
                  >
                     {t('product_research.top_sales.tabs.top_sales_products')}
                  </Nav.Link>
               </Nav.Item>
               <Nav.Item as="li" key="breakout">
                  <Nav.Link eventKey="breakout" as={Link} to="?tab=breakout"
                  >
                     {t('product_research.top_sales.tabs.breakout_products')}
                  </Nav.Link>
               </Nav.Item>
               <Nav.Item as="li" key="new">
                  <Nav.Link eventKey="new" as={Link} to="?tab=new"
                  >
                     {t('product_research.top_sales.tabs.new_products')}
                  </Nav.Link>
               </Nav.Item>
               <Nav.Item as="li" key="popular">
                  <Nav.Link eventKey="popular" as={Link} to="?tab=popular"
                  >
                     {t('product_research.top_sales.tabs.hot_products')}
                  </Nav.Link>
               </Nav.Item>
               <Nav.Item as="li" key="favorite">
                  <Nav.Link eventKey="favorite" as={Link} to="?tab=favorite"
                  >
                     {t('product_research.top_sales.tabs.favorites')}
                  </Nav.Link>
               </Nav.Item>
            </Nav>
            <Tab.Content>
               { tab == 'hot' && 
               <Tab.Pane
                  eventKey="hot"
                  key="hot"
               > 
                  <TopSalesFilter defaultCycle={'H24'} tab={tab} sort={10} />
               </Tab.Pane> }
               { tab == 'breakout' && 
               <Tab.Pane
                  eventKey="breakout"
                  key="breakout"
               >
                  <TopSalesFilter defaultCycle={'H24'} tab={tab} sort={23} />
               </Tab.Pane> }
               { tab == 'new' && 
               <Tab.Pane
                  eventKey="new"
                  key="new"
               >
                  <TopSalesFilter defaultCycle={'H24'} tab={tab} sort={10} />
               </Tab.Pane> }
               { tab == 'popular' && 
               <Tab.Pane
                  eventKey="popular"
                  key="popular"
               >
                  <TopSalesFilter defaultCycle={'LD1'} rankType={'PRODUCT_HOT_RISING'} tab={tab} sort={33} />
               </Tab.Pane> }
               { tab == 'favorite' && 
               <Tab.Pane
                  eventKey="favorite"
                  key="favorite"
               >
                  <FavoriteProduct sort={33} />
               </Tab.Pane> }
            </Tab.Content>
         </Tab.Container>
      </div>
   );
};

export default TopSales;