import React, { useContext, useMemo } from 'react';
import { Button, Table } from 'react-bootstrap';
import { Context } from '../../../../MasterLayout';
import { useTranslation } from 'react-i18next';
import { useFilters, useGlobalFilter, useTable } from 'react-table';
import { GlobalFilter } from './GlobalFilter';

const CanceledUsers = ({ data }) => {
  const { dispatch } = useContext(Context);
  const { t } = useTranslation();

  const headList = [
    {
      Header: t('register_finance_report.email'),
      className: 'text-center',
      accessor: 'email',
      Filter: '',
    },
    {
      Header: t('register_finance_report.plan'),
      className: 'text-center',
      accessor: 'stripe_price',
      Filter: '',
    },
    {
      Header: t('register_finance_report.reason'),
      className: 'text-center',
      accessor: 'reason',
      Filter: '',
    },
    {
      Header: t('register_finance_report.canceled_at'),
      className: 'text-center',
      accessor: 'timeZoneDate',
      Filter: '',
    },
    {
      Header: t('register_finance_report.ends_at'),
      className: 'text-center',
      accessor: 'ends_at',
      Filter: '',
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => headList, []); const list = useMemo(() => data.list, []);;
  const tableInstance = useTable({ columns, data: list, initialState: {} }, useFilters, useGlobalFilter);
  const { headerGroups, state: stateFilter, setGlobalFilter } = tableInstance;
  const { globalFilter } = stateFilter;

  const downloadCsv = async (fileName, tableId, indexLength) => {
    try {
      dispatch({ type: 'loader_show', response: true });
      let filename = fileName + Date.now();
      let csv = [];

      let columsLength = [];
      for (let k = 0; k < indexLength; k++) {
        columsLength.push(k);
      };

      let rows = document.querySelectorAll(`#${tableId} tr`);

      for (let i = 0; i < rows.length; i++) {
        let row = [], cols = rows[i].querySelectorAll("td, th");
        for (let j = 0; j < cols.length; j++) {
          if (columsLength.indexOf(j) !== -1) {
            let rowData = cols[j].innerText;
            rowData = rowData.split(",").join(" ");
            row.push(rowData);
          };
        };
        csv.push(row);
      };

      let csvContent = csv.map((e) => e.join(",")).join("\n");
      let csvFile;
      let downloadLink;

      csvFile = new Blob([csvContent], { type: "text/csv" });
      downloadLink = document.createElement("a");
      downloadLink.download = filename + ".csv";
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    } catch (error) {
      console.error("Error-Catch: downloadCsv " + error.message);
    };
    dispatch({ type: 'loader_hide', response: true });
  };

  return (
    <div>
      <div className="row mb-4">
        <div className="col-lg-12">
          <div className="fh-table">
            <Table responsive className="w-100">
              <div id="example_wrapper" className="dataTables_wrapper">
                <Button variant="primary" type="button" size="xs" className="btn btn-primary" onClick={() => downloadCsv('CANCEL_USER_', 'CANCELUSERLIST', headList.length)}>CSV</Button>
                <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                <table id="CANCELUSERLIST" className="table dataTable display w-100">
                  <thead>
                    {headerGroups.map((head, i) => (
                      <tr key={i} role="row" className='bg-light'>
                        {head.headers.map((column, j) => (<th key={j} className="text-center">{column.render('Header')}</th>))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {tableInstance.globalFilteredRows.length > 0 ? tableInstance.globalFilteredRows.map((v, i) => (
                      <tr key={i}>
                        <td className="text-center">{v.original.email}</td>
                        <td className="text-center">{v?.original?.plan?.name}</td>
                        <td className="text-center">{v.original.reason}</td>
                        <td className="text-center">{v.original.timeZoneDate.split('T')[0]}</td>
                        <td className="text-center">{v.original.ends_at.split('T')[0]}</td>
                      </tr>)) : t('register_finance_report.no_data')}
                  </tbody>
                </table>
              </div>
            </Table>
          </div>
          <div className="dataTables_info">
            {data.list.length == 0 ? t('register_finance_report.no_record') : `${t('register_finance_report.showing')} 1 ${t('register_finance_report.to')} ${data.list.length} ${t('register_finance_report.of')} ${data.list.length} ${t('register_finance_report.entries')}`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanceledUsers;