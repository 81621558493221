import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import ReactApexChart from "react-apexcharts";

const VideoAnalysisChart = ({ data }) => {
    const [salesHistory, setSalesHistory] = useState({ series: [], options: {} });
    const [chartFilter, setChartFilter] = useState('Increase Count');
    const [chartFilterOption] = useState(['Increase Count', 'Count']);
    const chartFilterOptionHtml = chartFilterOption.map(function (val) {
        return <Button key={val} variant="outline-dark" size="sm" className={(chartFilter == val ? 'btn-primary' : '')} onClick={() => { setChartFilter(val); }}>{val}</Button>
    });

    useEffect(() => {
        createChart();
    }, [chartFilter, data]);

    const createChart = () => {
        let viewsArr = [], commentsArr = [], likesArr = [], sharesArr = [], labelsArr = [];

        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            labelsArr.push(element.statistical_day);
            chartFilter == 'Increase Count' ? viewsArr.push(element.play_count_change) : viewsArr.push(element.play_count);
            chartFilter == 'Increase Count' ? likesArr.push(element.digg_count_change) : likesArr.push(element.digg_count);
            chartFilter == 'Increase Count' ? commentsArr.push(element.comment_count_change) : commentsArr.push(element.comment_count);
            chartFilter == 'Increase Count' ? sharesArr.push(element.share_count_change) : sharesArr.push(element.share_count);
        };

        let saleshistoryData = { ...salesHistory };
        saleshistoryData.series = [{ name: 'Views', type: 'line', data: viewsArr }, { name: 'Likes', type: 'line', data: likesArr }, { name: 'Comments', type: 'line', data: commentsArr }, { name: 'Shares', type: 'line', data: sharesArr }];
        saleshistoryData.options = {
            chart: {
                height: 350,
                type: 'line',

            },
            stroke: {
                curve: 'smooth'
            },
            fill: {
                type: 'solid',
                opacity: [1, 1, 1, 1],
            },
            labels: labelsArr,
            markers: {
                size: 0
            },
            yaxis: [
                {
                    labels: {
                        formatter: function (value) {
                            if (value >= 10 ** 3 && value < 10 ** 6) {
                                value = (value / 1000).toFixed(1) + ' K';
                            } else if (value >= 10 ** 6) {
                                value = (value / 1000000).toFixed(1) + ' M';
                            } 
                            return value;
                        }
                    },
                },
                {
                    labels: {
                        show: false,
                        formatter: function (value) {
                            if (value >= 10 ** 3 && value < 10 ** 6) {
                                value = (value / 1000).toFixed(1) + ' K';
                            } else if (value >= 10 ** 6) {
                                value = (value / 1000000).toFixed(1) + ' M';
                            } 
                            return value;
                        }
                    },
                },
                {
                    opposite: true,
                    labels: {
                        show: false,
                        formatter: function (value) {
                            if (value >= 10 ** 3 && value < 10 ** 6) {
                                value = (value / 1000).toFixed(1) + ' K';
                            } else if (value >= 10 ** 6) {
                                value = (value / 1000000).toFixed(1) + ' M';
                            }
                            return value;
                        }
                    },
                },
                {
                    opposite: true,
                    labels: {
                        formatter: function (value) {
                            if (value >= 10 ** 3 && value < 10 ** 6) {
                                value = (value / 1000).toFixed(1) + ' K';
                            } else if (value >= 10 ** 6) {
                                value = (value / 1000000).toFixed(1) + ' M';
                            }
                            return value;
                        }
                    },
                },
            ],
            tooltip: {
                shared: true,
                intersect: false,
                hideEmptySeries: false
            }
        };

        setSalesHistory(saleshistoryData);
    };
    return (
        <>
            <div className="col-lg-4">
                <ButtonGroup className='mb-1 ml-1'>
                    {chartFilterOptionHtml}
                </ButtonGroup>
            </div>
            <div className="col-lg-12" style={{ "height": "400px" }}>
                <ReactApexChart options={salesHistory.options} series={salesHistory.series} type="line" height={350} />
            </div>
        </>
    );
};

export default VideoAnalysisChart;