import { React, useState, useEffect, useContext } from 'react';
import { Tab, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import MyAccount from "./MyAccount";
import Billing from "./Billing";
import Subscription from "./Subscription";
import Axios from "../../plugins/axios";
import { Context } from '../../../MasterLayout';
import { useTranslation } from 'react-i18next';


let userData;
const Profile = () => {
    const { t } = useTranslation();

    const { dispatch } = useContext(Context);
    const search = useLocation().search;
    const tab = new URLSearchParams(search).get("tab") || 'myaccount';
    const [user, setUser] = useState({});
    const [loader, setLoader] = useState(true);
    const [reinit, setReinit] = useState(0);
    useEffect(() => {
        setLoader(true);
        getUser();
    }, [reinit]);

    const getUser = async () => {
        try {
            dispatch({ type: 'loader_show', response: true });
            const response = await Axios.get('/api/user?expiry=true').catch(e => console.error('Axios-Catch: ' + e.message));
            if (response && response.status === 200) {
                setUser(response.data.data);
                userData = response.data.data;
            }
        } catch (error) {
            console.error('Error-Catch: ' + error.message);
        };
        setLoader(false);
        dispatch({ type: 'loader_hide', response: true });
    };

    const reRender = async () => {
        let reload = reinit + 1;
        setReinit(reload);
    };

    return (
        <>
            {!loader &&
                <div className="">
                    {userData && (userData.user_type == 'user' || userData.user_type == 'free_full_access')
                        ?
                        <Tab.Container
                            defaultActiveKey={tab}
                        >
                            <Nav as="ul" className="nav-pills mb-4">
                                <Nav.Item as="li" key="myaccount">
                                    <Nav.Link eventKey="myaccount" as={Link} to="?tab=myaccount"
                                    >
                                        {t('user_profile.my_account')}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li" key="billing">
                                    <Nav.Link eventKey="billing" as={Link} to="?tab=billing"
                                    >
                                        {t('user_profile.billing')}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li" key="subscription">
                                    <Nav.Link eventKey="subscription" as={Link} to="?tab=subscription"
                                    >
                                        {t('user_profile.subscription')}
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                {tab == 'myaccount' &&
                                    <Tab.Pane
                                        eventKey="myaccount"
                                        key="myaccount"
                                    >
                                        <MyAccount user={user} func={reRender} />
                                    </Tab.Pane>}
                                {tab == 'billing' &&
                                    <Tab.Pane
                                        eventKey="billing"
                                        key="billing"
                                    >
                                        <Billing user={user} func={reRender} />
                                    </Tab.Pane>}
                                {tab == 'subscription' &&
                                    <Tab.Pane
                                        eventKey="subscription"
                                        key="subscription"
                                    >
                                        <Subscription user={user} func={reRender} />
                                    </Tab.Pane>}
                            </Tab.Content>
                        </Tab.Container>
                        :
                        <Tab.Container
                            defaultActiveKey={tab}
                        >
                            <Nav as="ul" className="nav-pills mb-4">
                                <Nav.Item as="li" key="myaccount">
                                    <Nav.Link eventKey="myaccount" as={Link} to="?tab=myaccount"
                                    >
                                        {t('user_profile.my_account')}
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                {tab == 'myaccount' &&
                                    <Tab.Pane
                                        eventKey="myaccount"
                                        key="myaccount"
                                    >
                                        <MyAccount user={user} func={reRender} />
                                    </Tab.Pane>}
                            </Tab.Content>
                        </Tab.Container>}
                </div>
            }
        </>
    );
};

export default Profile;