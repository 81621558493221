module.exports = global.config = {
    region: {
        'US': 'United States',
        'GB': 'United Kingdom',
        'ID': 'Indonesia',
        'TH': 'Thailand',
        'PH': 'Philippines',
        'VN': 'Viet Nam',
        'MY': 'Malaysia',
        'SG': 'Singapore',
    },
    countries: {
        "DZ": "Algeria",
        "IQ": "Iraq",
        "KW": "Kuwait",
        "LB": "Lebanon",
        "NP": "Nepal",
        "TN": "Tunisia",
        "AR": "Argentina",
        "CL": "Chile",
        "CO": "Colombia",
        "EC": "Ecuador",
        "EG": "Egypt",
        "MA": "Morocco",
        "PE": "Peru",
        "ZA": "South Africa",
        "SZ": "Swaziland",
        "BD": "Bangladesh",
        "BE": "Belgium",
        "KH": "Cambodia",
        "DK": "Denmark",
        "FI": "Finland",
        "FR": "France",
        "DE": "Germany",
        "GR": "Greece",
        "HU": "Hungary",
        "IS": "Iceland",
        "IL": "Israel",
        "IT": "Italy",
        "JO": "Jordan",
        "KZ": "Kazakhstan",
        "NL": "Netherlands",
        "NO": "Norway",
        "PL": "Poland",
        "PT": "Portugal",
        "RO": "Romania",
        "SK": "Slovakia",
        "ES": "Spain",
        "LK": "Sri Lanka",
        "SE": "Sweden",
        "CH": "Switzerland",
        "TW": "Taiwan",
        "UA": "Ukraine",
        "AE": "United Arab Emirates",
        "AU": "Australia",
        "BR": "Brazil",
        "CA": "Canada",
        "IN": "India",
        "JP": "Japan",
        "KR": "Korea",
        "MX": "Mexico",
        "NZ": "New Zealand",
        "RU": "Russian Federation",
        "SA": "Saudi Arabia",
        "TR": "Turkey"
    },
    printifyRegions: {
        'US': 'USA',
        "CA": "Canada",
        "EU": "Europe",
        'GB': 'United Kingdom',
        'CN': 'China',
        "AU": "Australia/NZ",
        "OT": "Other"
    },

    rangeOption: {
        '0-10000': '≤10K',
        '10000-100000': '10K-100K',
        '100000-1000000': '100K-1M',
        '1000000-10000000': '1M:10M',
        '10000000-': '≥10M',
    },
    // other global config variables you wish
    chineseCategories: [
        { name: "Art", namecn: "文学艺术" },
        { name: "Beauty Care", namecn: "美妆护理" },
        { name: "Brand", namecn: "品牌" },
        { name: "Celebrities", namecn: "网红名人" },
        { name: "Entertainment", namecn: "娱乐&特效" },
        { name: "Effects & Montage", namecn: "网络" },
        { name: "Food", namecn: "美食菜谱" },
        { name: "Fashion", namecn: "时尚&穿搭" },
        { name: "Finance", namecn: "金融经济" },
        { name: "Games", namecn: "游戏" },
        { name: "Government", namecn: "政府" },
        { name: "Health", namecn: "健康" },
        { name: "Key Option Leader", namecn: "权威顾问" },
        { name: "Life Style", namecn: "生活" },
        { name: "Media", namecn: "渠道" },
        { name: "NGO", namecn: "非政府组织" },
        { name: "Pets", namecn: "宠物" },
        { name: "Programming", namecn: "IT" },
        { name: "Sports", namecn: "运动" },
        { name: "Science & Education", namecn: "科学教育" },
        { name: "Service", namecn: "专业服务" },
        { name: "Talent", namecn: "唱歌跳舞" },
        { name: "Travel", namecn: "旅游" },
        { name: "Technology", namecn: "科技&电子" },
        { name: "Vlog", namecn: "Vlog" },
        { name: "Other", namecn: "其他" },

    ],
    ModuleLists: [
        { name: "Select Module", id: "" },
        { name: "Products Video", id: "Products Video" },
        { name: "Products Search", id: "Products Search" },
        { name: "Breakout Influencers", id: "Breakout Influencers" },
        { name: "Top Sales Products", id: "Top Sales Products" },
        { name: "Profitability Calculator", id: "Profitability Calculator" },
        { name: "Product Video Detail", id: "Product Video Detail" },
        { name: "Breakout Product Video", id: "Breakout Product Video" },
        { name: "Top Sale Influencer", id: "Top Sale Influencer" },
        { name: "Influencer Search", id: "Influencer Search" },
        { name: "Influencer Detail", id: "Influencer Detail" },
        { name: "Login", id: "Login" },
    ],
    switchPlanType: [
        { key: "Monthly", value: "monthly" },
        { key: "Annual", value: "annual" },
    ],
};