import { React } from 'react';
import { Tab, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import BreakoutSongFilters from './BreakoutSongFilters';
import FavoriteSongs from './FavouriteSongs';

const SongsRanking = () => {
   const { t } = useTranslation();
   const search = useLocation().search;
   const tab = new URLSearchParams(search).get("tab") || 'breakout';

   return (      
      <div className="custom-tab-1">
         <Tab.Container
            defaultActiveKey={tab}
         >
            <Nav as="ul" className="nav-tabs mb-0 bg-white">
               <Nav.Item as="li" key="breakout">
                  <Nav.Link eventKey="breakout" as={Link} to="?tab=breakout"
                  >
                     {t('creator_center.songs_ranking.tabs.breakout_songs')}
                  </Nav.Link>
               </Nav.Item>
               <Nav.Item as="li" key="popular">
                  <Nav.Link eventKey="popular" as={Link} to="?tab=popular"
                  >
                     {t('creator_center.songs_ranking.tabs.popular_songs')}
                  </Nav.Link>
               </Nav.Item>
               <Nav.Item as="li" key="favorite">
                  <Nav.Link eventKey="favorite" as={Link} to="?tab=favorite"
                  >
                     {t('common.favorites')}
                  </Nav.Link>
               </Nav.Item>
            </Nav>
            <Tab.Content>
               { tab == 'breakout' && 
               <Tab.Pane
                  eventKey="breakout"
                  key="breakout"
               > 
                  <BreakoutSongFilters defaultCycle={'W1'} tab={tab} sort={10} />
               </Tab.Pane> }
               { tab == 'popular' && 
               <Tab.Pane
                  eventKey="popular"
                  key="popular"
               >
                  <BreakoutSongFilters defaultCycle={'W1'} tab={tab} sort={10} />
               </Tab.Pane> }             
               { tab == 'favorite' && 
               <Tab.Pane
                  eventKey="favorite"
                  key="favorite"
               >
                  <FavoriteSongs sort={33} />
               </Tab.Pane> }
            </Tab.Content>
         </Tab.Container>
      </div>
   );
};

export default SongsRanking;