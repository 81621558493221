import Axios from "../../plugins/axios";
import { React, useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Table, OverlayTrigger, Popover } from "react-bootstrap";
import InfiniteScrollWrapper from "../Products/InfiniteScrollWrapper";
import '../../../config';
import Conversion from "../CommonComponents/Conversion";
import { Context } from '../../../MasterLayout';
import { useTranslation } from 'react-i18next';


const FavoriteShop = () => {
   const { t } = useTranslation();

   const { dispatch } = useContext(Context);
   const [page, setPage] = useState(0);
   const [items, setItems] = useState([]);
   const [hasMore, setHasMore] = useState(true);
   const [favourite, setFavourite] = useState([]);

   const tableHead = [
      {
         title: '#',
         className: 'text-right',
      },
      {
         title: t('shops.shop'),
      },
      {
         title: t('shops.main_category'),
      },
      {
         title: t('common.sales'),
         className: 'text-right sorting',
         sort: 37,
      },
      {
         title: t('common.revenue'),
         className: 'text-right sorting',
         sort: 36,
      },
      {
         title: t('shops.relevant_br_influencers'),
         className: 'text-right sorting',
         sort: 39,
      },
      {
         title: t('common.videos'),
         className: 'text-right sorting',
         sort: 40,
      },
      {
         title: t('common.products'),
         className: 'text-right sorting',
         sort: 41,
      },
      {
         title: t('common.favorite'),
         className: 'text-right',
      },
   ];

   const getFavoriteList = async (page) => {
      let params = {
         page: page,
         limit: 20
      }
      let response = await Axios.get('/api/favorite-shop', { params });
      dispatch({ type: 'subscription_expired', response: response.data.statusCode });

      if (response && response.status === 200 && response.data.result) {
         let array = await response.data.result ? response.data.result.map((value, index) => value.shop_id) : favourite;

         if (page == 0) {
            setItems(response.data.result);
            setFavourite(array);
         } else {
            setItems(items.concat(response.data.result));
            setFavourite(favourite.concat(array));
         }
         if (response.data.result.length === 0 || response.data.result.length < 20) {
            setHasMore(false);
         } else {
            setHasMore(true);
         }
      }
   };

   useEffect(() => {
      //do operation on state change
      setItems([]);
      setPage(0);
      getFavoriteList(0);
   }, []);

   const functionNext = async () => {
      if (items.length > 0) {
         let number = page + 1;
         setPage(number);
         getFavoriteList(number)
      }
   }

   const markFavourite = async (data) => {
      await Axios.post('/api/favorite-product', data);
      const index = favourite.indexOf(data.shop_id);
      if (index > -1) {
         favourite.splice(index, 1);
      } else {
         favourite.push(data.shop_id);
      }

      setFavourite(favourite.concat([]));
   };

   const countryicon = Object.keys(global?.config?.region).find(key => global?.config?.region[key] === items[0]?.sys_country?.country)

   return (
      <Card>
         <Card.Body className='p-2'>
            <Table responsive className="w-100">
               <div id="example_wrapper" className="dataTables_wrapper">
                  <InfiniteScrollWrapper
                     isInfiniteScrollOn={true}
                     lengthData={items.length}
                     functionNext={functionNext}
                     hasMore={hasMore}
                  >
                     <table id="example" className="display w-100 dataTable">
                        <thead>
                           <tr role="row">
                              {tableHead.map((value, index) => (
                                 <th key={index} className={value.className || ''} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                              ))}
                           </tr>
                        </thead>
                        <tbody>
                           {items?.length ? items?.map((shop, index) => (
                              <tr key={index}>
                                 <td className='text-right'>{shop.rank}</td>

                                 <td>
                                    <div className='d-flex'>
                                       <div className="prd-img mr-3">
                                          <OverlayTrigger
                                             key="right"
                                             trigger={["hover", "focus"]}
                                             placement="right"
                                             rootClose
                                             overlay={
                                                <Popover>
                                                   <Popover.Body>
                                                      {shop?.shop_logo_privatization &&
                                                         <img alt="" width={500} src={`https://t-img.picturehaven.net/product/${shop?.shop_logo_privatization}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                      }
                                                   </Popover.Body>
                                                </Popover>
                                             }
                                          >
                                             <Button variant="link" className='p-0' size="xs">
                                                <Link to={'/shops/' + shop.id + '/details'} target="_blank">
                                                   {shop?.shop_logo_privatization && <img alt="" width={70} className="prod-thum-img" src={`https://t-img.picturehaven.net/product/${shop?.shop_logo_privatization}?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1`} />}
                                                </Link>
                                             </Button>
                                          </OverlayTrigger>
                                       </div>
                                       <div className='prd-title'>
                                          <Link to={'/shops/' + shop.id + '/details'} target="_blank"><p>{shop?.shop_name || '--'}</p></Link>
                                          <p className='small'><span className={`fi fi-${countryicon?.toLocaleLowerCase()} mr-1`}></span>{shop?.sys_country?.country}</p>
                                       </div>
                                    </div>
                                 </td>
                                 {/* //MAIN CATEGORY */}
                                 <td>
                                    {shop?.category_list && shop?.category_list.length != 0 && <div className="mb-1">
                                       <span className="btn btn-outline-dark btn-xs px-2 py-1 mr-1 mb-1">{shop?.category_list[0]?.category_name}</span> <br />
                                       {shop?.category_list[1]?.category_name && <><span className="btn btn-outline-dark btn-xs px-2 py-1 mr-1 mb-1">{shop?.category_list[1]?.category_name}</span> <br /></>}
                                       {shop?.category_list.length > 2 && <OverlayTrigger
                                          key="right"
                                          trigger="click"
                                          placement="bottom"
                                          rootClose
                                          overlay={
                                             <Popover className="variants-tooltip">
                                                <Popover.Body>
                                                   <div className="m-2">
                                                      {shop.category_list && shop.category_list.map((v, i) => {
                                                         if (i != 0 && i != 1) return <span key={i} className="btn btn-outline-dark btn-xs px-2 py-1 mb-1 mr-1">{v.category_name}</span>
                                                      })}
                                                   </div>
                                                </Popover.Body>
                                             </Popover>
                                          }
                                       >
                                          <Button variant="outline-dark" size="xs" className='px-2 py-1'>{shop.category_list.length > 1 ? ('+' + (shop.category_list.length - 2)) : ''}  <i className="fa fa-chevron-down" aria-hidden="true"></i></Button>
                                       </OverlayTrigger>}
                                    </div>}

                                 </td>
                                 {/* // SALES */}
                                 <td className='text-right'><Conversion value={shop.sold_count} /></td>

                                 {/* //REVENUE */}
                                 <td className='text-right'>
                                    {shop?.sold_amount_usd}<br />
                                    <span className='text-muted'>{shop?.sold_amount_str}</span>
                                 </td>

                                 {/* // RELEVNT INFLUENCERS */}
                                 <td className='text-right'><Conversion value={shop.related_authors} /></td>

                                 {/* //VIDEOS  */}
                                 <td className='text-right'><Conversion value={shop.related_videos} /> </td>

                                 {/* //PRODUCTS  */}
                                 <td className='text-right'><Conversion value={shop.related_products} /></td>


                                 {/* //FAVORITES */}
                                 <td className="text-right">
                                    <div className="d-flex justify-content-end">
                                       <div className="mr-1">
                                          {<Button
                                             variant="outline-dark" size="xs"
                                             className="px-2 py-1"
                                             onClick={() => { markFavourite({ shop_id: shop.shop_id, shop_name: shop?.shop_name }); }}>
                                             <i className={"fa " + (favourite.indexOf(shop.shop_id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                                          </Button>}
                                       </div>
                                    </div>
                                 </td>
                              </tr>


                           )) : ''}
                        </tbody>
                     </table>
                  </InfiniteScrollWrapper>
               </div>
            </Table>
         </Card.Body>
      </Card>
   );
};

export default FavoriteShop;