import React, { useEffect, useRef, useState, useContext } from 'react'
import { Card, Nav, Tab, Button, OverlayTrigger, Popover, Modal, Tooltip, ProgressBar, Image } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import VideoAnalysisDetails from './VideoAnalysisDetails'
import RelevantProductvideo from './RelevantProductvideo'
import SalesAnalysisVideo from './SalesAnalysisVideo'
import Axios from '../../../../plugins/axios'
import { DateFormat } from '../../../CommonComponents/FormatNumber'
import Conversion from '../../../CommonComponents/Conversion'
import { TikTokEmbed } from 'react-social-media-embed';
import { ToastContainer, toast } from 'react-toastify'
import { Context } from '../../../../../MasterLayout';
import { useTranslation } from 'react-i18next';
import { isEmpty } from '../../../CommonComponents/isEmpty'
import PlanModal from '../../../CommonComponents/PlanModal'

function ProductVideo() {
    const { t } = useTranslation();
    const { dispatch } = useContext(Context);

    const childRef = useRef();
    const childRelevantLiveRef = useRef();

    const params = useParams()
    const [details, setDetails] = useState();
    const [contentModal, setContentModal] = useState(false);
    const [tikTokEmbedID, setTikTokEmbedID] = useState('');
    const [favorite, setFavorite] = useState(false);

    const [scriptModal, setScriptModal] = useState(false);
    const [scripttext, setscripttext] = useState("");
    const [progress, setProgress] = useState(0);
    const [progressBar, setProgressBar] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [limitMsg, setLimitMsg] = useState('');

    useEffect(() => {
        fetchdata();
    }, [])

    const fetchdata = async () => {
        try {
            const response = await Axios.post('/api/video-detail', { video_id: params?.productId })
            if (response?.data?.statusCode === 402) {
                setLimitMsg(response?.data?.msg || '');
                setModalShow(true);
            }
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });
            if (response && response.status === 200 && response.data.data) {
                setDetails(response?.data?.data);
                response?.data?.data.favorite?.length ? setFavorite(response?.data?.data.favorite?.some(fav => fav?.video_id === params?.productId)) : setFavorite(false);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const addFavorite = async () => {
        try {
            const response = await Axios.post('/api/favorite-product-video', { id: details?.id })
            setFavorite(response.data.favorite)
        } catch (error) {
            console.error(error)
        }
    }
    const downloadVideo = async () => {
        try {
            const response = await Axios.post('/api/video-download', {}, { responseType: 'blob' });
            if (response?.data?.statusCode === 402) {
                setLimitMsg(response?.data?.msg || '');
                setModalShow(true);
            }
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });

            const currentTimestamp = Date.now().toString();
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = currentTimestamp + '.mp4';
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(anchor);
        } catch (error) {
            console.error(error);
        }
    }

    const type = (e) => {
        const arrayOfStrings = e?.split(/,\s*/);
        const name = arrayOfStrings?.map(cat => {
            const matchedCategory = global.config?.chineseCategories?.find(chCat => chCat.namecn === cat || chCat.name === cat);
            return matchedCategory?.name;
        })
        return name?.join(',')
    }

    const handleClick = () => {
        const url = `https://www.tiktok.com/@${details?.author_unique_id}.oio/video/${params?.productId}`;
        window.open(url, '_blank');
    }


    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    async function retryApiCall(retryCount = 3) {
        try {
            const response = await Axios.post("/api/video-script-status", { video_id: params?.productId });
            if (response.data.data === "success") {
                return response.data;
            } else {
                if (retryCount > 0) {
                    await delay(3000);
                    return retryApiCall(retryCount - 1);
                } else {
                    throw new Error("Maximum retry attempts reached");
                }
            }
        } catch (error) {
            if (retryCount > 0) {
                await delay(3000);
                return retryApiCall(retryCount - 1);
            } else {
                return
            }
        }
    }

    const scriptHtml = async (aiScript) => {
        try {
            setProgressBar(true);
            setProgress(25);

            await Axios.post('/api/video-script-times');

            await Axios.post('/api/video-script-submit', { video_id: params?.productId });
            setProgress(50);

            await retryApiCall();
            setProgress(75);

            const download = await Axios.post('/api/video-script-export', { video_id: params?.productId, aiScript: aiScript });
            if (download?.data?.statusCode === 402) {
                setLimitMsg(download?.data?.msg || '');
                setModalShow(true);
            }
            setProgress(100);
            setProgressBar(false);

            if (download?.data?.data) {
                setscripttext(download?.data?.data);
                setScriptModal(true);
            } else {
                toast.error(t('product_research.product_details.failed_to_export_the_data'), {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error(t('product_research.product_details.failed_to_export_the_data_try_again'), {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const copyToClipboard = () => {
        const textToCopy = scripttext.split('\\n').join('\n');
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                toast.success(t('product_research.product_details.text_copied_to_clipboard'), {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch((error) => {
                console.error('Error copying text to clipboard:', error);
                toast.error(t('product_research.product_details.failed_to_copy_text_to_clipboard'), {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

    };

    const exportdata = async () => {
        const textToDownload = scripttext.split('\\n').join('\n');
        const blob = new Blob([textToDownload], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = "script_" + Date.now().toString() + ".txt";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success(t('product_research.product_details.data_downloaded_successfully'), {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    return (
        <>
            {limitMsg && <div className='d-flex flex-row justify-content-center'>
                <h4 className='flex my-2 text-center mx-2'><strong>{limitMsg}</strong></h4>
            </div>}

            {!isEmpty(details) && <>


                {/* </>} */}
                <div>
                    <Card>
                        <Card.Body>
                            <div className='row'>
                                <div className='col-lg-2'>
                                    <div className="prd-img mr-3">
                                        <a href={`https://www.tiktok.com/@${details.author_unique_id}/video/${details.id}`} target='_blank' rel="noreferrer">
                                            <img alt="" width={75} height={75} className="prod-thum-img" src={`https://t-img.picturehaven.net/tikmeta/${details?.origin_cover_privatization}?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                        </a>
                                        <Button
                                            variant="primary"
                                            className="p-0 video-popup-btn"
                                            onClick={() => { setTikTokEmbedID(details?.id); setContentModal(!contentModal); }}
                                        >
                                            <i className="fa fa-play-circle" aria-hidden="true"></i>
                                        </Button>
                                    </div>
                                </div>
                                <div className='col-lg-10'>
                                    <div className='d-flex align-items-start justify-content-between'>
                                        <div className='d-flex align-items-center'>
                                            <h4 className='mb-0 mr-5'>
                                                {details?.desc ? `${details?.desc.length > 40 ? details?.desc.substring(0, 42) + '...' : details?.desc}` : '--'}
                                            </h4>
                                            <Button variant="outline-dark" size="xs" className="px-2 py-1 mr-3"><i className="fa fa-language" aria-hidden="true"></i></Button>
                                            <Button variant="outline-dark" size="xs" className="px-2 py-1 mr-3" onClick={addFavorite} ><i className={"fa " + (favorite ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i></Button>
                                            <OverlayTrigger overlay={<Tooltip>{`https://www.tiktok.com/@${details?.author_unique_id}.oio/video/${params?.productId}`}</Tooltip>}>
                                                <Button onClick={handleClick} variant="outline-dark" size="xs" className="px-2 py-1 mr-3">
                                                    <i className="fa fa-link" aria-hidden="true"></i>
                                                </Button>
                                            </OverlayTrigger>
                                        </div>
                                        <div className="text-right">
                                            <p className="mb-0"><small>{t('product_research.product_details.data_shown_below_are')}</small></p>
                                            <p className="mb-0"><small>{t('product_research.product_details.time_of_first_discovery')}:</small></p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-4'>
                                            <div className='mb-2'>
                                                <p className='mb-0'><strong>{t('product_research.product_details.post_date')}:</strong>
                                                    &nbsp;&nbsp;
                                                    {details?.create_time ? <DateFormat timestamp={details?.create_time} /> : '--'}
                                                    <span></span></p>
                                                <p className='mb-0'><strong>{t('product_research.product_details.video_duration')}:</strong>{details?.video_duration ? `${details?.video_duration} s` : '--'}<span></span></p>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <OverlayTrigger
                                                    key="right"
                                                    trigger={["hover", "focus"]}
                                                    placement="right"
                                                    rootClose
                                                    overlay={
                                                        <Popover>
                                                            <Popover.Body>
                                                                {details.author_avatar_larger_privatization && <img src={"https://t-img.picturehaven.net/tikmeta/" + details.author_avatar_larger_privatization + "?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={400} className="prod-thum-img" />}
                                                            </Popover.Body>
                                                        </Popover>
                                                    }
                                                >
                                                    <Button variant="link" className='p-0' size="xs">
                                                        <Link to={'/influencers/details/' + details.author_id + "/breakout"} target="_blank">
                                                            {details.author_avatar_larger_privatization && <Image src={"https://t-img.picturehaven.net/tikmeta/" + details.author_avatar_larger_privatization + "?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={60} className="prod-thum-img" roundedCircle />}
                                                        </Link>
                                                    </Button>
                                                </OverlayTrigger>
                                                <div className="prd-title ml-2">
                                                    <Link to={'/influencers/details/' + details.author_id + "/breakout"} target="_blank">
                                                        <p className="mb-0">{details?.author_nickname}</p>
                                                    </Link>
                                                </div>

                                            </div>
                                            <div className='d-flex flex-wrap mb-3'>
                                                <p className='mb-0 mr-3'>
                                                    {details?.author_unique_id ? `${details?.author_unique_id.length > 8 ? "@" + details?.author_unique_id.substring(0, 6) + '...' : "@" + details?.author_unique_id}` : '--'}
                                                </p>
                                                <p className='mb-0 mr-3'>{t('common.followers')}：{details?.follower_count ? <Conversion value={details?.follower_count} /> : '--'}</p>
                                                <p className='mb-0 mr-3'>{t('common.videos')}：{details?.video_count ? <Conversion value={details?.video_count} /> : '--'}</p>
                                                <p className='mb-0 mr-3'>{t('product_research.product_details.type')}：{details?.author_categories ? type(details?.author_categories) : '--'}</p>
                                            </div>
                                            <Button onClick={downloadVideo} variant="primary" size="sm" className="py-2 mr-2 mb-2"><i className="fa fa-download" aria-hidden="true" ></i> {t('product_research.product_details.download_video')}</Button>
                                            <Button onClick={() => scriptHtml(false)} variant="primary" size="sm" className="py-2 mr-2 mb-2"><i className="fa fa-file-code-o" aria-hidden="true"></i> {t('product_research.product_details.script_export')}</Button>
                                            {/*<Button onClick={() => scriptHtml(true)} variant="primary" size="sm" className="py-2 mr-2 mb-2"><i className="fa fa-file-code-o" aria-hidden="true"></i> {t('product_research.product_details.export_ai_generated_script')}</Button>*/}

                                        </div>

                                        <div className='col-lg-8'>
                                            <div className='d-flex flex-wrap'>
                                                <div className='mr-5'>
                                                    <p className='mb-0'><strong>{t('common.revenue')}</strong></p>
                                                    <h3>{details?.total_sales_amount_usd ? details?.total_sales_amount_usd : '--'}</h3>
                                                    <p> {details?.total_sales_amount ? `(${details?.total_sales_amount ? details?.total_sales_amount : '--'})` : '--'}</p>
                                                    <p>{t('product_research.product_details.than_yesterday')}</p>
                                                    <p><span className='text-success'>{details?.increase_total_sales_amount_usd ? `+${details?.increase_total_sales_amount_usd}` : '-'} </span>&nbsp;&nbsp;({details?.increase_total_sales_amount_str ? details?.increase_total_sales_amount_str : '--'})</p>
                                                </div>
                                                <div className='mr-5'>
                                                    <p className='mb-0'><strong>{t('product_research.product_details.arv')}</strong></p>
                                                    <h3>{details?.avg_price_usd ? details?.avg_price_usd : '--'}</h3>
                                                    <p>{details?.avg_price ? `(${details?.avg_price})` : '--'}</p>
                                                </div>
                                                <div className='mr-5'>
                                                    <p className='mb-0'><strong>{t('product_research.product_details.sales_volume')}</strong></p>
                                                    <h3>{details?.total_sales_volume ? details?.total_sales_volume : '--'}</h3>
                                                    <p>{t('product_research.product_details.than_yesterday')}</p>
                                                    <p
                                                        className={` ${details?.increase_total_sales_volume > 0 ? 'text-success' :
                                                            details?.increase_total_sales_volume < 0 ? 'text-danger' :
                                                                'text-muted'
                                                            }`}
                                                    >
                                                        {details?.increase_total_sales_volume > 0 && "+"}
                                                        {details?.increase_total_sales_volume ? details?.increase_total_sales_volume : '--'}</p>
                                                </div>
                                                <div className='mr-5'>
                                                    <p className='mb-0'><strong>{t('common.views')}</strong></p>
                                                    <h3>{details?.play_count ? <Conversion value={details?.play_count} /> : '--'} </h3>
                                                    <p>{t('product_research.product_details.than_yesterday')} -</p>
                                                </div>
                                                <div className='mr-5'>
                                                    <p className='mb-0'><strong>{t('product_research.product_details.conversion_rate')}</strong></p>
                                                    <h3>{details?.conversion_rate ? `${(details?.conversion_rate * 100).toFixed(2)}%` : '0.00%'}</h3>
                                                    <p>{t('product_research.product_details.than_yesterday')}</p>
                                                    <p className={` ${(details?.increase_conversion_rate * 100).toFixed(2) > 0 ? 'text-success' :
                                                        (details?.increase_conversion_rate * 100).toFixed(2) < 0 ? 'text-danger' :
                                                            'text-muted'
                                                        }`}
                                                    >
                                                        {(details?.increase_conversion_rate * 100).toFixed(2) > 0 && "+"}
                                                        {details?.increase_conversion_rate ? `${(details?.increase_conversion_rate * 100).toFixed(2)}%` : '-'}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                    <div className="custom-tab-1">
                        <Tab.Container
                            defaultActiveKey="salesAnalysis"
                        >
                            <Nav as="ul" className="nav-tabs mb-0 bg-white">
                                <Nav.Item as="li" key="salesAnalysis">
                                    <Nav.Link eventKey="salesAnalysis" as={Link}
                                    >
                                        <i className="fa fa-bar-chart" aria-hidden="true"></i> {t('product_research.product_details.sales_analysis')}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li" key="videoAnalysis">
                                    <Nav.Link eventKey="videoAnalysis" as={Link} onClick={() => childRef.current.videoAnalysis()}
                                    >
                                        <i className="fa fa-file-video-o" aria-hidden="true"></i> {t('product_research.product_details.video_analysis')}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li" key="relevantProducts">
                                    <Nav.Link eventKey="relevantProducts" as={Link} onClick={() => childRelevantLiveRef.current.relevantProductVideo()}
                                    >
                                        <i className="fa fa-video-camera" aria-hidden="true"></i> {t('common.relevant_products')}
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane
                                    eventKey="salesAnalysis"
                                    key="salesAnalysis"
                                >
                                    <SalesAnalysisVideo />
                                </Tab.Pane>

                                <Tab.Pane
                                    eventKey="videoAnalysis"
                                    key="videoAnalysis"
                                >
                                    <VideoAnalysisDetails ref={childRef} />
                                </Tab.Pane>

                                <Tab.Pane
                                    eventKey="relevantProducts"
                                    key="relevantProducts"
                                >
                                    <RelevantProductvideo ref={childRelevantLiveRef} />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>

                </div>

                <Modal show={scriptModal} onHide={() => setScriptModal(false)} >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('product_research.product_details.export_script')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-0'>
                        <Card>
                            <Card.Body>
                                <div>
                                    {scripttext.split('\n').map((line, index) => (
                                        <div key={index}>{line}</div>
                                    ))}
                                </div>
                            </Card.Body>
                        </Card>
                        <div className='d-flex justify-content-center gap-2'>
                            <Button onClick={copyToClipboard}>{t('product_research.product_details.copy')}</Button>
                            <Button onClick={exportdata} >{t('product_research.product_details.export')}</Button>
                        </div>

                    </Modal.Body>
                </Modal>
                <Modal show={progressBar}>
                    <ProgressBar now={progress} />
                </Modal>
                {/* <!-- Video Modal --> */}
                <Modal className="fade video-popup" show={contentModal}>
                    <Modal.Body className='p-0'>
                        <div className='d-flex'>
                            <div className='text-center'>
                                <TikTokEmbed url={`https://www.tiktok.com/embed/${tikTokEmbedID}`} width={325} />
                            </div>
                            <div className='px-3'>
                                <div className='d-flex flex-column'>
                                    <Button
                                        variant="dark"
                                        className="mb-3"
                                        onClick={() => { setTikTokEmbedID(''); setContentModal(!contentModal); }}
                                    >
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                    </Button>
                                    {/* <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-line-chart" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-download" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-file-code-o" aria-hidden="true"></i>
                        </Button> */}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <ToastContainer />
            </>}
            <PlanModal setModalShow={setModalShow} modalShow={modalShow} />
        </>
    )
}

export default ProductVideo;