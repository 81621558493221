const reducer = (state, action) => {
  if (action.type === 'subscription_expired') {
    if (action.response == 405) state.modal_show = true;
  };
  if (action.type === 'loader_show') {
    if (action.response) state.loaderShow = true;
  };
  if (action.type === 'loader_hide') {
    if (action.response) state.loaderShow = false;
  };
  if (action.type === 'keyword_searched') {
    state.keyword = action.response;
  };
  if (action.type === 'user_ip') {
    state.ip = action.response;
  };
  if (action.type === 'switch_plan_type') {
    state.selectedPlanType = action.response;
  };

  return { ...state };
};

export default reducer;
