/* eslint-disable eqeqeq */
import React from 'react'
import InfiniteScrollWrapper from '../../Products/InfiniteScrollWrapper';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import Conversion from '../../CommonComponents/Conversion';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function LiveStreamersOnly({ sorting, sortClass, handleSort, functionNext, items, favourite, markFavourite, hasMore }) {
    const { t } = useTranslation();

    const tableHead = [
        {
            title: t('common.influencers'),
        },
        {
            title: t('common.revenue'),
            className: 'text-right sorting',
            sort: 61
        },
        {
            title: t('common.sales'),
            className: 'text-right sorting',
            sort: 60,
        },
        {
            title: t('influencers.atv'),
            className: 'text-right sorting',
            sort: 62,
        },
        {
            title: t('influencers.related_products'),
            className: 'text-right sorting',
            sort: 63,
        },
        {
            title: t('common.followers'),
            className: 'text-right sorting',
            sort: 1
        },
        {
            title: t('common.favorite'),
            className: 'text-right ',

        },

    ];

    const InfluencerSearchCountries = { ...global.config.region, ...global.config.countries };
    const countryicon = (e) => {
        const data = Object.keys(InfluencerSearchCountries).find(key => InfluencerSearchCountries[key] === e);
        return data ? data.toLowerCase() : "";
    }
    return (
        <div>
            <InfiniteScrollWrapper
                isInfiniteScrollOn={true}
                lengthData={items.length}
                functionNext={functionNext}
                hasMore={hasMore}
            >
                <table id="example" className="display w-100 dataTable">
                    <thead>
                        <tr role="row">
                            {tableHead.map((value, index) => (
                                <th key={index} className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort ? sortClass : '')} onClick={(e) => { value.sort && handleSort(value.sort) }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {items?.map((influencer, index) => (
                            <tr key={index}>
                                <td>
                                    <div className='d-flex'>
                                        <div className="prd-img mr-3">
                                            <OverlayTrigger
                                                key="right"
                                                trigger={["hover", "focus"]}
                                                placement="right"
                                                rootClose
                                                overlay={
                                                    <Popover>
                                                        <Popover.Body>
                                                            <img alt="" width={500} src={`https://t-img.picturehaven.net/tikmeta/${influencer?.avatar_larger_privatization ? influencer?.avatar_larger_privatization : influencer?.author?.author_avatar}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <Button variant="link" className='p-0' size="xs">
                                                    <Link to={'/influencers-search/details/' + influencer.id + '/discoveryInfluencers'} target="_blank">
                                                        <img alt="" width={70} src={`https://t-img.picturehaven.net/tikmeta/${influencer?.avatar_larger_privatization ? influencer?.avatar_larger_privatization : influencer?.author?.author_avatar}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                    </Link>
                                                </Button>
                                            </OverlayTrigger>
                                        </div>
                                        <div className='prd-title'>
                                            <Link to={'/influencers-search/details/' + influencer.id + '/discoveryInfluencers'} target="_blank">
                                                <p>{influencer?.nickname ? influencer?.nickname : influencer?.author?.nickname}</p>
                                            </Link>
                                            <h6><span className={`fi fi-${countryicon(influencer?.sys_country?.country)} mr-1`}></span>{influencer?.sys_country?.country || '--'}</h6>
                                        </div>
                                    </div>
                                </td>
                                <td className='text-right'>
                                    <h4>{influencer?.total_sales_usd}</h4>
                                    <p>({influencer?.total_sales})</p>
                                </td>
                                <td className='text-right'>{influencer?.sold_count}</td>
                                {/* <td>{((influencer?.sold_count_incr_rate * 1000) / 10).toFixed(2)}%</td> */}
                                <td className='text-right'>
                                    <h4>{influencer?.avg_price_usd}</h4>
                                    <p>({influencer?.avg_price})</p>
                                </td>
                                <td className='text-right'>{influencer?.product_count}</td>
                                <td className='text-right'><Conversion value={influencer.follower_count || 0} /></td>
                                <td className="text-right">
                                    <div className="d-flex justify-content-end">

                                        <div className="mr-1">
                                            {<Button
                                                variant="outline-dark" size="xs"
                                                className="px-2 py-1"
                                                onClick={() => { markFavourite({ influencer_id: influencer.id, nickname: influencer?.nickname }); }}>
                                                <i className={"fa " + (favourite.indexOf(influencer.id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                                            </Button>}
                                        </div>

                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </InfiniteScrollWrapper>
        </div>
    )
}

export default LiveStreamersOnly