import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import Axios from '../../plugins/axios';
import PlanModal from './PlanModal';

const Export = ({ payload, data_source, type }) => {
    const user = JSON.parse(localStorage.getItem('user'))
    const [loader, setLoader] = useState(false)
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('During file export, it is expected to be completed within five minutes. Please go to the Download Center to download the file.');
    const [exportCount, setExportCount] = useState()
    const [modalShow, setModalShow] = useState(false);
    const [limitMsg, setLimitMsg] = useState('');

    const handleClick = async () => {
        try {
            setLoader(true)
            const requestObject = {
                payload: payload,
                user_id: user._id,
                data_source: data_source,
                type
            }
            const response = await Axios.post('/api/export', requestObject);
            if (response?.data?.statusCode === 402) {
                setLimitMsg(response?.data?.msg || '');
                setModalShow(true);
            }

            setLoader(false)
            if (response && response.data && response.data.statusCode === 200) {
                setExportCount(response.data.exportCount)
                setMessage('During file export, it is expected to be completed within five minutes. Please go to the Download Center to download the file.')
                setTimeout(() => setShow(true), 2000);
            } else {
                setExportCount(null)
                setMessage(response.data.message)
                setShow(false)
            }
        } catch (error) {
            setLoader(false)
            console.error('Error exporting data:', error.message);
        }
    };

    return (
        <>
            <div>
                <Button className='btn-sm' onClick={handleClick} variant="primary" disabled={loader}>
                    {loader && <Spinner className='mr-2' as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                    {true && <i className="fa fa-upload mr-2" aria-hidden="true"></i>}
                    Export Data
                </Button>
            </div>
            <Modal show={show} onHide={() => setShow(!show)}>
                <Modal.Body>
                    {message}
                    <p className='text-danger'>
                        {exportCount && user.user_type === 'user' && `You have left ${5 - exportCount} of 5 Exports this Month`}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className='btn-sm' onClick={() => setShow(!show)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <PlanModal setModalShow={setModalShow} modalShow={modalShow} />
        </>
    );
};

export default Export;
