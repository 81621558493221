import { React } from 'react';
import { Tab, Nav } from "react-bootstrap";
import BreakoutVideo from "./BreakoutVideo";
import HotVideo from "./HotVideo";
import { Link, useLocation } from "react-router-dom";
import FavoriteProductVideo from './FavoriteProductVideo';
import { useTranslation } from 'react-i18next';


const ProductsVideos = () => {
   const search = useLocation().search;
   const tab = new URLSearchParams(search).get("tab") || 'BreakoutVideo';
   const { t } = useTranslation();

   return (
      <div className="custom-tab-1">
         <Tab.Container
            defaultActiveKey={tab}
         >
            <Nav as="ul" className="nav-tabs mb-0 bg-white">
               <Nav.Item as="li" key="BreakoutVideo">
                  <Nav.Link eventKey="BreakoutVideo" as={Link} to="?tab=BreakoutVideo">
                  {t('product_research.product_video.breakout_video.tab_name')}
                  </Nav.Link>
               </Nav.Item>
               <Nav.Item as="li" key="HotVideo">
                  <Nav.Link eventKey="HotVideo" as={Link} to="?tab=HotVideo">
                  {t('product_research.product_video.hot_video.tab_name')}
                  </Nav.Link>
               </Nav.Item>
               <Nav.Item as="li" key="FavoriteVideo">
                  <Nav.Link eventKey="FavoriteVideo" as={Link} to="?tab=FavoriteVideo">
                  {t('product_research.product_video.favorite.tab_name')}
                  </Nav.Link>
               </Nav.Item>
            </Nav>
            <Tab.Content>
               {tab == 'BreakoutVideo' && <Tab.Pane
                  eventKey="BreakoutVideo"
                  key="BreakoutVideo"
               >
                  <BreakoutVideo defaultCycle={'H24'} tab={tab} />
               </Tab.Pane>}
               {tab == 'HotVideo' && <Tab.Pane
                  eventKey="HotVideo"
                  key="HotVideo"
               >
                  <HotVideo defaultCycle={'H24'} tab={tab} />
               </Tab.Pane>}
               {tab == 'FavoriteVideo' && <Tab.Pane
                  eventKey="FavoriteVideo"
                  key="FavoriteVideo"
               >
                  <FavoriteProductVideo defaultCycle={'H24'} tab={tab} />
               </Tab.Pane>}
            </Tab.Content>
         </Tab.Container>
      </div>
   );
};

export default ProductsVideos;