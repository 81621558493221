import React from 'react'
import { Button, Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { DateFormat } from '../../../CommonComponents/FormatNumber';
import Conversion from '../../../CommonComponents/Conversion';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


function RelevantVideoInfList({ currentpage, setTikTokEmbedID, contentModal, setContentModal, sorting, sortClass, handleSort, relevantVideolist, totalCount, handlePageClick }) {
    const { t } = useTranslation();

    const tableHead = [
        {
            title: t('common.videos'),
        },
        {
            title: t('common.views'),
            className: 'text-right sorting-desc-icon',
            sort: 3
        },
        {
            title: t('common.likes'),
            className: 'text-right sorting-desc-icon',
            sort: 8,
        },
        {
            title: t('influencers.shares'),
            className: 'text-right'
        },
        {
            title: t('influencers.comments'),
            className: 'text-right',
        },
        {
            title: t('influencers.post_date'),
            className: 'text-right',
        },
    ];
    return (
        <div>
            <div className="card-body">
                <Table responsive className="w-100">
                    <div id="example_wrapper" className="dataTables_wrapper">
                        <table id="example" className="display w-100 dataTable">
                            <thead>
                                <tr role="row">
                                    {tableHead.map((value, index) => (
                                        <th key={index} className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort ? sortClass : '')} onClick={(e) => { value.sort && handleSort(value.sort) }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {relevantVideolist?.map((product) => (
                                    <tr>
                                        <td>
                                            <div className='d-flex'>
                                            <div className="prd-img mr-3">
                                            <Link to={'/products/' + product.id + '/video-details'} target="_blank">

                                                <img alt="" width={55} height={55} className="prod-thum-img" src={`https://t-img.picturehaven.net/tikmeta/${product?.origin_cover_privatization}?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                </Link>

                                                <Button
                                                    variant="primary"
                                                    className="p-0 video-popup-btn"
                                                    onClick={() => { setTikTokEmbedID(product.id); setContentModal(!contentModal); }}
                                                >
                                                    <i className="fa fa-play-circle" aria-hidden="true"></i>
                                                </Button>
                                            </div>
                                            <div className="prd-title">
                                                <Link to={'/products/' + product.id + '/video-details'} target="_blank">
                                                    <p className='mb-2'>{product.desc.length > 40 ? product.desc.substring(0, 40) + '...' : product.desc}</p>
                                                </Link>
                                            </div>
                                            </div>
                                        </td>
                                        <td className='text-right'>
                                            {product?.play_count ? '+' : ''} {product?.play_count ? <Conversion value={product?.play_count} /> : '--'}
                                        </td>
                                        <td className='text-right'>
                                            {product?.digg_count ? '+' : ''} {product?.digg_count ? <Conversion value={product?.digg_count} /> : '--'}

                                        </td>
                                        <td className='text-right'>
                                            {product?.share_count ? '+' : ''} {product?.share_count ? <Conversion value={product?.share_count} /> : '--'}
                                        </td>
                                        <td className='text-right'>
                                            {product?.comment_count ? '+' : ''} {product?.comment_count ? <Conversion value={product?.comment_count} /> : '--'}
                                        </td>
                                        <td className='text-right'>{product?.create_time ? <DateFormat timestamp={product?.create_time} /> : '--'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Table>
                <div className="dataTables_paginate paging_simple_numbers">
                    <ReactPaginate
                        nextLabel="Next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={totalCount / 10}
                        previousLabel="< Previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        forcePage={currentpage - 1}
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
        </div>
    )
}

export default RelevantVideoInfList