import React, { useEffect, useState, useContext } from "react";
import { Card, Form, Table, OverlayTrigger, Popover, Button, Tooltip } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Axios from "../../plugins/axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Context } from '../../../MasterLayout';
import { useTranslation } from 'react-i18next';


const ProductMasterList = () => {
   const { t } = useTranslation();

   let coloumns = [t('product_list.name'), t('product_list.brand'), t('product_list.cog'), t('product_list.shipping_cost'), t('product_list.actions')]
   const [page, setPage] = useState(1);
   const [limit, setlimit] = useState(10);
   const [totalEntries, setTotalEntries] = useState(0);
   const [StoreData, setStoreData] = useState([]);
   const [editeddataCog, setediteddataCog] = useState([])
   const [editeddataShippingCost, setediteddataShippingCost] = useState([])
   const [previousshippingdata, setpreviousshippingdata] = useState(0)
   const [previousCogdata, setpreviousCogdata] = useState(0)
   const [SearchValue, setSearchValue] = useState('');
   const [sort, setsort] = useState({ title: 0, brand: 0, cog: 0, shippingCost: 0 })
   const [EditCogdata, setEditCogdata] = useState([])
   const [EditShippingCostdata, setEditShippingCostdata] = useState([])
   const [pageCount, setPageCount] = useState(0);
   const [edit, setedit] = useState(false)
   const { dispatch } = useContext(Context);

   // COMMON FETCH STORE DATA 
   const fetchData = async (sortid = 'createdAt', sortascdsc = 1, searchvalue = null) => {
      try {
         let response = await Axios.get(`/api/tiktok/product?search=${searchvalue}&page=${page}&limit=${limit}&sortby=${sortid}&sort=${sortascdsc}`);
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });

         if (response && response.data && response.data.result) {
            const { results, totalCount, searchResults } = response?.data?.result
            setStoreData(searchvalue ? searchResults ? searchResults : [] : results ? results : [])
            setTotalEntries(totalCount ? totalCount : 0);
            setPageCount(Math.ceil(totalCount / limit));
         } else {
            console.error("Failed to fetch data");
         }
      } catch (error) {
         console.error("Error fetching data:", error);
      }
   };

   //COMMON CURRENT SORTBY AND SORT VALUES
   function currentSortValue() {
      let soryBy
      let sortValue
      if (sort.title) {
         soryBy = "title"
         sortValue = sort.title
      } else if (sort.brand) {
         soryBy = "brand"
         sortValue = sort.brand
      } else if (sort.cog) {
         soryBy = "cost_of_good"
         sortValue = sort.cog
      } else if (sort.shippingCost) {
         soryBy = "shipping_cost"
         sortValue = sort.shippingCost
      }
      return { soryBy, sortValue }
   }

   // INITIAL RENDERING STARTS FROM HERE (useEffect)
   useEffect(() => {
      const { soryBy, sortValue } = currentSortValue()
      fetchData(soryBy, sortValue, SearchValue);
   }, [page, limit]);


   //SEARCHING OPERATION STARTS HERE
   const handlsearchvalue = (e) => {
      const value = e.target.value
      setSearchValue(value)
   }
   const handleEnterKeyPress = async (e) => {
      if (e.key === 'Enter') {
         setPage(1);
         let response = await Axios.get(`/api/tiktok/product?search=${SearchValue}&page=${1}&limit=${limit}&sortby=${"title"}&sort=${1}`);
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });
         if (response && response.data && response.data.result) {
            const { searchResults, results, totalCount } = response?.data?.result
            setStoreData(SearchValue.length ? searchResults ? searchResults : [] : results ? results : [])
            setTotalEntries(totalCount ? totalCount : 0);
            setPageCount(Math.ceil(totalCount / limit));
         } else {
            console.error("Failed to fetch data");
         }
      }
   };


   //PAGINATION AND LIMIT 
   const handlePageClick = (event) => {
      setPage(event.selected + 1);
   };
   const onClickSetLimit = (e) => {
      if (e.target.value != limit) {
         setlimit(e.target.value);
         setPage(1);
      }
   };

   //SOERTING FUNCTIONALITY
   const handleSort = (e) => {
      const sortid = e.target.id;
      setPage(1);
      if (sortid === "title") {
         if (sort.title === 1) {
            setsort({ title: -1, brand: 0, cog: 0, shippingCost: 0 })
         } else {
            setsort({ title: 1, brand: 0, cog: 0, shippingCost: 0 })
         }
         fetchData(sortid, sort.title === 1 ? -1 : 1, SearchValue)
      } else if (sortid === "brand") {
         if (sort.brand === 1) {
            setsort({ title: 0, brand: -1, cog: 0, shippingCost: 0 })
         } else {
            setsort({ title: 0, brand: 1, cog: 0, shippingCost: 0 })
         }
         fetchData(sortid, sort.brand === 1 ? -1 : 1, SearchValue)
      } else if (sortid === "cost_of_good") {
         if (sort.cog === 1) {
            setsort({ title: 0, brand: 0, cog: -1, shippingCost: 0 })
         } else {
            setsort({ title: 0, brand: 0, cog: 1, shippingCost: 0 })
         }
         fetchData(sortid, sort.cog === 1 ? -1 : 1, SearchValue)
      } else if (sortid === "shipping_cost") {

         if (sort.shippingCost === 1) {
            setsort({ title: 0, brand: 0, cog: 0, shippingCost: -1 })
         } else {
            setsort({ title: 0, brand: 0, cog: 0, shippingCost: 1 })
         }
         fetchData(sortid, sort.shippingCost === 1 ? -1 : 1, SearchValue)
      }
   }

   // EDIT BUTTON FUNCTIONALITY STARTS HERE
   // ONCLICKING SAVE BUTTON FUNCTIONALITY
   const handleSaveEdit = async (data, index) => {
      //SHIPPING COST
      const shippingCostUpdatedData = editeddataShippingCost.find((e) => e.id === data._id);
      if (shippingCostUpdatedData?.textdata !== data?.shipping_cost && shippingCostUpdatedData?.textdata?.length) {
         data.shipping_cost = shippingCostUpdatedData.textdata;
      }
      // COST OF GOODS
      const cogUpdatedData = editeddataCog.find((e) => e.id === data._id);
      if (cogUpdatedData?.textdata !== data?.cost_of_good && cogUpdatedData?.textdata?.length) {
         data.cost_of_good = cogUpdatedData.textdata;
      }

      // SEND UPDATED DATA TO API
      const editdata = { _id: data._id, cost_of_good: data?.cost_of_good ? Number(data?.cost_of_good) : 0, shipping_cost: data.shipping_cost ? Number(data.shipping_cost) : 0 };
      if (previousshippingdata !== editdata?.shipping_cost || previousCogdata !== editdata?.cost_of_good) {
         const response = await Axios.patch('/api/tiktok/product', editdata);
         if (response.data.statusCode === 200) {
            toast.success(t("product_list.toast_message_1"), {
               position: "top-center",
               autoClose: 3000,
            });
            const updatedresults = StoreData.map((e) => {
               if (e._id === response.data.result._id) {
                  e.cost_of_good = cogUpdatedData.textdata
                  e.shipping_cost = shippingCostUpdatedData.textdata
                  return e
               }
               return e
            });
            if (shippingCostUpdatedData.id) {
               const newShippingCostupdatedArray = EditShippingCostdata.filter(item => item._id !== shippingCostUpdatedData.id);
               setEditShippingCostdata(newShippingCostupdatedArray);
            }
            if (cogUpdatedData.id) {
               const newCogupdatedArray = EditCogdata.filter(item => item._id !== cogUpdatedData.id);
               setEditCogdata(newCogupdatedArray);
            }

            setStoreData(updatedresults);
            if (!EditCogdata?.length && !EditShippingCostdata?.length) {
               setedit(!edit)
            }
         } else {
            console.error(response.data.message);
            toast.error(t("product_list.toast_message_2"), {
               position: "top-center",
               autoClose: 3000,
            })
         }
      } else {
         const updatedresults = StoreData.map((e) => {
            if (e._id === editdata._id) {
               e.cost_of_good = cogUpdatedData.textdata
               e.shipping_cost = shippingCostUpdatedData.textdata
               return e
            }
            return e
         });
         if (shippingCostUpdatedData.id) {
            const newShippingCostupdatedArray = EditShippingCostdata.filter(item => item._id !== shippingCostUpdatedData.id);
            setEditShippingCostdata(newShippingCostupdatedArray);
         }
         if (cogUpdatedData.id) {
            const newCogupdatedArray = EditCogdata.filter(item => item._id !== cogUpdatedData.id);
            setEditCogdata(newCogupdatedArray);
         }

         setStoreData(updatedresults);
         if (!EditCogdata?.length && !EditShippingCostdata?.length) {
            setedit(!edit)
         }

      }
   }

   const handleDeleteEdit = async (data, index) => {
      const newShippingCostupdatedArray = EditShippingCostdata.filter(item => item._id !== data._id);
      setEditShippingCostdata(newShippingCostupdatedArray);
      const newCogupdatedArray = EditCogdata.filter(item => item._id !== data._id);
      setEditCogdata(newCogupdatedArray);
      if (!EditCogdata?.length && !EditShippingCostdata?.length) {
         setedit(!edit)
      }
   }
   //EDIT BUTTON FUNCTIONLITY
   const handleEditCogShippingButton = async (data, index) => {
      setpreviousshippingdata(data?.shipping_cost);
      setpreviousCogdata(data?.cost_of_good);
      setediteddataShippingCost([...editeddataShippingCost, { textdata: data?.shipping_cost ? data?.shipping_cost : 0, id: data._id }]);
      setediteddataCog([...editeddataCog, { textdata: data?.cost_of_good ? data?.cost_of_good : 0, id: data._id }]);
      setedit(true)
      setEditCogdata([...EditCogdata, data]);
      setEditShippingCostdata([...EditShippingCostdata, data]);
   }

   const handleEditShippingCost = (e, data) => {
      const text = e.target.value ? Number(e.target.value) : 0;
      for (let i = 0; i < StoreData.length; i++) {
         if (StoreData[i]._id === data._id) {
            StoreData[i].shipping_cost = text
         }
      }
      const hasUpdatedData = editeddataShippingCost.some((e) => e.id === data._id);
      if (hasUpdatedData) {
         const updatedData = editeddataShippingCost.map((item) => {
            if (item.id === data._id) {
               return { ...item, textdata: text };
            }
            return item;
         });
         setediteddataShippingCost(updatedData);
      } else {
         setediteddataShippingCost([...editeddataShippingCost, { textdata: text, id: data._id }]);
      }
   };

   const handleEditCog = (e, data) => {
      const text = e.target.value ? Number(e.target.value) : 0;
      for (let i = 0; i < StoreData.length; i++) {
         if (StoreData[i]._id === data._id) {
            StoreData[i].cost_of_good = text
         }
      }
      const hasUpdatedData = editeddataCog.some((e) => e.id === data._id);
      if (hasUpdatedData) {
         const updatedData = editeddataCog.map((item) => {
            if (item.id === data._id) {
               return { ...item, textdata: text };
            }
            return item;
         });
         setediteddataCog(updatedData);
      } else {
         setediteddataCog([...editeddataCog, { textdata: text, id: data._id }]);
      }
   };

   return (
      <Card>
         <Card.Body>
            <Card.Title>
               <div className="row">
                  <div className="col-lg-6">
                     {t("product_list.lists_of_products")}
                  </div>
                  <div className="col-lg-6">
                     <div className="d-flex align-items-center justify-content-end">
                        <div className="dropdown">
                           <button className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">{limit}</button>
                           <div className="dropdown-menu">
                              <div className="dropdown-item"><option className="limit" value='10' onClick={onClickSetLimit}>10</option></div>
                              <div className="dropdown-item"><option className="limit" value='25' onClick={onClickSetLimit}>25</option></div>
                              <div className="dropdown-item"><option className="limit" value='50' onClick={onClickSetLimit}>50</option></div>
                              <div className="dropdown-item"><option className="limit" value='100' onClick={onClickSetLimit}>100</option></div>
                           </div>
                        </div>
                        <Form.Control onKeyPress={handleEnterKeyPress} value={SearchValue} onChange={(e) => handlsearchvalue(e)} className="w-auto form-control-sm" placeholder={t("product_list.search_products")} />
                     </div>
                  </div>
               </div>
            </Card.Title>
            <Table responsive className="display w-100 dataTable">
               <thead>
                  <tr role="row">
                     <th onClick={(e) => { handleSort(e) }} id="title" className={`sorting ${(sort.title === 1 ? "sorting-asc" : '') || (sort.title === -1 ? "sorting-desc" : '')}`}>{coloumns[0]} &nbsp;<span onClick={(e) => { handleSort(e) }} id="title" /></th>
                     <th onClick={(e) => { handleSort(e) }} id="brand" className={`text-right sorting ${(sort.brand === 1 ? "sorting-asc" : '') || (sort.brand === -1 ? "sorting-desc" : '')}`}>{coloumns[1]} &nbsp;<span onClick={(e) => { handleSort(e) }} id="brand" /></th>
                     <th onClick={(e) => { handleSort(e) }} id="cost_of_good" className={`text-right sorting ${(sort.cog === 1 ? "sorting-asc" : '') || (sort.cog === -1 ? "sorting-desc" : '')}`}>{coloumns[2]} &nbsp;<span onClick={(e) => { handleSort(e) }} id="cost_of_good" /></th>
                     <th onClick={(e) => { handleSort(e) }} id="shipping_cost" className={`text-right sorting ${(sort.shippingCost === 1 ? "sorting-asc" : '') || (sort.shippingCost === -1 ? "sorting-desc" : '')}`}>{coloumns[3]} &nbsp;<span onClick={(e) => { handleSort(e) }} id="shipping_cost" /></th>
                     <th className="text-right">{coloumns[4]}</th>
                  </tr>
               </thead>
               <tbody>
                  {StoreData?.length ? StoreData?.map((data, i) => (<tr key={i}>
                     <td className="">
                        <div className="d-flex">
                           <div className="prd-img mr-3">
                              <OverlayTrigger
                                 key="right"
                                 trigger={["hover", "focus"]}
                                 placement="right"
                                 rootClose
                                 overlay={
                                    <Popover>
                                       <Popover.Body>
                                          <img width={500} src={data?.main_images[0]?.urls[0]} alt="" />
                                       </Popover.Body>
                                    </Popover>
                                 }
                              >
                                 <Button variant="link" className='p-0' size="xs">
                                    <img width={70} src={data?.main_images[0]?.urls[0]} alt="" />
                                 </Button>
                              </OverlayTrigger>
                           </div>
                           <div className="prd-title">
                              <p>
                                 <OverlayTrigger overlay={<Tooltip>{data.title}</Tooltip>}>
                                    <span>{data.title.length > 40 ? data.title.substring(0, 40) + '...' : data.title}</span>
                                 </OverlayTrigger>
                              </p>
                              <p>{t("product_list.sku")}:&nbsp;{data?.seller_sku}</p>
                           </div>
                        </div>
                     </td>
                     <td className="text-right">{data?.brand?.name ? data?.brand?.name : "--"}</td>
                     <td className="text-right">
                        {
                           EditCogdata.includes(data)
                              ?

                              <div className="d-flex justify-content-end">
                                 <Form.Control min="0" type='Number' value={data.cost_of_good} onChange={(e) => handleEditCog(e, data, i)} className="w-25 h-25" />
                              </div>
                              : (data?.cost_of_good ? data?.cost_of_good : "0")
                        }
                     </td>
                     <td className="text-right">
                        {
                           EditShippingCostdata.includes(data)
                              ?
                              <div className="d-flex justify-content-end">
                                 <Form.Control min="0" type='Number' value={data.shipping_cost} onChange={(e) => handleEditShippingCost(e, data, i)} className="w-25 h-25" />
                              </div>
                              : (data?.shipping_cost ? data?.shipping_cost : "0")
                        }
                     </td>
                     <td className="text-right">
                        <div className="d-flex justify-content-end">
                           {edit && (EditCogdata.includes(data) || EditShippingCostdata.includes(data)) ?
                              <div className="d-flex">
                                 <a href="#" onClick={() => handleSaveEdit(data, i)}
                                    className="btn btn-outline-primary btn-xs mr-1">

                                    <i className="fa fa-floppy-o" aria-hidden="true"></i>
                                 </a>
                                 <Link
                                    onClick={() => handleDeleteEdit(data, i)}
                                    className="btn btn-outline-danger btn-xs"
                                 >
                                    <i className="fa fa-trash"></i>
                                 </Link>
                              </div>
                              : <Link
                                 onClick={() => handleEditCogShippingButton(data, i)}
                                 className="btn btn-outline-primary btn-xs mr-1"
                              >
                                 <i className="fa fa-pencil"></i>
                              </Link>}
                        </div>
                     </td>
                  </tr>)) : t('common.no_data_found')}
               </tbody>
            </Table>
            <div className="d-flex justify-content-between align-items-center mt-3">
               <div className="dataTables_info">
                  {t('common.showing')} {totalEntries ? (((page - 1) * limit) + 1) : 0} {t('common.to')}{" "}
                  {totalEntries ? Math.min(page * limit, totalEntries) : 0} {t('common.of')} {totalEntries} {t('common.entries')}
               </div>
               <div className="dataTables_paginate paging_simple_numbers">
                  <ReactPaginate
                     nextLabel="Next >"
                     onPageChange={handlePageClick}
                     pageRangeDisplayed={1}
                     marginPagesDisplayed={1}
                     pageCount={pageCount}
                     previousLabel="< Previous"
                     pageClassName="page-item"
                     pageLinkClassName="page-link"
                     previousClassName="page-item"
                     previousLinkClassName="page-link"
                     nextClassName="page-item"
                     nextLinkClassName="page-link"
                     breakLabel="..."
                     breakClassName="page-item"
                     breakLinkClassName="page-link"
                     containerClassName="pagination"
                     activeClassName="active"
                     renderOnZeroPageCount={null}
                     forcePage={page - 1}
                  />
               </div>
            </div>
            <ToastContainer />
         </Card.Body>
      </Card>
   );
};

export default ProductMasterList;