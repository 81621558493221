import React, { useEffect, useState, useContext } from 'react';
import { Button, Col, Form, Card } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from 'moment';
import Axios from '../../../plugins/axios';
import { Context } from '../../../../MasterLayout';
import FreeUsers from './FreeUsers';
import CanceledUsers from './CanceledUsers';
import RecurringUsers from './RecurringUsers';
import { useTranslation } from 'react-i18next';
import RevenueRecognition from './RevenueRecognition';
import { isEmpty } from '../../CommonComponents/isEmpty';


const RegisterFinanceReport = () => {
    const { dispatch } = useContext(Context);
    const { t } = useTranslation();
    const [dateRange, setDateRange] = useState({ start_date: new Date(new Date().setDate(new Date().getDate() - 7)), end_date: new Date() });
    const [timeZone, setTimeZone] = useState(-8);
    const [type, setType] = useState('');
    const [plan, setPlan] = useState('');
    const [plans, setPlans] = useState([]);
    const [coupons, setCoupons] = useState([]);
    const [coupon, setCoupon] = useState('');
    const [reportData, setReportData] = useState(null);
    const [showComponent, setShowComponent] = useState('');
    const timeZoneArr = [
        { label: t('register_finance_report.timezone1'), value: -8, key: "-08:00" },
        { label: t('register_finance_report.timezone2'), value: -4, key: "-04:00" },
        { label: t('register_finance_report.timezone3'), value: 0, key: "+00:00" }
    ];
    const typeArr = [
        { label: t('register_finance_report.free_user'), value: 'free_user' },
        { label: t('register_finance_report.canceled_user'), value: 'canceled_user' },
        { label: t('register_finance_report.recurring_user'), value: 'recurring_user' },
        { label: t('register_finance_report.revenue_recognition'), value: 'revenue_recognition' }
    ];

    const handleApply = (event, picker) => {
        picker.element.val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
        setDateRange({ start_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') });
    };

    const searchResults = async () => {
        try {
            setReportData(null);
            setShowComponent(type);
            if (type != '') {
                dispatch({ type: 'loader_show', response: true });
                let payload = {
                    type: type,
                    coupon: coupon,
                    start_date: dateRange.start_date,
                    end_date: dateRange.end_date,
                    timeZone: timeZone
                };
                if (plan) payload.plan = plan;
                let response = await Axios.post('/api/register-finance-report', payload).catch((e) => console.error('Axios-Catch: ' + e.message));
                dispatch({ type: 'subscription_expired', response: response.data.statusCode });

                if (response && response.status === 200 && response.data.data) {
                    setReportData(response.data.data);
                };
            };
        } catch (error) {
            console.error('Error-Catch: ' + error.message);
        };
        dispatch({ type: 'loader_hide', response: true });
    };

    useEffect(() => {
        getPlans();
        getCoupons();
    }, []);

    const getPlans = async () => {
        try {
            let response = await Axios.get('/api/plans').catch((e) => console.error('Axios-Catch: ' + e.message));
            if (response.status == 200 && response.data.data) {
                response.data.data.map((v) => {
                    v.value = v.stripe_price;
                    v.label = v.name;
                    return v;
                });
                setPlans(response.data.data)
            };
        } catch (error) {
            console.error('Error-Catch: ' + error.message);
        };
    };

    const getCoupons = async () => {
        let response = await Axios.get('/api/coupons').catch((e) => console.error('Axios-Catch: ' + e.message));
        if (response.status == 200 && response.data.data) {
            response.data.data.map((v) => {
                v.value = v.name;
                v.label = v.name;
                return v;
            })
            setCoupons(response.data.data)
        };
    };

    return (
        <>
            <div className="row justify-content-center">
                <Col lg="12">
                    <Card>
                        <Card.Body>
                            <div className='d-flex flex-wrap'>
                                <div className="dropdown product-rating-menu mr-2">
                                    <Form.Select className="form-control form-control-sm" defaultValue={timeZone != '' ? timeZone : ''}
                                        onChange={(e) => setTimeZone(e.target.value)}>
                                        {timeZoneArr.map((v, i) => { return <option key={i} value={v.value}>{v.label}</option> })}
                                    </Form.Select>
                                </div>
                                <div className="dropdown product-rating-menu mr-2">
                                    <Form.Select className="form-control form-control-sm" defaultValue={type != '' ? type : ''}
                                        onChange={(e) => setType(e.target.value)}>
                                        <option value=''>{t('register_finance_report.select_type')}</option>
                                        {typeArr.map((v, i) => { return <option key={i} value={v.value}>{v.label}</option> })}
                                    </Form.Select>
                                </div>
                                {type == 'recurring_user' && <div className="dropdown product-rating-menu mr-2">
                                    <Form.Select className="form-control form-control-sm" defaultValue={plan != '' ? plan : ''}
                                        onChange={(e) => setPlan(e.target.value)}>
                                        <option value=''>{t('register_finance_report.select_plan')}</option>
                                        {plans.map((v, i) => {
                                            if (v.value !== 'free_plan') {
                                                return <option key={i} value={v.value}>{v.label}</option>;
                                            }
                                            return null;
                                        })}
                                    </Form.Select>
                                </div>}
                                {(type == 'revenue_recognition' || type == 'free_user') && <div className="col-sm-2">
                                    <Form.Select
                                        className="form-control form-control-sm"
                                        defaultValue={coupon != '' ? coupon : ''}
                                        onChange={(e) => setCoupon(e.target.value)}
                                    >
                                        <option value=''>{t('user_management.select_coupon')}</option>
                                        {coupons.map((v, i) => { return <option key={i} value={v.value}>{v.value}</option> })}
                                    </Form.Select>
                                </div>}
                                <DateRangePicker onApply={handleApply} initialSettings={{
                                    startDate: moment(new Date(dateRange.start_date)).format('MM/DD/YYYY'), endDate: moment(new Date(dateRange.end_date)).format('MM/DD/YYYY'),
                                    ranges: {
                                        'Today': [moment().toDate(), moment().toDate()],
                                        'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                                        'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                                        'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
                                        'This Month': [moment().startOf('month').toDate(), moment().toDate()],
                                        'Last Month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                                        'Last 3 Month': [moment().subtract(3, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                                        'Last 6 Month': [moment().subtract(6, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                                    },
                                }}>
                                    <input type="text" className="form-control w-auto form-control-sm input-daterange-timepicker pull-right" />
                                </DateRangePicker>
                                <Button variant="primary" type="button" size="xs" className="btn btn-primary ml-2" onClick={() => searchResults()}>{t('register_finance_report.submit')}</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
            {showComponent == 'free_user' && !isEmpty(reportData) && <FreeUsers data={reportData} />}
            {showComponent == 'canceled_user' && !isEmpty(reportData) && <CanceledUsers data={reportData} />}
            {showComponent == 'recurring_user' && !isEmpty(reportData) && <RecurringUsers data={reportData} />}
            {showComponent == 'revenue_recognition' && !isEmpty(reportData) && <RevenueRecognition data={reportData} />}
        </>
    )
};

export default RegisterFinanceReport;