import { React, useState } from 'react';
import { Collapse } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { isEmpty } from './isEmpty';

function Type({ handleCategory, categoryId1, endLevelShow, singleCategory, setSingleCategory }) {
    const { t } = useTranslation();

    const categoryList = global.config.chineseCategories;
    const [open, setOpen] = useState(false);

    const selectCategory = async (categoryId, categoryName) => {
        handleCategory(categoryId, categoryName);
    };

    const RecursiveComponent = ({ parentCategory, className, previousCategoryId, previousCategoryName, parent }) => {
        previousCategoryId = previousCategoryId ? previousCategoryId : parentCategory.id
        previousCategoryName = previousCategoryName ? previousCategoryName : parentCategory.category

        return (
            <ul key={parentCategory.id} className={className} aria-labelledby="dropdownMenuLink">
                {
                    !isEmpty(parent) &&
                    <li key={"all-" + parentCategory.id} style={{ cursor: 'pointer' }}>
                        {/*eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid*/}
                        <a className="dropdown-item" onClick={() => { selectCategory(previousCategoryId, previousCategoryName); }}>All</a> </li>
                }
                {
                    parentCategory.child.map((category, index) => (
                        <li key={category.id} className={endLevelShow == 1 && !isEmpty(category.child) && category.child.length > 0 ? 'submenu submenu-arrow' : ''} style={{ cursor: 'pointer' }}>
                            {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                            <a className="dropdown-item" onClick={() => { selectCategory(previousCategoryId + "," + category.id, previousCategoryName + "," + category.category); }}>
                                {category.category}
                            </a>
                            {endLevelShow == 1 && !isEmpty(category.child) && category.child.length > 0 && <RecursiveComponent parentCategory={category} previousCategoryId={previousCategoryId + "," + category.id} previousCategoryName={previousCategoryName + "," + category.category} className={'submenu'} />}
                        </li>
                    ))
                }
            </ul>
        );
    };



    return (
        <>
            <div className="d-flex align-items-center flex-wrap mb-2">
                <p className="m-0 mr-3"><strong>{t('common.type')}</strong></p>
                {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a className={"btn " + (categoryId1 == '' ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 mb-2"}
                    onClick={() => { setSingleCategory({ name: "", namecn: "" }) }}>
                    All
                </a>
                {
                    categoryList.slice(0, 10).map((category, index) => (
                        <div key={index}>
                            {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                            <a
                                className={"btn " + (categoryId1 == category.name ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 mb-2"}
                                onClick={() => { setSingleCategory(category) }}>
                                {category.name}
                            </a>
                        </div>

                    ))
                }
                {/*eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid*/}
                <a
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className='btn btn-outline-dark btn-xs show-sub mb-2'><i className="fa fa-chevron-down" aria-hidden="true"></i></a>
            </div>
            <Collapse in={open}>
                <div id="example-collapse-text" className='pl-5'>
                    <div className='d-flex align-items-center flex-wrap'>
                        {
                            categoryList.slice(10).map((category, index) => (
                                <div key={index} className="dropdown mb-2">
                                    {/*eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid*/}
                                    <a
                                        className={"btn " + (categoryId1 == category.name ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 "}
                                        onClick={() => { setSingleCategory(category) }}>
                                        {category.name}
                                    </a>
                                </div>

                            ))
                        }
                    </div>
                </div>
            </Collapse>
        </>
    )
}

export default Type