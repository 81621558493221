import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { Card, Row, Col, OverlayTrigger, Table, Tooltip, Popover, Button, Accordion } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Axios from '../../plugins/axios.js';
import Select from "react-select";
import StoreFetch from "./StoreFetch.js";
import ProfitLanding from "./ProfitLanding.js";
import StorePerformance from "./StorePerformance.js";
import NetProfitChart from './NetProfitChart.js';
import UnitSoldChart from './UnitSoldChart.js';
import { Context } from '../../../MasterLayout.js';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty } from '../CommonComponents/isEmpty.js';
import './OrderDetail.css';

const OrdersDetail = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(Context);
  const [item, setItem] = useState(null);
  const params = useParams();

  useEffect(() => {
    getOrderDetail();
  }, []);

  const getOrderDetail = async () => {
    try {
      dispatch({ type: 'loader_show', response: true });
      let response = await Axios.get(`/api/orders/${params?.order_id}`).catch((e) => console.error('Axios-Catch: ' + e.message));
      dispatch({ type: 'subscription_expired', response: response.data.statusCode });

      if (response && response.status === 200) {
        setItem(response.data.data);
      };
    } catch (error) {
      console.error('Error-Catch: ' + error.message);
    };
    dispatch({ type: 'loader_hide', response: true });
  };

  return (
    <>
      {!isEmpty(item) && <div className="manage-orders-page">
        <Row className="justify-content-md-center">
          <div className="order-number">{item?.id || '--'}</div>
          <Col xs lg="8">
            <div className="order-details">
              <div className="order-status order-title">{item?.status || '--'}</div>
              <div className="order-info">
                <div className="shipping-method">
                  <div className="location">Location</div>
                  <div className="label">{item?.recipient_address?.district_info?.[0]?.address_name || '--'}</div>
                </div>
                <div className="shipping-method">
                  <div className="location">Created Time</div>
                  <div className="label">{item?.create_time ? moment(item?.create_time).format("DD/MM/YYYY hh:mm:ss a") : "--"}</div>
                </div>
                <div className="shipping-method">
                  <div className="label">Tracking number:</div>
                  <div className="value">{item?.tracking_number || '--'}</div>
                </div>
                <div className="shipping-method">
                  <div className="label">Shipping method:</div>
                  <div className="value">{item?.shipping_type || '--'}</div>
                </div>
                <div className="order-type">
                  <div className="label">Order type:</div>
                  <div className="value">{item?.line_items?.[0]?.sku_type || '--'}</div>
                </div>
                <div className="late-dispatch">
                  <div className="label">Delivery option ID</div>
                  <div className="value">{item?.delivery_option_id || '--'}</div>
                </div>
                <div className="warehouse">
                  <div className="label">Delivery option name:</div>
                  <div className="value">{item?.delivery_option_name || '--'}</div>
                </div>
              </div>
            </div>
            <div className="order-details">
              <div className="package-label">Order Items</div>
              {item?.line_items.map((v, i) => (
                <div key={i}>
                  <div className="package-details">
                    <div className="package-info">
                      <div className="item-details">
                        <div className="item-image">
                          <img src={v?.sku_image} alt="Item" />
                        </div>
                        <div className="item-info">
                          <div className="sku">SKU ID: {v?.sku_id}</div>
                          <div className="item-name">{v?.product_name}</div>
                          <div className="item-name">{v?.sku_name}</div>
                          <div className="item-price">${v?.sale_price} x {v?.quantity_sold}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Col>
          <Col xs lg="4">
            <div className="customer-details">
              <div className="customer-paid-label">What your customer paid</div>
              <div className="customer-paid-details">
                <div className="payment-method">
                  <div className="label">Payment method:</div>
                  <div className="value">{item?.payment_method_name}</div>
                </div>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Show Details</Accordion.Header>
                    <Accordion.Body>
                      <div className="item-subtotal">
                        <div className="label">Item(s) subtotal after discounts:</div>
                        <div className="value">{item?.payment?.currency} {item?.payment?.sub_total}</div>
                      </div>
                      <div className="shipping-fee">
                        <div className="label">Shipping fee after discounts:</div>
                        <div className="value">{item?.payment?.currency} {item?.payment?.shipping_fee}</div>
                      </div>
                      <div className="taxes">
                        <div className="label">Taxes:</div>
                        <div className="value">{item?.payment?.currency} {item?.payment?.tax}</div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <div className="total">
                  <div className="label">Total:</div>
                  <div className="value">{item?.payment?.currency} {item?.payment?.total_amount}</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>}
    </>
  );
};

export default OrdersDetail;