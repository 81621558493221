import React, { useState, useEffect, useContext } from 'react';
import { ButtonGroup, Button, Collapse, Card, Table, Form } from "react-bootstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useTranslation } from "react-i18next";
import { Context } from "../../../../MasterLayout";
import RangeSlider from "../../Products/RangeSlider";
import BreakoutSongs from "./BreakoutSongs";
import Axios from "../../../plugins/axios";

const BreakoutSongFilters = ({ tab, defaultCycle, rankType }) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [countryCode, setCountryCode] = useState('');
    const [cycle, setCycle] = useState(defaultCycle);
    const [version, setVersion] = useState('');
    const [versionList, setVersionList] = useState([]);
    const [videoRange, setVideoRange] = useState();
    const [durationRange, setDurationRange] = useState();
    const [favourite, setFavourite] = useState([]);
    const { dispatch } = useContext(Context);
    const [open, setOpen] = useState(false);
    const [isCommerce, setIsCommerce] = useState(false);

    const cycleList = {
        'breakout': { 'W1': 'Weekly' },
        'popular': { 'W1': 'Weekly' },
    };

    const endPointURL = {
        'hot': '/api/viral/breakout-songs',
        'breakout': '/api/viral/breakout-songs',
        'popular': '/api/viral/popular-songs',
    };

    const videoRangeOption = {
        '0-10000': '0-10K',
        '10000-50000': '10K-50K',
        '50000-500000': '50K-500K',
        '500000-1000000': '500K-1M',
        '1000000-5000000': '1M-5M',
        '5000000-1000000000': '5M-1B',
        "1000000000-": '1B+',
    };

    const durationRangeOption = {
        '0-5': '0-5s',
        '6-15': '6s-15s',
        '16-30': '16s-30s',
        '31-60': '31s-60s',
        '61-120': '61s-120s'
    };

    useEffect(() => {
        resetItemsAndPage();
        if (version === '') {
            getVersion(cycle);
        } else {
            productSearch(0);
        }
    }, [countryCode, videoRange, durationRange, version, isCommerce]);

    const resetItemsAndPage = () => {
        setItems([]);
        setPage(0);
    };

    const markFavourite = async (data) => {
        try {
            await Axios.post('/api/song/favourite', data);
            const index = favourite.indexOf(data.music_id);
            if (index > -1) {
                favourite.splice(index, 1);
            } else {
                favourite.push(data.music_id);
            }
            setFavourite(favourite.concat([]));
        } catch (error) {
            console.error(error.message)
        }
    };

    const selectCountry = (countryCode) => {
        setCountryCode(countryCode);
    };

    const getVersion = async (cycle) => {
        try {
            let params = { cycle };
            if (rankType) params['rank_type'] = rankType;
            const response = await Axios.get('/api/version', { params });
            const versionData = response.data.data;
            if (versionData) {
                setVersion(versionData[0].version);
                setVersionList(versionData);
            }
        } catch (error) {
            console.error('Error fetching version:', error.message);
        }
    };

    const productSearch = async (page) => {
        try {
            let params = { page: page, cycle, version };
            if (countryCode !== '') params['country_code'] = countryCode;
            if (videoRange) {
                params['video_start'] = videoRange.from;
                params['video_end'] = videoRange.to;
            }
            if (isCommerce) {
                params['is_commerce'] = isCommerce;
            }
            if (durationRange) {
                params['duration_start'] = durationRange.from;
                params['duration_end'] = durationRange.to;
            }
            const response = await Axios.post(endPointURL[tab], params);
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });
            handleResponse(response, page);
        } catch (error) {
            console.error('Error fetching products:', error.message);
        }
    };

    const handleResponse = (response, page) => {
        if (response && response.status === 200 && response.data.data) {
            const responseData = response.data;
            const array = response.data.favorite ? response.data.favorite.map(value => value.song_id) : favourite;
            if (page === 0) {
                setItems(responseData.data);
                setFavourite(array);
            } else {
                setItems(prevItems => [...prevItems, ...responseData.data]);
                setFavourite(prevFav => [...prevFav, ...array]);
            }
            setHasMore(responseData.data.length > 0 && responseData.data.length === 20);
            dispatch({ type: 'subscription_expired', response: responseData.statusCode });
        }
    };

    const functionNext = () => {
        const nextPage = page + 1;
        setPage(nextPage);
        productSearch(nextPage);
    };

    const handleVideoRange = (value) => {
        setVideoRange(value);
    };

    const handleDurationRange = (value) => {
        setDurationRange(value);
    };
    const handleSwitchChange = (e) => {
        setIsCommerce(e.target.checked);
    };

    const countries = { ...global.config.region, ...global.config.countries };
    const regionList = Object.keys(global.config.region).map(function (key) {
        return <a key={key} className={"btn my-1 " + (countryCode == key ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 d-flex align-items-center mb-2"} onClick={() => { selectCountry(key); }}><span className={`fi fi-${key.toLocaleLowerCase()} mr-1`}></span>  {global.config.region[key]}</a>
    });

    const renderCycleButtons = () => {
        return Object.keys(cycleList[tab]).map((key) => (
            <Button key={key} variant={`outline-primary ${cycle === key ? 'btn-primary' : ''}`} size="xs" onClick={() => { setCycle(key); getVersion(key); }}>{cycleList[tab][key]}</Button>
        ));
    };

    return (
        <>
            <Card className='rounded-0'>
                <Card.Body>
                    <div className='topfilter'>
                        <div className="d-flex align-items-center flex-wrap mb-2">
                            <p className="m-0 mr-3"><strong>{t('creator_center.songs_ranking.filter.country')}</strong></p>
                            <a className={"btn " + (countryCode == '' ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 mb-2 my-1"}
                                onClick={() => selectCountry('')}>
                                {t('product_research.products_search.all')}
                            </a>
                            {regionList}
                            <a
                                onClick={() => setOpen(!open)}
                                aria-controls="example-collapse-text"
                                aria-expanded={open}
                                className='btn btn-outline-dark btn-xs show-sub mb-2 mb-2 my-1'><i className="fa fa-chevron-down" aria-hidden="true"></i></a>
                            <Collapse in={open}>
                                <div id="example-collapse-text" className='pl-5 mx-4'>
                                    <div className='d-flex align-items-center flex-wrap '>
                                        {
                                            Object.keys(countries).slice(8).map((key) => (
                                                (
                                                    countries &&
                                                    (
                                                        <a key={key} className={"btn my-1 " + (countryCode == key ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 d-flex align-items-center mb-2"} onClick={() => setCountryCode(key)}><span className={`fi fi-${key.toLocaleLowerCase()} mr-1`}></span>{countries[key]}</a>
                                                    )

                                                )

                                            ))
                                        }
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <p className="m-0 mr-3"><strong>{t('creator_center.songs_ranking.filter.filters')}</strong></p>
                            <div className="dropdown product-rating-menu">
                                <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    Videos
                                </a>
                                <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                    <RangeSlider min={0} max={1000000000} defaultMin={0} defaultMax={1000000000} rangeOption={videoRangeOption} rangeOptionName={'video'} step={10} handleUpdate={handleVideoRange} />
                                </div>
                            </div>
                            <div className="dropdown product-rating-menu">
                                <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    Duration
                                </a>
                                <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                    <RangeSlider min={0} max={100} defaultMin={0} defaultMax={600} rangeOption={durationRangeOption} rangeOptionName={'duration'} step={1} handleUpdate={handleDurationRange} />
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center mb-2 pl-3'>
                            <Form>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="custom-switch"
                                    label={t('creator_center.songs_search.eCommerceSwitch')}
                                    onChange={handleSwitchChange}
                                    checked={isCommerce}
                                />
                            </Form>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <ButtonGroup aria-label="Basic example" className='mr-2'>
                                {renderCycleButtons()}
                            </ButtonGroup>
                            <div className="dropdown product-rating-menu">
                                <div className="form-group mb-0 mr-3">
                                    <select className="form-control form-control-sm" onChange={(e) => { setVersion(e.target.value); }} value={version}>
                                        {versionList.map((value, index) => (
                                            <option key={index} value={value.version}>{value.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <p className='text-muted mb-0'><small>{t('product_research.top_sales.filter.long_text_2')}</small></p>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body className='p-2'>
                    <Table responsive className="w-100">
                        <div id="example_wrapper" className="dataTables_wrapper">
                            {(tab === 'hot' || tab === 'breakout' || tab === 'popular') && <BreakoutSongs functionNext={functionNext} items={items} favourite={favourite} markFavourite={markFavourite} hasMore={hasMore} />}
                        </div>
                    </Table>
                </Card.Body>
            </Card>
        </>
    );
};

export default BreakoutSongFilters;
