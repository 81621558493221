import Axios from "../../plugins/axios";
import React, { useState, useEffect, useContext } from 'react';
import { Table, Button, Card, Modal, Form } from "react-bootstrap";
import '../../../css/storeListing/storeListing.css';
import { Context } from '../../../MasterLayout';
import { useTranslation } from 'react-i18next';
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isEmpty } from "../CommonComponents/isEmpty";


const OneoffQuestion = () => {
    const sort = "createdAt";
    const limit = 10;
    const { dispatch } = useContext(Context);
    const { t } = useTranslation();
    const [modalShow, setModalShow] = useState(false);
    const [questionMessage, setQuestionMessage] = useState('');
    const [planMessage, setplanMessage] = useState('');
    const [update, setUpdate] = useState(false);
    const [page, setPage] = useState(0);
    const [items, setItems] = useState([]);
    const [sorting, setSorting] = useState({ sort: sort, sort_type: -1 });
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemIdToDelete, setItemIdToDelete] = useState(null);
    const [itemIdToEdit, setItemIdToEdit] = useState(null);
    const [recordsCount, setRecordsCount] = useState(0);
    const [reinit, setReinit] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [plan, setplan] = useState([]);
    const [type, setType] = useState([]);
    const [isMandatory, setIsMandatory] = useState(0);
    const [question, setQuestion] = useState('');
    const [planValue, setplanValue] = useState('')
    const [typeValue, setTypeValue] = useState('');
    const [search, setSearch] = useState('');
    const [sortOrder, setSortOrder] = useState();
    const [sortMessage, setSortMessage] = useState('');

    const tableHead = [
        { title: t('oneoffQuestion.ranking'), className: 'text-right' },
        { title: t('oneoffQuestion.question'), className: 'text-left sorting', sort: 'question' },
        { title: t('oneoffQuestion.plan'), className: 'text-left sorting', sort: 'plan_slug' },
        { title: t('oneoffQuestion.sort_order'), className: 'text-right sorting', sort: 'sort_order' },
        { title: t('oneoffQuestion.type'), className: 'text-left sorting', sort: 'type' },
        { title: t('oneoffQuestion.is_mandatory'), className: 'text-left sorting', sort: 'is_mandatory' },
        { title: t('oneoffQuestion.createdAt'), className: 'text-right sorting', sort: 'createdAt' },
        { title: t('oneoffQuestion.action'), className: 'text-right' },
    ];

    useEffect(() => {
        setplan([{ plan: "Influencer Video" }])
        TypeValue();
        getQuestions(0);
    }, [sorting]);

    const TypeValue = () => {
        const typeArray = [
            {
                lable: 'Text',
                type: 'text'
            },
            {
                lable: 'Textarea',
                type: 'textarea'
            },
            {
                lable: 'Number',
                type: 'number'
            },
            {
                lable: 'Email',
                type: 'email'
            },
            {
                lable: 'File',
                type: 'file'
            },
            {
                lable: 'Radio',
                type: 'radio'
            },
        ];
        setType(typeArray)
        setTypeValue(typeArray[0].type)
    }

    const getQuestions = async (page) => {
        try {
            let params = {
                page: page,
                limit: limit,
                sort: sorting.sort,
                sort_type: sorting.sort_type,
                search
            };
            let queryString = '';
            for (let key in params) {
                if (params.hasOwnProperty(key)) {
                    if (queryString.length > 0) {
                        queryString += '&';
                    }
                    queryString += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
                }
            }
            const response = await Axios.get(`/api/questions?${queryString}`);
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });
            if (response && response.status === 200 && response.data.data) {
                const responseData = response.data;
                if (page === 0) {
                    setItems(responseData.data.results);
                    setRecordsCount(responseData.data.recordsCount)
                    setPageCount(responseData.data.totalPages)
                } else {
                    setItems(responseData.data.results);
                }
            }
        } catch (error) {

        }
    }

    const createOrUpdate = async () => {
        try {
            if (update) {
                const itemToUpdate = items.find(item => item._id === itemIdToEdit);
                if (itemToUpdate) {
                    if (itemToUpdate.question === question && itemToUpdate.plan_slug === planValue && itemToUpdate.type === typeValue && itemToUpdate.is_mandatory === isMandatory && itemToUpdate.sort_order === sortOrder) {
                        editSomethingToast();
                        return;
                    }
                }
            }
            if (!question || !planValue || (update && !sortOrder)) {
                if (!question) {
                    setQuestionMessage(t('common.question_message'));
                };
                if (!planValue) {
                    setplanMessage(t('common.plan_message'));
                };
                if (!sortOrder) {
                    setSortMessage(t('common.sort_message'));
                };
            } else {
                dispatch({ type: 'loader_show', response: true });

                let data = {
                    question,
                    plan: planValue,
                    is_mandatory: isMandatory,
                    type: typeValue,
                    sort_order: sortOrder
                }
                let response;
                if (update) {
                    response = await Axios.patch(`/api/questions/${itemIdToEdit}`, data)
                        .catch((error) => {
                            console.error('Axios-Catch: ' + error.message);
                        });
                } else {
                    response = await Axios.post('/api/questions', data).catch((e) => console.error('Axios-Catch: ' + e.message));
                }

                if (response && (response.status === 200 || response.status === 201)) {
                    if (update) {
                        successUpdateToast();
                    } else {
                        successCreateToast()
                    }
                    setIsMandatory(0)
                    setQuestion('')
                    setplanValue('')
                    setTypeValue('')
                    getQuestions(0);
                    setModalShow(!modalShow);
                } else {
                    setModalShow(!modalShow);
                    setIsMandatory(0)
                    setQuestion('')
                    setplanValue('')
                    setTypeValue('')
                    getQuestions(0);
                }
            };
        } catch (error) {
            console.error('Error-Catch: ' + error.message);
        };
        dispatch({ type: 'loader_hide', response: true });
    };
    const handleSort = (sort, sortType) => {
        if (sort !== sorting.sort || (sortType && sortType !== sorting.sort_type)) {
            setSorting({ sort, sort_type: sortType });
        }
    };
    const handlePageClick = (event) => {
        setPage(event.selected);
        setReinit(reinit + 1);
        getQuestions(event.selected);
    };

    const handleEdit = (item) => {
        setUpdate(true);
        if (item.question) {
            setQuestion(item.question)
        }
        if (item.plan_slug) {
            setplanValue(item.plan_slug)
        }
        if (item.type) {
            setTypeValue(item.type)
        }
        if (item.is_mandatory) {
            setIsMandatory(item.is_mandatory ? true : false)
        }
        if (item.sort_order) {
            setSortOrder(item.sort_order)
        }
        setItemIdToEdit(item._id)
        setModalShow(!modalShow);
    }
    const handleDelete = (id) => {
        setItemIdToDelete(id);
        setShowDeleteModal(true);

    };
    const confirmDelete = async () => {
        const response = await Axios.delete(`/api/questions?id=${itemIdToDelete}`);
        if (response && response.status === 200 && response?.data?.statusCode === 200) {
            setItemIdToDelete(null)
            getQuestions(0);
        }
        setShowDeleteModal(false);
        deleteSuccessToast();
    };
    const getSortClass = (sort) => {
        if (sort === sorting.sort) {
            return sorting.sort_type === -1 ? 'sorting-desc' : 'sorting-asc';
        }
        return '';
    };
    const handleplanChange = (plan) => {
        setplanValue(plan)
        if (plan) {
            setplanMessage()
        } else {
            setplanMessage(t('common.plan_message'));
        }
    }
    const handleTypeChange = (type) => {
        setTypeValue(type)
    }
    const handleCheckboxChange = (isChecked) => {
        setIsMandatory(isChecked ? 1 : 0)
    };

    const editSomethingToast = () => {
        toast.warn("Edit something to change", {
            position: 'top-center',
            autoClose: 5000
        });
    };
    const successUpdateToast = () => {
        toast.success("Successfully updated", {
            position: 'top-center',
            autoClose: 5000
        });
    };

    const successCreateToast = () => {
        toast.success("Successfully created", {
            position: 'top-center',
            autoClose: 5000
        });
    };

    const deleteSuccessToast = () => {
        toast.success("Deleted successfully", {
            position: 'top-center',
            autoClose: 5000
        });
    };
    const handleEnterKeyPress = async (e) => {
        if (e.key === 'Enter') {
            setSearch(e.target.value);
            getQuestions(0);
        }
    };
    function convertDateFormat(dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }
    const handleModalClose = () => {
        setIsMandatory(0)
        setQuestion('')
        setplanValue('')
        setTypeValue('')
        setSortOrder();
        setQuestionMessage('');
        setplanMessage('');
        setSortMessage('')
        setModalShow(!modalShow)
    }

    return (
        <>
            <div>
                <ToastContainer />
            </div>
            <Card>
                <Card.Body>
                    <div className="d-flex justify-content-end align-items-center mb-3">
                        <div className="d-flex align-items-center justify-content-center">
                            <p className="text-right my-0 mx-2">
                                <button type="button" className="btn btn-primary btn-xs" onClick={() => { setUpdate(false); setModalShow(!modalShow); }}>{t('common.add_question')}</button>
                            </p>
                        </div>
                        <div className="">
                            <Form.Control value={search} onBlur={() => setReinit(reinit + 1)} onChange={(e) => setSearch(e.target.value)} className="w-auto form-control-sm" placeholder={t('common.search')}
                                onKeyPress={(e) => { handleEnterKeyPress(e) }}
                            />
                        </div>
                    </div>
                    <Table id="example" className="display w-100 dataTable">
                        <thead>
                            <tr role="row">
                                {tableHead.map((v, i) => (
                                    <th key={i} className={(v.className || '') + ' ' + getSortClass(v.sort)} onClick={() => { v.sort && handleSort(v.sort, sorting.sort === v.sort ? -sorting.sort_type : 1) }}>
                                        {v.title}
                                        {v.sort && sorting.sort === v.sort && (
                                            <span className={`sorting-icon ${sorting.sort_type === -1 ? 'desc' : 'asc'}`} />
                                        )}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {!isEmpty(items) && items.map((item, index) =>
                                <tr key={index}>
                                    <td className='text-right'>{index + 1}</td>
                                    <td>
                                        {item.question ? item.question : '--'}
                                    </td>
                                    <td>
                                        {item.plan_slug ? item.plan_slug : '--'}
                                    </td>
                                    <td className="text-right">
                                        {item.sort_order ? item.sort_order : '--'}
                                    </td>
                                    <td>
                                        {item.type ? item.type : '--'}
                                    </td>
                                    <td>
                                        {
                                            item.is_mandatory === 1 ?
                                                <i className="fa fa-check text-success" aria-hidden="true"></i> :
                                                <i className="fa fa-times text-danger" aria-hidden="true"></i>
                                        }
                                    </td>
                                    <td className="text-right">
                                        {item.createdAt ? convertDateFormat(item.createdAt) : '--'}
                                    </td>
                                    <td className="text-right">
                                        <Button variant="outline-dark" onClick={() => handleEdit(item)} size="xs" className="mr-1 px-2 py-1">
                                            <i className="fa fa-pencil"></i>
                                        </Button>
                                        <Button onClick={() => handleDelete(item._id)} variant="outline-danger" size="xs" className="mr-1 px-2 py-1">
                                            <i className="fa fa-trash"></i>
                                        </Button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div className="dataTables_info">
                            {recordsCount === 0 ? "No - Record Found" :
                                `Showing ${(((page) * limit) + 1)} to ${Math.min((page + 1) * limit, recordsCount)} of ${recordsCount} entries`}
                        </div>
                        <div className="dataTables_paginate paging_simple_numbers">
                            <ReactPaginate
                                nextLabel="Next >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={1}
                                pageCount={pageCount}
                                forcePage={page}
                                previousLabel="< Previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>


            <Modal className="fade bd-example-modal-lg" show={modalShow} size="lg">
                <Modal.Header>
                    <Modal.Title>{update ? t('oneoffQuestion.update_question') : t('oneoffQuestion.add_question')}</Modal.Title>
                    <Button variant="danger" className="close" size="sm" onClick={() => setModalShow(!modalShow)}>X</Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="input-group mb-3">
                        <div className="col-sm-12">
                            <label htmlFor="question" className="form-label text-sm">
                                {t('oneoffQuestion.question')}<span className="text-danger">*</span>
                            </label>
                            <input
                                id="question"
                                type="text"
                                className={`form-control form-control-sm ${questionMessage ? 'is-invalid' : ''}`}
                                placeholder={t('oneoffQuestion.question_placeholder')}
                                value={question}
                                onChange={(e) => { setQuestion(e.target.value); setQuestionMessage(''); }}
                                onBlur={() => !question ? setQuestionMessage(t('common.question_message')) : ''}
                                required
                            />
                            {questionMessage && <div className="invalid-feedback">{questionMessage}</div>}
                        </div>
                        <div className="col-sm-12 mt-2">
                            <label htmlFor="plan" className="form-label text-sm">
                                {t('oneoffQuestion.plan')}<span className="text-danger">*</span>
                            </label>
                            <Form.Select
                                id="plan"
                                aria-label="Default select example"
                                size="sm"
                                onChange={(e) => handleplanChange(e.target.value)}
                                onBlur={() => !planValue ? setplanMessage(t('common.plan_message')) : ''}
                                required
                            >
                                {plan.length > 0 ?
                                    <>
                                        <option value=''>Select Plan</option>
                                        {plan.map(v => <option value={v.plan}>{v.plan}</option>)}
                                    </> :
                                    <option value="null">No plan to Select</option>
                                }
                            </Form.Select>
                            {planMessage && <div className="text-danger">{planMessage}</div>}
                        </div>
                        <div className="col-sm-12 mt-2">
                            <label htmlFor="type" className="form-label text-sm">
                                {t('oneoffQuestion.input_type')}
                            </label>
                            <Form.Select
                                id="type"
                                aria-label="Default select example"
                                size="sm"
                                onChange={(e) => handleTypeChange(e.target.value)}
                            >
                                {type.length > 0 ?
                                    <>
                                        {type.map(v => <option value={v.type}>{v.lable}</option>)}
                                    </> :
                                    <option value="null">No Type to Select</option>
                                }
                            </Form.Select>
                        </div>
                        {update &&
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="sort" className="form-label text-sm">
                                    {t('oneoffQuestion.sort_order')}<span className="text-danger">*</span>
                                </label>
                                <input
                                    id="sort"
                                    type="number"
                                    className={`form-control form-control-sm ${sortMessage ? 'is-invalid' : ''}`}
                                    placeholder={t('oneoffQuestion.sort_placeholder')}
                                    value={sortOrder}
                                    onChange={(e) => { setSortOrder(e.target.value); setSortMessage(''); }}
                                    onBlur={() => !sortOrder ? setSortMessage(t('common.sort_message')) : ''}
                                    required
                                />
                                {sortMessage && <div className="text-danger">{sortMessage}</div>}
                            </div>
                        }
                        <div className="col-sm-12 mt-2">
                            <div key={`default-checkbox`} className="mb-3">
                                <Form.Check
                                    type='checkbox'
                                    id={`is_mandatory`}
                                    label={t('oneoffQuestion.is_mendatory')}
                                    value={isMandatory}
                                    className="ms-0 me-0"
                                    defaultChecked={isMandatory}
                                    onChange={(e) => handleCheckboxChange(e.target.checked)}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-danger" size="sm" onClick={() => handleModalClose()}>{t('common.cancel')}</Button>
                    <Button variant="primary" type="button" size="sm" className="btn btn-primary" onClick={() => createOrUpdate()}>{update ? t('common.update') : t('common.create')}</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} animation={false}>
                <Modal.Body>
                    <h4 className="mb-0">
                        Want to delete?
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-dark" size="sm" onClick={() => setShowDeleteModal(false)}>
                        No
                    </Button>
                    <Button variant="danger" size="sm" onClick={confirmDelete}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default OneoffQuestion;