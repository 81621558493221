import React, { useEffect, useState, useContext } from 'react'
import { Button, ButtonGroup, Card, Form, Table } from 'react-bootstrap'
import Category from '../../Products/Category';
import RangeSlider from '../../Products/RangeSlider';
import Axios from '../../../plugins/axios';
import TrendingShops from './TrendingShops';
import HotShopList from './HotShopList';
import ShopSearch from '../ShopSearch';
import { useTranslation } from 'react-i18next';
import { Context } from '../../../../MasterLayout';
import Export from '../../CommonComponents/export';
import PlanModal from '../../CommonComponents/PlanModal';


function ShopRankingFilter({ tab, defaultCycle, rankType }) {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(Context);
    const [page, setPage] = useState(0);
    const [items, setItems] = useState([]);
    const initialCountrycode = tab === 'shops-search' ? '' : 'US'
    const [countryCode, setCountryCode] = useState(initialCountrycode);
    const [category1, setCategory1] = useState('');
    const [category2, setCategory2] = useState('');
    const [category3] = useState('');
    const [categoryId1, setCategoryId1] = useState('');
    const [categoryId2, setCategoryId2] = useState('');
    const [categoryId3] = useState('');
    const [ShopAttribute, setShopAttribute] = useState('');
    const [ShopAttributeorgname, setShopAttributeOrgname] = useState('Shop Attribute');
    const [StoreStatusorgname, setStoreStatusOrgname] = useState('Store status');
    const [StoreStatus, setStoreStatus] = useState("");
    const [salesRange, setSalesRange] = useState([]);
    const [relevantInfluencersRange, setRelevantInfluencersRange] = useState([]);
    const [version, setVersion] = useState('');
    const [versionList, setVersionList] = useState([]);
    const [cycle, setCycle] = useState(defaultCycle);
    const [favourite, setFavourite] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [searchValue, setSearchValue] = useState(state && state.keyword ? state.keyword : '');
    const [searchKeyword, setSearchKeyword] = useState(state && state.keyword ? state.keyword : '');
    const [payload, setPayload] = useState({});
    const [limitMsg, setLimitMsg] = useState('');
    const [modalShow, setModalShow] = useState(false);


    let sortbytab
    if (tab === 'trending-shops') {
        sortbytab = 38;
    } else if (tab === "hot-shops") {
        sortbytab = 37;
    } else if (tab === "shops-search") {
        sortbytab = 43;
        // sortbytab = 37;

    }
    const [sorting, setSorting] = useState({ sort: sortbytab, sort_type: 'DESC' });
    const sortClass = sorting.sort_type == 'DESC' ? 'sorting-desc' : 'sorting-asc';
    const sortType = sorting.sort_type == 'DESC' ? 'ASC' : 'DESC';

    const handleSort = async (sort, sortType) => {
        if (sort != sorting.sort || (sortType && sortType != sorting.sort_type)) {
            setSorting({ sort, sort_type: sortType });
        }
    };
    const functionNext = async () => {
        if (items.length > 0) {
            let number = page + 1;
            setPage(number);
            shopsSearch(number);
        }
    }


    const cycleList = {
        'trending-shops': {
            'H24': '1 Day',
            'W1': 'Weekly',
            'M1': 'Monthly',
        },
        'hot-shops': {
            'H24': '1 Day',
            'W1': 'Weekly',
            'M1': 'Monthly',
        },
        'shops-search': {
            'H24': '1 Day',
            'W1': 'Weekly',
            'M1': 'Monthly',
        },
    };

    const endPointURL = {
        'trending-shops': '/api/shops/trending-shops',
        'hot-shops': '/api/shops/hot-shop-list',
        'shops-search': '/api/shops/shop-search',
    }
    const getVersion = async (cycle) => {
        let params = {
            cycle
        }
        if (rankType) {
            params['rank_type'] = rankType;
        }
        let response = await Axios.get('/api/version', { params });
        if (response.data.data) {
            setVersion(response.data.data[0].version);
        }
        setVersionList(response.data.data ? response.data.data : []);
    };


    const shopsSearch = async (page) => {
        try {
            let params = {
                sort: sorting.sort,
                sort_type: sorting.sort_type,
                page,
                cycle,
                version
            };

            if (countryCode != '') {
                params['country_code'] = countryCode;
            }
            if (category1 != '') {
                params['category1'] = category1;
            }
            if (category2 != '') {
                params['category2'] = category2;
            }
            if (category3 != '') {
                params['category3'] = category3;
            }
            if (categoryId1 != '') {
                params['category_id1'] = categoryId1;
            }
            if (categoryId2 != '') {
                params['category_id2'] = categoryId2;
            }
            if (categoryId3 != '') {
                params['category_id3'] = categoryId3;
            }
            if (ShopAttribute === "overseas") {
                params['ship_from'] = "overseas";
            }
            if (ShopAttribute === "local") {
                params['ship_from'] = "local";
            }
            if (StoreStatus === "normal") {
                params['shop_status'] = true;
            }
            if (StoreStatus === "closed") {
                params['shop_status'] = false;
            }

            if (relevantInfluencersRange.to || relevantInfluencersRange.from) {
                params['start_related_author'] = relevantInfluencersRange.from;
            }
            if (relevantInfluencersRange.to && relevantInfluencersRange.to != '') {
                params['end_related_author'] = relevantInfluencersRange.to;
            }
            if (salesRange.to || salesRange.from) {
                params['sold_count_start'] = salesRange.from;
            }
            if (salesRange.to && salesRange.to != '') {
                params['sold_count_end'] = salesRange.to;
            }
            if (searchKeyword != "") {
                params['keyword'] = searchKeyword;
            }
            setPayload(params)

            let response = await Axios.post(endPointURL[tab], params);
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });

            setLimitMsg(response?.data?.msg || '');
            if (response?.data?.statusCode === 402) setModalShow(true);

            let array = await response.data.favorite ? response.data.favorite.map((value, index) => value.shop_id) : favourite;
            if (page == 0) {
                setItems(response?.data?.data ? response.data.data : []);
                setFavourite(array);
            } else {
                setItems(items.concat(response.data.data));
                setFavourite(favourite.concat(array));
            }
            if (response.data.data.length === 0 || response.data.data.length < 20) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }

        } catch (error) {

        }
    }

    useEffect(() => {
        //do operation on state change
        setItems([]);
        setPage(0);
        if (version == '') {
            getVersion(cycle);
        } else {
            shopsSearch(0);
        }
    }, [version, sorting, countryCode, searchKeyword, category1, category2, category3, categoryId1, categoryId2, categoryId3, StoreStatus, ShopAttribute, salesRange, relevantInfluencersRange])



    const selectCountry = async (countryCode) => {
        setCountryCode(countryCode);
    };
    const regionList = Object.keys(global.config.region).map(function (key) {
        return <a key={key} className={"btn my-1 " + (countryCode == key ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 d-flex align-items-center mb-2"} onClick={() => { selectCountry(key); }}><span className={`fi fi-${key.toLocaleLowerCase()} mr-1`}></span>{global.config.region[key]}</a>
    });
    const setSingleCategory = async (e) => {
        setCategory1(e.category);
        setCategoryId1(e.id);
    }
    const handleCategory = async (categoryIds, categoryNames) => {
        setCategory1(categoryNames[0] ? categoryNames[0] : '');
        setCategory2(categoryNames[1] ? categoryNames[1] : '');
        setCategoryId1(categoryIds[0] ? categoryIds[0] : '');
        setCategoryId2(categoryIds[1] ? categoryIds[1] : '');
    };
    const onclickSetshopAttribute = (e) => {
        const value = e.target.getAttribute('value')
        const text = e.target.innerText
        setShopAttributeOrgname(text);
        setShopAttribute(value)

    };
    const onclickSetStoreStatus = (e) => {
        const value = e.target.getAttribute('value')
        const text = e.target.innerText
        setStoreStatus(value)
        setStoreStatusOrgname(text);


    };

    const cycleHtml = Object.keys(cycleList[tab]).map(function (key) {
        return <Button key={key} variant={"outline-primary " + (cycle == key ? 'btn-primary' : '')} size="xs" onClick={() => { setCycle(key); getVersion(key); }}>{cycleList[tab][key]}</Button>
    });

    const markFavourite = async (data) => {
        try {
            await Axios.post('/api/favorite-shop', data);
            const index = favourite.indexOf(data.shop_id);
            if (index > -1) {
                favourite.splice(index, 1);
            } else {
                favourite.push(data.shop_id);
            }
            setFavourite(favourite.concat([]));
        } catch (error) {
            console.error(error.message)
        }
    };

    const handleEnterKeyPress = async (e) => {
        if (e.key === 'Enter') {
            setSearchKeyword(searchValue)
        }
    }
    const longText = {
        "trending-shops": t('shops.long_text_1'),
        "hot-shops": t('shops.long_text_2')
    }
    return (
        <div>
            <Card className='rounded-0'>
                <Card.Body>
                    {limitMsg && <div className='d-flex flex-row justify-content-center'>
                        <h4 className='flex my-2 text-center mx-2'><strong>{limitMsg}</strong></h4>
                        <button type='button' className="btn btn-outline-primary btn-xs" onClick={() => setModalShow(true)}>Upgrade</button>
                    </div>}
                    <div className='topfilter'>
                        <div className='d-flex'>
                            <p><small>{longText[tab]}</small></p>
                            {/* <p><small>TikTok shops that automatically ranked by the sales growth ratio.</small></p> */}
                            {tab === "shops-search" ?
                                <Form.Control onKeyPress={handleEnterKeyPress} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} className="w-25 form-control-sm ml-2" placeholder={t('shops.search_shops')} />
                                : ''}
                        </div>
                        <div className="d-flex align-items-center flex-wrap mb-2">
                            <p className="m-0 mr-3"><strong>{t('common.country')}</strong></p>
                            {tab === "shops-search" ?
                                <a className={"btn my-1 " + (countryCode == "" ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 d-flex align-items-center mb-2 mb-2 my-1"} onClick={() => { selectCountry(""); }}>{t('common.all')}</a>
                                : ''}

                            {regionList}
                        </div>
                        <Category singleCategory={false} setSingleCategory={setSingleCategory} handleCategory={handleCategory} categoryId1={categoryId1} />
                        <div className="d-flex align-items-center flex-wrap mb-3">
                            <p className="m-0 mr-3"><strong>{t('common.filters')}</strong></p>

                            <div className="dropdown product-rating-menu">
                                <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle"
                                    role="button"
                                    id="dropdownMenuLink"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {t('common.sales')}
                                </a>
                                <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                    <RangeSlider min={0} max={10000000} defaultMin={0} defaultMax={10000000} rangeOption={global.config.rangeOption} rangeOptionName={'views'} step={10} handleUpdate={(e) => { setSalesRange(e) }} />
                                </div>
                            </div>


                            {tab !== "trending-shops" ?
                                <div className="dropdown product-rating-menu">
                                    <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle"
                                        role="button"
                                        id="dropdownMenuLink"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        {t('shops.relevant_influencers')}
                                    </a>
                                    <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                        <RangeSlider min={0} max={5000} defaultMin={0} defaultMax={5000} rangeOption={global.config.rangeOption} rangeOptionName={'views'} step={10} handleUpdate={(e) => { setRelevantInfluencersRange(e) }} />
                                    </div>
                                </div>
                                :
                                ''
                            }
                        </div>
                        <div className="d-flex align-items-center flex-wrap mb-3">
                            <p className="m-0 mr-3"><strong>{t('shops.advance_filtering')}</strong></p>

                            <div className="dropdown ml-2">
                                <button className="btn btn-outline-dark dropdown-toggle btn-xs" type="button" data-toggle="dropdown" aria-expanded="false">
                                    {ShopAttributeorgname}
                                </button>
                                <div className="dropdown-menu">
                                    <a className="dropdown-item" href="#" value='Shop Attribute' onClick={onclickSetshopAttribute}>{t('shops.shop_attribute')}</a>
                                    <a className="dropdown-item" href="#" value='overseas' onClick={onclickSetshopAttribute}>{t('shops.cross_border_shop')}</a>
                                    <a className="dropdown-item" href="#" value='local' onClick={onclickSetshopAttribute}>{t('shops.local_shop')}</a>
                                </div>
                            </div>
                            <div className="dropdown ml-2">
                                <button className="btn btn-outline-dark dropdown-toggle btn-xs" type="button" data-toggle="dropdown" aria-expanded="false">
                                    {StoreStatusorgname}
                                </button>
                                <div className="dropdown-menu">
                                    <a className="dropdown-item" href="#" value='Store status' onClick={onclickSetStoreStatus}>{t('shops.store_status')}</a>
                                    <a className="dropdown-item" href="#" value='normal' onClick={onclickSetStoreStatus}>{t('shops.normal')}</a>
                                    <a className="dropdown-item" href="#" value='closed' onClick={onclickSetStoreStatus}>{t('shops.closed')}</a>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center mb-2">
                            <ButtonGroup aria-label="Basic example" className='mr-2'>
                                {cycleHtml}
                            </ButtonGroup>
                            <div className="dropdown product-rating-menu">
                                <div className="form-group mb-0 mr-3">
                                    <select className="form-control form-control-sm" onChange={(e) => { setVersion(e.target.value); }} value={version}>
                                        {versionList.map((value, index) => (
                                            <option key={index} value={value.version}>{value.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <p className='text-muted mb-0'><small>{t('shops.last_updated_in_6_hours')}</small></p>
                        </div>
                        <div className="d-flex justify-content-end align-items-center mx-2 my-3">
                            <Export payload={payload} data_source={tab === 'trending-shops' ? "Trending Shops" : tab === 'hot-shops' ? "Hot Shop List" : tab === 'shops-search' ? "Shop Search" : ""} type={tab === 'shops-search' ? "Shop Search" : "Shop"} />
                        </div>



                    </div>
                </Card.Body>
            </Card>

            <Card>
                <Card.Body className='p-2'>
                    <Table responsive className="w-100">
                        <div id="example_wrapper" className="dataTables_wrapper">
                            {tab == 'trending-shops' && <TrendingShops sortType={sortType} handleSort={handleSort} sortClass={sortClass} sorting={sorting} functionNext={functionNext} items={items} favourite={favourite} markFavourite={markFavourite} hasMore={hasMore} />}
                            {tab == 'hot-shops' && <HotShopList sortType={sortType} handleSort={handleSort} sortClass={sortClass} sorting={sorting} functionNext={functionNext} items={items} favourite={favourite} markFavourite={markFavourite} hasMore={hasMore} />}
                            {tab == 'shops-search' && <ShopSearch sortType={sortType} handleSort={handleSort} sortClass={sortClass} sorting={sorting} functionNext={functionNext} items={items} favourite={favourite} markFavourite={markFavourite} hasMore={hasMore} />}
                        </div>
                    </Table>
                </Card.Body>
            </Card>
            <PlanModal setModalShow={setModalShow} modalShow={modalShow} />
        </div>
    )
}

export default ShopRankingFilter