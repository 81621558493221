import React, { useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from 'moment';

const EventCalendar = ({ calendarEvents, showList, startDate}) => {
   const calendarRef = useRef(null);
   useEffect(() => {
         calendarRef.current.getApi().gotoDate(startDate)
   }, [startDate]);

   /**
    * when we click on event we are displaying event details
    */
   const eventClick = (eventClick) => {
      showList(moment(new Date(eventClick.event.start)).format('YYYY-MM-DD'))
   };

   const select = (info) => {
      showList(moment(new Date(info.startStr)).format('YYYY-MM-DD'))
   };

   return (
      <FullCalendar
         ref={calendarRef}
         defaultView="dayGridMonth"
         header={{
            left: "prev,next today",
            center: "title",
            right:
               "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
         }}
         rerenderDelay={10}
         eventDurationEditable={false}
         editable={true}
         droppable={true}
         plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
         ]}
         events={calendarEvents}
         eventClick={eventClick}
         selectable={true}
         select={select}
         rerenderEvents={true}
      />
   );
}

export default EventCalendar;
