import React, { useState, useEffect, useContext } from 'react';
import { Button, Card, Table, Form, InputGroup, Collapse } from "react-bootstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useTranslation } from "react-i18next";
import { Context } from '../../../../../MasterLayout';
import Axios from '../../../../plugins/axios';
import RangeSlider from '../../../Products/RangeSlider';
import HashtageSearch from './HashtageSearch';


const HashtageSearchFilters = () => {
    const sort = 3;
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [countryCode, setCountryCode] = useState('');
    const [favourite, setFavourite] = useState([]);
    const { state, dispatch } = useContext(Context);
    const [viewRange, setViewRange] = useState();
    const [likeRange, setLikeRange] = useState();
    const [isCommerce, setIsCommerce] = useState(false)
    const [SearchValue, setSearchValue] = useState(state && state.keyword ? state.keyword : '');
    const [sorting, setSorting] = useState({ sort: sort, sort_type: 'DESC' });
    const [open, setOpen] = useState(false);
    const sortClass = sorting.sort_type == 'DESC' ? 'sorting-desc' : 'sorting-asc';
    const sortType = sorting.sort_type == 'DESC' ? 'ASC' : 'DESC';

    const handleSort = async (sort, sortType) => {
        if (sort != sorting.sort || (sortType && sortType != sorting.sort_type)) {
            setSorting({ sort, sort_type: sortType });
        }
    };

    const viewRangeOption = {
        '0-10000': '<10K',
        '10000-100000': '10K-100K',
        '100000-1000000': '100K-1M',
        '1000000-10000000': '1M-10M',
        '10000000-': '10M+',
    };
    const likeRangeOption = {
        '0-10000': '<10K',
        '10000-100000': '10K-100K',
        '100000-1000000': '100K-1M',
        '1000000-10000000': '1M-10M',
        '10000000-': '10M+',
    };

    useEffect(() => {
        fetchData(0);
    }, [countryCode, isCommerce, sorting, viewRange, likeRange]);


    const markFavourite = async (data) => {
        try {
            await Axios.post('api/hashtage/favourite', data);
            const index = favourite.indexOf(data.hashtage_id);
            if (index > -1) {
                favourite.splice(index, 1);
            } else {
                favourite.push(data.hashtage_id);
            }
            setFavourite(favourite.concat([]));
        } catch (error) {
            console.error(error.message)
        }
    };
    const handleViewRange = (value) => {
        setViewRange(value);
    };
    const handleLikesRange = (value) => {
        setLikeRange(value);
    };

    const selectCountry = (countryCode) => {
        setCountryCode(countryCode);
    };



    const fetchData = async (page) => {

        try {
            let params = {
                page: page,
                sort: sorting.sort,
                sort_type: sorting.sort_type,
            };
            if (SearchValue) {
                params['keyword'] = SearchValue;
            }
            if (isCommerce) {
                params['is_commerce'] = isCommerce;
            }
            if (viewRange) {
                params['play_start'] = viewRange.from;
                params['play_end'] = viewRange.to;
            }
            if (likeRange) {
                params['digg_start'] = likeRange.from;
                params['digg_end'] = likeRange.to;
            }
            if (countryCode !== '') params['country_code'] = countryCode;
            const response = await Axios.post('/api/search/hastage', params);
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });
            handleResponse(response, page);
        } catch (error) {
            console.error('Error fetching products:', error.message);
        }
    };

    const handleResponse = (response, page) => {
        if (response && response.status === 200 && response.data.data) {
            const responseData = response.data;
            const array = response.data.favorite ? response.data.favorite.map(value => value.hashtage_id) : favourite;
            if (page == 0) {
                setItems(response.data.data);
                setFavourite(array);
            } else {
                setItems(items.concat(response.data.data));
                setFavourite(favourite.concat(array));
            }
            if (response.data.data.length === 0 || response.data.data.length < 20) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
            dispatch({ type: 'subscription_expired', response: responseData.statusCode });
        }
    };

    const functionNext = () => {
        const nextPage = page + 1;
        setPage(nextPage);
        fetchData(nextPage);
    };

    const handleSwitchChange = (e) => {
        setIsCommerce(e.target.checked);
    };

    const countries = { ...global.config.region, ...global.config.countries };
    const regionList = Object.keys(global.config.region).map(function (key) {
        return <a key={key} className={"btn my-1 " + (countryCode == key ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 d-flex align-items-center mb-2"} onClick={() => { selectCountry(key); }}><span className={`fi fi-${key.toLocaleLowerCase()} mr-1`}></span>  {global.config.region[key]}</a>
    });



    return (
        <>
            <Card className='rounded-0'>
                <Card.Body>
                    <div className='d-flex justify-content-end mb-2'>
                        <InputGroup className="w-25 h-25">
                            <Form.Control
                                type="text"
                                value={SearchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                placeholder={t('creator_center.hashtage_search.search_hashtages')}
                                className="w-25 h-25 p-2"
                                onKeyPress={() => { fetchData(page) }}
                            />
                            <Button variant="outline-primary" size='xs' className="w-25 h-25 p-2" onClick={() => { fetchData(page) }}>
                                <i className="fa fa-search" aria-hidden="true"></i>
                            </Button>
                        </InputGroup>
                    </div>
                    <div className='topfilter'>
                        <div className="d-flex align-items-center flex-wrap mb-2">
                            <p className="m-0 mr-3"><strong>{t('creator_center.songs_ranking.filter.country')}</strong></p>
                            <a className={"btn " + (countryCode == '' ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 mb-2 my-1"}
                                onClick={() => selectCountry('')}>
                                {t('product_research.products_search.all')}
                            </a>
                            {regionList}
                            <a
                                onClick={() => setOpen(!open)}
                                aria-controls="example-collapse-text"
                                aria-expanded={open}
                                className='btn btn-outline-dark btn-xs show-sub mb-2 mb-2 my-1'><i className="fa fa-chevron-down" aria-hidden="true"></i></a>
                            <Collapse in={open}>
                                <div id="example-collapse-text" className='pl-5 mx-4'>
                                    <div className='d-flex align-items-center flex-wrap '>
                                        {
                                            Object.keys(countries).slice(8).map((key) => (
                                                (
                                                    countries &&
                                                    (
                                                        <a key={key} className={"btn my-1 " + (countryCode == key ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 d-flex align-items-center mb-2"} onClick={() => setCountryCode(key)}><span className={`fi fi-${key.toLocaleLowerCase()} mr-1`}></span>{countries[key]}</a>
                                                    )

                                                )

                                            ))
                                        }
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <p className="m-0 mr-3"><strong>{t('creator_center.songs_ranking.filter.filters')}</strong></p>
                            <div className="dropdown product-rating-menu">
                                <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    Industry
                                </a>
                                <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                    <p>No Data</p>
                                </div>
                            </div>
                            <div className="dropdown product-rating-menu">
                                <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    Views
                                </a>
                                <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                    <RangeSlider min={0} max={10000000} defaultMin={0} defaultMax={10000000} rangeOption={viewRangeOption} rangeOptionName={'view'} step={10} handleUpdate={handleViewRange} />
                                </div>
                            </div>
                            <div className="dropdown product-rating-menu">
                                <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    Likes
                                </a>
                                <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                    <RangeSlider min={0} max={10000000} defaultMin={0} defaultMax={10000000} rangeOption={likeRangeOption} rangeOptionName={'duration'} step={1} handleUpdate={handleLikesRange} />
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center mb-2 pl-3'>
                            <Form>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="custom-switch"
                                    label={t('creator_center.hashtage_search.eCommerceSwitch')}
                                    onChange={handleSwitchChange}
                                    checked={isCommerce}
                                />
                            </Form>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body className='p-2'>
                    <Table responsive className="w-100">
                        <div id="example_wrapper" className="dataTables_wrapper">
                            <HashtageSearch sortType={sortType} handleSort={handleSort} sortClass={sortClass} sorting={sorting} functionNext={functionNext} items={items} favourite={favourite} markFavourite={markFavourite} hasMore={hasMore} />
                        </div>
                    </Table>
                </Card.Body>
            </Card>
        </>
    );
};

export default HashtageSearchFilters;
