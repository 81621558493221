import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';
import Axios from '../../../plugins/axios';

const InfluencerVideoDeleteModal = ({ showDeleteModal, setShowDeleteModal, setItemIdToDelete, getInfluencerVideo, itemIdToDelete }) => {

    const confirmDelete = async () => {
        const response = await Axios.delete(`/api/influencer-videos?id=${itemIdToDelete}`);
        if (response && response.status === 200 && response?.data?.statusCode === 200) {
            deleteSuccessToast()
            setItemIdToDelete(null)
            getInfluencerVideo(1);
        }
        setShowDeleteModal(false);
    };

    const deleteSuccessToast = () => {
        toast.success("Deleted successfully", {
            position: 'top-center',
            autoClose: 5000
        });
    };

    return (
        <>
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} animation={false}>
                <Modal.Body>Want to delete?</Modal.Body>
                <Modal.Footer>
                    <Button variant="btn btn-sm btn-secondry mr-2" onClick={() => setShowDeleteModal(false)}>
                        Close
                    </Button>
                    <Button variant="btn btn-sm btn-danger mr-2" onClick={confirmDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default InfluencerVideoDeleteModal;