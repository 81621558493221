import React, { useState, useEffect } from "react";
import "../../css/landing/landing.css";
import "../../css/landing/bootstrap.min.css";
import "../../css/landing/glightbox.min.css";
import "../../css/landing/main.css";
import "../../css/landing/tiny-slider.css";
import { InputGroup, Form, Button, Spinner } from 'react-bootstrap'
import ProductsresearchImg from "../../../src/images/products-research.png";
import AffiliateImg from "../../../src/images/affiliate.png";
import ProfitLossImg from "../../../src/images/profit-loss.png";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Link } from "react-router-dom";
import Axios from "../plugins/axios";
import { useTranslation } from 'react-i18next';

function MyButton(props) {
   return <Button variant="primary" id="button-addon2" size="xs"  {...props}>
      Copy
   </Button>
}
const Affiliate = () => {
   const [isUserLogin, setIsUserLogin] = useState(false);
   const [spinner, setSpinner] = useState(false);
   const [value, setValue] = useState('');
   const [copied, setCopied] = useState(false);
   const { t } = useTranslation();

   useEffect(() => {
      if (localStorage.getItem('token') && localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).user_type == 'user') {
         getAffiliate();
      };
   }, []);

   const getAffiliate = async () => {
      try {
         setSpinner(true);
         let response = await Axios.post('/api/affiliate').catch(e => console.error('Axios-Error: ' + e.message));
         if (response.status == 200 && response.data.statusCode == 200 && response.data.data) {
            setIsUserLogin(true);
            setValue(response.data.data.affiliate_url);
         };
      } catch (error) {
         console.error('Error-Catch: ' + error.message);
      };
      setSpinner(false);
   };

   return (
      <>
         {spinner && <div className='loader-warp'>
            <Spinner animation="border" variant="primary" />
         </div>}
         <Header />
         <section id="home" className="hero-area hero-inner-area">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-lg-7 col-md-12 col-12">
                     <div className="hero-content">
                        <h1 className="mb-0">{t('affiliate.partner_with_viralytic')}</h1>
                        <p>{t('affiliate.earn_commissions')}</p>
                        {isUserLogin == false && <div className="button mb-2">
                           <Link to={process.env.REACT_APP_AFFILIATE_URL} target="_blank">
                              <Button className="btn">
                                 {t('affiliate.get_started')}
                              </Button>
                           </Link>
                        </div>}
                        {isUserLogin == true && <>
                           <InputGroup className="mb-0">
                              <Form.Control
                                 placeholder="Leaddyno's url"
                                 aria-label="Leaddyno's url"
                                 aria-describedby="basic-addon2"
                                 onChange={(e) => { setValue(e.target.value); setCopied(false); }}
                                 value={value}
                                 size="sm"
                              />
                              <MyButton onClick={() => { navigator.clipboard.writeText(value); setCopied(true); }}>{t('affiliate.copy_to_clipboard')}</MyButton>
                           </InputGroup>
                           {copied ? <span className="text-success small">{t('affiliate.url_copied_successfully')}</span> : null}
                        </>}
                     </div>
                  </div>
                  <div className="col-lg-5 col-md-12 col-12 text-center">
                     <div className="hero-image">
                        <img src={ProductsresearchImg} alt="" width={445} height={392} />
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section id="pricing" className="pricing-table section pb-0">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <div className="section-title mb-0 px-0">
                        <h2>{t('affiliate.introduction')}</h2>
                        <p>{t('affiliate.welcome_to_viralytic')}</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section id="features" className="features section">
            <div className="container">
               <div className="row mb-5 align-items-center">
                  <div className="col-lg-5 col-md-6 col-12">
                     <div className="single-feature mt-0 text-center">
                        <img src={AffiliateImg} alt="" />
                     </div>
                  </div>
                  <div className="col-lg-7 col-md-6 col-12">
                     <h3>{t('affiliate.why_become_a_viralytic')}</h3>
                     <ul>
                        <li className="mb-3"><span dangerouslySetInnerHTML={{ __html: t(`affiliate.generous_commissions`) }} /></li>
                        <li className="mb-3"><span dangerouslySetInnerHTML={{ __html: t(`affiliate.cutting_edge_tools`) }} /></li>
                        <li className="mb-3"><span dangerouslySetInnerHTML={{ __html: t(`affiliate.easy_integration`) }} /></li>
                        <li><span dangerouslySetInnerHTML={{ __html: t(`affiliate.dedicated_support`) }} /></li>
                     </ul>
                  </div>
               </div>
               <div className="row mb-5 align-items-center">
                  <div className="col-lg-7 col-md-6 col-12">
                     <h3>{t('affiliate.how_it_works')}</h3>
                     <ul>
                        <li className="mb-3"><span dangerouslySetInnerHTML={{ __html: t(`affiliate.sign_up`) }} /></li>
                        <li className="mb-3"><span dangerouslySetInnerHTML={{ __html: t(`affiliate.receive_your_unique_link`) }} /></li>
                        <li className="mb-3"><span dangerouslySetInnerHTML={{ __html: t(`affiliate.promote_viralytic`) }} /></li>
                        <li><span dangerouslySetInnerHTML={{ __html: t(`affiliate.earn_commissions_earn`) }} /></li>
                     </ul>
                  </div>
                  <div className="col-lg-5 col-md-6 col-12">
                     <div className="single-feature mt-0">
                        <img src={ProfitLossImg} alt="" />
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section id="pricing" className="pricing-table section pt-0">
            <div className="container">
               <div className="row">
                  <div className="col-lg-4 col-md-6 col-12">
                     <div className="single-table">
                        <div className="table-head">
                           <h4 className="title">{t('affiliate.affiliate_dashboard')}</h4>
                           <p>{t('affiliate.access_our_user_friendly')}</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                     <div className="single-table">
                        <div className="table-head">
                           <h4 className="title">{t('affiliate.promotional_materials')}</h4>
                           <p>{t('affiliate.access_a_library_of')}</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                     <div className="single-table">
                        <div className="table-head">
                           <h4 className="title">{t('affiliate.join_now_and_boost')}</h4>
                           <p>{t('affiliate.ready_to_boost_your')}</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="section call-action">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                     <div className="cta-content">
                        <h2 className="mb-2">{t('affiliate.start_earning')}</h2>
                        <p className="mb-2">{t('affiliate.sign_up_now_and_transform')}</p>
                        <div className="button mt-3">
                           <a href="/pre-register" className="btn">{t('affiliate.start_free_trial')}</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <Footer />
      </>
   );
};

export default Affiliate;