import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Context } from "../../../MasterLayout";
import Axios from "../../plugins/axios";
import { toast, ToastContainer } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCard from "../StripeCard/Stripecard";
import PlanTable from "./PlanTable";
import { switchPlanType } from "../../../config";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PlanModal = ({ modalShow, setModalShow }) => {

    const stripeRef = useRef(null);
    const addressRef = useRef(null);
    const { t } = useTranslation();
    const { dispatch } = useContext(Context);
    const [user, setUser] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);
    const [billingModalShow, setBillingModalShow] = useState(false);
    const [stripeMessage, setStripeMessage] = useState('');

    useEffect(() => {
        getUser();
    }, []);

    const getUser = async () => {
        try {
            dispatch({ type: 'loader_show', response: true });
            const response = await Axios.get('/api/user?expiry=true').catch(e => console.error('Axios-Catch: ' + e.message));
            if (response && response.status === 200) {
                setUser(response.data.data);
            }
        } catch (error) {
            console.error('Error-Catch: ' + error.message);
        };
        dispatch({ type: 'loader_hide', response: true });
    };


    const confirmUpgrade = async (plan) => {
        let obj = { ...user }
        obj.plan = obj.plan ? obj.plan : {};
        obj.plan.slug = plan.slug;
        obj.plan.name = plan.name;
        setUser(obj);
        setConfirmModal(true);
        setModalShow(false);
    };

    const upgradeSubscription = async (data) => {
        try {
            setConfirmModal(false);
            if ((!data.pm_last_four || !data.pm_type) && !data.paymentMethodId) {
                setBillingModalShow(true)
            } else {
                dispatch({ type: 'loader_show', response: true });

                const response = await Axios.post('/api/subscription/update', data).catch((e) => {
                    console.error('Axios-Catch: onBlurEmail ' + e.message);
                });

                if (response && response.status == 200) {
                    toast.success("Successfully updated", {
                        position: 'top-center',
                        autoClose: 5000
                    });
                    window.location.reload();
                    setModalShow(false);
                };
            }
        } catch (error) {
            console.error(error.message);
        }
        dispatch({ type: 'loader_hide', response: true });
    };

    const toSelectPlan = async () => {
        try {
            dispatch({ type: 'loader_show', response: true });
            let stripeResponse = await stripeRef.current.stripePaymentMethod(user.full_name, user.email);
            if (stripeResponse.statusCode === 200 && stripeResponse.data && stripeResponse.data.id) {
                setBillingModalShow(false);
                await upgradeSubscription({ ...user, paymentMethodId: stripeResponse.data.id });
            } else {
                setStripeMessage(stripeResponse.message);
            };
        } catch (error) {

        }
        dispatch({ type: 'loader_hide', response: true });
    };


    return (
        <>
            <div>
                <ToastContainer />
            </div>
            <Modal className="fade bd-example-modal-lg" show={modalShow} size="xl">
                <Modal.Header>
                    <Modal.Title>{t('user_profile.update_subscription')}</Modal.Title>
                    <Button variant="danger" className="close" size="sm" onClick={() => setModalShow(false)}>X</Button>
                </Modal.Header>
                <Modal.Body>
                    <PlanTable func={confirmUpgrade} text={"Start Now"} />
                </Modal.Body>
            </Modal >


            <Modal className="fade bd-example-modal-lg" show={confirmModal} size="md">
                <Modal.Header>
                    <Modal.Title>Upgrade Subscription</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body>
                            Are you sure you want to switch to {user?.plan?.name} ?
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => upgradeSubscription(user)}>
                        Confirm
                    </Button>
                    <Button variant="primary" onClick={() => { setConfirmModal(false); setModalShow(true); }}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                className="fade bd-example-modal-lg"
                show={billingModalShow}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title> {t('user_profile.update_billing_information')}</Modal.Title>
                    <Button
                        variant="danger"
                        className="close"
                        size="sm"
                        onClick={() => setBillingModalShow(false)}
                    >
                        X
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group" onClick={() => setStripeMessage('')}>
                        <Elements stripe={stripePromise}>
                            <StripeCard ref={stripeRef} />
                        </Elements>
                        {stripeMessage && <div className="text-danger">{stripeMessage}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        size="sm"
                        onClick={() => setBillingModalShow(false)}
                    >
                        {t('user_profile.cancel')}
                    </Button>
                    <Button
                        variant="primary"
                        type="button"
                        size="sm"
                        className="btn btn-primary"
                        onClick={() => toSelectPlan()}
                    >
                        {t('user_profile.next')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default PlanModal;