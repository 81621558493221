import Axios from "../../../plugins/axios";
import React, { forwardRef, useEffect, useImperativeHandle, useState, useContext } from "react";
import { Button, Card, Table, Popover, OverlayTrigger, Tooltip } from "react-bootstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import RangeSlider from "../RangeSlider";
import { useParams } from 'react-router-dom';
import InfiniteScrollWrapper from "../InfiniteScrollWrapper";
import ConvertMillisecond from "../ConvertMillisecond";
import Conversion from "../../CommonComponents/Conversion";
import { Context } from '../../../../MasterLayout';
import { useTranslation } from 'react-i18next';

const RelevantLivetab = forwardRef((props, ref) => {
   const { t } = useTranslation();
   const { dispatch } = useContext(Context);
   const params = useParams();
   const [page, setPage] = useState(0);
   const [onLoad, setOnLoad] = useState(false);
   const [items, setItems] = useState([]);
   const [hasMore, setHasMore] = useState(true);
   const [salesRange, setSalesRange] = useState([]);
   const [revenueRange, setRevenueRange] = useState([]);
   const [cumulativeViewersRange, setCumulativeViewersRange] = useState([]);
   const [onlineViewersRange, setOnlineViewersRange] = useState([]);
   const [sorting, setSorting] = useState({ sort: 52, sort_type: 'DESC' });
   const sortType = sorting.sort_type == 'DESC' ? 'ASC' : 'DESC';
   const sortClass = sorting.sort_type == 'DESC' ? 'sorting-desc' : 'sorting-asc';
   const rangeOption = {
      '0-10000': '<10K',
      '10000-100000': '10K-100K',
      '100000-1000000': '100K-1M',
      '1000000-10000000': '1M-10M',
      '10000000-': '10M+',
   }, revenueRangeOption = {
      '0-10000': '<$10K',
      '10000-100000': '$10K-$100K',
      '100000-1000000': '$100K-$1M',
      '1000000-10000000': '$1M-$10M',
      '10000000-': '$10M+',
   };

   const tableHead = [
      {
         title: '#',
         className: 'text-right',
      },
      {
         title: t('product_research.product_details.live_intro'),
      },
      {
         title: t('common.revenue'),
         className: 'text-right sorting',
         sort: 52,
      },
      {
         title: t('common.sales'),
         className: 'text-right sorting',
         sort: 51,
      },
      {
         title: t('product_research.product_details.sales_conversion_rate'),
         className: 'text-right sorting',
         sort: 58,
      },
      {
         title: t('product_research.product_details.coumulative_br_viewers'),
         className: 'text-right sorting',
         sort: 50,
      },
      {
         title: t('product_research.product_details.online_br_viewers_peak'),
         className: 'text-right sorting',
         sort: 56,
      },
      {
         title: t('product_research.product_details.average_viewers'),
         className: 'text-right sorting',
         sort: 57,
      },
      {
         title: t('product_research.product_details.listed_products'),
         className: 'text-right sorting',
         sort: 53,
      },
      {
         title: t('product_research.product_details.start_time'),
         className: 'text-right sorting',
         sort: 49,
      },
   ];

   const handleSalesRange = async (value) => {
      setSalesRange(value);
   };
   const handleRevenueRange = async (value) => {
      setRevenueRange(value);
   };
   const handleCumulativeViewers = async (value) => {
      setCumulativeViewersRange(value);
   };
   const handleOnlineViewersPeakRange = async (value) => {
      setOnlineViewersRange(value);
   };

   useImperativeHandle(ref, () => ({
      async relevantLiveTab() {
         if (onLoad == false) {
            relevantLive(page);
         }
         setOnLoad(true);
      }

   }));
   useEffect(() => {
      if (onLoad == true) {
         setPage(0);
         relevantLive(0);
      }
   }, [sorting, salesRange, revenueRange, cumulativeViewersRange, onlineViewersRange]);

   const relevantLive = async (page) => {
      try {

         let data = {
            sort: sorting.sort,
            page: page,
            sort_type: sorting.sort_type,
            product_id: params.productId,
            country_code: props.product.country.country_code || '',
         }

         if (salesRange.from) {
            data['sold_count_start'] = salesRange.from;
         }
         if (salesRange.to && salesRange.to != '') {
            data['sold_count_end'] = salesRange.to;
         }

         if (revenueRange.from) {
            data['sold_amount_start'] = revenueRange.from;
         }
         if (revenueRange.to && revenueRange.to != '') {
            data['sold_amount_end'] = revenueRange.to;
         }

         if (cumulativeViewersRange.from) {
            data['total_user_start'] = cumulativeViewersRange.from;
         }
         if (cumulativeViewersRange.to && cumulativeViewersRange.to != '') {
            data['total_user_end'] = cumulativeViewersRange.to;
         }

         if (onlineViewersRange.from) {
            data['max_user_count_start'] = onlineViewersRange.from;
         }
         if (onlineViewersRange.to && onlineViewersRange.to != '') {
            data['max_user_count_end'] = onlineViewersRange.to;
         }

         let response = await Axios.post('/api/relevant-lives', data);
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });

         if (response && response.status === 200) {
            if (page == 0) {
               setItems(response.data.data);
            } else {
               setItems(items.concat(response.data.data));
            }
            setHasMore(response.data.data.length === 0 || response.data.data.length < 20 ? false : true);
         }
      } catch (error) {
         console.error('Error: ' + error.message);
      };
   }
   const handleSort = async (sort, sortType) => {
      if (sort != sorting.sort || (sortType && sortType != sorting.sort_type)) {
         setSorting({ sort, sort_type: sortType });
      }
   };
   const functionNext = async () => {
      if (items.length > 0) {
         let number = page + 1;
         setPage(number);
         relevantLive(number);
      }
   }

   return (
      <Card className='rounded-0'>
         <Card.Body>
            <div className='row'>
               <div className="col-lg-12">
                  <h3>{t('product_research.product_details.live_list')}</h3>
               </div>
               <div className='col-lg-12'>
                  <div className="d-flex align-items-center mb-2">
                     <p className="m-0 mr-3"><strong>{t('common.filters')}</strong></p>
                     <div className="dropdown product-rating-menu">
                        <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle"
                           role="button"
                           id="dropdownMenuLink"
                           data-bs-toggle="dropdown" aria-expanded="false">
                           {t('common.sales')}
                        </a>
                        <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                           <RangeSlider min={0} max={10000000000000} defaultMin={0} defaultMax={10000000} rangeOption={rangeOption} rangeOptionName={'sales'} step={10} handleUpdate={handleSalesRange} />
                        </div>
                     </div>
                     <div className="dropdown product-rating-menu">
                        <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle"
                           role="button"
                           id="dropdownMenuLink"
                           data-bs-toggle="dropdown" aria-expanded="false">
                           {t('common.revenue')}
                        </a>
                        <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                           <RangeSlider min={0} max={10000000000000} defaultMin={0} defaultMax={10000000} rangeOption={revenueRangeOption} rangeOptionName={'revenue'} step={10} handleUpdate={handleRevenueRange} />
                        </div>
                     </div>
                     <div className="dropdown product-rating-menu">
                        <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle"
                           role="button"
                           id="dropdownMenuLink"
                           data-bs-toggle="dropdown" aria-expanded="false">
                           {t('product_research.product_details.coumulative_viewers')}
                        </a>
                        <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                           <RangeSlider min={0} max={10000000000000} defaultMin={0} defaultMax={10000000} rangeOption={rangeOption} rangeOptionName={'cumulativeViewers'} step={10} handleUpdate={handleCumulativeViewers} />
                        </div>
                     </div>
                     <div className="dropdown product-rating-menu">
                        <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle"
                           role="button"
                           id="dropdownMenuLink"
                           data-bs-toggle="dropdown" aria-expanded="false">
                           {t('product_research.product_details.online_viewers_peak')}
                        </a>
                        <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                           <RangeSlider min={0} max={10000000000000} defaultMin={0} defaultMax={10000000} rangeOption={rangeOption} rangeOptionName={'onlineViewersPeak'} step={10} handleUpdate={handleOnlineViewersPeakRange} />
                        </div>
                     </div>
                     <p className="m-0 mr-3"><strong>{t('product_research.product_details.start_time')}</strong></p>
                  </div>
               </div>
            </div>

            <Table responsive className="w-100">
               <div id="example_wrapper" className="dataTables_wrapper">
                  <InfiniteScrollWrapper
                     isInfiniteScrollOn={true}
                     lengthData={items.length}
                     functionNext={functionNext}
                     hasMore={hasMore}
                  >
                     <table id="example" className="display w-100 dataTable">
                        <thead>
                           <tr role="row">
                              {tableHead.map((value, index) => (
                                 <th key={index} className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort ? sortClass : '')} onClick={(e) => { value.sort && handleSort(value.sort, sorting.sort == value.sort ? sortType : 'DESC') }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                              ))}
                           </tr>
                        </thead>
                        <tbody>
                           {items.map((value, index) => (
                              <tr key={index}>
                                 <td className="text-right">{index + 1}</td>
                                 <td>
                                    <div className="d-flex">
                                       <div className="prd-img mr-3">
                                          <OverlayTrigger
                                             key="right"
                                             trigger={["hover", "focus"]}
                                             placement="right"
                                             rootClose
                                             overlay={
                                                <Popover>
                                                   <Popover.Body>
                                                      {value.room_cover && <img src={"https://t-img.picturehaven.net/product/" + value.room_cover + "?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={500} />}
                                                   </Popover.Body>
                                                </Popover>
                                             }
                                          >
                                             <Button variant="link" className='p-0' size="xs">
                                                {value.room_cover && <img src={"https://t-img.picturehaven.net/product/" + value.room_cover + "?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={80} />}
                                             </Button>
                                          </OverlayTrigger>
                                       </div>
                                       <div className="prd-title">
                                          <OverlayTrigger overlay={<Tooltip>{value.title}</Tooltip>}>
                                             <p className='mb-2'>{value.title.length > 40 ? value.title.substring(0, 40) + '...' : value.title}</p>
                                          </OverlayTrigger>
                                          <p>
                                             Duration: <ConvertMillisecond ms={value.live_time} />
                                          </p>
                                          <div className="d-flex align-items-center">
                                             <div className="mr-2">
                                                <OverlayTrigger
                                                   key="right"
                                                   trigger={["hover", "focus"]}
                                                   placement="right"
                                                   rootClose
                                                   overlay={
                                                      <Popover>
                                                         <Popover.Body>
                                                            {value.room_cover && <img src={"https://t-img.picturehaven.net/product/" + value.room_cover + "?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={500} />}
                                                         </Popover.Body>
                                                      </Popover>
                                                   }
                                                >
                                                   <Button variant="link" className='p-0' size="xs">
                                                      {value.room_cover && <img src={"https://t-img.picturehaven.net/product/" + value.room_cover + "?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={20} />}
                                                   </Button>
                                                </OverlayTrigger>
                                             </div>
                                             <div>
                                                {value.nickname || ''}
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </td>
                                 <td className="text-right">
                                    {value.total_sales_usd || ''}<br />
                                    <span className="text-muted">({value.total_sales_str || '-'})</span>
                                 </td>
                                 <td className="text-right"><Conversion value={value.sold_count || 0} /></td>
                                 <td className="text-right">{value.sales_conversion_rate ? value.sales_conversion_rate + '%' : '-'}</td>
                                 <td className="text-right"><Conversion value={value.total_user || 0} /></td>
                                 <td className="text-right"><Conversion value={value.max_user_count || 0} /></td>
                                 <td className="text-right"><Conversion value={value.avg_user_count || 0} /></td>
                                 <td className="text-right"><Conversion value={value.product_count || 0} /></td>
                                 <td className="text-right">{value.live_start_time ? new Date(value.live_start_time).toISOString().split('T')[0] : '-'}</td>
                              </tr>
                           ))}
                        </tbody >
                     </table >
                  </InfiniteScrollWrapper >
               </div>
            </Table>
         </Card.Body>
      </Card>
   );
});

export default RelevantLivetab;