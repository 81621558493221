import React, { createContext, useReducer } from 'react';
import reducer from './reducer';
import { Button, Modal, Spinner } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { switchPlanType } from './config';
const intialState = {
  modal_show: false,
  loaderShow: false,
  keyword: '',
  ip: '',
  selectedPlanType: switchPlanType[0]
};

const GeneralLayout = ({ children }) => {

  const { pathname } = window.location;
  ReactGA.send({ hitType: "pageview", page: pathname });

  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, intialState);
  if (state && state.ip == '') {
    setTimeout(async () => {
      let ipAddress = await fetch("https://geolocation-db.com/json/").then(response => { return response.json() }).catch(err => console.error(err.message));
      if (ipAddress) {
        dispatch({ type: 'user_ip', response: ipAddress.IPv4 });
        localStorage.setItem('ip_address', ipAddress.IPv4);
      };
    }, 10000);
  };
  return (
    <GeneralContext.Provider value={{ state, dispatch }} >
      {children}
      <Modal
        show={state.modal_show || false}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>{t('master_layout.upgrade_your_account')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <i className="fa fa-times display-1 text-danger" aria-hidden="true"></i>
          <h2>{t('master_layout.your_plan_is_expired')}</h2>
          <h4 className="mb-3">{t('master_layout.please_upgrade_your_plan')}</h4>
          <Button variant="primary" className="mx-2 mb-3" size="sm" onClick={() => { window.location.href = '/profile?tab=subscription'; }}>{t('master_layout.upgrade_now')}</Button>
          <p className="small">{t('master_layout.if_you_have_any_questions')}</p>
        </Modal.Body>
      </Modal>
      {state.loaderShow && <div className="loader-wrapper-2">
        <Spinner animation="grow" variant="primary" />
      </div>}
    </GeneralContext.Provider>
  )
}

export const GeneralContext = createContext(intialState);

export default GeneralLayout;
