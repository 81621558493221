import React from "react";
import { Card, Button } from "react-bootstrap";

const ProfitLanding = () => {
   return (
      <>
         <div className="row">
            <div className="col-lg-7">
               <Card>
                  <Card.Body>
                     <p className='mb-2 lead'>Profits Overview</p>
                     <h1>
                        Your Financial<br />
                        Base of Operations
                     </h1>
                     <p>View Sales, PPC and Inventory data in one place for your TikTok Stores while also manage the store through our store manager!</p>
                     <ul className="list-icons mb-4">
                        <li>
                           <span className="align-middle mr-2">
                              <i className="fa fa-check text-info"></i>
                           </span>
                           See data from any period of time to make decisions
                        </li>
                        <li>
                           <span className="align-middle mr-2">
                              <i className="fa fa-check text-info"></i>
                           </span>
                           Analyse your PPC Campaigns in one place
                        </li>
                        <li>
                           <span className="align-middle mr-2">
                              <i className="fa fa-check text-info"></i>
                           </span>
                           Get an insight on inventory and reorder levels
                        </li>
                        <li>
                           <span className="align-middle mr-2">
                              <i className="fa fa-check text-info"></i>
                           </span>
                           Access to our powerful Store Manager Tool
                        </li>
                        <li>
                           <span className="align-middle mr-2">
                              <i className="fa fa-check text-info"></i>
                           </span>
                           Add operational expenses to view your actual profits
                        </li>
                     </ul>
                     <Button variant="primary" size="sm" onClick={() => window.location.href = '/tiktok-store/store-manager'}>
                        <i className="fa fa-sign-in mr-2" aria-hidden="true"></i>
                        Connect Tiktok Store
                     </Button>
                  </Card.Body>
               </Card>
            </div>
            <div className="col-lg-5">
               <Card className="bg-light">
                  <Card.Body>
                     {/* <video className="w-100 mb-4" controls loop>
                        <source
                           src={myVideo}
                           type="video/mp4"
                           allowFullScreen
                        />
                     </video> */}
                     {/* <Button variant="outline-dark" size="sm">Watch the Video</Button> */}
                  </Card.Body>
               </Card>
            </div>
         </div>
         <div className="row mb-5 justify-content-center">
            <div className="col-lg-7 text-center">
               <h2>Your Data is Secure!</h2>
               <p>
                  <strong>We take extreme precaution while handling data. We have appropriate
                     checks in place to make sure that your data is private and secure.</strong>
               </p>
               <div className="row">
                  <div className="col-lg-4 text-center">
                     <Card>
                        <Card.Body>
                           <i className="fa fa-shield display-4 text-primary" aria-hidden="true"></i>
                           <p>
                              Your data is private and secure in our servers
                           </p>
                        </Card.Body>
                     </Card>
                  </div>
                  <div className="col-lg-4 text-center">
                     <Card>
                        <Card.Body>
                           <i className="fa fa-file-archive-o display-4 text-primary" aria-hidden="true"></i>
                           <p>
                              We will never share or sell your personal information to anyone
                           </p>
                        </Card.Body>
                     </Card>
                  </div>
                  <div className="col-lg-4 text-center">
                     <Card>
                        <Card.Body>
                           <i className="fa fa-exchange display-4 text-primary" aria-hidden="true"></i>
                           <p>
                              We never use your data to compete with you in anyway
                           </p>
                        </Card.Body>
                     </Card>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default ProfitLanding;