export const isEmpty = (value) => {
    if (value === null || value === undefined) {
        return true;
    } else if (typeof value === "string" && value.trim() === "") {
        return true;
    } else if (Array.isArray(value) && value.length === 0) {
        return true;
    } else if (typeof value === "object" && Object.keys(value).length === 0) {
        return true;
    } else {
        return false;
    }
};