import React, { Fragment, useState, useRef, useContext } from "react";
import Axios from "../plugins/axios.js";
import { Card, Button, Modal } from "react-bootstrap";
import StripeCard from "../components/StripeCard/Stripecard";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { GeneralContext } from "../../GeneralLayout.js";
import LogoImg from "../../../src/images/logo.svg";
import FooterSlick from "./components/FooterSlick";
import PlanTable from "../components/CommonComponents/PlanTable.js";

//	Load Stripe Key from .env
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const trialEndDate = moment(new Date(new Date().setDate(new Date().getDate() + 7))).format('MMMM DD, YYYY')

const Preregister = () => {
	const stripeRef = useRef(null);
	const addressRef = useRef(null);
	const { dispatch } = useContext(GeneralContext);
	const { t } = useTranslation();
	const language = localStorage.getItem("language") || "en"
	const [goSteps, setGoSteps] = useState(1);
	const [sellerMessage, setSellerMessage] = useState('');
	const [existingSeller, setExistingSeller] = useState(false);
	const [notExistingSeller, setNotExistingSeller] = useState(false);
	const [fullNameMessage, setFullNameMessage] = useState('');
	const [fullName, setFullName] = useState('');
	const [, setValidEmail] = useState(false);
	const [emailMessage, setEmailMessage] = useState('');
	const [email, setEmail] = useState('');
	const [passwordMessage, setPasswordMessage] = useState('');
	const [password, setPassword] = useState('');
	const [planSelected, setPlanSelected] = useState('standard_annual');
	const [phoneMessage, setPhoneMessage] = useState('');
	const [phone, setPhone] = useState('');
	const [addressMessage, setAddressMessage] = useState('');
	const [stripeMessage, setStripeMessage] = useState('');
	const [address, setAddress] = useState(null);
	const [termsAgreed, setTermsAgreed] = useState(false);
	const [termsMessage, setTermsMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [show, setShow] = useState(false);
	const [coupon, setCoupon] = useState({
		coupon: '',
		charges_text: '',
		charges_label_text: '',
		errorMsg: '',
	});
	const [myPlan, setMyPlan] = useState({
		name: 'Standard Annually',
		price: process.env.REACT_APP_SUBSCRIPTION_PLAN_ANNUAL_PRICE,
		tenure: 'Annually',
	})

	const handleNextButtonClick = async () => {
		if (goSteps === 0) {
			if (notExistingSeller || existingSeller) {
				setGoSteps(1);
			} else {
				setSellerMessage(t('registration.step_1.please_select_one'));
			};
		} else if (goSteps === 1) {
			if (!fullName || !email || !password) {
				if (!fullName) {
					setFullNameMessage(t('registration.step_1.long_text_4'));
				};
				if (!email) {
					setValidEmail(false);
					setEmailMessage(t('registration.step_1.long_text_2'));
				}
				if (!password) {
					setPasswordMessage(t('registration.step_1.long_text_3'));
				};
			} else {
				await checkEmail();
			};
		} else if (goSteps === 2) {
			setGoSteps(3);
		} else if (goSteps === 3) {
			const phoneRegex = /^(?:[0-9\-\\(\\)\\/.]\s?){6,15}[0-9]{1}$/;
			if (!phone || !phoneRegex.test(phone) || !address || !termsAgreed) {
				if (!phone) {
					setPhoneMessage(t('registration.step_1.phone_number_is_required'));
				};
				if (!phoneRegex.test(phone)) {
					setPhoneMessage(t('registration.step_3.valid_phone'));
				};
				if (!address) {
					setAddressMessage(t('registration.step_1.address_is_required'));
				};
				if (!termsAgreed) {
					setTermsMessage(t('registration.step_1.accept_terms_and_conditions'));
				};
			} else {
				setStripeMessage('');
				setErrorMessage('');
				setAddress(address);
				await registerHandler();
			};
		};
	};

	const handlePrevButtonClick = async () => {
		if (goSteps === 1) {
			setGoSteps(0);
		} else if (goSteps === 2) {
			setGoSteps(1);
		} else if (goSteps === 3) {
			setGoSteps(2);
		};
	};

	const sellerChecked = (option) => {
		if (option === 0) {
			setNotExistingSeller(true);
			setExistingSeller(false);
		} else if (option === 1) {
			setNotExistingSeller(false);
			setExistingSeller(true);
		};
		setSellerMessage('')
	};

	const checkEmail = async () => {
		// eslint-disable-next-line no-useless-escape
		const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (emailRegex.test(email)) {
			try {
				dispatch({ type: 'loader_show', response: true });
				const response = await Axios.post('/api/userexists', {
					email: email.toLocaleLowerCase(),
				}).catch((e) => {
					setValidEmail(false);
					setEmailMessage('Server Error, Try Again Later');
					console.error('Axios-Catch: onBlurEmail ' + e.message);
				});

				if (response && response.status === 200) {
					if (response.data && response.data.statusCode === 200) {
						if (response.data.validEmail) {
							setValidEmail(true);
							setGoSteps(2);
						} else {
							setEmailMessage(t(`registration.step_1.${response.data.message}`));
							setValidEmail(false);
						};
					} else {
						setEmailMessage(response.data.message);
						setValidEmail(false);
					};
				} else {
					setEmailMessage('Server Error, Try Again Later');
					setValidEmail(false);
				};
			} catch (error) {
				setValidEmail(false);
				setEmailMessage('Server Error, Try Again Later');
				console.error('Catch: onBlurEmail ' + error.message);
			};
		} else {
			setValidEmail(false);
			setEmailMessage(t('registration.step_1.long_text_5'));
		};
		dispatch({ type: 'loader_hide', response: true });
	};

	const onBlurEmail = () => {
		setValidEmail(false);
		if (!email) {
			setEmailMessage(t('registration.step_1.email_address_is_required'));
		};
	};

	const checkPhoneNumber = (value) => {
		setPhone(value);
		const phoneRegex = /^(?:[0-9\-\\(\\)\\/.]\s?){6,15}[0-9]{1}$/;
		if (!phone) {
			setPhoneMessage(t('registration.step_3.long_text_10'));
		} else {
			if (phoneRegex.test(phone)) {
				setPhoneMessage('');
			} else {
				setPhoneMessage(t('registration.step_3.valid_phone'));
			};
		};
	};

	const handlePlanSelected = (plan) => {
		const selectedPlan = plan.slug;
		setMyPlan(plan);
		setPlanSelected(selectedPlan);
		handleNextButtonClick();
	};

	const onSelectAddress = async () => {
		const autocomplete = new window.google.maps.places.Autocomplete(addressRef.current);

		window.google.maps.event.addListener(autocomplete, 'place_changed', async function () {
			const place = await autocomplete.getPlace();
			if (place.address_components) {
				let addressObj = {
					address: place.formatted_address,
					postal: place.address_components[place.address_components.length - 1]?.long_name,
					country: place.address_components[place.address_components.length - 2]?.long_name,
					state: place.address_components[place.address_components.length - 3]?.long_name,
					city: place.address_components[place.address_components.length - 4]?.long_name,
				};
				setAddress(addressObj)
			};
		});
	};

	//	Request to Stripe
	const registerHandler = async () => {
		try {
			dispatch({ type: 'loader_show', response: true });

			let stripeResponse = await stripeRef.current.stripePaymentMethod(fullName, email);

			if ((stripeResponse.statusCode === 200 && stripeResponse.data && stripeResponse.data.id) || (planSelected === 'free_plan')) {
				try {
					let obj = {
						full_name: fullName,
						email: email.toLocaleLowerCase(),
						mobileNumber: phone,
						password: password,
						language: language,
						selectedPlan: planSelected,
						existing_seller: existingSeller ? 1 : 0,
						paymentMethodId: stripeResponse?.data?.id || '',
						address_details: address,
						coupon: coupon.selectedCoupon || '',
					};

					let response = await Axios.post("/api/register", obj).catch((e) => {
						console.error('Axios-Catch: registerHandler ' + e.message);
					});

					if (response && response.status === 200) {
						if (response.data && response.data.statusCode === 200 && response.data.data) {
							localStorage.setItem('token', response.data.data.authToken);
							if (!localStorage.getItem('language')) {
								localStorage.setItem('language', "en");
							}
							localStorage.setItem('user', JSON.stringify(response.data.data.user));
							window.location.href = '/dashboard';
						} else {
							setErrorMessage(response.data.message);
						};
					} else {
						setErrorMessage('Server Error! Please Try Again Later');
					};
				} catch (error) {
					console.error('Catch: Register ' + error.message);
				};
			} else {
				setStripeMessage(stripeResponse.message);
				console.error('Stripe-Error: ' + stripeResponse.message);
			};
		} catch (error) {
			console.error('Catch: Register ' + error.message);
		};
		dispatch({ type: 'loader_hide', response: true });
	};

	const promoCheck = async () => {
		try {
			let errorMsg = '', selectedCoupon = '', discount = 0, chargesText = true, chargesLabelText = '';
			if (coupon.coupon != '') {
				dispatch({ type: 'loader_show', response: true });
				const response = await Axios.post('/api/promo-code/check', {
					coupon: coupon.coupon,
					selected_plan: planSelected
				}).catch(e => console.error('Axios-Catch: ' + e.message));
				if (response && response.status === 200) {
					if (response.data.result) {
						let coupon = response.data.result;
						selectedCoupon = coupon.name;
						chargesText = coupon.charges_text
						chargesLabelText = coupon.charges_label_text

						if (coupon.type == 'percentage') {
							discount = parseFloat(((myPlan.price * coupon.amount_or_percentage) / 100).toFixed(2));
						} else {
							discount = parseFloat(coupon.amount_or_percentage);
						}
					} else {
						errorMsg = response.data.message || 'Invalid coupon';
						setShow(true);
					};
				} else {
					errorMsg = 'Invalid coupon';
					setShow(true);
				};
			}

			setCoupon({
				...coupon,
				selectedCoupon,
				charges_text: chargesText,
				charges_label_text: chargesLabelText,
				errorMsg,
			});
			setMyPlan({
				...myPlan,
				coupon: coupon.coupon,
				'discounted_price': discount > 0 ? parseFloat((myPlan.price - discount).toFixed(2)) : 0,
			});
		} catch (error) {
			setMyPlan({
				...myPlan,
				'discounted_price': 0,
			});
		};
		dispatch({ type: 'loader_hide', response: true });
	};

	return (
		<Fragment>
			<div className="container">
				<div className="row justify-content-center align-items-center h-80 py-5">
					<div className="col-xl-12 col-xxl-12">
						<div className="form-wizard">
							{goSteps === 0 && (
								<>
									<div className="row justify-content-center">
										<div className="col-lg-6">
											<section className="text-center">
												<h2 className="mb-4">
													{t('registration.step_0.long_text_1')}
												</h2>
												<div className="radio">
													<label htmlFor="notExistingSeller">
														<input type="radio" name="optradio" id="notExistingSeller" checked={notExistingSeller}
															className="mr-2"
															onChange={() => sellerChecked(0)}
														/>
														{t('registration.step_0.long_text_2')}
													</label>
												</div>
												<div className="radio">
													<label htmlFor="existingSeller">
														<input type="radio" name="optradio" id="existingSeller" checked={existingSeller}
															className="mr-2" onChange={() => sellerChecked(1)}
														/>
														{t('registration.step_0.long_text_3')}
													</label>
												</div>
												{sellerMessage && <div className="text-danger">{sellerMessage}</div>}
											</section>
										</div>
									</div>
									<div className="text-center toolbar toolbar-bottom p-2">
										<button className="btn btn-primary sw-btn-next" onClick={() => { handleNextButtonClick(); }}> {t('registration.step_0.Next')} </button>
									</div>
								</>
							)}
							{goSteps === 1 && (
								<>
									<div onKeyDown={(e) => e.key == 'Enter' ? handleNextButtonClick() : ''}>
										<div className="row justify-content-center">
											<div className="col-lg-4">
												<section className="text-center">
													<a href="/" ><img src={LogoImg} alt="Logo" width={200} className="mb-4" /></a>
													<h2>{t('registration.step_1.long_text_1')}</h2>
													<div className="input-group mb-3">
														<input
															type="text"
															className={`form-control ${fullNameMessage ? 'is-invalid' : ''}`}
															placeholder={t('registration.step_1.full_name')}
															value={fullName}
															onChange={(e) => {
																setFullName(e.target.value); setFullNameMessage('');
															}}
															onBlur={() => !fullName ? setFullNameMessage(t('registration.step_1.long_text_7')) : ''}
															required
														/>
														{fullNameMessage && <div className="invalid-feedback">{fullNameMessage}</div>}
													</div>
													<div className="input-group mb-3">
														<input
															type="email"
															className={`form-control ${emailMessage ? 'is-invalid' : ''}`}
															placeholder={t('registration.step_1.email_address')}
															value={email}
															onChange={(e) => { setEmail(e.target.value); setEmailMessage('') }}
															onBlur={() => onBlurEmail()}
															required
														/>
														{emailMessage && <div className="invalid-feedback">{emailMessage}</div>}
													</div>
													<div className="input-group mb-3">
														<input
															type="password"
															className={`form-control ${passwordMessage ? 'is-invalid' : ''}`}
															placeholder={t('registration.step_1.password')}
															value={password}
															onChange={(e) => { setPassword(e.target.value); setPasswordMessage('') }}
															onBlur={() => !password ? setPasswordMessage(t('registration.step_1.long_text_3')) : ''}
															required
														/>
														{passwordMessage && <div className="invalid-feedback">{passwordMessage}</div>}
													</div>
												</section>
											</div>
										</div>

										<div className="text-center toolbar toolbar-bottom p-2">
											<button className="btn btn-primary sw-btn-next" onClick={() => { handleNextButtonClick() }} >{t('registration.step_1.next')}</button>
										</div>
									</div>
								</>
							)}
							{goSteps === 2 && (
								<>
									<div className="row justify-content-center">
										<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center mb-4">
											<a href="/" ><img src={LogoImg} alt="Logo" width={200} className="mb-4" /></a>
											<h2 className="mb-1">{t('registration.step_2.choose_your_viralytic_plan')}</h2>
											<p className="mb-0">{t('registration.step_2.experience_the_full_potential')}</p>
											<p className="lead text-black">{t('registration.step_2.save_with_annual')}</p>
										</div>
									</div>
									<PlanTable func={handlePlanSelected} text={"Start Now"} showFree />
								</>
							)}
							{goSteps === 3 && (
								<div onKeyDown={(e) => e.key == 'Enter' ? handleNextButtonClick() : ''}>
									{planSelected === 'free_plan'
										?
										<div className="row align-items-start py-5">
											<div className="col-lg-8">
												<h2>{t('registration.step_3.order_summary')} </h2>
												<p><strong>Your plan is 100% free and you will have unlimited access of free plan.</strong></p>
												<hr />
												<p><strong>{t('registration.step_3.long_text_3')}</strong></p>
												<p>No. you won’t be charged until you switch to another plan. After your switch, you’ll be charged accordingly.</p>
												<p><strong>{t('registration.step_3.long_text_6')}</strong></p>
												<p>{t('registration.step_3.long_text_7')} "{t('registration.step_3.long_text_8')}" {t('registration.step_3.long_text_9')}support@viralytic.com</p>
												<div>
													<div className="form-group">
														<input
															className={`form-control ${phoneMessage ? 'is-invalid' : ''}`}
															placeholder={t('registration.step_3.phone_number')}
															value={phone}
															type="number"
															onChange={(e) => { setPhone(e.target.value); setPhoneMessage('') }}
															onBlur={(e) => !phone ? setPhoneMessage(t('registration.step_3.long_text_10')) : checkPhoneNumber(e.target.value)}
															required
														/>
														{phoneMessage && <div className="invalid-feedback">{phoneMessage}</div>}
													</div>
													<div className="form-group" onClick={() => setAddressMessage('')}>
														<input
															ref={addressRef}
															type="text"
															className={`form-control ${addressMessage ? 'is-invalid' : ''}`}
															placeholder={t('registration.step_3.full_address')}
															onChange={() => setAddressMessage('')}
															onBlur={() => !address ? setAddressMessage(t('registration.step_3.long_text_11')) : ''}
															onSelect={() => { onSelectAddress() }}
															required
														/>
														{addressMessage && <div className="invalid-feedback">{addressMessage}</div>}
													</div>
													<hr />
													<div className="form-group" onClick={() => setStripeMessage('')}>
														<Elements stripe={stripePromise}>
															<StripeCard ref={stripeRef} />
														</Elements>
														{stripeMessage && <div className="text-danger">{stripeMessage}</div>}
													</div>
													<div className="border p-3 mb-3">
														<div className="form-check mb-2">
															<input
																type="checkbox"
																checked={termsAgreed}
																className={`form-check-input ${termsMessage ? 'is-invalid' : ''}`}
																id="check1"
																required
																onChange={() => { setTermsAgreed((e) => !e); setTermsMessage('') }}
															/>
															<label className="form-check-label" htmlFor="check1">
																Unlimited access of free plan from today. Easily Cancel through your dashboard or by e-mail support@viralytic.com . I also agree to the Viralytic Terms of Services and Privacy Policy.
															</label>
															{termsMessage && <div className="invalid-feedback">{termsMessage}</div>}
														</div>
														{errorMessage && <div className="text-danger">{errorMessage}</div>}
													</div>
													<div className="">
														<p className="text-right">
															<Button variant="primary" className="mb-2" onClick={() => handleNextButtonClick()}>Start My Free Trial Now! </Button>
														</p>
														<div className="text-right toolbar toolbar-bottom p-2">
															<button type="button" className="btn btn-secondary sw-btn-prev mr-" onClick={() => handlePrevButtonClick()}>{t('registration.step_3.prev')}</button>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-4">
												<h1 className="text-primary">{t('registration.step_3.THANK_YOU')}</h1>
												<h3>{t('registration.step_3.long_text_15')}</h3>
												<p><strong>{t('registration.step_3.long_text_16')}</strong></p>
												<p className="mb-0"><strong>{t('registration.step_3.long_text_17')}</strong></p>
												<p>{t('registration.step_3.long_text_18')}</p>

												<p className="mb-0"><strong>{t('registration.step_3.long_text_19')}</strong></p>
												<p>{t('registration.step_3.long_text_20')}</p>

												<p className="mb-0"><strong>{t('registration.step_3.long_text_21')}</strong></p>
												<p>{t('registration.step_3.long_text_22')}</p>
												<Card className="text-white bg-primary">
													<Card.Body className="mb-0">
														<h4 className="text-white">{t('registration.step_3.long_text_23')}</h4>
														<div className="row">
															<div className="col-8">
																<p>{myPlan.name} ({t('registration.step_3.free_trial')})</p>
															</div>
															<div className="col-4 text-right">
																<p><strong>$0</strong></p>
															</div>
														</div>
														<div className="row">
															<div className="col-12">
																<div className="input-group mb-3">
																	<input
																		type="text"
																		className={`form-control form-control-sm`}
																		placeholder={t('registration.step_3.enter_promo_code')}
																		value={coupon.coupon}
																		onChange={(e) => {
																			setCoupon({ ...coupon, coupon: (e.target.value).trim().toUpperCase() });
																		}}
																		disabled={planSelected === 'free_plan'}
																	/>
																	<div className="input-group-append">
																		<button className="btn btn-secondary btn-xs sw-btn-next" disabled={planSelected === 'free_plan'} onClick={() => { if (planSelected !== 'free_plan') promoCheck(); }}> {t('registration.step_3.apply')} </button>
																	</div>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-8">
																<p>{t('registration.step_3.todays_total')}</p>
															</div>
															<div className="col-4 text-right">
																<p><strong>$0.00</strong></p>
															</div>
														</div>
														<hr />
														<div className="row">
															<div className="col-12">
																<p className="mb-0"><small>Selected Plan: Free Plan Unlimited</small></p>
															</div>
														</div>
													</Card.Body>
												</Card>
												<div id="card-element"></div>
												<div id="card-errors" className="text-danger" role="alert"></div>
											</div>
										</div>
										:
										<div className="row align-items-start py-5">
											<div className="col-lg-8">
												<h2>{t('registration.step_3.order_summary')} </h2>
												<p><strong>{t('registration.step_3.long_text_1')} {trialEndDate}{t('registration.step_3.long_text_2')}</strong></p>
												<hr />
												<p><strong>{t('registration.step_3.long_text_3')}</strong></p>
												<p>{t('registration.step_3.long_text_4')} {trialEndDate}{t('registration.step_3.long_text_5')}
													{(coupon.selectedCoupon === undefined || coupon.selectedCoupon === '' || coupon.charges_text === true) ? <>{t('registration.step_3.then_just')} <strong className={myPlan.discounted_price && myPlan.discounted_price > 0 ? 'line-through' : ''}>${myPlan.price} {myPlan.tenure}</strong><strong>{myPlan.discounted_price && myPlan.discounted_price > 0 ? ` $${myPlan.discounted_price} ${myPlan.tenure}` : ''}</strong></> : <strong>{", " + coupon.charges_label_text}</strong>}.</p>
												<p><strong>{t('registration.step_3.long_text_6')}</strong></p>
												<p>{t('registration.step_3.long_text_7')} "{t('registration.step_3.long_text_8')}" {t('registration.step_3.long_text_9')}support@viralytic.com</p>
												<div>
													<div className="form-group">
														<input
															className={`form-control ${phoneMessage ? 'is-invalid' : ''}`}
															placeholder={t('registration.step_3.phone_number')}
															value={phone}
															type="number"
															onChange={(e) => { setPhone(e.target.value); setPhoneMessage('') }}
															onBlur={(e) => !phone ? setPhoneMessage(t('registration.step_3.long_text_10')) : checkPhoneNumber(e.target.value)}
															required
														/>
														{phoneMessage && <div className="invalid-feedback">{phoneMessage}</div>}
													</div>
													<div className="form-group" onClick={() => setAddressMessage('')}>
														<input
															ref={addressRef}
															type="text"
															className={`form-control ${addressMessage ? 'is-invalid' : ''}`}
															placeholder={t('registration.step_3.full_address')}
															onChange={() => setAddressMessage('')}
															onBlur={() => !address ? setAddressMessage(t('registration.step_3.long_text_11')) : ''}
															onSelect={() => { onSelectAddress() }}
															required
														/>
														{addressMessage && <div className="invalid-feedback">{addressMessage}</div>}
													</div>
													<hr />
													<div className="form-group" onClick={() => setStripeMessage('')}>
														<Elements stripe={stripePromise}>
															<StripeCard ref={stripeRef} />
														</Elements>
														{stripeMessage && <div className="text-danger">{stripeMessage}</div>}
													</div>
													<div className="border p-3 mb-3">
														<div className="form-check mb-2">
															<input
																type="checkbox"
																checked={termsAgreed}
																className={`form-check-input ${termsMessage ? 'is-invalid' : ''}`}
																id="check1"
																required
																onChange={() => { setTermsAgreed((e) => !e); setTermsMessage('') }}
															/>
															<label className="form-check-label" htmlFor="check1">
																{t('registration.step_3.long_text_12')} {(coupon.selectedCoupon === undefined || coupon.selectedCoupon === '' || coupon.charges_text === true) ? <>{t('registration.step_3.then_just')} <strong className={myPlan.discounted_price && myPlan.discounted_price > 0 ? 'line-through' : ''}>${myPlan.price} {myPlan.tenure}</strong><strong>{myPlan.discounted_price && myPlan.discounted_price > 0 ? ` $${myPlan.discounted_price} ${myPlan.tenure}` : ''}</strong></> : <strong>{coupon.charges_label_text}</strong>}{t('registration.step_3.long_text_13')}support@viralytic.com{t('registration.step_3.long_text_25')}
															</label>
															{termsMessage && <div className="invalid-feedback">{termsMessage}</div>}
														</div>
														{errorMessage && <div className="text-danger">{errorMessage}</div>}
													</div>
													<div className="">
														<p className="text-right">
															<Button variant="primary" className="mb-2" onClick={() => handleNextButtonClick()}>{t('registration.step_3.long_text_14')}</Button>
														</p>
														<div className="text-right toolbar toolbar-bottom p-2">
															<button type="button" className="btn btn-secondary sw-btn-prev mr-" onClick={() => handlePrevButtonClick()}>{t('registration.step_3.prev')}</button>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-4">
												<h1 className="text-primary">{t('registration.step_3.THANK_YOU')}</h1>
												<h3>{t('registration.step_3.long_text_15')}</h3>
												<p><strong>{t('registration.step_3.long_text_16')}</strong></p>
												<p className="mb-0"><strong>{t('registration.step_3.long_text_17')}</strong></p>
												<p>{t('registration.step_3.long_text_18')}</p>

												<p className="mb-0"><strong>{t('registration.step_3.long_text_19')}</strong></p>
												<p>{t('registration.step_3.long_text_20')}</p>

												<p className="mb-0"><strong>{t('registration.step_3.long_text_21')}</strong></p>
												<p>{t('registration.step_3.long_text_22')}</p>
												<Card className="text-white bg-primary">
													<Card.Body className="mb-0">
														<h4 className="text-white">{t('registration.step_3.long_text_23')}</h4>
														<div className="row">
															<div className="col-8">
																<p>{myPlan.name} ({t('registration.step_3.free_trial')})</p>
															</div>
															<div className="col-4 text-right">
																<p><strong>$0</strong></p>
															</div>
														</div>
														<div className="row">
															<div className="col-12">
																<div className="input-group mb-3">
																	<input
																		type="text"
																		className={`form-control form-control-sm`}
																		placeholder={t('registration.step_3.enter_promo_code')}
																		value={coupon.coupon}
																		onChange={(e) => {
																			setCoupon({ ...coupon, coupon: (e.target.value).trim().toUpperCase() });
																		}}
																	/>
																	<div className="input-group-append">
																		<button className="btn btn-secondary btn-xs sw-btn-next" onClick={() => { promoCheck(); }}> {t('registration.step_3.apply')} </button>
																	</div>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-8">
																<p>{t('registration.step_3.todays_total')}</p>
															</div>
															<div className="col-4 text-right">
																<p><strong>$0.00</strong></p>
															</div>
														</div>
														<hr />
														<div className="row">
															<div className="col-12">
																<p className="mb-0"><small>{t('registration.step_3.selected_plan')} {myPlan.name} {t('registration.step_3.long_text_24')}
																	{(coupon.selectedCoupon === undefined || coupon.selectedCoupon === '' || coupon.charges_text === true) ? <>{t('registration.step_3.then_just')} <strong className={myPlan.discounted_price && myPlan.discounted_price > 0 ? 'line-through' : ''}>${myPlan.price} {myPlan.tenure}</strong><strong>{myPlan.discounted_price && myPlan.discounted_price > 0 ? ` $${myPlan.discounted_price} ${myPlan.tenure}` : ''}</strong></> : <strong>{", " + coupon.charges_label_text}</strong>}
																</small></p>
															</div>
														</div>
													</Card.Body>
												</Card>
												<div id="card-element"></div>
												<div id="card-errors" className="text-danger" role="alert"></div>
											</div>
										</div>
									}
								</div>
							)}

						</div>
					</div>
				</div>
			</div>
			<FooterSlick />
			<Modal show={show} onHide={() => setShow(false)}>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body className="text-center">
					<span className="bolt-icon d-inline-block mb-4">
						<i className="fa fa-times" aria-hidden="true"></i>
					</span>
					<h3 className="mb-3">{t('registration.modal.long_text_1')}</h3>
					<Button variant="primary" size="sm" className="mb-3" onClick={() => setShow(false)}>
						{t('registration.modal.long_text_2')}
					</Button>
					<p>
						{t('registration.modal.long_text_3')}
					</p>
				</Modal.Body>
			</Modal>
		</Fragment>
	);
};

export default Preregister;
