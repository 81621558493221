import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';

export const GlobalFilter = ({ filter, setFilter }) => {
	const { t } = useTranslation();

	return (
		<div>
			<Form.Control value={filter || ''} onChange={(e) => setFilter(e.target.value)} className="w-auto form-control-sm float-right m-2" placeholder={t('user_management.search')} />
		</div>
	)
} 