import { React } from 'react';
import { Tab, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import HashtageRankingFilters from './HashtageRankingFilter';
import FavoriteHashtage from './FavouriteHashtage';

const Hashtage = () => {
   const { t } = useTranslation();
   const search = useLocation().search;
   const tab = new URLSearchParams(search).get("tab") || 'breakout';

   return (      
      <div className="custom-tab-1">
         <Tab.Container
            defaultActiveKey={tab}
         >
            <Nav as="ul" className="nav-tabs mb-0 bg-white">
               <Nav.Item as="li" key="breakout">
                  <Nav.Link eventKey="breakout" as={Link} to="?tab=breakout"
                  >
                     {t('creator_center.hashtage_ranking.tabs.breakout_hashtage')}
                  </Nav.Link>
               </Nav.Item>
               <Nav.Item as="li" key="popular">
                  <Nav.Link eventKey="popular" as={Link} to="?tab=popular"
                  >
                     {t('creator_center.hashtage_ranking.tabs.popular_hashtage')}
                  </Nav.Link>
               </Nav.Item>
               <Nav.Item as="li" key="favorite">
                  <Nav.Link eventKey="favorite" as={Link} to="?tab=favorite"
                  >
                     {t('common.favorites')}
                  </Nav.Link>
               </Nav.Item>
            </Nav>
            <Tab.Content>
               { tab === 'breakout' && 
               <Tab.Pane
                  eventKey="breakout"
                  key="breakout"
               > 
                  <HashtageRankingFilters defaultCycle={'D3'} tab={tab} sort={27} rankType={'TAG_RISING_V2'} virsionSize={15}/>
               </Tab.Pane> }
               { tab === 'popular' && 
               <Tab.Pane
                  eventKey="popular"
                  key="popular"
               >
                  <HashtageRankingFilters defaultCycle={'D3'} tab={tab} sort={3} rankType={'TAG_RISING_V2'} virsionSize={15}/>
               </Tab.Pane> }             
               { tab === 'favorite' && 
               <Tab.Pane
                  eventKey="favorite"
                  key="favorite"
               >
                  <FavoriteHashtage sort={33} />
               </Tab.Pane> }
            </Tab.Content>
         </Tab.Container>
      </div>
   );
};

export default Hashtage;