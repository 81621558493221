import React from 'react'
import InfiniteScrollWrapper from '../../Products/InfiniteScrollWrapper';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function BreakoutInfluencers({ sortType, sorting, sortClass, handleSort, functionNext, items, favourite, markFavourite, hasMore }) {
   const { t } = useTranslation();


   const tableHead = [
      {
         title: '#',
         className: 'text-right',
      },
      {
         title: t('common.influencers'),
      },
      {
         title: t('influencers.previous_sales'),
         className: 'text-right sorting',
         sort: 34,
      },
      {
         title: t('influencers.current_sales'),
         className: 'text-right sorting',
         sort: 19,
      },
      {
         title: t('influencers.sales_growth_ratio'),
         className: 'text-right sorting',
         sort: 23,
      },
      {
         title: t('influencers.atv'),
         className: 'text-right sorting',
         sort: 20,
      },
      {
         title: t('common.revenue'),
         className: 'text-right sorting',
         sort: 18,
      },
      {
         title:t('common.followers'),
         className: 'text-right sorting',
         sort: 1,
      },
      {
         title: t('common.favorite'),
         className: 'text-right',
      },
   ];

   const countryicon = Object.keys(global?.config?.region).find(key => global?.config?.region[key] === items[0]?.sys_country?.country)

   return (
      <div>
         <InfiniteScrollWrapper
            isInfiniteScrollOn={true}
            lengthData={items.length}
            functionNext={functionNext}
            hasMore={hasMore}
         >
            <table id="example" className="display w-100 dataTable">
               <thead>
                  <tr role="row">
                     {tableHead.map((value, index) => (
                        <th key={index} className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort ? sortClass : '')} onClick={(e) => { value.sort && handleSort(value.sort, sorting.sort == value.sort ? sortType : 'DESC') }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                     ))}
                  </tr>
               </thead>
               <tbody>
                  {items?.map((influencer, index) => (
                     <tr key={index}>
                        <td className='text-right'>{influencer.rank}</td>
                        <td>
                           <div className='d-flex align-items-center'>
                              <div className="prd-img mr-3">
                                 <OverlayTrigger
                                    key="right"
                                    trigger={["hover", "focus"]}
                                    placement="right"
                                    rootClose
                                    overlay={
                                       <Popover>
                                          <Popover.Body>
                                             {influencer.avatar_larger_privatization &&
                                                <img alt="" width={500} src={`https://t-img.picturehaven.net/tikmeta/${influencer.avatar_larger_privatization}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                             }
                                          </Popover.Body>
                                       </Popover>
                                    }
                                 >
                                    <Button variant="link" className='p-0' size="xs">
                                       <Link to={'/influencers/details/' + influencer.id + '/breakout'} target="_blank">
                                          {influencer.avatar_larger_privatization &&
                                             <img alt="" width={70} className="prod-thum-img" src={`https://t-img.picturehaven.net/tikmeta/${influencer.avatar_larger_privatization}?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                          }
                                       </Link>
                                    </Button>
                                 </OverlayTrigger>
                              </div>
                              <div className='prd-title'>
                                 <Link to={'/influencers/details/' + influencer.id + '/breakout'} target="_blank"
                                 >
                                    <p>{influencer?.nickname || '--'}</p>
                                 </Link>
                                 <p className='small'><span className={`fi fi-${countryicon.toLocaleLowerCase()} mr-1`}></span>{influencer?.sys_country?.country || '--'}</p>
                                 {influencer?.categories && influencer?.categories.length != 0 && <div className="mb-1">
                                    <span className="btn btn-outline-dark btn-xs px-2 py-1 mr-1 mb-1">{influencer?.categories[0]?.name}</span> <br />
                                    {influencer?.categories[1]?.name && <><span className="btn btn-outline-dark btn-xs px-2 py-1 mr-1 mb-1">{influencer?.categories[1]?.name}</span> <br /></>}
                                    {influencer?.categories.length > 2 && <OverlayTrigger
                                       key="right"
                                       trigger="click"
                                       placement="bottom"
                                       rootClose
                                       overlay={
                                          <Popover className="variants-tooltip">
                                             <Popover.Body>
                                                <div className="m-2">
                                                {influencer.categories && influencer.categories.slice(2).map((v, i) => (
                                                   <span key={i + 2} className="btn btn-outline-dark btn-xs px-2 py-1 mr-1">{v.name}</span>
                                                ))}
                                                </div>
                                             </Popover.Body>
                                          </Popover>
                                       }
                                    >
                                       <Button variant="outline-dark" size="xs" className='px-2 py-1'>{influencer.categories.length > 1 ? ('+' + (influencer.categories.length - 2)) : ''}  <i className="fa fa-chevron-down" aria-hidden="true"></i></Button>
                                    </OverlayTrigger>}
                                 </div>}

                              </div>
                           </div>
                        </td>
                        <td className='text-right'>
                           {influencer?.last_sold_count ? influencer?.last_sold_count : '--'}
                        </td>
                        <td className='text-right'>{influencer?.estimated_sales_volume ? influencer?.estimated_sales_volume : '--'}</td>
                        <td className='text-right'>{influencer?.sold_count_incr_rate ? `${((influencer?.sold_count_incr_rate * 1000) / 10).toFixed(2)}%` : '--'}</td>
                        <td className='text-right'>
                           <div>
                              {influencer?.estimated_avg_price_usd || '--'} <br />
                              <span className='text-muted'>{influencer?.estimated_avg_price ? `(${influencer?.estimated_avg_price})` : '--'}</span>
                           </div>
                        </td>
                        <td className='text-right'>
                           <div>
                              {influencer?.estimated_sales_amount_usd || '--'}<br />
                              <span className='text-muted'>{influencer?.estimated_sales_amount ? `(${influencer?.estimated_sales_amount})` : '--'}</span>
                           </div>
                        </td>
                        <td className='text-right'>{influencer?.follower_count_str || '--'}</td>
                        <td className="text-right">
                           <div className="d-flex justify-content-end">
                              <div className="mr-1">
                                 {<Button
                                    variant="outline-dark" size="xs"
                                    className="px-2 py-1"
                                    onClick={() => { markFavourite({ influencer_id: influencer.id, nickname: influencer?.nickname }); }}>
                                    <i className={"fa " + (favourite.indexOf(influencer.id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                                 </Button>}
                              </div>
                           </div>
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
         </InfiniteScrollWrapper>
      </div>
   )
}

export default BreakoutInfluencers