import React from 'react'
import { Button, OverlayTrigger, Popover, Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { FormatDate, FormatTime } from '../../../CommonComponents/FormatNumber';
import Conversion from '../../../CommonComponents/Conversion';

function RelevantLiveList({ relevantLivelist, totalCount, handlePageClick }) {
    return (
        <div>
            <div className="card-body">
                <Table responsive className="w-100">
                    <div id="example_wrapper" className="dataTables_wrapper">
                        <table id="example" className="display w-100 dataTable">
                            <thead>
                                <tr role="row">
                                    <th>Status</th>
                                    <th className='text-right'>Start Time</th>
                                    <th className='text-right'>Sales</th>
                                    <th className='text-right'>Revenue</th>
                                    <th className='text-right'>Average viewers</th>
                                    <th className='text-right'>Total viewers</th>
                                    <th className='text-right'>Engagement rate(ER)</th>
                                    <th className='text-right'>Sales Rate</th>
                                    <th className='text-right'>Products</th>
                                    <th>live sales Height</th>
                                </tr>
                            </thead>
                            <tbody>
                                {relevantLivelist?.length?( relevantLivelist?.map((product) => (
                                    <tr>
                                        <td>
                                            <div className='d-flex'>
                                                <div className="prd-img mr-3">
                                                    <OverlayTrigger
                                                        key="right"
                                                        trigger={["hover", "focus"]}
                                                        placement="right"
                                                        rootClose
                                                        overlay={
                                                            <Popover>
                                                                <Popover.Body>
                                                                    {product?.room_cover &&
                                                                        <img alt="" width={500} src={`
                                                                        https://t-img.picturehaven.net/product/${product?.room_cover}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1
                                                                        `} />
                                                                    }
                                                                </Popover.Body>
                                                            </Popover>
                                                        }
                                                    >
                                                        <Button variant="link" className='p-0' size="xs">
                                                            {product?.room_cover &&
                                                                <img alt="" width={70} className="prod-thum-img" src={`https://t-img.picturehaven.net/product/${product?.room_cover}?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                            }
                                                        </Button>
                                                    </OverlayTrigger>
                                                </div>
                                                <div className='prd-title'>
                                                    <p>{product?.title}</p>
                                                    <p className='small'>Duration: <FormatTime milliseconds={product?.live_time} /></p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='text-right'><FormatDate timestamp={product?.live_start_time} /></td>
                                        <td className='text-right'>{product?.sold_count}</td>
                                        <td className='text-right'>
                                            {product?.total_sales_usd_str} <br />
                                                <span className='text-muted'>({product?.total_sales_str})</span>
                                        </td>
                                        <td className='text-right'>{product?.avg_user_count}</td>

                                        <td className='text-right'> <Conversion value={product?.total_user} /></td>
                                        <td className='text-right'>{product?.follower_conversion_rate}%</td>
                                        <td className='text-right'>{product?.conversion_rate}%</td>
                                        <td className='text-right'>{product?.product_count}</td>
                                        <td>
                                            {product?.category_list && product?.category_list.length != 0 && <div className="mb-1">
                                                <span className="btn btn-outline-dark btn-xs px-2 py-1 mr-1 mb-1">{product?.category_list[0]?.category_name}</span> <br />
                                                {product?.category_list[1]?.category_name && <><span className="btn btn-outline-dark btn-xs px-2 py-1 mr-1 mb-1">{product?.category_list[1]?.category_name}</span> <br /></>}
                                                <span className="btn btn-outline-dark btn-xs px-2 py-1 mr-1 mb-1">more +</span> <br />
                                            </div>}
                                        </td>
                                    </tr>
                                ))):'No Data'}
                            </tbody>
                        </table>
                    </div>
                </Table>
                <div className="dataTables_paginate paging_simple_numbers">
                    <ReactPaginate
                        nextLabel="Next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={totalCount / 4}
                        previousLabel="< Previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
        </div>
    )
}

export default RelevantLiveList