import { React, useState, useContext } from 'react';
import { Button, Form, Card, OverlayTrigger, Popover, Image, Modal } from 'react-bootstrap';
import Axios from '../../plugins/axios';
import { InputGroup } from 'react-bootstrap'
import { Link, useLocation } from "react-router-dom";
import { GeneralContext } from '../../../GeneralLayout';
import ProductsresearchImg from "../../../../src/images/Illustration.png";
import RecordingvideoImg from "../../../../src/images/recording-video.png";
import Frame1Img from "../../../../src/images/Frame1.png";

import { DateFormat } from '../CommonComponents/FormatNumber'
import Conversion from '../CommonComponents/Conversion'
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from "react-toastify";
import InfluencersImg from "../../../../src/images/influencers.png";
import Header from "../../pages/components/Header";
import Footer from "../../pages/components/Footer";

const ProductVideoSalesEstimate = () => {
    const { t } = useTranslation();
    const { dispatch } = useContext(GeneralContext);
    const [videoId, setVideoId] = useState('');
    const [details, setDetails] = useState({});
    const [showModel, setShowModel] = useState(false);
    const isUserLoggedIn = localStorage.getItem('user') && localStorage.getItem('token') ? true : false;
    let videoDetailLimit = localStorage.getItem('video-detail') ? JSON.parse(localStorage.getItem('video-detail')) : [];

    const handleSearch = async () => {
        try {
            let id = videoId;
            let isValid = isValidUrl(videoId);
            if (isValid == true) {
                var videoUrl = videoId;
                if (videoUrl.toLowerCase().search('tiktok.com/t/') >= 0) {
                    let result = await getOriginalURL(videoUrl);
                    if (result != '') {
                        videoUrl = result;
                    }
                }
                let url = new URL(videoUrl).pathname;
                if (url.split("/")[3]) {
                    id = url.split("/")[3];
                } else {
                    return toast.error(t('common.invalid_url'), {
                        position: "top-center",
                        autoClose: 3000,
                    });
                }
            }
            if (!isUserLoggedIn && videoDetailLimit.length >= 3) {
                setShowModel(true);
                return;
            }
            if (id != '') {
                const response = await Axios.post('/api/video-detail', { video_id: id })
                if (!isUserLoggedIn) {
                    dispatch({ type: 'subscription_expired', response: response.data.statusCode });
                }
                if (response && response.status === 200 && response.data.data) {
                    setDetails(response?.data?.data);
                    if (!isUserLoggedIn) {
                        videoDetailLimit.push(id);
                        localStorage.setItem('video-detail', JSON.stringify(videoDetailLimit));
                    }
                } else {
                    setDetails({});
                    return toast.error(t('common.no_data_found'), {
                        position: "top-center",
                        autoClose: 3000,
                    });
                }
            }
        } catch (error) {
            console.error(error);
        }
        //const response = await Axios.post("/api/coupon", data);

    };

    const getOriginalURL = async (url) => {
        let originalUrl = url;
        try {
            const response = await Axios.get('/api/unshorten?uri=' + url)
            if (response && response.status === 200 && response.data.data) {
                originalUrl = response.data.data;
            }
        } catch (error) {
            
        }

        return originalUrl;
    }
    const isValidUrl = (string) => {
        try {
            new URL(string);
            return true;
        } catch (err) {
            return false;
        }
    }

    const type = (e) => {
        const arrayOfStrings = e?.split(/,\s*/);
        const name = arrayOfStrings?.map(cat => {
            const matchedCategory = global.config?.chineseCategories?.find(chCat => chCat.namecn === cat || chCat.name === cat);
            return matchedCategory?.name;
        })
        return name?.join(',')
    }

    return (
        <>
            <section id="home" className="hero-area hero-inner-area video-sales-banner">
                <div className="container mb-5">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-12 col-12">
                            <div className="hero-content">
                                <h1 className="mb-0">Find hot-selling products with our TikTok Shop Sales Estimator! 🔥</h1>
                                <p>Join over hundreds of sellers using our TikTok Shop Sales Estimator to predict monthly sales and power your product launches to success!</p>
                                { !isUserLoggedIn && <div className="button mb-2">
                                    <a className="btn btn-primary" href="/pre-register">
                                        Try Now For Free
                                    </a>
                                    Already a user? <a href="/login">Login</a>
                                </div> }

                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 col-12 text-center">
                            <div className="hero-image">
                                <img src={ProductsresearchImg} alt="" width={366} height={366} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-8 col-12">
                            <ToastContainer />
                            <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Card className='vs-card'>
                                        <Card.Body className='p-5'>
                                            <div className='text-end'>
                                                { !isUserLoggedIn && <p className='mb-0'>{videoDetailLimit.length}/3 Free Estimates Used</p> }
                                                <div className='d-flex justify-content-end { !isUserLoggedIn ? "used-vs" : ""}'>
                                                    <div className='active'></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            </div>
                                            <Form.Label><strong>{t('common.product_video_id')}</strong></Form.Label>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <Form.Control size='sm' value={videoId} onChange={(e) => setVideoId((e.target.value).trim())} type="text" placeholder={t('common.product_video_id')} />
                                                <Button onClick={handleSearch} className='ms-2' size='xs'>{t('common.search')}</Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Form.Group>
                            </Form>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                            {details.id &&
                                <Card className='h-auto'>
                                    <Card.Body>
                                        <div className='row'>
                                            <div className='col-lg-1 text-center'>
                                                <div className="prd-img mr-3">
                                                    <a href={`https://www.tiktok.com/@${details.author_unique_id}/video/${details.id}`} target='_blank' rel="noreferrer">
                                                        <img alt="" width={75} height={75} className="prod-thum-img" src={`https://t-img.picturehaven.net/tikmeta/${details?.origin_cover_privatization}?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className='col-lg-11'>
                                                <div className='d-flex align-items-start justify-content-between'>
                                                    <div className='d-flex align-items-center'>
                                                        <h4 className='mb-0 mr-5'>
                                                            {details?.desc ? `${details?.desc.length > 40 ? details?.desc.substring(0, 42) + '...' : details?.desc}` : '--'}
                                                        </h4>
                                                    </div>
                                                    <div className="text-right">
                                                        <p className="mb-0"><small>{t('product_research.product_details.data_shown_below_are')}</small></p>
                                                        <p className="mb-0"><small>{t('product_research.product_details.time_of_first_discovery')}:</small></p>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-4'>
                                                        <div className='mb-2'>
                                                            <p className='mb-0'><strong>{t('product_research.product_details.post_date')}:</strong>
                                                                &nbsp;&nbsp;
                                                                {details?.create_time ? <DateFormat timestamp={details?.create_time} /> : '--'}
                                                                <span></span></p>
                                                            <p className='mb-0'><strong>{t('product_research.product_details.video_duration')}:</strong>{details?.video_duration ? `${details?.video_duration} s` : '--'}<span></span></p>
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <OverlayTrigger
                                                                key="right"
                                                                trigger={["hover", "focus"]}
                                                                placement="right"
                                                                rootClose
                                                                overlay={
                                                                    <Popover>
                                                                        <Popover.Body>
                                                                            {details.author_avatar_larger_privatization && <img src={"https://t-img.picturehaven.net/tikmeta/" + details.author_avatar_larger_privatization + "?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={400} className="prod-thum-img" />}
                                                                        </Popover.Body>
                                                                    </Popover>
                                                                }
                                                            >
                                                                <Button variant="link" className='p-0' size="xs">
                                                                    <Link to={'/influencers/details/' + details.author_id + "/breakout"} target="_blank">
                                                                        {details.author_avatar_larger_privatization && <Image src={"https://t-img.picturehaven.net/tikmeta/" + details.author_avatar_larger_privatization + "?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={60} className="prod-thum-img" roundedCircle />}
                                                                    </Link>
                                                                </Button>
                                                            </OverlayTrigger>
                                                            <div className="prd-title ml-2">
                                                                <Link to={'/influencers/details/' + details.author_id + "/breakout"} target="_blank">
                                                                    <p className="mb-0">{details?.author_nickname}</p>
                                                                </Link>
                                                            </div>

                                                        </div>
                                                        <div className='d-flex flex-wrap mb-3'>
                                                            <p className='mb-0 mr-3'>
                                                                {details?.author_unique_id ? `${details?.author_unique_id.length > 8 ? "@" + details?.author_unique_id.substring(0, 6) + '...' : "@" + details?.author_unique_id}` : '--'}
                                                                <br />
                                                                {t('common.followers')}：{details?.follower_count ? <Conversion value={details?.follower_count} /> : '--'}
                                                                <br />
                                                                {t('common.videos')}：{details?.video_count ? <Conversion value={details?.video_count} /> : '--'}
                                                                <br />
                                                                {t('product_research.product_details.type')}：{details?.author_categories ? type(details?.author_categories) : '--'}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-8'>
                                                        <div className='d-flex flex-wrap justify-content-between'>
                                                            <div className='mr-5 mb-4'>
                                                                <p className='mb-0'><strong>{t('common.revenue')}</strong></p>
                                                                <h3 className='mb-0'>{details?.total_sales_amount_usd ? details?.total_sales_amount_usd : '--'}</h3>

                                                                <p className='mb-0 pt-2'><strong>{t('common.yesterday')}</strong></p>
                                                                <h3 className='mb-0 text-success'>{details?.increase_total_sales_amount_usd ? `+${details?.increase_total_sales_amount_usd}` : '-'}</h3>
                                                            </div>
                                                            <div className='mr-5 mb-4'>
                                                                <p className='mb-0'><strong>{t('product_research.product_details.arv')}</strong></p>
                                                                <h3 className='mb-0'>{details?.avg_price_usd ? details?.avg_price_usd : '--'}</h3>
                                                            </div>
                                                            <div className='mr-5 mb-4'>
                                                                <p className='mb-0'><strong>{t('product_research.product_details.sales_volume')}</strong></p>
                                                                <h3 className='mb-0'>{details?.total_sales_volume ? details?.total_sales_volume : '--'}</h3>
                                                                <p className='mb-0 pt-2'><strong>{t('common.yesterday')}</strong></p>
                                                                <h3
                                                                    className={` ${details?.increase_total_sales_volume > 0 ? 'text-success mb-0' :
                                                                        details?.increase_total_sales_volume < 0 ? 'text-danger mb-0' :
                                                                            'text-muted mb-0'
                                                                        }`}
                                                                >
                                                                    {details?.increase_total_sales_volume > 0 && "+"}
                                                                    {details?.increase_total_sales_volume ? details?.increase_total_sales_volume : '--'}</h3>
                                                            </div>
                                                            <div className='mr-5 mb-4'>
                                                                <p className='mb-0'><strong>{t('common.views')}</strong></p>
                                                                <h3 className='mb-0'>{details?.play_count ? <Conversion value={details?.play_count} /> : '--'} </h3>
                                                            </div>
                                                            <div className='mr-5 mb-4'>
                                                                <p className='mb-0'><strong>{t('product_research.product_details.conversion_rate')}</strong></p>
                                                                <h3 className='mb-0'>{details?.conversion_rate ? `${(details?.conversion_rate * 100).toFixed(2)}%` : '0.00%'}</h3>
                                                                <p className='mb-0 pt-2'><strong>{t('common.yesterday')}</strong></p>
                                                                <p className={` ${(details?.increase_conversion_rate * 100).toFixed(2) > 0 ? 'text-success mb-0' :
                                                                    (details?.increase_conversion_rate * 100).toFixed(2) < 0 ? 'text-danger mb-0' :
                                                                        'text-muted mb-0'
                                                                    }`}
                                                                >
                                                                    {(details?.increase_conversion_rate * 100).toFixed(2) > 0 && "+"}
                                                                    {details?.increase_conversion_rate ? `${(details?.increase_conversion_rate * 100).toFixed(2)}%` : '-'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            }
                        </div>
                    </div>

                </div>
            </section>

            { !isUserLoggedIn && <>
            <div className='bg-black black-sec'>
                <div className="container">
                    <div className='row justify-content-center align-items-center'>
                        <div className='col-lg-7 py-4 text-white'>
                            <h2 className='text-white'>Loving the Sales Estimator?</h2>
                            <p>Sign up to unlock more monthly searches and get all the tools you need to succeed on TikTok Shop! 🚀📊</p>
                            <div className="button mb-2">
                                <a href="/pre-register" className="btn btn-primary">
                                    Get Instant Access for Free
                                </a>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <img src={RecordingvideoImg} alt="" width={241} height={243} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-0 py-5">
                <div className="row align-items-center mt-5">
                    <div className="col-lg-12 col-md-12 col-12 text-center text-bold">
                        <h2>Transform your TikTok Shop with product insights, profit metrics and influencer mastery!</h2>
                    </div>
                </div>
                <div className="row align-items-center mt-2 justify-content-center">
                    <div className="col-lg-4 col-md-6 col-12 p-0">
                        <Card className='mb-0'>
                            <Card.Body className='p-3'>
                                <h3>Get Instant Access to All The Tools You Need To Succeed on Tiktok Shop</h3>
                                <p>Analyse thousands of products with key insights that will help you select the best products for your Tiktok Shop</p>
                                <Button size="sm" className="btn btn-primary py-1">
                                    Explore the Tools Now
                                </Button>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-lg-7 col-md-6 col-12 p-0">
                        <img src={Frame1Img} alt="" width={603} height={320} />
                    </div>
                </div>
            </div>

            <div className='bg-black black-sec py-5'>
                <div className="container mt-0">
                    <div className='row justify-content-center align-items-center'>
                        <div className='col-lg-4 text-white'>
                            <h2 className='text-white'>Ready to go<br /> all the way in?</h2>
                        </div>
                        <div className='col-lg-7 text-white'>
                            <p>From first-time sellers to seasoned e-commerce brands, we’re here to help your business grow and succeed on TikTok Shop!</p>
                            <div className="button">
                                <a href="/pricing" className="btn btn-primary">
                                    Explore our pricing plans
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>}
            {isUserLoggedIn == false && <Footer />}

            <Modal show={showModel} onHide={() => setShowModel(false)} backdrop="static" keyboard={false} centered>
                <Modal.Body>
                    <div className="text-center">
                        <i className="fa fa-times-circle display-1 text-danger" aria-hidden="true"></i>
                        <p>{t('product_research.product_video.sign_up_for_more_search')}</p>
                        <Button variant="primary" size="sm">
                            <Link to="/login" style={{ color: "white" }}><strong>Sign Up</strong></Link>
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ProductVideoSalesEstimate;