import React from "react";
import "../../css/landing/landing.css";
import "../../css/landing/bootstrap.min.css";
import "../../css/landing/glightbox.min.css";
import "../../css/landing/main.css";
import "../../css/landing/tiny-slider.css";
import LazyLoad from 'react-lazy-load';
import ProfitLossImg from "../../../src/images/profit-loss.png";
import ProductResearchImg from "../../../src/images/product-research.png";
import InfluencersImg from "../../../src/images/influencers.png";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useTranslation } from 'react-i18next';

const Home = () => {
   const { t } = useTranslation();   
   return (
      <>
         <Header />

         <section id="home" className="hero-area">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-lg-7 col-md-12 col-12">
                     <div className="hero-content">
                        <h1>{t('home.revolutionize_your_tiktok')}</h1>
                        <p>{t('home.uncover_profitable_products')}</p>
                        <div className="button">
                           <a href="/pre-register" className="btn">{t('home.get_started_for_free')}</a>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-5 col-md-12 col-12">
                     <div className="hero-image">
                        <img src={ProductResearchImg} alt="" width={445} height={392} />
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="our-achievement section py-5 bg-black">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                     <div className="title">
                        <h2>{t('home.welcome_to_viralytic')}</h2>
                        <p className="lead mb-0">{t('home.explore_our_advanced_product')}</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <LazyLoad height={1531}>
            <section id="features" className="features section">
               <div className="container">
                  <div className="row mb-4">
                     <div className="col-12">
                        <div className="section-title">
                           <h2 className="mb-2">{t('home.what_we_offre')}</h2>
                        </div>
                     </div>
                  </div>
                  <div className="row mb-5 align-items-center">
                     <div className="col-lg-5 col-md-6 col-12">
                        <div className="single-feature mt-0">
                           <img src={ProductResearchImg} alt="" width={373} height={329} />
                        </div>
                     </div>
                     <div className="col-lg-7 col-md-6 col-12">
                        <h3>{t('home.product_research')}</h3>
                        <p>{t('home.in_the_dynamic_landscape_of_ecommerce')}</p>
                        <div className="button">
                           <a href="/products-research" className="btn">{t('home.learn_more')}</a>
                        </div>
                     </div>
                  </div>
                  <div className="row mb-5 align-items-center">
                     <div className="col-lg-7 col-md-6 col-12">
                        <h3>{t('home.profit_loss')}</h3>
                        <p>{t('home.navigate_the_financial_terrain')}</p>
                        <div className="button">
                           <a href="/my-tiktok-shop" className="btn">{t('home.learn_more')}</a>
                        </div>
                     </div>
                     <div className="col-lg-5 col-md-6 col-12">
                        <div className="single-feature mt-0">
                           <img src={ProfitLossImg} alt="" width={373} height={325}  />
                        </div>
                     </div>
                  </div>
                  <div className="row align-items-center">
                     <div className="col-lg-5 col-md-6 col-12">
                        <div className="single-feature mt-0">
                           <img src={InfluencersImg} alt="" width={373} height={349} />
                        </div>
                     </div>
                     <div className="col-lg-7 col-md-6 col-12">
                        <h3>{t('home.influencers')}</h3>
                        <p>{t('home.elevate_your_brand')}</p>
                        <div className="button">
                           <a href="/influencers" className="btn">{t('home.learn_more')}</a>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </LazyLoad>         
         <LazyLoad height={258}>
            <section className="section call-action">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-12 col-md-12 col-12">
                        <div className="cta-content">
                           <h2>{t('home.focus_on_tiktok_analytics')}</h2>
                           <div className="button">
                              <a href="/pre-register" className="btn">{t('home.start_free_trial')}</a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </LazyLoad>
         <Footer />
      </>
   );
};

export default Home;