import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LogoImg from "../../../../src/images/logo.svg";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const ResetPassword = () => {
  const { t } = useTranslation();
  const [Language, setLanguage] = useState("")

  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    if (lng === 'en') {
      setLanguage('en')
    } else if (lng === 'es') {
      setLanguage('es')
    }
  };

  useEffect(() => {
    const lng = localStorage.getItem("language");
    if (lng === 'en') {
      setLanguage('en')
    } else if (lng === 'es') {
      setLanguage('es')
    }
  }, [])


  return (
    <header className="la-header navbar-area">
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="/"><img src={LogoImg} alt="Logo" width={160} height={36} /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className='navbar-toggler mobile-menu-btn' />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="">
              <Nav.Link className='nav-item p-0' href="/#">{t('header.free_training')}</Nav.Link>
              <Nav.Link className='nav-item p-0' href="/pricing">{t('header.pricing')}</Nav.Link>
              <Nav.Link className='nav-item p-0' href="/affiliate">{t('header.affiliate')}</Nav.Link>
              <Nav.Link className='nav-item p-0' href="/our-story">{t('header.our_story')}</Nav.Link>
              
            </Nav>
          </Navbar.Collapse>
          <div className="btn-group mr-3 text-nowrap" role="group" aria-label="Basic example">
                <button type="button" className={`btn  btn-xs py-1 px-2 ${Language === 'en' || Language == '' ? 'btn-primary' : 'btn-outline-dark'}`} onClick={() => changeLanguage('en')}><span className="fi fi-us"></span> EN</button>
                <button type="button" className={`btn  btn-xs py-1 px-2 ${Language === 'es' ? 'btn-primary' : 'btn-outline-dark'}`} onClick={() => changeLanguage('es')}><span className="fi fi-es"></span>  ES</button>
              </div>

              <div className="button add-list-button">
                <a href="/login" className="btn-link">{t('header.log_in')}</a>
              </div>
              <div className="button add-list-button">
                <a href="/pre-register" className="btn py-1 px-3">{t('header.get_started_for_free')}</a>
              </div>
        </Container>
      </Navbar>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="nav-inner">
              <nav className="">
                {/* <a className="navbar-brand" href="/">
                  <img src={LogoImg} alt="Logo" />
                </a> */}
                {/* <button className="navbar-toggler mobile-menu-btn" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                  aria-expanded="false" aria-label="Toggle navigation">
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                </button> */}
                <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                  <ul id="nav" className="navbar-nav ms-auto">
                    <li className="nav-item">
                      <a href="/#" className="active"
                        aria-label="Toggle navigation">{t('header.free_training')}</a>
                    </li>
                    <li className="nav-item">
                      <a href="/pricing" className=""
                        aria-label="Toggle navigation">{t('header.pricing')}</a>
                    </li>
                    <li className="nav-item">
                      <a href="/affiliate" className=""
                        aria-label="Toggle navigation">{t('header.affiliate')}</a>
                    </li>
                    <li className="nav-item">
                      <a href="/our-story" aria-label="Toggle navigation">{t('header.our_story')}</a>
                    </li>
                  </ul>
                </div>

                {/* <div className="btn-group mr-3 text-nowrap" role="group" aria-label="Basic example">
                  <button type="button" className={`btn  btn-xs py-1 px-2 ${Language === 'en' || Language == '' ? 'btn-primary' : 'btn-outline-dark'}`}  onClick={() => changeLanguage('en')}><span className="fi fi-us"></span> EN</button>
                  <button type="button" className={`btn  btn-xs py-1 px-2 ${Language === 'es' ? 'btn-primary' : 'btn-outline-dark'}`}  onClick={() => changeLanguage('es')}><span className="fi fi-es"></span>  ES</button>                  
                </div>

                <div className="button add-list-button">
                  <a href="/login" className="btn-link">{t('header.log_in')}</a>
                </div>
                <div className="button add-list-button">
                  <a href="/pre-register" className="btn py-1 px-3">{t('header.get_started_for_free')}</a>
                </div> */}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default ResetPassword;
