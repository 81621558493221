import Axios from "../../../plugins/axios";
import React, { forwardRef, useEffect, useState, useImperativeHandle, useContext } from "react";
import { Button, Card, Table, Popover, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import RangeSlider from "../RangeSlider";
import { Link, useParams } from 'react-router-dom';
import InfiniteScrollWrapper from "../InfiniteScrollWrapper";
import { TikTokEmbed } from 'react-social-media-embed';
import Conversion from "../../CommonComponents/Conversion";
import { Context } from '../../../../MasterLayout';
import { useTranslation } from 'react-i18next';


const RelevantVideostab = forwardRef((props, ref) => {
   const { t } = useTranslation();

   const { dispatch } = useContext(Context);
   const params = useParams();
   const [page, setPage] = useState(0);
   const [onLoad, setOnLoad] = useState(false);
   const [items, setItems] = useState([]);
   const [hasMore, setHasMore] = useState(true);
   const [sorting, setSorting] = useState({ sort: 18, sort_type: 'DESC' });
   const [viewRange, setViewRange] = useState([]);
   const [likeRange, setLikeRange] = useState([]);
   const [shareRange, setShareRange] = useState([]);
   const sortType = sorting.sort_type == 'DESC' ? 'ASC' : 'DESC';
   const sortClass = sorting.sort_type == 'DESC' ? 'sorting-desc' : 'sorting-asc';
   const [contentModal, setContentModal] = useState(false);
   const [tikTokEmbedID, setTikTokEmbedID] = useState('');
   const [favourite, setFavourite] = useState([]);
   const rangeOption = {
      '0-10000': '<10K',
      '10000-100000': '10K-100K',
      '100000-1000000': '100K-1M',
      '1000000-10000000': '1M-10M',
      '10000000-': '10M+',
   };

   const tableHead = [
      {
         title: '#',
         className: 'text-right',
      },
      {
         title: t('common.videos'),
      },
      {
         title: t('common.influencers'),
      },
      {
         title: t('common.revenue'),
         className: 'text-right sorting',
         sort: 18,
      },
      {
         title: t('common.sales'),
         className: 'text-right sorting',
         sort: 19,
      },
      {
         title: t('common.views'),
         className: 'text-right sorting',
         sort: 3,
      },
      {
         title: t('common.likes'),
         className: 'text-right sorting',
         sort: 8,
      },
      {
         title: t('common.comments'),
         className: 'text-right sorting',
         sort: 5,
      },
      {
         title: t('common.shares'),
         className: 'text-right sorting',
         sort: 6,
      },
      {
         title: t('product_research.product_details.post_date'),
         className: 'text-right sorting',
         sort: 26,
      },
      {
         title: t('common.favorite'),
         className: 'text-right',
      },
   ];

   const handleViewRange = async (value) => {
      setViewRange(value);
   };
   const handleLikeRange = async (value) => {
      setLikeRange(value);
   };
   const handleShareRange = async (value) => {
      setShareRange(value);
   };

   useImperativeHandle(ref, () => ({
      async relevantVideosTab() {
         if (onLoad == false) {
            relevantVideos(page);
         }
         setOnLoad(true);
      }

   }));

   useEffect(() => {
      if (onLoad == true) {
         setPage(0);
         relevantVideos(0);
      }
   }, [sorting, viewRange, likeRange, shareRange]);

   const relevantVideos = async (page) => {
      try {
         let data = {
            is_commerce: true,
            sort: sorting.sort,
            page: page,
            sort_type: sorting.sort_type,
            product_id: params.productId,
         }

         if (viewRange.from) {
            data['play_start'] = viewRange.from;
         }
         if (viewRange.to && viewRange.to != '') {
            data['play_end'] = viewRange.to;
         }

         if (likeRange.from) {
            data['digg_start'] = likeRange.from;
         }
         if (likeRange.to && likeRange.to != '') {
            data['digg_end'] = likeRange.to;
         }

         if (shareRange.from) {
            data['share_start'] = shareRange.from;
         }
         if (shareRange.to && shareRange.to != '') {
            data['share_end'] = shareRange.to;
         }

         let response = await Axios.post('/api/relevant-videos', data);
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });
         if (response && response.status === 200) {
            let array = await response.data.favorite ? response.data.favorite.map((value, index) => value.video_id) : favourite;

            if (page == 0) {
               setItems(response.data.data);
               setFavourite(array);
            } else {
               setItems(items.concat(response.data.data));
               setFavourite(favourite.concat(array));
            }
            setHasMore(response.data.data.length === 0 || response.data.data.length < 20 ? false : true);
         }
      } catch (error) {
         console.error('Error: ' + error.message);
      };
   }
   const handleSort = async (sort, sortType) => {
      if (sort != sorting.sort || (sortType && sortType != sorting.sort_type)) {
         setSorting({ sort, sort_type: sortType });
      }
   };
   const functionNext = async () => {
      if (items.length > 0) {
         let number = page + 1;
         setPage(number);
         relevantVideos(number);
      }
   }

   const markFavourite = async (data) => {
      await Axios.post('/api/favorite-product-video', data);
      const index = favourite.indexOf(data.id);
      if (index > -1) {
         favourite.splice(index, 1);
      } else {
         favourite.push(data.id);
      }

      setFavourite(favourite.concat([]));
   };

   return (
      <>
         <Card className='rounded-0'>
            <Card.Body>
               <div className='row'>
                  <div className="col-lg-12">
                     <h3>{t('product_research.product_details.video_list')}</h3>
                  </div>
                  <div className='col-lg-12'>
                     <div className="d-flex align-items-center mb-2">
                        <p className="m-0 mr-3"><strong>{t('common.filters')}</strong></p>
                        <div className="dropdown product-rating-menu">
                           <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown" aria-expanded="false">
                              {t('common.views')}
                           </a>
                           <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                              <RangeSlider min={0} max={10000000000000} defaultMin={0} defaultMax={10000000} rangeOption={rangeOption} rangeOptionName={'views'} step={10} handleUpdate={handleViewRange} />
                           </div>
                        </div>
                        <div className="dropdown product-rating-menu">
                           <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown" aria-expanded="false">
                              {t('common.likes')}
                           </a>
                           <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                              <RangeSlider min={0} max={10000000000000} defaultMin={0} defaultMax={10000000} rangeOption={rangeOption} rangeOptionName={'likes'} step={10} handleUpdate={handleLikeRange} />
                           </div>
                        </div>
                        <div className="dropdown product-rating-menu">
                           <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown" aria-expanded="false">
                              {t('common.shares')}
                           </a>
                           <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                              <RangeSlider min={0} max={10000000000000} defaultMin={0} defaultMax={10000000} rangeOption={rangeOption} rangeOptionName={'shares'} step={10} handleUpdate={handleShareRange} />
                           </div>
                        </div>
                        <p className="m-0 mr-3"><strong> {t('product_research.product_details.post_date')}</strong></p>
                     </div>
                  </div>
               </div>
               <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                     <InfiniteScrollWrapper
                        isInfiniteScrollOn={true}
                        lengthData={items.length}
                        functionNext={functionNext}
                        hasMore={hasMore}
                     >
                        <table id="example" className="display w-100 dataTable">
                           <thead>
                              <tr role="row">
                                 {tableHead.map((value, index) => (
                                    <th key={index} className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort ? sortClass : '')} onClick={(e) => { value.sort && handleSort(value.sort, sorting.sort == value.sort ? sortType : 'DESC') }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                                 ))}
                              </tr>
                           </thead>
                           <tbody>
                              {items.map((value, index) => (
                                 <tr key={index}>
                                    <td className="text-right">{index + 1}</td>
                                    <td>
                                       <div className="d-flex">
                                          <div className="prd-img mr-3">
                                             <Link to={'/products/' + value.id + '/video-details'} target="_blank">
                                                {value.origin_cover_privatization && <img src={'https://t-img.picturehaven.net/tikmeta/' + value.origin_cover_privatization + '?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={80} />}
                                             </Link>
                                             <Button
                                                variant="primary"
                                                className="p-0 video-popup-btn"
                                                onClick={() => { setTikTokEmbedID(value.id); setContentModal(!contentModal); }}
                                             >
                                                <i className="fa fa-play-circle" aria-hidden="true"></i>
                                             </Button>
                                          </div>
                                          <div className="prd-title">
                                             <OverlayTrigger overlay={<Tooltip>{value.desc}</Tooltip>}>
                                                <Link to={'/products/' + value.id + '/video-details'} target="_blank">
                                                   <p className='mb-2'>{value.desc.length > 40 ? value.desc.substring(0, 40) + '...' : value.desc}</p>
                                                </Link>
                                             </OverlayTrigger>
                                             <div className="d-flex align-items-center">
                                                <span className="text-muted">
                                                   Video duration: {value.video_duration || ''}s
                                                </span>
                                             </div>
                                          </div>
                                       </div>
                                    </td>
                                    <td>
                                       <div className="d-flex">
                                          <div className="prd-img mr-3">
                                             <OverlayTrigger
                                                key="right"
                                                trigger={["hover", "focus"]}
                                                placement="right"
                                                rootClose
                                                overlay={
                                                   <Popover>
                                                      <Popover.Body>
                                                         {value.avatar_larger_privatization && <img src={'https://t-img.picturehaven.net/tikmeta/' + value.avatar_larger_privatization + '?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={500} />}
                                                      </Popover.Body>
                                                   </Popover>
                                                }
                                             >
                                                <Button variant="link" className='p-0' size="xs">
                                                   <Link to={'/influencers/details/' + value.author_id + '/breakout'} target="_blank">
                                                      {value.avatar_larger_privatization && <img src={'https://t-img.picturehaven.net/tikmeta/' + value.avatar_larger_privatization + '?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={80} />}
                                                   </Link>
                                                </Button>
                                             </OverlayTrigger>
                                          </div>
                                          <div className="prd-title">
                                             <p className='mb-2'>
                                                <Link to={'/influencers/details/' + value.author_id + '/breakout'} target="_blank">
                                                   {value.author_nickname || '-'}
                                                </Link>
                                             </p>
                                             <div className="d-flex align-items-center">
                                                <span className="text-muted">  {value.sys_country && <span className={value?.sys_country?.country_code.toLowerCase() ? `fi fi-${value?.sys_country?.country_code.toLowerCase()} mr-1` : ''}></span>}{value.sys_country && value.sys_country.country}</span>
                                             </div>
                                             <div className="d-flex align-items-center">
                                                <span className="text-muted">
                                                   Followers: <Conversion value={value.author_follower_count || 0} />
                                                </span>
                                             </div>
                                          </div>
                                       </div>
                                    </td>
                                    <td className="text-right">
                                       {value.total_estimated_sales_amount_usd || ''}<br />
                                       <span className="text-muted">({value.total_estimated_sales_amount_str || '-'})</span>
                                    </td>
                                    <td className="text-right">{value.total_estimated_sales_volume_str || '-'}</td>
                                    <td className="text-right"><Conversion value={value.play_count || 0} /></td>
                                    <td className="text-right"><Conversion value={value.digg_count || 0} /></td>
                                    <td className="text-right"><Conversion value={value.comment_count || 0} /></td>
                                    <td className="text-right"><Conversion value={value.share_count || 0} /></td>
                                    <td className="text-right">{value.create_time ? new Date(value.create_time * 1000).toISOString().split('T')[0] : '-'}</td>
                                    <td className="text-right">
                                       <Button
                                          variant="outline-dark" size="xs"
                                          className="px-2 py-1"
                                          onClick={() => { markFavourite({ id: value.id, country_code: value.sys_country.country_code }); }}>
                                          <i className={"fa " + (favourite.indexOf(value.id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                                       </Button>
                                    </td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>
                     </InfiniteScrollWrapper>
                  </div>
               </Table>
            </Card.Body>
         </Card>
         {/* <!-- Video Modal --> */}
         <Modal Modal className="fade video-popup" show={contentModal} >
            <Modal.Body className='p-0'>
               <div className='d-flex'>
                  <div className='text-center'>
                     <TikTokEmbed url={`https://www.tiktok.com/embed/${tikTokEmbedID}`} width={325} />
                  </div>
                  <div className='px-3'>
                     <div className='d-flex flex-column'>
                        <Button
                           variant="dark"
                           className="mb-3"
                           onClick={() => { setTikTokEmbedID(''); setContentModal(!contentModal); }}
                        >
                           <i className="fa fa-times" aria-hidden="true"></i>
                        </Button>
                        {/* <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-line-chart" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-download" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-file-code-o" aria-hidden="true"></i>
                        </Button> */}
                     </div>
                  </div>
               </div>
            </Modal.Body>
         </Modal>
      </>
   );
});

export default RelevantVideostab;