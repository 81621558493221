import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import logo from '../../images/logo.png';
import Axios from "../plugins/axios.js";
import { useTranslation } from 'react-i18next';
import FooterSlick from "./components/FooterSlick";
import { GeneralContext } from "../../GeneralLayout.js";

const Login = () => {
   const { dispatch } = useContext(GeneralContext);
   const [loginData, setLoginData] = useState({});
   const [emailMessage, setEmailMessage] = useState('');
   const [passwordMessage, setPasswordMessage] = useState('');
   const [errorMessage, setErrorMessage] = useState('');
   const handleBlur = async (e) => {
      const newLoginData = { ...loginData };
      newLoginData[e.target.name] = e.target.value;
      setLoginData(newLoginData);
      setErrorMessage('');
   };
   const { t } = useTranslation();

   const submitHandler = async () => {
      try {
         setErrorMessage('');
         if (!loginData.email || !loginData.password) {
            if (!loginData.email) {
               setEmailMessage(t('login.long_text_1'));
            }
            if (!loginData.password) {
               setPasswordMessage(t('login.long_text_2'));
            };
         } else {
            dispatch({ type: 'loader_show', response: true });
            let response = await Axios.post("/api/login", { ...loginData, email: loginData.email.toLocaleLowerCase() }).catch((e) => {
               console.error('Axios-Catch: onLogin ' + e.message);
               setErrorMessage('Server Error, Try Again Later');
            });

            if (response && response.status === 200) {
               if (response.data && response.data.statusCode === 200 && response.data.data) {
                  localStorage.setItem('token', response.data.data.authToken);
                  localStorage.setItem('user', JSON.stringify(response.data.data.user));
                  window.location.href = '/dashboard';
               } else {
                  setErrorMessage(response?.data ? response.data.message : 'Server Error, Try Again Later');
               };
            } else {
               setErrorMessage(t('login.server_error_try_again_later'));
               console.error(response);
            };
         };
      } catch (error) {
         setErrorMessage('Server Error, Try Again Later');
         console.error('Catch: onLogin ' + error.message);
      };
      dispatch({ type: 'loader_hide', response: true });
   };

   return (
      <>
         <div className="row justify-content-center h-100 align-items-center h-80">
            <div className="col-md-6">
               <div className="row no-gutters">
                  <div className="col-xl-12">
                     <div className="auth-form">
                        <div className="text-center mb-3">
                           <a href="/" ><img src={logo} alt="Viralytic" /></a>
                        </div>
                        <h4 className="text-center mb-4"> {t('login.sign_in_account')}  </h4>
                        <div className="authincation-content p-5" onKeyDown={(e) => e.key == 'Enter' ? submitHandler() : ''}>
                           <form>
                              <div className="form-group">
                                 <label className="mb-1 text-white">  <strong>{t('login.email')}</strong> </label>
                                 <input type="email" className={`form-control ${emailMessage ? 'is-invalid' : ''}`} id="email" name="email" value={loginData.email} onChange={(e) => { handleBlur(e); setEmailMessage('') }} />
                                 {emailMessage && <div className="invalid-feedback">{emailMessage}</div>}
                              </div>
                              <div className="form-group">
                                 <label className="mb-1 text-white "> <strong>{t('login.password')}</strong>  </label>
                                 <input type="password" className={`form-control ${passwordMessage ? 'is-invalid' : ''}`} id="password" name="password" value={loginData.password} onChange={(e) => { handleBlur(e); setPasswordMessage('') }} />
                                 {passwordMessage && <div className="invalid-feedback">{passwordMessage}</div>}
                              </div>
                              <div className="text-center">{errorMessage && <h4 className=" text-danger">{errorMessage}</h4>}</div>
                              <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                 <div className="form-group">
                                    <div className="custom-control custom-checkbox ml-1 ">
                                       <input type="checkbox" className="custom-control-input" id="basic_checkbox_1" />
                                       <label className="custom-control-label text-white" htmlFor="basic_checkbox_1"  >  {t('login.remember_my_preference')} </label>
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <Link className="text-white" to="/forgot-password"  > {t('login.forgot_password')} </Link>
                                 </div>
                              </div>
                              <div className="text-center">
                                 <button type="button" className="btn bg-white text-primary btn-block" onClick={() => submitHandler()} > {t('login.Sign-In')} </button>
                              </div>
                           </form>
                           <div className="new-account mt-3">
                              <p className="text-white"> {t('login.Dont_have_an_account')}  <Link className="text-white" to="/pre-register">  {t("login.Sign-up")}   </Link>  </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div >
         <FooterSlick />
      </>
   );
};

export default Login;