import React from 'react';

const ConvertMillisecond = ({ ms }) => {
   var d, h, m, s;
   s = Math.floor(ms / 1000);
   m = Math.floor(s / 60);
   s = s % 60;
   h = Math.floor(m / 60);
   m = m % 60;
   d = Math.floor(h / 24);
   h = h % 24;

   var pad = function (n) { return n < 10 ? '0' + n : n; };
   var result = (d > 0 ? d + 'd ' : '') + (h > 0 ? pad(h) + 'h ' : '') + (m > 0 ? pad(m) + 'm ' : '') + (s > 0 ? pad(s) + 's ' : '')

   return (
      <>
         {result}
      </ >
   );
};

export default ConvertMillisecond;