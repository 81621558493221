import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { Card, Row, Col, OverlayTrigger, Table, Tooltip, Popover, Button } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Axios from '../../plugins/axios.js';
import Select from "react-select";
import StoreFetch from "./StoreFetch.js";
import ProfitLanding from "./ProfitLanding.js";
import StorePerformance from "./StorePerformance.js";
import NetProfitChart from './NetProfitChart.js';
import UnitSoldChart from './UnitSoldChart.js';
import { Context } from '../../../MasterLayout.js';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OrdersTable from './OrdersTable.js';

const OrdersList = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(Context);
  const [onLoad, setOnLoad] = useState(true);
  const [stores, setStores] = useState([]);
  const [productCount, setProductCount] = useState(0);
  const [sellerShippingPrice, setSellerShippingPrice] = useState(0);
  const [currentStore, setCurrentStore] = useState('');
  const [summaryData, setSummaryData] = useState(null);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [storePerformanceDateRange, setStorePerformanceDateRange] = useState({
    'yesterday': {
      start_date: new Date(new Date().setDate(new Date().getDate() - 1)),
      end_date: new Date()
    },
    'last_7_days': {
      start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
      end_date: new Date()
    },
    'last_30_days': {
      start_date: new Date(new Date().setDate(new Date().getDate() - 30)),
      end_date: new Date()
    }
  });
  const [bestSellerDateRange, setBestSellerDateRange] = useState({ start_date: new Date(new Date().setDate(new Date().getDate() - 30)), end_date: new Date() });

  useEffect(() => {
    setOnLoad(true);
    if (stores.length == 0) {
      getStores()
    } else {
      getOrders(1, bestSellerDateRange);
    };
  }, [currentStore]);

  const getStores = async () => {
    try {
      dispatch({ type: 'loader_show', response: true });
      setOnLoad(true);
      let response = await Axios.get('/api/dashboard/stores').catch((e) => console.error('Axios-Catch: ' + e.message));
      dispatch({ type: 'subscription_expired', response: response.data.statusCode });

      if (response && response.status === 200) {
        if (response.data?.data?.stores.length != 0) {
          response.data.data.stores.map((v) => {
            v.value = v._id;
            v.label = v.name;
            return v;
          });
          setStores(response.data.data.stores);
          let storeIndex = 0;
          if (response.data?.data?.default_store) {
            storeIndex = response.data.data.stores.findIndex(n => n._id == response.data.data.default_store)
          };
          setCurrentStore(response.data.data.stores[storeIndex]);
          setProductCount(response.data?.data?.productCount);
        } else {
          setOnLoad(false);
          dispatch({ type: 'loader_hide', response: true });
        };
      };
    } catch (error) {
      console.error('Error-Catch: ' + error.message);
      dispatch({ type: 'loader_hide', response: true });
    };
  };

  const getOrders = async (currentPage, dateRange) => {
    try {
      setOnLoad(true);
      dispatch({ type: 'loader_show', response: true });
      let response = await Axios.get(`/api/orders?store_id=${currentStore._id}&page=${currentPage}&start_date=${dateRange?.start_date}&end_date=${dateRange?.end_date}`).catch((e) => console.error('Axios-Catch: ' + e.message));
      dispatch({ type: 'subscription_expired', response: response.data.statusCode });

      if (response && response.status === 200) {
        setItems(response.data.data)
        setHasMore(response.data.data >= 10)
      };
    } catch (error) {
      console.error('Error-Catch: ' + error.message);
    };
    setOnLoad(false);
    dispatch({ type: 'loader_hide', response: true });
  };

  const handleApply = (event, picker) => {
    picker.element.val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
    setBestSellerDateRange({ start_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') })
    getOrders(1, { start_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') })
  };

  const functionNext = async () => {
    if (items.length > 0) {
      let number = page + 1;
      setPage(number);
      await getOrders(number, bestSellerDateRange)
    }
  }

  return (
    <>
      {!onLoad &&
        <>
          {stores.length != 0 &&
            <>
              <div className="revenue-section">
                <Row className="align-items-center mb-3">
                  <Col md="6" className="text-center text-sm-left">
                  </Col>
                  <Col md="6" className="">
                    <div className="d-flex align-items-center justify-content-end flex-column flex-sm-row">
                      <div className="dropdown product-rating-menu">
                        <Select
                          closeMenuOnSelect={true}
                          defaultValue={currentStore}
                          options={stores}
                          onChange={(e) => setCurrentStore(e)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              {productCount != 0 &&
                <>
                  <div className="bestsellers-section">
                    <Row className="align-items-center mb-3">
                      <Col md="6" className="text-center text-sm-left">
                        <h4 className="fw-bolder">Today's Orders</h4>
                      </Col>
                      <Col md="6" className="">
                        <div className="d-flex align-items-center justify-content-end flex-column flex-sm-row">
                          <DateRangePicker onApply={handleApply} initialSettings={{
                            startDate: moment(new Date(bestSellerDateRange.start_date)).format('MM/DD/YYYY'), endDate: moment(new Date(bestSellerDateRange.end_date)).format('MM/DD/YYYY'),
                            ranges: {
                              'Today': [moment().toDate(), moment().toDate()],
                              'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                              'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                              'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
                              'This Month': [moment().startOf('month').toDate(), moment().toDate()],
                              'Last Month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                              'Last 3 Month': [moment().subtract(3, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                              'Last 6 Month': [moment().subtract(6, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                            },
                          }}>
                            <input type="text" className="form-control w-auto form-control-sm input-daterange-timepicker pull-right" />
                          </DateRangePicker>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card>
                          <Card.Body className="p-0">
                            <OrdersTable items={items} hasMore={hasMore} functionNext={functionNext} />
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </>
              }
              {productCount == 0 && <StoreFetch />}
            </>
          }
          {stores.length == 0 && <ProfitLanding />}
        </>
      }
    </>
  );
};

export default OrdersList;