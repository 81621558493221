import moment from 'moment';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { Button, ButtonGroup, Card } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { useParams } from 'react-router-dom';
import Axios from '../../../../plugins/axios';
import SalesMatricsTabVideo from './SalesMatricsTabVideo';
import Conversion from '../../../CommonComponents/Conversion';
import SalesAnalysisChart from "./SalesAnalysisChart";
import { Context } from '../../../../../MasterLayout';
import { useTranslation } from 'react-i18next';


const SalesAnalysisVideo = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(Context);
  const [relevantVideosFilter, setrelevantVideosFilterFilter] = useState('7_days');
  const dateRangeRef = useRef();
  const [relevantVideo, setRelevantVideo] = useState();
  const params = useParams();
  const [chartData, setChartData] = useState([]);
  const [page, setpage] = useState(1)


  const [dateFilterOption] = useState({
    '7_days': {
      label: 'Past 7 Days',
      start_day: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0],
      end_day: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
    },
    '15_days': {
      label: 'Past 15 Days',
      start_day: new Date(new Date().setDate(new Date().getDate() - 15)).toISOString().split('T')[0],
      end_day: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
    },
    '30_days': {
      label: 'Past 30 Days',
      start_day: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0],
      end_day: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
    }
  });
  const [dateRange, setDateRange] = useState(dateFilterOption[relevantVideosFilter]);
  const dateFilterOptionHtml = Object.keys(dateFilterOption).map(function (key) {
    return <Button key={key} variant="outline-dark" size="xs" className={(relevantVideosFilter == key ? 'btn-primary' : '')} onClick={() => { setrelevantVideosFilterFilter(key); }}>{dateFilterOption[key].label}</Button>
  });
  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD')
    );
    setDateRange({ start_day: picker.startDate.format('YYYY-MM-DD'), end_day: picker.endDate.format('YYYY-MM-DD') })
    setrelevantVideosFilterFilter('custom');
  };

  const handleCancel = (event, picker) => {
    picker.element.val('');
    setDateRange([]);
  };
  const changeDate = (startDate, endDate) => {
    dateRangeRef.current.setStartDate(moment(new Date(startDate)).format('MM/DD/YYYY'));
    dateRangeRef.current.setEndDate(moment(new Date(endDate)).format('MM/DD/YYYY'));
  };
  const [relevantVideolist, setRelevantVideolist] = useState([]);

  const relevantVideosAPI = async () => {
    try {
      let bodyParams = {};
      if (relevantVideosFilter == 'custom') {
        bodyParams = {
          start_day: dateRange.start_day,
          end_day: dateRange.end_day,
        };
      } else {
        bodyParams = dateFilterOption[relevantVideosFilter];
        setDateRange(bodyParams);
      }
      bodyParams.video_id = params.productId;

      changeDate(bodyParams.start_day, bodyParams.end_day);

      let response = await Axios.post('/api/video-sale-analysis', bodyParams);
      dispatch({ type: 'subscription_expired', response: response.data.statusCode });
      if (response && response.status === 200) {
        let passChartData = [...response.data.data.sales_history];
        setChartData(passChartData);

        let saleshistory = [];
        if (response?.data?.data.sales_history) {
          response?.data?.data.sales_history.reverse();
          saleshistory = response?.data?.data.sales_history;
        }
        setRelevantVideo(response.data.data);
        const docs = [];
        for (let i = 0; i < 5; i++) {
          if (saleshistory[i]) {
            docs.push(saleshistory[i])
          } else { break }
        };
        setRelevantVideolist(docs);
        setpage(1);
      }
    } catch (error) {
      console.error('Error: ' + error.message);
    };
  }
  useEffect(() => {
    relevantVideosAPI();
    setpage(1);
  }, [relevantVideosFilter]);

  const handlePageClick = (event) => {
    setpage(event.selected + 1);
    const startIndex = ((event.selected + 1) - 1) * 5;
    const endIndex = Math.min(startIndex + 5, relevantVideo?.sales_history?.length);
    const docs = [];
    for (let i = startIndex; i < endIndex; i++) {
      if (relevantVideo?.sales_history[i]) {
        docs.push(relevantVideo?.sales_history[i])
      } else { break; }
    };
    setRelevantVideolist(docs)
  };
  return (
    <div>
      <Card>
        <Card.Body>
          <div className="row mb-4">
            <div className="col-lg-5">
              <h3>{t('product_research.product_details.sales_analysis')}</h3>
            </div>
            <div className="col-lg-7 text-right">
              <div className='d-flex justify-content-end align-items-center'>
                <ButtonGroup className='mr-4'>
                  {dateFilterOptionHtml}
                </ButtonGroup>
                <DateRangePicker
                  ref={dateRangeRef}
                  initialSettings={{ startDate: moment(new Date(dateRange.start_day)).format('MM/DD/YYYY'), endDate: moment(new Date(dateRange.end_day)).format('MM/DD/YYYY') }}
                  onApply={handleApply} onCancel={handleCancel} /*onCallback={handleCallback}*/
                >
                  <input type="text" placeholder={'First Seen'} className="form-control form-control-sm input-daterange-timepicker w-auto" value={dateRange.start_day + " - " + dateRange.end_day} />
                </DateRangePicker>
              </div>
            </div>
          </div>
          <div className='d-flex mb-4'>
            <Card className="bg-light mr-3 mb-2">
              <Card.Body className=" mb-0">
                <Card.Text>
                  <p className='mb-0'><strong>{t('product_research.product_details.growth_rate')}</strong></p>
                  <h3>{relevantVideo?.sold_count_cycle_crc ? `${(relevantVideo?.sold_count_cycle_crc * 100).toFixed(2)}%` : '--'}</h3>
                  <p className='d-flex mb-0'>{t('product_research.product_details.than_last_cycle')}
                    &nbsp;
                    <p
                      className={`mb-0 ${relevantVideo?.sold_count_cycle_crc_cycle_crc > 0 ? 'text-success' :
                        relevantVideo?.sold_count_cycle_crc_cycle_crc < 0 ? 'text-danger' :
                          'text-muted'
                        }`}
                    >
                      {relevantVideo?.sold_count_cycle_crc_cycle_crc > 0 ? '+' : ''}
                      {relevantVideo?.sold_count_cycle_crc_cycle_crc ? `${(relevantVideo?.sold_count_cycle_crc_cycle_crc * 100).toFixed(2)}%` : '0.00%'}
                    </p>
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="bg-light mr-3 mb-2">
              <Card.Body className=" mb-0">
                <Card.Text>
                  <p className='mb-0'><strong>{t('product_research.product_details.daily_conversion_rate')}</strong></p>
                  <div className='d-flex'>
                    <h3>{relevantVideo?.conversion_rate ? `${(relevantVideo?.conversion_rate * 100).toFixed(2)}%` : '--'}</h3>
                  </div>
                  <p className='d-flex mb-0'>{t('product_research.product_details.than_last_cycle')}
                    &nbsp;
                    <p
                      className={`mb-0 ${relevantVideo?.conversion_rate_cycle_crc > 0 ? 'text-success' :
                        relevantVideo?.conversion_rate_cycle_crc < 0 ? 'text-danger' :
                          'text-muted'
                        }`}

                    >
                      {(relevantVideo?.conversion_rate_cycle_crc * 100).toFixed(2) > 0 && '+'}
                      {relevantVideo?.conversion_rate_cycle_crc ? `${(relevantVideo?.conversion_rate_cycle_crc * 100).toFixed(2)}%` : '0.00%'}
                    </p>
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="bg-light mr-3 mb-2">
              <Card.Body className=" mb-0">
                <Card.Text>
                  <p className='mb-0'><strong>S{t('product_research.product_details.sales_volume')}</strong></p>
                  <div className='d-flex'>
                    <h3>{relevantVideo?.sold_count ? relevantVideo?.sold_count : '--'}</h3>
                  </div>
                  <p className='d-flex mb-0'>{t('product_research.product_details.than_last_cycle')}
                    &nbsp;
                    <p
                      className={`mb-0 ${relevantVideo?.sold_count_incr > 0 ? 'text-success' :
                        relevantVideo?.sold_count_incr < 0 ? 'text-danger' :
                          'text-muted'
                        }`}
                    >
                      {relevantVideo?.sold_count_incr > 0 ? '+' : ''}
                      {relevantVideo?.sold_count_incr ? <Conversion value={relevantVideo?.sold_count_incr} /> : '--'}
                    </p>
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="bg-light mr-3 mb-2">
              <Card.Body className=" mb-0">
                <Card.Text>
                  <p className='mb-0'><strong>{t('common.revenue')}</strong></p>
                  <div className='d-flex'>
                    <h3>{relevantVideo?.sales_amount_usd_str ? relevantVideo?.sales_amount_usd_str : '--'}</h3>
                    <p>{relevantVideo?.sales_amount_str ? `(${relevantVideo?.sales_amount_str})` : '--'}</p>
                  </div>
                  <p className='d-flex mb-0'>{t('product_research.product_details.than_last_cycle')}
                    &nbsp;
                    <p
                      className={`mb-0 ${relevantVideo?.sales_amount_cycle_crc > 0 ? 'text-success' :
                        relevantVideo?.sales_amount_cycle_crc < 0 ? 'text-danger' :
                          'text-muted'
                        }`}

                    >
                      {relevantVideo?.hasOwnProperty('sales_amount_cycle_crc') ? (relevantVideo?.sold_count_incr > 0 ? '+' : '') + ((relevantVideo?.sales_amount_cycle_crc * 100).toFixed(2)) + '%' : '-'}
                    </p>
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="bg-light mr-3 mb-2">
              <Card.Body className=" mb-0">
                <Card.Text>
                  <p className='mb-0'><strong>{t('product_research.product_details.related_goods')}</strong></p>
                  <h3>{relevantVideo?.related_product_count}</h3>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          {/* GRAPH PART WILL BE DONE */}

          <div className="row mb-4">
            {chartData.length > 0 && <SalesAnalysisChart data={chartData} />}
          </div>

          {/* METRICS */}
          <div className="row mb-4">
            <div className="col-lg-5">
              <h3>{t('product_research.product_details.metrics')}</h3>
            </div>
            <SalesMatricsTabVideo currentpage={page} saleshistory={relevantVideolist} handlePageClick={handlePageClick} totalCount={relevantVideo?.sales_history?.length} />
          </div>

        </Card.Body>
      </Card>
    </div>
  )
}

export default SalesAnalysisVideo

