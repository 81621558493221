import Axios from '../../../plugins/axios';
import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import InfiniteScrollWrapper from "../../Products/InfiniteScrollWrapper";
import { useTranslation } from 'react-i18next';
import Conversion from '../../CommonComponents/Conversion';
import { TikTokEmbed } from 'react-social-media-embed';
import { Sparklines, SparklinesLine } from 'react-sparklines';

const FavoriteHashtage = ({ sort }) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [favourite, setFavourite] = useState([]);
    const [tikTokEmbedID, setTikTokEmbedID] = useState('');
    const [contentModal, setContentModal] = useState(false);

    const tableHead = [
        { title: t('creator_center.hashtage_ranking.table.ranking') },
        { title: t('creator_center.hashtage_ranking.table.hashtage') },
        { title: t('creator_center.hashtage_ranking.table.posts'), className: 'text-left', sort: 2 },
        { title: t('creator_center.hashtage_ranking.table.views'), className: 'text-left', sort: 3 },
        { title: t('creator_center.hashtage_ranking.table.likes'), className: 'text-left', sort: 8 },
        { title: t('creator_center.hashtage_ranking.table.like_rate'), className: 'text-left' },
        { title: t('creator_center.hashtage_ranking.table.views_growth'), className: 'text-left ', sort: 27 },
        { title: t('creator_center.hashtage_ranking.table.related_video_trends'), className: 'text-left' },
        { title: t('creator_center.hashtage_ranking.table.hot_videos'), className: 'text-left' },
        { title: t('creator_center.hashtage_ranking.table.favourite'), className: 'text-left' }
    ];

    const getFavoriteList = async (page) => {
        let params = {
            page: page,
            limit: 20
        }
        let response = await Axios.get('/api/favorite-hashtages', { params });

        let array = await response.data.result ? response.data.result.map((value, index) => value.tag_id) : favourite;
        if (page === 0) {
            setItems(response.data.result);
            setFavourite(array);
        } else {
            setItems(items.concat(response.data.result));
            setFavourite(favourite.concat(array));
        }
        if (response.data.result.length === 0 || response.data.result.length < 20) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
    };

    useEffect(() => {
        getFavoriteList(page);
    }, []);

    const functionNext = async () => {
        if (items.length > 0) {
            let number = page + 1;
            setPage(number);
            getFavoriteList(number)
        }
    }

    const markFavourite = async (data) => {
        try {
            await Axios.post('/api/hashtage/favourite', data);
            const index = favourite.indexOf(data.hashtage_id);
            if (index > -1) {
                favourite.splice(index, 1);
            } else {
                favourite.push(data.hashtage_id);
            }
            setFavourite(favourite.concat([]));
        } catch (error) {
            console.error(error.message)
        }
    };
    function formatLikeRate(likeRate) {
        let rate = likeRate * 100;
        let formattedRate = rate.toFixed(2);
        return formattedRate + '%';
    }

    return (
        <>
            <InfiniteScrollWrapper
                isInfiniteScrollOn={true}
                lengthData={items.length}
                functionNext={functionNext}
                hasMore={hasMore}
            >
                <table id="example" className="display w-100 dataTable">
                    <thead>
                        <tr role="row">
                            {tableHead.map((value, index) => (
                                <th key={index} className={(value.className || '')} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((hashtage, index) => (
                            <tr key={index}>
                                <td><p className="ml-4">{index + 1}</p></td>
                                <td>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <div className="prd-title">
                                            <p className='m-0'>
                                                <OverlayTrigger overlay={<Tooltip>{hashtage.title}</Tooltip>}>
                                                    <Link to={'/hashtage/' + hashtage.id + '/details'}>
                                                        <span className='font-weight-normal text-dark'>{hashtage.title.length > 40 ? hashtage.title.substring(0, 40) + '...' : hashtage.title}</span>
                                                    </Link>
                                                </OverlayTrigger>
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td className="text-left">
                                    {hashtage?.video_count ? <Conversion value={hashtage?.video_count} /> : '--'}
                                </td>
                                <td className="text-left">
                                    {hashtage?.play_count ? <Conversion value={hashtage?.play_count} /> : '--'}
                                </td>
                                <td className="text-left">
                                    {hashtage?.digg_count ? <Conversion value={hashtage?.digg_count} /> : '0'}
                                </td>
                                <td className="text-left">
                                    {hashtage?.digg_count_rate ? formatLikeRate(hashtage?.digg_count_rate) : '0'}
                                </td>
                                <td className="text-left">
                                    {hashtage?.play_count_incr ?
                                        <>
                                            <i className="fa fa-arrow-up text-success mr-1" aria-hidden="true"></i>
                                            <Conversion value={hashtage?.play_count_incr} />
                                        </> : '--'}
                                </td>

                                <td className="text-center ml-2">
                                    <div className='w-50 d-flex justify-content-center'>
                                        <Sparklines
                                            data={hashtage.trends && hashtage.trends.length > 0 ? hashtage.trends.map(value => value.video_count_incr).reverse() : [0, 0]}
                                            width={60}
                                            height={30}
                                        >
                                            <SparklinesLine color="#2f7efe" />
                                        </Sparklines>
                                    </div>
                                </td>
                                <td className="text-left">
                                    <div className="d-flex justify-content-left align-items-left">
                                        {hashtage.hot_videos && hashtage.hot_videos.map(video => (
                                            <div className="prd-img mx-1">
                                                {video.origin_cover_privatization &&
                                                    <img src={"https://t-img.picturehaven.net/tikmeta/" + video.origin_cover_privatization + "?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />
                                                }
                                                <Button variant="primary" className="p-0 video-popup-btn" onClick={() => { setTikTokEmbedID(video.id); setContentModal(!contentModal); }}>
                                                    <i className="fa fa-play-circle" aria-hidden="true"></i>
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                </td>
                                <td className="text-right">
                                    <div className="d-flex justify-content-end">
                                        {<Button
                                            variant="outline-dark" size="xs"
                                            className="px-2 py-1"
                                            onClick={() => { markFavourite({ hashtage_id: hashtage.tag_id, nickname: hashtage?.nickname }) }}>
                                            <i className={"fa " + (favourite.indexOf(hashtage.tag_id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                                        </Button>}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </InfiniteScrollWrapper>
            <Modal className="fade video-popup" show={contentModal}>
                <Modal.Body className='p-0'>
                    <div className='d-flex'>
                        <div className='text-center'>
                            <TikTokEmbed url={`https://www.tiktok.com/embed/${tikTokEmbedID}`} width={325} />
                        </div>
                        <div className='px-3'>
                            <div className='d-flex flex-column'>
                                <Button
                                    variant="dark"
                                    className="mb-3"
                                    onClick={() => { setTikTokEmbedID(''); setContentModal(!contentModal); }}
                                >
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default FavoriteHashtage;