import React from "react";
import { Card, Button } from "react-bootstrap";


const StoreManager = () => {

   function generateRandomString(length) {
      const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let randomString = '';
      for (let i = 0; i < length; i++) {
        randomString += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return randomString;
    }

   const randomstring = generateRandomString(10);
   const authorizationUrl = process.env.REACT_APP_TIK_TOK_STORE_AUTHENTICATION_URL+randomstring;
   
   const handleRedirect = async () => {
      try {
         localStorage.setItem('state', randomstring);
         window.location.href = authorizationUrl;

      } catch (error) {
         console.error('Error connecting to TikTok Shop:', error);
      }
   };

   return (      
      <Card>
         <Card.Body>
            <div className="row justify-content-center">
               <div className="col-lg-4">
                  <div className="text-center">
                     <h2 className="mb-4">Connect To TikTok Store</h2>
                     <p className="mb-4">Visit <span className="badge badge-primary">TikTok Store</span> to Allow Application Access</p>
                     <Button variant="primary" className="mb-1" onClick={handleRedirect}>Connect</Button>
                  </div>
               </div>
            </div>
         </Card.Body>
      </Card>      
   );
};

export default StoreManager;