import React, { useState } from 'react';
import { Button, Card, Modal } from "react-bootstrap";
import InfiniteScrollWrapper from '../../Products/InfiniteScrollWrapper';
import { TikTokEmbed } from 'react-social-media-embed';


const HashtageHotVideos = ({ functionNext, items, hasMore }) => {
    const [contentModal, setContentModal] = useState(false);
    const [tikTokEmbedID, setTikTokEmbedID] = useState('');
    return (
        <>
            <InfiniteScrollWrapper
                isInfiniteScrollOn={true}
                lengthData={items.length}
                functionNext={functionNext}
                hasMore={hasMore}
            >
                <div className="d-flex flex-wrap">
                    {items.map((item, index) => (
                        <Card key={index} style={{ width: '13rem', margin: '10px' }}>
                            <div className='d-flex justify-content-center align-items-center'>
                                <div style={{ width: '100%', objectFit: 'cover' }}>
                                    {item.origin_cover_privatization && <img src={"https://t-img.picturehaven.net/tikmeta/" + item.origin_cover_privatization + "?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" className="prod-thum-img" />}
                                    <Button
                                        variant="primary"
                                        className="p-0 video-popup-btn"
                                        onClick={() => { setTikTokEmbedID(item.id); setContentModal(!contentModal); }}
                                    >
                                        <i className="fa fa-play-circle" aria-hidden="true"></i>
                                    </Button>
                                </div>
                            </div>
                            <div>
                                <span className='mx-1'>{item.play_count}</span>
                                <span className='mx-1'>{item.digg_count}</span>
                                <span className='mx-1'>{item.comment_count}</span>
                            </div>
                            <div className='d-flex justify-content-start align-items-center'>
                                <div style={{ width: '30px' }} >
                                    {item.avatar_larger_privatization && <img src={"https://t-img.picturehaven.net/tikmeta/" + item.avatar_larger_privatization + "?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" className="prod-thum-img rounded-circle" />}
                                </div>
                                {item.author_nickname}
                            </div>
                        </Card>
                    ))}
                </div>
            </InfiniteScrollWrapper>
            <Modal className="fade video-popup" show={contentModal}>
                <Modal.Body className='p-0'>
                    <div className='d-flex'>
                        <div className='text-center'>
                            <TikTokEmbed url={`https://www.tiktok.com/embed/${tikTokEmbedID}`} width={325} />
                        </div>
                        <div className='px-3'>
                            <div className='d-flex flex-column'>
                                <Button
                                    variant="dark"
                                    className="mb-3"
                                    onClick={() => { setTikTokEmbedID(''); setContentModal(!contentModal); }}
                                >
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default HashtageHotVideos;
