import React from 'react'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { DateFormat } from '../../../CommonComponents/FormatNumber';
import Conversion from '../../../CommonComponents/Conversion';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function RelevantVideoList({ setTikTokEmbedID, contentModal, setContentModal, sorting, sortClass, handleSort, relevantVideolist, totalCount, handlePageClick, currentPage }) {
    const { t } = useTranslation();

    const tableHead = [
        {
            title: t('common.videos'),
        },
        {
            title: t('common.sales'),
            className: 'text-right sorting-desc-icon',
            sort: 19
        },
        {
            title: t('common.revenue'),
            className: 'text-right sorting-desc-icon',
            sort: 18
        },
        {
            // title: 'Sales Volume <br /> Explosion',
            title: `${t('influencers.sales_volume')} <br/> ${t('influencers.explosion')}`,
            className: 'text-right ',
        },
        {
            title: `${t('common.sales')} <br/> ${t('influencers.conversion_rate')}`,
            className: 'text-right '
        },
        {
            title: t('common.views'),
            className: 'text-right sorting-desc-icon',
            sort: 3
        },
        {
            title: t('common.likes'),
            className: 'text-right sorting-desc-icon',
            sort: 8
        },
        {
            title: t('influencers.shares'),
            className: 'text-right ',
        },
        {
            title: t('influencers.comments'),
            className: 'text-right ',
        },
        {
            title: t('influencers.post_date'),
            className: 'text-right ',
        },

    ];
    return (
        <div>
            <div className="card-body p-0">
                <Table responsive className="w-100">
                    <div id="example_wrapper" className="dataTables_wrapper">
                        <table id="example" className="display w-100 dataTable">
                            <thead>
                                <tr role="row">
                                    {tableHead.map((value, index) => (
                                        <th key={index} className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort ? sortClass : '')} onClick={(e) => { value.sort && handleSort(value.sort) }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {relevantVideolist.length ? (relevantVideolist?.map((product) => (
                                    <tr>
                                        <td >
                                            <div className="d-flex">
                                                <div className="prd-img mr-3">
                                                <Link to={'/products/' + product.id + '/video-details'} target="_blank">
                                                    {product.origin_cover_privatization && <img src={"https://t-img.picturehaven.net/tikmeta/" + product.origin_cover_privatization + "?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={80} />}
                                                    </Link>
                                                    <Button
                                                        variant="primary"
                                                        className="p-0 video-popup-btn"
                                                        onClick={() => { setTikTokEmbedID(product.id); setContentModal(!contentModal); }}
                                                    >
                                                        <i className="fa fa-play-circle" aria-hidden="true"></i>
                                                    </Button>
                                                </div>
                                                <div className="prd-title">
                                                    <OverlayTrigger overlay={<Tooltip>{product.desc}</Tooltip>}>
                                                        <Link to={'/products/' + product.id + '/video-details'} target="_blank">
                                                            <p className='mb-2'>{product.desc.length > 40 ? product.desc.substring(0, 40) + '...' : product.desc}</p>
                                                        </Link>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>                                            
                                        </td>
                                        <td className='text-right' >{product?.hasOwnProperty('estimated_sales_volume') ?
                                            <Conversion value={product.estimated_sales_volume} />
                                            : '--'}</td>
                                        <td className='text-right'>
                                            {product?.estimated_sales_amount_by_usd_str ? `${product.estimated_sales_amount_by_usd_str}` : '--'} <br />
                                            <span className='text-muted'>{product?.estimated_sales_amount_str ? `(${product?.estimated_sales_amount_str})` : "--"}</span>

                                        </td>
                                        <td className='text-right'>
                                            {product?.hasOwnProperty('sold_count_cycle_crc') ? `${(product?.sold_count_cycle_crc * 100).toFixed(2)}%` : '--'}
                                        </td>
                                        <td className='text-right'>
                                            {product?.hasOwnProperty('conversion_rate') ? `${(product.conversion_rate * 100).toFixed(2)}%` : '--'}
                                        </td>
                                        <td className='text-right' >
                                            {product.play_count > 0 ? '+' : ''}{product.play_count < 0 ? '-' : ''}  {product?.hasOwnProperty('play_count') ?
                                                <Conversion value={product.play_count} />
                                                : '0'}
                                        </td>
                                        <td className='text-right' >
                                            {product?.digg_count > 0 ? '+' : ''}{product?.digg_count < 0 ? '-' : ''}     {product?.hasOwnProperty('digg_count') ? (product.digg_count ?
                                                <Conversion value={product?.digg_count} />
                                                : '0')
                                                : '--'}
                                        </td>
                                        <td className='text-right'>
                                            {product?.share_count > 0 ? '+' : ''}{product?.share_count < 0 ? '-' : ''} {product?.share_count ? <Conversion value={product.share_count} /> : '0'}
                                        </td>
                                        <td className='text-right'>
                                            {product?.hasOwnProperty('comment_count') ? (product.comment_count ? `+${product?.comment_count}` : '0') : '--'}
                                        </td>
                                        <td className='text-right' >{product?.create_time ? <DateFormat timestamp={product?.create_time} /> : '--'}</td>
                                    </tr>
                                ))) : 'No Data'}
                            </tbody>
                        </table>
                    </div>
                </Table>
                <div className="dataTables_paginate paging_simple_numbers">
                    <ReactPaginate
                        nextLabel="Next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={totalCount}
                        previousLabel="< Previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        forcePage={currentPage}
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
        </div>
    )
}

export default RelevantVideoList