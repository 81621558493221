import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import InfluencerFilter from './InfluencerFilter';
import FavoriteInfluencer from './FavoriteInfluencers';
import { useTranslation } from 'react-i18next';


function TopSalesInfluencers() {
    const { t } = useTranslation();

    const search = useLocation().search;
    const tab = new URLSearchParams(search).get('tab')|| 'breakout-Influencers';    
    
    return (
        <div>
            <div className="custom-tab-1">
                <Tab.Container
                    defaultActiveKey={tab}
                >
                    <Nav as="ul" className="nav-tabs mb-0 bg-white">
                        <Nav.Item as="li" key="breakout-Influencers">
                            <Nav.Link eventKey="breakout-Influencers" as={Link} to="?tab=breakout-Influencers"
                            >
                               {t('influencers.tab_name.breakout_influencers')}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" key="live-video-Influencers">
                            <Nav.Link eventKey="live-video-Influencers" as={Link} to="?tab=live-video-Influencers"
                            >
                               {t('influencers.tab_name.top_video_creators')}
                            </Nav.Link>
                        </Nav.Item>

                        {/* <Nav.Item as="li" key="live-stream-Influencers">
                            <Nav.Link eventKey="live-stream-Influencers" as={Link} to="?tab=live-stream-Influencers"
                            >
                                {t('influencers.tab_name.top_live_streamers')}
                            </Nav.Link>
                        </Nav.Item> */}
                        <Nav.Item as="li" key="Favorite-Influencers">
                            <Nav.Link eventKey="Favorite-Influencers" as={Link} to="?tab=Favorite-Influencers"
                            >
                                {t('common.favorite')}
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>

                        {tab == 'breakout-Influencers' &&
                            <Tab.Pane
                                eventKey="breakout-Influencers"
                                key="breakout-Influencers"
                            >
                                <InfluencerFilter defaultCycle={'H24'} tab={tab} />
                            </Tab.Pane>}
                        {tab == 'live-video-Influencers' &&
                            <Tab.Pane
                                eventKey="live-video-Influencers"
                                key="live-video-Influencers"
                            >
                                <InfluencerFilter defaultCycle={'H24'} tab={tab} />
                            </Tab.Pane>}
                        {/* {tab == 'live-stream-Influencers' &&
                            <Tab.Pane
                                eventKey="live-stream-Influencers"
                                key="live-stream-Influencers"
                            >
                                <InfluencerFilter defaultCycle={'H24'} tab={tab} />
                            </Tab.Pane>} */}
                            {tab == 'Favorite-Influencers' &&
                            <Tab.Pane
                                eventKey="Favorite-Influencers"
                                key="Favorite-Influencers"
                            >
                                <FavoriteInfluencer defaultCycle={'H24'} tab={tab} />
                            </Tab.Pane>}
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    )
}

export default TopSalesInfluencers