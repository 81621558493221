/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useContext } from 'react'
import { Card, Button, Modal } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import "react-toastify/dist/ReactToastify.css";
import Axios from '../../../plugins/axios'
import Conversion from '../../CommonComponents/Conversion'
import { Context } from '../../../../MasterLayout';
import { useTranslation } from 'react-i18next';
import SongsAnalysisChart from './SongAlanysisChart';
import HotVideos from './HotVideos';
import { TikTokEmbed } from 'react-social-media-embed';
import { isEmpty } from '../../CommonComponents/isEmpty';


function SongDetail() {
    const { t } = useTranslation();
    const { dispatch } = useContext(Context);
    const customerReviewsRef = useRef();
    const params = useParams()
    const [details, setDetails] = useState();
    const [page, setPage] = useState(0);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [favorite, setFavorite] = useState(false);
    const [contentModal, setContentModal] = useState(false);
    const [tikTokEmbedID, setTikTokEmbedID] = useState('');

    useEffect(() => {
        fetchdata();
        productSearch();
    }, []);
    const functionNext = async () => {
        let number = page + 1;
        setPage(number);
        productSearch(number)
    }

    const productSearch = async (page) => {
        try {
            let data = {
                music_id: params?.musicId,
                page: page
            }
            let response = await Axios.post('/api/music/topVideo', data);
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });
            if (response && response.status === 200 && response.data.data) {
                if (page == 0) {
                    setItems(response.data.data);
                } else {
                    setItems(items.concat(response.data.data));
                }
                if (response.data.data.length === 0 || response.data.data.length < 20) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                }
            }
        } catch (error) {
            console.error('Error: ' + error.message);
        }
    }

    const fetchdata = async () => {
        try {
            const response = await Axios.post('/api/music/detail', { music_id: params?.musicId });
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });
            setFavorite(response?.data?.data?.favorite)
            setDetails(response?.data?.data);
        } catch (error) {
            console.error(error);
        }
    }

    const addFavorite = async () => {
        try {
            const response = await Axios.post('/api/song/favourite', { id: details?.id })
            setFavorite(response.data.favorite)
        } catch (error) {
            console.error(error)
        }

    }




    return (
        <>
            {!isEmpty(details) &&
                <>
                    <Card>
                        <Card.Body>
                            <div className='row'>
                                <div className='col-lg-2 d-flex justify-content-center align-items-center'>
                                    <div className="d-flex align-item-center">
                                        <div className="prd-img mr-3">
                                            {details.cover_large_privatization && <img src={"https://t-img.picturehaven.net/tikmeta/" + details.cover_large_privatization + "?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />}
                                            <Button
                                                variant="primary"
                                                className="p-0 video-popup-btn"
                                                onClick={() => { setTikTokEmbedID(details.id); setContentModal(!contentModal); }}
                                            >
                                                <i className="fa fa-play-circle" aria-hidden="true"></i>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-10'>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <h4 className="mb-0 mr-5">{details?.title}</h4>
                                            <Button onClick={addFavorite} variant="outline-dark" size="xs" className="px-2 py-1 mr-1"><i
                                                className={"fa " + (favorite ? "fa-star text-primary" : "fa-star-o")}
                                            ></i></Button>
                                        </div>
                                        {/* <div className="text-right">
                                            <p className="mb-0"><small>{t('influencers.longtext_1')}</small></p>
                                        </div> */}
                                    </div>
                                    <div className='row'>

                                        <div className='col-lg-5'>
                                            <div className='row mt-4'>


                                                <div className='col-lg-4 mb-3'>
                                                    <p className='mb-0'>{t('creator_center.songs_ranking.songDetail.popular_in_country')}: {details?.top_country_code ? details?.top_country_code : ''}</p>
                                                    <p className='mt-2'>{t('creator_center.songs_ranking.songDetail.music_duration')}: {details?.top_country_code ? details?.duration : ''} s</p>


                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-lg-7'>
                                            <div className='row mb-3'>
                                                <div className='col-lg-4'>
                                                    <p className='mb-0'>{t('creator_center.songs_ranking.songDetail.used_times')}</p>
                                                    <h4 className='mb-0'>{details?.digg_count ? <Conversion value={details?.digg_count} /> : '--'}</h4>
                                                    <p className='mb-0'>{t('creator_center.songs_ranking.songDetail.yesterday')}</p>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <p className='mb-0'>{t('creator_center.songs_ranking.songDetail.views')}</p>
                                                    <h4 className='mb-0'>{details?.digg_count ? <Conversion value={details?.digg_count} /> : '--'}</h4>
                                                    <p className='mb-0'>{t('creator_center.songs_ranking.songDetail.yesterday')}</p>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <p className='mb-0'>{t('creator_center.songs_ranking.songDetail.suka')}</p>
                                                    <h4 className='mb-0'>{details?.digg_count ? <Conversion value={details?.digg_count} /> : '--'}</h4>
                                                    <p className='mb-0'>{t('creator_center.songs_ranking.songDetail.yesterday')}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <SongsAnalysisChart ref={customerReviewsRef} />
                    <div className="col-lg-5">
                        <h3>{t('creator_center.songs_ranking.title.hot_videos')}</h3>
                    </div>
                    <HotVideos functionNext={functionNext} items={items} hasMore={hasMore} />
                    <Modal className="fade video-popup" show={contentModal}>
                        <Modal.Body className='p-0'>
                            <div className='d-flex'>
                                <div className='text-center'>
                                    <TikTokEmbed url={`https://www.tiktok.com/embed/${tikTokEmbedID}`} width={325} />
                                </div>
                                <div className='px-3'>
                                    <div className='d-flex flex-column'>
                                        <Button
                                            variant="dark"
                                            className="mb-3"
                                            onClick={() => { setTikTokEmbedID(''); setContentModal(!contentModal); }}
                                        >
                                            <i className="fa fa-times" aria-hidden="true"></i>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            }
        </>
    )
}

export default SongDetail;