import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { forwardRef, useImperativeHandle } from 'react';

const StripeCard = forwardRef((props, ref) => {
    // stripe items
    const stripe = useStripe();
    const elements = useElements();

    // main function
    useImperativeHandle(ref, () => ({
        async stripePaymentMethod(name, email) {
            return new Promise(async (resolve, reject) => {
                let message, statusCode = 200, result = null;
                try {
                    const { paymentMethod, error } = await stripe?.createPaymentMethod({
                        type: "card",
                        card: elements?.getElement(CardElement),
                        billing_details: {
                            name: name,
                            email: email.toLocaleLowerCase()
                        },
                    }).catch((e) => {
                        statusCode = 400;
                        message = e.message;
                        console.error('Axios-Catch: paymentMethod ' + e.message);
                    });

                    if (error) {
                        statusCode = 400;
                        result = null;
                        message = error.message;
                    } else {
                        result = paymentMethod;
                        message = 'Success';
                    };
                } catch (error) {
                    statusCode = 400;
                    message = error.message;
                    console.error('Catch: paymentMethod ' + error.message);
                };

                let obj = {
                    data: result,
                    statusCode: statusCode,
                    message: message
                };
                resolve(obj);
            });
        },
    }));

    const configCardElement = {
        iconStyle: 'solid',
        style: {
            base: {
                fontSize: '16px'
            }
        },
        hidePostalCode: true
    };

    return (
        <div className="bg-white p-3 grid gap-4 m-auto">
            <CardElement options={configCardElement} />
        </div>
    );
})

export default StripeCard;