import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Table } from 'react-bootstrap'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import Axios from '../../plugins/axios'
import ReactPaginate from 'react-paginate'
import { useTranslation } from 'react-i18next';
import { Context } from '../../../MasterLayout';


function EventLog() {
    const { t } = useTranslation();
    const { dispatch } = useContext(Context);

    const [EventlogList, setEventlogList] = useState([]);
    const [sort, setsort] = useState({ email: 0, module: 0, event_type: 0, value: 0, subscription: 0, country: 0, createdAt: 0, ip: 0 })
    const [SearchEmailValue, setSearchemailValue] = useState('');
    const [SearchValue, setSearchValue] = useState('');
    const [storePerformanceDateRange, setStorePerformanceDateRange] = useState({
        'yesterday': {
            start_date: new Date(new Date().setDate(new Date().getDate() - 1)),
            end_date: new Date()
        },
        'last_7_days': {
            start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
            end_date: new Date()
        },
        'last_30_days': {
            start_date: new Date(new Date().setDate(new Date().getDate() - 30)),
            end_date: new Date()
        }
    });
    const [dateRange, setDateRange] = useState({ start_date: new Date(storePerformanceDateRange['yesterday'].start_date), end_date: new Date(storePerformanceDateRange['yesterday'].end_date) });

    const [moduleList] = useState(global.config?.ModuleLists);

    const [page, setPage] = useState(1);
    const [ModuleValue, setModuleValue] = useState('');
    const [ModuleValueReal, setRealModuleValue] = useState('Select Module');
    const [pageCount, setPageCount] = useState(0);
    const [limit, setlimit] = useState(10);
    const [totalEntries, setTotalEntries] = useState(0);
    const [parameteropen, setparameteropen] = useState(false)
    const [parameterentries, setparameterentries] = useState([])

    function currentSortValue() {
        let soryBy
        let sortValue

        if (sort.email) {
            soryBy = "email"
            sortValue = sort.email
        } else if (sort.module) {
            soryBy = "module"
            sortValue = sort.module
        }
        else if (sort.event_type) {
            soryBy = "event_type"
            sortValue = sort.event_type
        }
        else if (sort.value) {
            soryBy = "value"
            sortValue = sort.value
        }
        else if (sort.subscription) {
            soryBy = "subscription"
            sortValue = sort.subscription
        }
        else if (sort.createdAt) {
            soryBy = "createdAt"
            sortValue = sort.createdAt
        }
        else if (sort.ip) {
            soryBy = "ip"
            sortValue = sort.ip
        }

        return { soryBy, sortValue }
    }

    const fetchdata = async (sortid = 'createdAt', sortascdsc, searchvalue = null) => {
        dispatch({ type: 'loader_show', response: true });
        let response = await Axios.get(`/api/event-log?searchemail=${SearchEmailValue}&page=${page}&limit=${limit}&sortby=${sortid}&sort=${sortascdsc || 1}&filter=${ModuleValue}&filterOf=${ModuleValue === '' ? '' : 'module'}&search=${SearchValue}&startDate=${new Date(storePerformanceDateRange.yesterday.start_date)}&endDate=${new Date(storePerformanceDateRange.yesterday.end_date)}`);
        dispatch({ type: 'subscription_expired', response: response.data.statusCode });

        if (response && response.data && response.data.result) {
            const { results, totalCount } = response?.data?.result
            setEventlogList(results ? results : [])
            setTotalEntries(totalCount ? totalCount : 0);
            setPageCount(Math.ceil(totalCount / limit));
        } else {
            console.error("Failed to fetch data");
        }
        dispatch({ type: 'loader_hide', response: true });
    }

    useEffect(() => {
        const { soryBy, sortValue } = currentSortValue()
        fetchdata(soryBy, sortValue, SearchValue);
    }, [page, limit, ModuleValue, storePerformanceDateRange]);

    //PAGINATION AND LIMIT 
    const handlePageClick = (event) => {
        setPage(event.selected + 1);
    };
    const onClickSetLimit = (e) => {
        if (limit !== e.target.value) {
            setlimit(e.target.value);
            setPage(1);
        }
    };

    const handleSort = (e) => {
        const sortid = e.target.id;
        setPage(1);
        if (sortid === "email") {
            if (sort.email === 1) {
                setsort({ email: -1, module: 0, event_type: 0, value: 0, subscription: 0, country: 0, createdAt: 0, ip: 0 })
            } else {
                setsort({ email: 1, module: 0, event_type: 0, value: 0, subscription: 0, country: 0, createdAt: 0, ip: 0 })
            }
            fetchdata(sortid, sort.email === 1 ? -1 : 1, SearchEmailValue, SearchValue)
        } else if (sortid === "module") {
            if (sort.module === 1) {
                setsort({ email: 0, module: -1, event_type: 0, value: 0, subscription: 0, country: 0, createdAt: 0, ip: 0 })
            } else {
                setsort({ email: 0, module: 1, event_type: 0, value: 0, subscription: 0, country: 0, createdAt: 0, ip: 0 })
            }
            fetchdata(sortid, sort.module === 1 ? -1 : 1, SearchEmailValue, SearchValue)
        } else if (sortid === "event_type") {
            if (sort.event_type === 1) {
                setsort({ email: 0, module: 0, event_type: -1, value: 0, subscription: 0, country: 0, createdAt: 0, ip: 0 })
            } else {
                setsort({
                    email: 0, module: 0, event_type: 1, value: 0, subscription: 0, country: 0, createdAt: 0, ip: 0
                })
            }
            fetchdata(sortid, sort.event_type === 1 ? -1 : 1, SearchEmailValue, SearchValue)
        }
        else if (sortid === "value") {
            if (sort.value === 1) {
                setsort({ email: 0, module: 0, event_type: 0, value: -1, subscription: 0, country: 0, createdAt: 0, ip: 0 })
            } else {
                setsort({
                    email: 0, module: 0, event_type: 0, value: 1, subscription: 0, country: 0, createdAt: 0, ip: 0
                })
            }
            fetchdata(sortid, sort.value === 1 ? -1 : 1, SearchEmailValue, SearchValue)
        }
        else if (sortid === "subscription") {
            if (sort.subscription === 1) {
                setsort({ email: 0, module: 0, event_type: 0, value: 0, subscription: -1, country: 0, createdAt: 0, ip: 0 })
            } else {
                setsort({
                    email: 0, module: 0, event_type: 0, value: 0, subscription: 1, country: 0, createdAt: 0, ip: 0
                })
            }
            fetchdata(sortid, sort.subscription === 1 ? -1 : 1, SearchEmailValue, SearchValue)
        }
        else if (sortid === "country") {
            if (sort.subscription === 1) {
                setsort({ email: 0, module: 0, event_type: 0, value: 0, subscription: 0, country: -1, createdAt: 0, ip: 0 })
            } else {
                setsort({
                    email: 0, module: 0, event_type: 0, value: 0, subscription: 0, country: 1, createdAt: 0, ip: 0
                })
            }
            fetchdata(sortid, sort.country === 1 ? -1 : 1, SearchEmailValue, SearchValue)
        }
        else if (sortid === "createdAt") {
            if (sort.createdAt === 1) {
                setsort({ email: 0, module: 0, event_type: 0, value: 0, subscription: 0, country: 0, createdAt: -1, ip: 0 })
            } else {
                setsort({ email: 0, module: 0, event_type: 0, value: 0, subscription: 0, country: 0, createdAt: 1, ip: 0 })
            }
            fetchdata(sortid, sort.createdAt === 1 ? -1 : 1, SearchEmailValue, SearchValue)
        }
        else if (sortid === "ip") {
            if (sort.ip === 1) {
                setsort({ email: 0, module: 0, event_type: 0, value: 0, subscription: 0, country: 0, createdAt: 0, ip: -1 })
            } else {
                setsort({ email: 0, module: 0, event_type: 0, value: 1, subscription: 0, country: 0, createdAt: 0, ip: 1 })
            }
            fetchdata(sortid, sort.ip === 1 ? -1 : 1, SearchEmailValue, SearchValue)
        }
    }
    const handlsearchEmailvalue = (e) => {
        const value = e.target.value
        setSearchemailValue(value)
    }
    const handlsearchvalue = (e) => {
        const value = e.target.value
        setSearchValue(value)
    }
    const handleEnterKeyPress = async (e) => {
        if (e.key === 'Enter') {
            dispatch({ type: 'loader_show', response: true });
            setPage(1);
            let response = await Axios.get(`/api/event-log?searchemail=${SearchEmailValue}&search=${SearchValue}&filter=${ModuleValue}&filterOf=${ModuleValue === '' ? '' : 'module'}&page=${page}&limit=${limit}&sortby=${'email'}&sort=${1}&startDate=${new Date(storePerformanceDateRange.yesterday.start_date)}&endDate=${new Date(storePerformanceDateRange.yesterday.end_date)}`);
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });

            if (response && response.data && response.data.result) {

                const { results, totalCount } = response?.data?.result
                setEventlogList(results ? results : [])
                setTotalEntries(totalCount ? totalCount : 0);
                setPageCount(Math.ceil(totalCount / limit));
            } else {
                setEventlogList([]);
                console.error("Failed to fetch data");
            }
            dispatch({ type: 'loader_hide', response: true });
        }
    };
    const handleEnterKeyPressSearch = async (e) => {
        if (e.key === 'Enter') {
            setPage(1);
            dispatch({ type: 'loader_show', response: true });
            let response = await Axios.get(`/api/event-log?page=${page}&limit=${limit}&sortby=${'email'}&sort=${1}&search=${SearchValue}&filter=${ModuleValue}&filterOf=${ModuleValue === '' ? '' : 'module'}&searchemail=${SearchEmailValue}&startDate=${new Date(storePerformanceDateRange.yesterday.start_date)}&endDate=${new Date(storePerformanceDateRange.yesterday.end_date)}`);
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });

            if (response && response.data && response.data.result) {

                const { results, totalCount } = response?.data?.result
                setEventlogList(results ? results : [])
                setTotalEntries(totalCount ? totalCount : 0);
                setPageCount(Math.ceil(totalCount / limit));

            } else {
                setEventlogList([]);
                console.error("Failed to fetch data");
            }
            dispatch({ type: 'loader_hide', response: true });
        }
    };

    const parameteropennn = (parameter) => {
        const parameterObj = JSON.parse(parameter);
        const entries = Object.entries(parameterObj);
        setparameterentries(entries)
        setparameteropen(true)

    }

    const arrayToCsv = (dataArray) => {
        const header = Object.keys(dataArray[0]).join(',');
        const rows = dataArray.map(obj => Object.values(obj).join(',')).join('\n');
        return `${header}\n${rows}`;
    };

    const downloadCsv = (dataArray, fileName) => {
        const csvString = arrayToCsv(dataArray);
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const handleDownload = () => {
        const data = EventlogList.map((e) => ({
            "Email": e.user.email,
            "Module": e.module,
            "Event_Type": e.event_type,
            "Value": e.value,
            "Parameters": e.parameter,
            "IP": e.ip || '',
            "Subscription": e.subscription || '',
            "On-date": moment(e.createdAt).format('DD-MM-YYYY hh:mm:ss a')
        }));
        downloadCsv(data, 'users-event-logs.csv');
    };
    const handleDateRange = (name, filter) => {
        let dateRangeFilter = { ...storePerformanceDateRange };
        dateRangeFilter[name] = filter;
        setStorePerformanceDateRange(dateRangeFilter);
    };

    const handleApply = (event, picker) => {
        picker.element.val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
        setDateRange({ start_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') })
        handleDateRange("yesterday", { start_date: picker.startDate.toISOString(), end_date: picker.endDate.toISOString() })
    };

    return (
        <div>
            <Card>
                <Card.Body>
                    <Card.Title>
                        <div className="row">
                            <div className='d-flex'>
                                <div className="col-lg-2">
                                    {t("eventLog.filter_by_module")}
                                </div>
                                <div className="dropdown product-rating-menu">
                                    <div className="dropdown">
                                        <button className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle">{ModuleValueReal}</button>
                                        <div className="dropdown-menu">
                                            {moduleList.map((value, index) => (
                                                <div key={index} className="dropdown-item"><option className="limit" value={value.name} onClick={(e) => { setModuleValue(value.id); setRealModuleValue(e.target.value) }} >{value.name}</option></div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    {t("eventLog.filter_by_user")}
                                </div>
                                <Form.Control className="w-auto form-control-sm" onKeyPress={handleEnterKeyPress} value={SearchEmailValue} onChange={(e) => handlsearchEmailvalue(e)} placeholder={t("eventLog.enter_emailid")} />
                                <Col className="text-right">
                                    <DateRangePicker onApply={handleApply} initialSettings={{
                                        startDate: moment(new Date(dateRange.start_date)).format('MM/DD/YYYY'), endDate: moment(new Date(dateRange.end_date)).format('MM/DD/YYYY'),
                                        ranges: {
                                            'Today': [moment().toDate(), moment().toDate()],
                                            'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                                            'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                                            'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
                                            'This Month': [moment().startOf('month').toDate(), moment().toDate()],
                                            'Last Month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                                            'Last 3 Month': [moment().subtract(3, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                                            'Last 6 Month': [moment().subtract(6, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                                        },
                                    }}>
                                        <input type="text" className="form-control w-auto form-control-sm input-daterange-timepicker pull-right" />
                                    </DateRangePicker>
                                </Col>
                            </div>
                            <div className='d-flex'>
                                <div className="dropdown col-lg-2">
                                    <button className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle">{limit}</button>
                                    <div className="dropdown-menu">
                                        <div className="dropdown-item"><option className="limit" value='10' onClick={onClickSetLimit}>10</option></div>
                                        <div className="dropdown-item"><option className="limit" value='25' onClick={onClickSetLimit}>25</option></div>
                                        <div className="dropdown-item"><option className="limit" value='50' onClick={onClickSetLimit}>50</option></div>
                                        <div className="dropdown-item"><option className="limit" value='100' onClick={onClickSetLimit} >100</option></div>
                                    </div>
                                </div>
                                <Button onClick={handleDownload} className="w-auto btn-xs mr-2 "> CSV</Button>
                                <Form.Control className="w-auto form-control-sm " onKeyPress={handleEnterKeyPressSearch} value={SearchValue} onChange={(e) => handlsearchvalue(e)} placeholder={t("eventLog.search")} />
                            </div>
                        </div>
                    </Card.Title>
                    <Table responsive className="display w-100 dataTable">
                        <thead>
                            <tr role="row">
                                <th className={`text-center sorting ${(sort.email === 1 ? "sorting-asc" : '') || (sort.email === -1 ? "sorting-desc" : '')}`} onClick={(e) => { handleSort(e) }} id="email">{t("eventLog.email")} &nbsp;<span id="email" /></th>
                                <th className={`text-center sorting ${(sort.module === 1 ? "sorting-asc" : '') || (sort.module === -1 ? "sorting-desc" : '')}`} onClick={(e) => { handleSort(e) }} id="module">{t("eventLog.module")} &nbsp;<span id="module" /></th>
                                <th className={`text-center sorting ${(sort.event_type === 1 ? "sorting-asc" : '') || (sort.event_type === -1 ? "sorting-desc" : '')}`} onClick={(e) => { handleSort(e) }} id="event_type">{t("eventLog.event_type")} &nbsp;<span id="event_type" /></th>
                                <th className={`text-center sorting ${(sort.value === 1 ? "sorting-asc" : '') || (sort.value === -1 ? "sorting-desc" : '')}`} onClick={(e) => { handleSort(e) }} id="value">{t("eventLog.event_value")} &nbsp;<span id="value" /></th>
                                <th onClick={(e) => { handleSort(e) }} id="parameters">Parameters &nbsp;<span id="parameters" /></th>
                                <th className={`text-center sorting ${(sort.ip === 1 ? "sorting-asc" : '') || (sort.ip === -1 ? "sorting-desc" : '')}`} onClick={(e) => { handleSort(e) }} id="ip">{t("eventLog.ip")} &nbsp;<span id="ip" /></th>
                                <th className={`text-center sorting ${(sort.subscription === 1 ? "sorting-asc" : '') || (sort.subscription === -1 ? "sorting-desc" : '')}`} onClick={(e) => { handleSort(e) }} id="subscription">{t("eventLog.subscription")} &nbsp;<span id="subscription" /></th>
                                <th className={`text-center sorting ${(sort.createdAt === 1 ? "sorting-asc" : '') || (sort.createdAt === -1 ? "sorting-desc" : '')}`} onClick={(e) => { handleSort(e) }} id="createdAt">{t("eventLog.on_date")} &nbsp;<span id="createdAt" /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {EventlogList.map((data, i) => (
                                <tr style={{ height: "3.5rem" }} key={i}>
                                    <td>{data?.user?.email}</td>
                                    <td>{data.module}</td>
                                    <td>{data.event_type}</td>
                                    <td>{data.value}</td>
                                    <td><Button variant="outline-primary" className="btn btn-outline-primary btn-xs"
                                        onClick={() => parameteropennn(data.parameter)}>
                                        <i className="fa fa-lg fa-eye"></i>
                                    </Button>
                                    </td>
                                    <td>{data.ip}</td>
                                    <td>{data?.subscription || '-'}</td>
                                    <td>{moment(data.createdAt).format('DD-MM-YYYY hh:mm:ss a')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div className="dataTables_info">
                            {t('common.showing')} {totalEntries ? (((page - 1) * limit) + 1) : 0} {t('common.to')}{" "}
                            {Math.min(page * limit, totalEntries) || 0} {t('common.of')} {totalEntries} {t('common.entries')}
                        </div>
                        <div className="dataTables_paginate paging_simple_numbers">
                            <ReactPaginate
                                nextLabel="Next >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={1}
                                marginPagesDisplayed={1}
                                pageCount={pageCount}
                                previousLabel="< Previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                                forcePage={page - 1}
                            />
                        </div>
                    </div>

                </Card.Body>
            </Card>
            <Modal show={parameteropen} onHide={() => setparameteropen(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Event Log Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-0'>
                    <Card>
                        <Card.Body>
                            <Table responsive className="display w-100 dataTable">
                                <thead>
                                    <tr role="row">
                                        <th>Property &nbsp;<span id="email" /></th>
                                        <th>Value &nbsp;<span id="email" /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {parameterentries.map(([key, value]) => (
                                        <tr key={key}>
                                            <td>{key}</td>
                                            <td>{typeof value === 'object' ? JSON.stringify(value) : value}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default EventLog