import React, { useContext, useEffect, useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import { Context } from '../../../MasterLayout';
import Axios from '../../plugins/axios';

function UserAnswerList() {
    const location = useLocation();
    const { state } = location;
    const { dispatch } = useContext(Context);
    const [QuestionAndAnswers, setQuestionAndAnswers] = useState([]);

    async function fetchData() {
        try {
            dispatch({ type: 'loader_show', response: true });

            const response = await Axios.get(`/api/question-answer?userid=${state.user._id}`)
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });
            if (response && response.status === 200 && response.data.result) {
                response.data.result.forEach(obj => {
                    if (obj.question.id == process.env.REACT_APP_QUE_ID) {
                        try {
                            obj.answer = JSON.parse(obj.answer);
                        } catch (error) {
                            console.error("Error parsing JSON answer:", error);
                        }
                    }
                });
                setQuestionAndAnswers(response.data.result);
            }
        } catch (error) {
            console.error(error);
        }
        dispatch({ type: 'loader_hide', response: true });
    }

    useEffect(() => {
        fetchData();
    }, []);

    function VideoPlayer({ src }) {
        return (
            <Card className="video-card">
                <div className="video-player">
                    <div className="section d-flex justify-content-center embed-responsive embed-responsive-16by9">
                        <video className="embed-responsive-item" controls loop muted>
                            <source src={src} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </Card>
        );
    }

    return (
        <div className=' d-flex'>
            <h1 className='w-30'>Answered by {state?.user?.name}</h1>
            <div className='col-lg-5'>
                <Card>
                    <Card.Body>
                        <Form>
                            {QuestionAndAnswers.length > 0 && QuestionAndAnswers.map((data, i) => (
                                <Form.Group key={i} className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>{data?.question?.question}</Form.Label>
                                    {process.env.REACT_APP_QUE_ID == data?.question?.id ? <div className='d-grid'>
                                        <div className='row'>
                                            {typeof data.answer === 'object' && Object.keys(data.answer).some(key => ['0', '1', '2'].includes(key)) && [0, 1, 2].map((key) => (data.answer.hasOwnProperty(key.toString()) && <div key={key} className='col-lg-4'>
                                                <VideoPlayer src={data.answer[key.toString()]} />
                                            </div>
                                            ))}
                                        </div>
                                    </div> : <Form.Control size='sm' value={data?.answer} type="text" disabled />}
                                </Form.Group>
                            ))}
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}

export default UserAnswerList
