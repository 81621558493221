import Axios from "../../plugins/axios";
import React, { useState, useEffect, useContext } from 'react';
import { Button, Card, Form, Spinner, Table } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import ReactPaginate from "react-paginate";
import moment from 'moment';
import { Context } from '../../../MasterLayout';

const ExportModule = () => {
    const sort = "updatedAt";
    const limit = 10;
    const { t } = useTranslation();
    const { dispatch } = useContext(Context);
    const [page, setPage] = useState(1);
    const [items, setItems] = useState([]);
    const [sorting, setSorting] = useState({ sort: sort, sort_type: -1 });
    const [recordsCount, setRecordsCount] = useState(0);
    const [reinit, setReinit] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [type, setType] = useState();
    const typeList = [{ label: 'Product', value: 'Product' }, { label: 'Product Search', value: 'Product Search' }, { label: 'Product Video', value: 'Product Video' }, { label: 'Influencer', value: 'Influencer' }, { label: 'Influencers Search', value: 'Influencers Search' }, { label: 'Shop', value: 'Shop' }, { label: 'Shop Search', value: 'Shop Search' },];
    const tableHead = [
        { title: t('export.oprate'), className: 'text-left' },
        { title: t('export.type'), className: 'text-left' },
        { title: t('export.data_source'), className: 'text-left' },
        { title: t('export.country'), className: 'text-left' },
        { title: t('export.file_name'), className: 'text-left sorting' },
        { title: t('export.status'), className: 'text-left' },
        { title: t('export.time'), className: 'text-left' },
    ];

    useEffect(() => {
        getExports(1);
    }, [sorting, type]);

    const getExports = async (page) => {
        try {
            let params = {
                page: page,
                limit: limit,
                sort: sorting.sort,
                sort_type: sorting.sort_type,
            };
            if (type) {
                params['type'] = type
            }
            let queryString = '';
            for (let key in params) {
                if (params.hasOwnProperty(key)) {
                    if (queryString.length > 0) {
                        queryString += '&';
                    }
                    queryString += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
                }
            }
            dispatch({ type: 'loader_show', response: true });
            const response = await Axios.get(`/api/export?${queryString}`);
            if (response && response.status === 200 && response.data.data) {
                const responseData = response.data;
                setItems(responseData.data.results);
                setRecordsCount(responseData.data.recordsCount)
                setPageCount(responseData.data.totalPages)
                setPage(responseData.data.currentPage)
            }
            dispatch({ type: 'loader_hide', response: true });
        } catch (error) {

        }
    }
    const handleSort = (sort, sortType) => {
        if (sort !== sorting.sort || (sortType && sortType !== sorting.sort_type)) {
            setSorting({ sort, sort_type: sortType });
        }
    };
    const handlePageClick = (event) => {
        setPage(event.selected + 1);
        setReinit(reinit + 1);
        getExports(event.selected + 1);
    };

    const getSortClass = (sort) => {
        if (sort === sorting.sort) {
            return sorting.sort_type === -1 ? 'sorting-desc' : 'sorting-asc';
        }
        return '';
    };
    const countries = { ...global.config.region, ...global.config.countries };

    return (
        <>
            <Card>
                <Card.Body>
                    <Card.Title>
                        <div className="dropdown product-rating-menu d-flex justify-content-end align-items-center" style={{ width: '150px' }}>
                            <Form.Select className="form-control form-control-sm w-100" key={reinit}
                                defaultValue={type}
                                onChange={(e) => { setType(e.target.value); setReinit(reinit + 1) }}
                            >
                                <option value=''>{t('export.select_type')}</option>
                                {typeList.map((v, i) => { return <option key={i} value={v.value}>{v.label}</option> })}
                            </Form.Select>
                        </div>
                    </Card.Title>
                    <Table responsive className="display w-100 dataTable">
                        <thead>
                            <tr role="row">
                                {tableHead.map((v, i) => (
                                    <th key={i} className={(v.className || '') + ' ' + getSortClass(v.sort)} onClick={() => { v.sort && handleSort(v.sort, sorting.sort === v.sort ? -sorting.sort_type : 1) }}>
                                        {v.title}
                                        {v.sort && sorting.sort === v.sort && (
                                            <span className={`sorting-icon ${sorting.sort_type === -1 ? 'desc' : 'asc'}`} />
                                        )}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {items.length > 0 && items.map((item, index) =>
                                <tr key={index}>
                                    <td>
                                        {item.file_name
                                            ?
                                            <Button variant="outline-dark" className="px-2 py-1 btn btn-outline-dark btn-xs" onClick={() => window.open(`https://${process.env.REACT_APP_S3BUCKET_NAME}.s3.amazonaws.com/${item.file_name}`, '_blank')}><i className="fa fa-lg fa-download"></i></Button>
                                            :
                                            <Button variant="outline-dark" className="px-2 py-1 btn btn-outline-dark btn-xs">
                                                <Spinner animation="border" size="sm" role="status"></Spinner>
                                            </Button>
                                        }
                                    </td>
                                    <td className="text-left"><p> {item.type ? item.type : '--'}</p></td>
                                    <td className="text-left"><p>{item.data_source ? item.data_source : '--'}</p></td>
                                    <td className='text-left'>
                                        {item?.payload?.country_code && <p className='small'>
                                            {item?.payload?.country_code && <span className={`fi fi-${item?.payload?.country_code.toLowerCase()}`}></span>}
                                            <span className={`fi`}></span>{countries[item?.payload?.country_code] || '--'}
                                        </p>}
                                    </td>
                                    <td className="text-left"><p>{item.file_name ? item.file_name.split('/')[1] : '--'}</p></td>
                                    <td className="text-left"><p>{item.status ? item.status : '--'}</p></td>
                                    <td className="text-left"><p>
                                        {item.updatedAt ? moment(new Date(item.updatedAt)).format('DD MMM, YYYY - hh:mm:ss a') : '--'}
                                    </p></td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div className="dataTables_info">
                            {recordsCount == 0 ? "No - Record Found" :
                                `Showing ${(((page - 1) * limit) + 1)} to ${Math.min(page * limit, recordsCount)} of ${recordsCount} entries`}
                        </div>
                        <div className="dataTables_paginate paging_simple_numbers">
                            <ReactPaginate
                                nextLabel="Next >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={1}
                                pageCount={pageCount}
                                forcePage={page - 1}
                                previousLabel="< Previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default ExportModule;