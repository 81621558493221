import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Form } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from 'moment';
import PlanUpgrade from './PlanUpgrade';
import { isEmpty } from '../../components/CommonComponents/isEmpty';
import Axios from '../../plugins/axios';
import { Context } from '../../../MasterLayout';
import { useTranslation } from 'react-i18next';


const AdminDashboard = () => {
    const { t } = useTranslation();
    const { dispatch } = useContext(Context);
    const [freeToUpgrade, setFreeToUpgrade] = useState([]);
    const [dateRange, setDateRange] = useState({ start_date: new Date(new Date().setDate(new Date().getDate() - 7)), end_date: new Date() });
    const [timeZone, setTimeZone] = useState(-8);
    const [reinit, setReinit] = useState(0);
    const timeZoneArr = [
        { label: t('register_finance_report.timezone1'), value: -8, key: "-08:00" },
        { label: t('register_finance_report.timezone2'), value: -4, key: "-04:00" },
        { label: t('register_finance_report.timezone3'), value: 0, key: "+00:00" }
    ];

    const handleApply = (event, picker) => {
        picker.element.val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
        setDateRange({ start_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') });
    };

    const getUpgrades = async () => {
        try {
            const timeZoneObj = timeZoneArr.filter(v => v.value == timeZone);

            dispatch({ type: 'loader_show', response: true });
            let response = await Axios.post('/api/free-to-upgrade', { start_date: dateRange.start_date, end_date: dateRange.end_date, timeZone: timeZone, timeZoneKey: timeZoneObj[0].key }).catch((e) => console.error('Axios-Catch: ' + e.message));
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });

            if (response && response.status === 200 && response.data.data) {
                setFreeToUpgrade(response.data.data);
            };
        } catch (error) {
            console.error('Error-Catch: ' + error.message);
        };
        dispatch({ type: 'loader_hide', response: true });
    };

    useEffect(() => {
        getUpgrades()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange, reinit])

    return (
        <>
            <div className="row justify-content-center p-3">
                <Col lg="6">
                    <div className="dropdown product-rating-menu  mr-1">
                        <Form.Select className="form-control form-control-sm" key={reinit} defaultValue={timeZone != '' ? timeZone : ''}
                            onChange={(e) => { setTimeZone(e.target.value); setReinit(reinit + 1) }}>
                            {timeZoneArr.map((v, i) => { return <option key={i} value={v.value}>{v.label}</option> })}
                        </Form.Select>
                    </div>
                </Col>
                <Col lg="6">
                    <DateRangePicker onApply={handleApply} initialSettings={{
                        startDate: moment(new Date(dateRange.start_date)).format('MM/DD/YYYY'), endDate: moment(new Date(dateRange.end_date)).format('MM/DD/YYYY'),
                        ranges: {
                            'Today': [moment().toDate(), moment().toDate()],
                            'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                            'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                            'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
                            'This Month': [moment().startOf('month').toDate(), moment().toDate()],
                            'Last Month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                            'Last 3 Month': [moment().subtract(3, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                            'Last 6 Month': [moment().subtract(6, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                        },
                    }}
                    >
                        <input type="text" className="form-control w-auto form-control-sm input-daterange-timepicker pull-right" />
                    </DateRangePicker>
                </Col>
            </div>
            <div className="store-performance-section">
                <Row>
                    <Col>
                        <h4 className="fw-bolder">{t('admin_dashboard.free_to_upgrade')}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4">
                        {!isEmpty(freeToUpgrade) && <PlanUpgrade data={freeToUpgrade[0]} />}
                    </Col>
                    <Col lg="4">
                        {!isEmpty(freeToUpgrade) && <PlanUpgrade data={freeToUpgrade[1]} />}
                    </Col>
                    <Col lg="4">
                        {!isEmpty(freeToUpgrade) && <PlanUpgrade data={freeToUpgrade[2]} />}
                    </Col>
                </Row>
            </div>
        </>
    )
};

export default AdminDashboard;