import React, { forwardRef, useState, useImperativeHandle } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

const SalesProductMatrix = forwardRef((props, ref) => {
   const { t } = useTranslation();

   const headList = [
      {
         title: t('product_research.product_details.date'),
         className: 'text-right sorting',
         sort: 'sort_by_date',
      },
      {
         title: t('common.price'),
         className: 'text-right',
      },
      {
         title: t('product_research.product_details.total_sales'),
         className: 'text-right sorting',
         sort: 'sort_by_history_sold',
      },
      {
         title: t('product_research.product_details.total_revenue'),
         className: 'text-right sorting',
         sort: 'sort_by_history_sold',
      },
      {
         title: t('product_research.product_details.number_of_live_broadcasts'),
         className: 'text-right sorting',
         sort: 'sort_by_live_broadcasts',
      },
   ];

   const [salesAnalysis, setSalesAnalysis] = useState({});
   const [productSalesSorting, setProductSalesSorting] = useState({ sort: 'sort_by_date', sort_type: 'ASC' });
   const productSalesSortType = productSalesSorting.sort_type == 'DESC' ? 'ASC' : 'DESC';
   const productSalesSortClass = productSalesSorting.sort_type == 'DESC' ? 'sorting-desc' : 'sorting-asc';

   useImperativeHandle(ref, () => ({
      async salesProduct(data) {
         data.sort_by_sold_count.sort(function (a, b) { return b.live_count - a.live_count });
         setSalesAnalysis(data);
      },
   }));

   const productSalesSort = async (sort, sortType) => {
      if (sort != productSalesSorting.sort || (sortType && sortType != productSalesSorting.sort_type)) {
         let obj = { ...salesAnalysis };
         if (sort == 'sort_by_date') {
            obj.sort_by_date.reverse();
         } else if (sort == 'sort_by_history_sold') {
            obj.sort_by_history_sold.reverse();
         } else if (sort == 'sort_by_live_broadcasts') {
            obj.sort_by_sold_count.reverse();
         };
         setSalesAnalysis(obj);
         setProductSalesSorting({ sort, sort_type: sortType });
      };
   };


   return (
      <div className="row mb-4">
         <div className="col-lg-12">
            <h3>{t('product_research.product_details.metrics')}</h3>
            <div className="fh-table">
               <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                     <table id="example" className="display w-100 dataTable">
                        <thead>
                           <tr role="row" className='bg-light'>
                              {headList.map((v, i) => (
                                 <th key={i} className={(v.className || '') + ' ' + (v.sort && v.sort == productSalesSorting.sort ? productSalesSortClass : '')} onClick={() => { v.sort && productSalesSort(v.sort, productSalesSorting.sort == v.sort ? productSalesSortType : 'DESC') }} dangerouslySetInnerHTML={{ __html: v.title }}></th>
                              ))}
                           </tr>
                        </thead>
                        <tbody>
                           {productSalesSorting.sort == 'sort_by_date' && salesAnalysis.sort_by_date && salesAnalysis.sort_by_date.map((value, index) => (
                              <tr key={index}>
                                 <td className="text-right">{value.statistical_day}</td>
                                 <td className="text-right">
                                    {value.price_usd}<br />
                                    {/* <span className="text-muted">{value.price && '(' + value.price + ')' || '-'}</span> */}
                                    <span className="text-muted">{value.price ? `(${value.price})` : '-'}</span>
                                 </td>
                                 <td className="text-right">
                                    {value.analyze_history_sales_count || '-'}
                                 </td>
                                 <td className="text-right">
                                    {value.history_sales_amount_usd}<br />
                                    {/* <span className="text-muted">{value.history_sales_amount && '(' + value.history_sales_amount + ')' || '-'}</span> */}
                                    <span className="text-muted">{value.history_sales_amount ? `(${value.history_sales_amount})` : '-'}</span>
                                 </td>
                                 <td className="text-right">
                                    {value.live_count}
                                 </td>
                              </tr>
                           ))}
                           {productSalesSorting.sort == 'sort_by_history_sold' && salesAnalysis.sort_by_history_sold && salesAnalysis.sort_by_history_sold.map((value, index) => (
                              <tr key={index}>
                                 <td className="text-right">{value.statistical_day}</td>
                                 <td className="text-right">
                                    {value.price_usd}<br />
                                    {/* <span className="text-muted">{value.price && '(' + value.price + ')' || '-'}</span> */}
                                    <span className="text-muted">{value.price ? `(${value.price})` : '-'}</span>
                                 </td>
                                 <td className="text-right">
                                    {value.analyze_history_sales_count || '-'}
                                 </td>
                                 <td className="text-right">
                                    {value.history_sales_amount_usd}<br />
                                    {/* <span className="text-muted">{value.history_sales_amount && '(' + value.history_sales_amount + ')' || '-'}</span> */}
                                    <span className="text-muted">{value.history_sales_amount ? `(${value.history_sales_amount})` : '-'}</span>
                                 </td>
                                 <td className="text-right">
                                    {value.live_count}
                                 </td>
                              </tr>
                           ))}
                           {productSalesSorting.sort == 'sort_by_live_broadcasts' && salesAnalysis.sort_by_sold_count && salesAnalysis.sort_by_sold_count.map((value, index) => (
                              <tr key={index}>
                                 <td className="text-right">{value.statistical_day}</td>
                                 <td className="text-right">
                                    {value.price_usd}<br />
                                    <span className="text-muted">{value.price ? `(${value.price})` : '-'}</span>
                                 </td>
                                 <td className="text-right">
                                    {value.analyze_history_sales_count || '-'}
                                 </td>
                                 <td className="text-right">
                                    {value.history_sales_amount_usd}<br />
                                    <span className="text-muted">{value.history_sales_amount ? `(${value.history_sales_amount})` : '-'}</span>
                                 </td>
                                 <td className="text-right">
                                    {value.live_count}
                                 </td>
                              </tr>
                           ))}
                        </tbody>
                     </table>
                  </div>
               </Table>
            </div>
         </div>
      </div>
   );
});

export default SalesProductMatrix;