import React, { useEffect, useState } from 'react'
import ReactApexChart from "react-apexcharts";

const SalesAnalysisChart = ({ data }) => {
    const [salesHistory, setSalesHistory] = useState({ series: [], options: {} });

    useEffect(() => {
        createChart();
    }, [data]);

    const createChart = () => {
        let growthRateArr = [], salesArr = [], conversionRateArr = [], revenueArr = [], labelsArr = [];

        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            labelsArr.push(element.date);
            growthRateArr.push((parseFloat(element.cycle_crc) * 100));
            conversionRateArr.push((parseFloat(element.conversion_rate) * 100));
            salesArr.push(element.sold_count);
            revenueArr.push(element.sales_amount);
        };

        let saleshistoryData = { ...salesHistory };
        saleshistoryData.series = [{ name: 'Growth Rate', type: 'line', data: growthRateArr }, { name: 'Conversion Rate', type: 'line', data: conversionRateArr }, { name: 'Sales', type: 'line', data: salesArr }, { name: 'Revenue', type: 'line', data: revenueArr }];
        saleshistoryData.options = {
            chart: {
                height: 350,
                type: 'line',

            },
            stroke: {
                curve: 'smooth'
            },
            fill: {
                type: 'solid',
                opacity: [1, 0, 1, 0],
            },
            labels: labelsArr,
            markers: {
                size: 0
            },
            yaxis: [
                {
                    labels: {
                        formatter: function (value) {
                            return parseFloat(value).toFixed(2) + "%";
                        }
                    },
                },
                {
                    labels: {
                        show: false,
                        formatter: function (value) {
                            return parseFloat(value).toFixed(2) + "%";
                        }
                    },
                },
                {
                    opposite: true,
                    labels: {
                        formatter: function (value) {
                            return parseFloat(value);
                        }
                    },
                },
                {
                    opposite: true,
                    labels: {
                        show: false,
                        formatter: function (value) {
                            if (value >= 10 ** 3 && value < 10 ** 6) {
                                value = (value / 1000).toFixed(1) + ' K'
                            } else if (value >= 10 ** 6) {
                                value = (value / 1000000).toFixed(1) + ' M'
                            } else {
                                // eslint-disable-next-line no-self-assign
                                value = value;
                            }
                            return '$ ' + value
                        }
                    },
                },
            ],
            tooltip: {
                shared: true,
                intersect: false,
                hideEmptySeries: false
            }
        };

        setSalesHistory(saleshistoryData);
    };
    return (
        <>
            <div className="col-lg-12" style={{ "height": "400px" }}>
                <ReactApexChart options={salesHistory.options} series={salesHistory.series} type="line" height={350} />
            </div>
        </>
    );
};

export default SalesAnalysisChart;